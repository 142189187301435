import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import defaultImage from 'images/defaultCoverImage.svg';

import { StyledSpecial, Subtitle, Title, Paragraph } from './style';

function Special({ data }) {
    const { image, title, summary, main, entityType, slug } = data;

    return (
        <StyledSpecial>
            <Link id='gtm-murmuration-item-special' to={`/${entityType}/${slug}`}>
                <div>
                    <img src={image || defaultImage} alt="" />
                </div>
                
                <div>
                    <Subtitle className='font-italic'>Special Feature</Subtitle>
                    <Title className='font-uppercase'>{title}</Title>
                    <Paragraph>
                        {ReactHtmlParser(summary || main)}
                    </Paragraph>
                </div>
            </Link>
        </StyledSpecial>
    )
}
export default Special