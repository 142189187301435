import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';

import ApiService from '../../../services/api.service';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import { ReactComponent as SpiracleLogoSmall } from '../../../images/Spiral.svg';

export default class ForgotPassword extends Component {
    state = {
        recoverPassword: false,
        forgotPasswordError: false,
    };

    // componentDidMount() {
    //     if (localStorage.getItem("token") == null) {
    //         this.props.history.push("/access-denied");
    //     }
    // }

    /**
     * Handle forgot password form
     */
    submitForgotPasswordForm = (values, callbacks) => {
        this.setState(
            {
                forgotPasswordError: false,
            },
            () => {
                const data = {
                    email: values.forgotPasswordEmail,
                };

                const { setSubmitting } = callbacks;

                ApiService.forgotPassword(data)
                    .then((r) => {
                        this.setState(
                            {
                                recoverPassword: true,
                            },
                            () => {
                                setSubmitting(false);
                            }
                        );
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            forgotPasswordError: true,
                        });
                    });
            }
        );
    };

    render() {
        //** FORMIK FORGOT PASSWORD */
        const forgotPasswordValidationSchema = Yup.object().shape({
            forgotPasswordEmail: Yup.string().email('Email must be valid').required('Email is required'),
        });

        const forgotPasswordInitialValues = {
            forgotPasswordEmail: '',
        };
        //** FORMIK FORGOT PASSWORD END */

        return (
            <div className='c-forgot-password container container__basic-page c-homepage c-homepage--main-content'>
                <PageMeta>
                    <title>Forgot Password | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                {!this.state.recoverPassword ? (
                    <>
                        <h1 className='font-secondary font-400 mb-30 text-center letter-spacing-15'>RECOVER YOUR PASSWORD</h1>
                        <h4 className='font-italic text-center font-400 margin-reset'>Don’t worry, it happens to the best of us</h4>
                        <div className='h-flex h-flex-center'>
                            <div className='full-width'>
                                <Formik initialValues={forgotPasswordInitialValues} validateOnMount={true} validationSchema={forgotPasswordValidationSchema} onSubmit={this.submitForgotPasswordForm}>
                                    {(form) => {
                                        return (
                                            <Form className='c-form'>
                                                <div>
                                                    <Field
                                                        className={`bg-white ${form.errors && form.errors.forgotPasswordEmail && form.touched && form.touched.forgotPasswordEmail ? 'invalid' : ''}`}
                                                        placeholder='Your email'
                                                        type='email'
                                                        name='forgotPasswordEmail'
                                                        required
                                                        id='forgotPasswordEmail'
                                                        onFocus={() => this.setState({ forgotPasswordError: false })}
                                                    />
                                                </div>
                                                <div className='c-error-message mt-5'>
                                                    <ErrorMessage name='forgotPasswordEmail' component='div' />
                                                    {this.state.forgotPasswordError ? <div>Invalid email</div> : null}
                                                </div>
                                                <div className='mt-25'>
                                                    <button
                                                        id='gtm-send-me-a-recovery-link'
                                                        className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${
                                                            form.isSubmitting ? 'submitting' : ''
                                                        }`}
                                                        type='submit'
                                                        disabled={form.isSubmitting || !form.isValid}>
                                                        Send me a recovery link
                                                        <SpiracleLogoSmall />
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className='text-center font-italic font-18'>Please check your email for recovery password link.</p>
                    </>
                )}
            </div>
        );
    }
}
