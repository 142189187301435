import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import ApiService from 'services/api.service';

import AudioBookSingleFilter from 'components/UI/AudioBook/AudioBookSingleFilter';

import { Filters, StyledChip, StyledChipHolder } from 'pages/AudioBooks/style';
import { Heading } from './style';

function StyleFilters() {
    const [styles, setStyles] = useState([]);
    const [activeStyles, setActiveStyles] = useState([]);
    const [stylesFilter, setStylesFilter] = useState([]);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        /**
         * Get styles filter
         */
        const pageURLSearchParams = new URLSearchParams()
        pageURLSearchParams.append('itemsPerPage', 200);

        ApiService.styles(pageURLSearchParams).then((r) => {
            setStyles(r.data["hydra:member"].map(item => {
                return {
                    title: item.title,
                    id: item.id
                }
            }));
        });
    }, [])

    useEffect(() => {
        /**
         * Get filters from URL
         */
        const searchParams = new URLSearchParams(location.search);
        const stylesUrl = searchParams.get('styles[]') ? searchParams.get('styles[]').split(',').map(item => {return parseInt(item, 10)}) : [];
        setStylesFilter(stylesUrl)

        //fill state and active state with style 
        if (searchParams.get('styles[]')) {
            const actStyles = [];
            searchParams.get('styles[]').split(',').forEach(id => {
                let filteredCat = styles.filter(cat => cat.id === parseInt(id))
                let styleId = filteredCat[0]?.id;

                if (styleId) {
                    actStyles.push(styleId)
                    setActiveStyles(actStyles)
                }
            })
        } 
    }, [location.search, styles])

    /**
     * API call for audiobooks with filters
     */  
    const filterApi = (arr) => {
        const searchParams = new URLSearchParams();

        if (arr.length) {
            searchParams.append('styles[]', arr);
        }

        history.replace(`/browse?${searchParams.toString()}`)
    };

    /**
     * Handle click on filter options
     */  
    const handleFilter = (id) => {
        //if options already exists then remove it
        if (stylesFilter.includes(id)) {
            const index = stylesFilter.indexOf(id);
            const newArr = [...stylesFilter];
            newArr.splice(index, 1);

            setStylesFilter(newArr)
            filterApi(newArr)
        
        } else {
            //if options doesn't exist, push to all filters and typeFilter
            const newArr = [...stylesFilter];
            newArr.push(id);
    
            setStylesFilter(newArr)
            filterApi(newArr)
        }

        if (activeStyles.includes(id)) {
            //active state push if it doesn't exist
            const index = activeStyles.indexOf(id);
            const newArr = [...activeStyles];
            newArr.splice(index, 1);
    
            setActiveStyles(newArr)
            filterApi(newArr)
         
        } else {
            //active state push if it doesn't exist
            const newArr = [...activeStyles];
            newArr.push(id);

            setActiveStyles(newArr)
            filterApi(newArr)
        }
    };

    /**
     * Handle click on style filter
     */  
    const handleStyleFilter = (id) => {
        handleFilter(id);
    };

    let renderStyles= [];
    if (styles.length) {
        renderStyles = styles.map((style, index) => {
            
            return (
                <StyledChipHolder key={`style-${index}`}>
                    <StyledChip                    
                        className={activeStyles.includes(style.id) ? 'active' : ''}            
                        onClick={() => handleStyleFilter(style.id)}
                    >
                        {style.title}
                    </StyledChip>
                </StyledChipHolder>
            );
        });
    }

    return (
        styles.length ? 
            <div className='text-center mb-45'>
                <Filters>
                    <Heading>
                        <p className='font-14 font-uppercase font-secondary'>Explore the Spiracle world</p>
                        <h2 className='font-italic'>Browse audiobooks</h2>
                    </Heading>

                    <AudioBookSingleFilter
                        filtersId={'stylesContainer'}
                        filters={renderStyles}                    
                    />
                </Filters>
            </div> : null
    )
}
export default StyleFilters