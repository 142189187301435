import React, { Component } from "react";

import { FreeBookContainer } from './style';

class FreeBook extends Component {    
    render() {          
        return (            
           <FreeBookContainer small={this.props.small} horizontal={this.props.horizontal}>Free</FreeBookContainer>
        )
    }
}

export default FreeBook;
