import * as cartActionTypes from '../../../store/actions/cartActions';

import React, { Component } from 'react';

import AudioBookListItem from 'components/UI/AudioBook/AudioBookListItem';
import { CartItemHolder } from "components/UI/ShoppingBag/style";
import { connect } from 'react-redux';
import { withRouter } from "react-router";

class ShoppingBagItems extends Component {
    
    state = {
        inLibrary: false,
    }

    componentDidMount() {
        const cartItems = [];

        if (this.props.cart) {
            this.props.cart.map((book, index) => {
                const locations = []
                if (book.audioBook.locations && book.audioBook.locations.length) {
                    book.audioBook.locations.map((location) => {
                        return locations.push(location.title)
                    })
                }
                const tags = []
                if (book.audioBook.tags && book.audioBook.tags.length) {
                    book.audioBook.tags.map((tag) => {
                        return tags.push(tag.title)
                    })
                }
                const authors = []
                if (book.audioBook.authors && book.audioBook.authors.length) {
                    book.audioBook.authors.map((author) => {
                        return authors.push(author.fullName)
                    })
                }
                const narrators = []
                if (book.audioBook.narrators && book.audioBook.narrators.length) {
                    book.audioBook.narrators.map((narrator) => {
                        return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                    })
                }
                return cartItems.push({
                    item_id: book.audioBook.slug,
                    item_name: book.audioBook.title,
                    item_brand: book.audioBook.publisher ? book.audioBook.publisher?.name : '',
                    item_category: book.audioBook.category ? book.audioBook.category?.name : '',
                    item_locations: locations?.length ? locations.join(', ') : '',
                    item_tags: tags?.length ? tags.join(', ') : '',
                    item_price: book.audioBook.price,
                    item_author: authors?.length ? authors.join(', ') : '',
                    item_narrator: narrators?.length ? narrators.join(', ') : '',
                    item_translator: book.audioBook.translator ? `${book.audioBook.translator.firstName} ${book.audioBook.translator.lastName}` : '',
                    item_length: `${book.audioBook.lengthHours}:${book.audioBook.lengthMin}`,
                })
            })
        }
        if (window.dataLayer) {
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                event: "view_cart",
                ecommerce: {
                    currency: "GBP",
                    value: this.props.subtotal,
                    items: cartItems
                }
            });
        }
    }

    handleCheckboxChange = () => {
        this.setState ({ inLibrary: !this.state.inLibrary })
    }
    
    render() {        
        return (  
            <>       
                {this.props.cart.map((book, index) => {
                    let bookInfo                    
                    if (book.audioBook) {
                        bookInfo = book.audioBook
                    } else {
                        bookInfo = book.eBook
                    }

                    return (
                        <CartItemHolder 
                            key={`book-cart-item-holder-${book.uuid}${this.props.shoppingBag ? '-shopping-bag' : ''}`}
                            >
                            <AudioBookListItem 
                                // key={`book-list-item-${index}${this.props.shoppingBag ? '-shopping-bag' : ''}`}
                                // id={`book-list-item-${index}${this.props.shoppingBag ? '-shopping-bag' : ''}`}
                                book={book}
                                uuid={book.uuid}              
                                title={bookInfo.title}
                                authors={bookInfo.authors}
                                imgSrc={bookInfo.cover}
                                imgAlt={bookInfo.coverAlt}
                                price={bookInfo.price}
                                subscriptionPrice={bookInfo.subscriptionPrice}
                                slug={bookInfo.slug}
                                link={`/${book.audioBook ? 'audiobooks' : 'ebooks'}/${bookInfo.slug}`}
                                eBook={bookInfo.eBook}
                                isEdition={bookInfo.edition}
                                bookBgColor={bookInfo.bookBackgroundColour}
                                containerBgColor={bookInfo.containerBackgroundColour}
                                textColor={bookInfo.textColour}
                                type={book.audioBook ? 'audiobook' : 'ebooks'}
                                geoBlocked={bookInfo.geoBlocked}
                                inLibrary={bookInfo.inLibrary}                                                                                                         
                                horizontal
                                index={index}
                                mobileOnly
                                shoppingBag={this.props.shoppingBag}
                                cartItemsList={this.props.cartItemsList}
                                disabled={bookInfo.geoBlocked}
                                onClick={this.props.onClick}
                            />  
                        </CartItemHolder>
                    );
                })}          
            </>         
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart
})

const mapDispatchToProps = dispatch => {
    return {
        onRemoveCartItem: (uuid) => dispatch(cartActionTypes.onRemoveCartItem(uuid))
    }
  }

const shoppingBagItemsWithRouter = withRouter(ShoppingBagItems);

export default connect(mapStateToProps, mapDispatchToProps)(shoppingBagItemsWithRouter);