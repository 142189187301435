import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import img from '../../../images/audiobook-in-a-card.png'

import { StyledBanner, Dash } from './style';

function Banner({className}) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        document.body.classList.add('show-banner');
    }, [])

    const handleHide = () => {
        setShow(false);
        document.body.classList.remove('show-banner');
    }
    return (
        show ?
            <StyledBanner className={`${className} font-18`}>
                <img src={img} alt="Audiobook in a card" />
                <span className='font-italic mt-6'>Audiobook in a Card</span>
                <Dash className='ml-5 mr-5'>-</Dash>
                <span className='font-secondary mr-5'>Now available in select bookshops.</span>
                <Link id='gtm-banner-learn-more' to='/info/audiobook-in-a-card' className='font-secondary'>Learn more</Link>
                <i id='gtm-close-banner' className='icon-close font-22' onClick={handleHide}></i>
            </StyledBanner> : null
    )
}
export default Banner