import * as actionTypes from '../actions/orderActions';

const initialState = {
    order: null
}

const orderReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.SET_ORDER:
            return { 
                ...state,
                order: action.order
            };
        default:
            return state;
    }
}

export default orderReducer;