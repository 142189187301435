export const GET_CART_ITEMS = 'GET_CART_ITEMS';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const CLEAR_CART = 'CLEAR_CART';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_CHECKBOX = 'VALIDATE_CHEKBOX';
export const CLEAR_VALIDATION = 'CLEAR_VALIDATION';
export const REMOVE_CHECKBOX = 'REMOVE_CHECKBOX';
export const REMOVE_EMAIL = 'REMOVE_EMAIL';

export const onRemoveEmail = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_EMAIL
        })
    }
}

export const onRemoveCheckbox = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_CHECKBOX
        })
    }
}

export const onClearValidation = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_VALIDATION
        })
    }
}

export const onValidateCheckbox = (payload) => {
    return dispatch => {
        dispatch ({
            type: VALIDATE_CHECKBOX,
            valid: payload
        })
    }
}

export const onValidateEmail = (valid, index) => {
    return dispatch => {
        dispatch ({
            type: VALIDATE_EMAIL,
            valid: valid,
            index: index
        })
    }
}

export const onGetCartItems = (payload) => {
    return dispatch => {
        dispatch({ 
            type: GET_CART_ITEMS,
            cartItems: payload 
        })
    }
}

export const onRemoveCartItem = (payload) => {
    return dispatch => {
        dispatch({ 
            type: REMOVE_CART_ITEM,
            uuid: payload 
        })
    }
}

export const onAddCartItem = (payload) => {
    return dispatch => {
        dispatch({ 
            type: ADD_CART_ITEM,
            cartItem: payload 
        })
    }
}

export const onClearCart = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_CART
        })
    }
}