import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ApiService from 'services/api.service';

import Intro from 'components/UI/BrowsePage/Intro/Intro';
import StyleFilters from 'components/UI/BrowsePage/Filters/Filters';
import DownloadApp from 'shared/DownloadApp/DownloadApp';
import AudioBookSingleCarousel from 'components/UI/AudiobookSingle/AudioBookSingleCarousel';
import FeaturedPublishers from 'components/UI/BrowsePage/FeaturedPublishers/FeaturedPublishers';
import PageMeta from 'shared/PageMeta/PageMeta';
import Loader from 'shared/Loader/Loader';

import { StyledBrowse } from './style';

function Browse() {
	const [editions, setEditions] = useState([]);
	const [audioBooks, setAudioBooks] = useState([]);
	const [secondEditions, setSecondEditions] = useState([]);

	const [isLoadingEditions, setIsLoadingEditions] = useState(true);
	const [isLoadingAudioBooks, setIsLoadingAudioBooks] = useState(true);
	const [isLoadingSecondEditions, setIsLoadingSecondEditions] = useState(true);

	const [allCategoriesAudioBooks, setAllCategoriesAudioBooks] = useState([]);

	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const stylesUrl = searchParams.get('styles[]') ? searchParams.get('styles[]').split(',').map(item => {return parseInt(item, 10)}) : [];

		const params = new URLSearchParams();
		params.append('itemsPerPage', 12);
		if (stylesUrl.length) {
			params.append('styles[]', stylesUrl);
		}

		const paramsString = new URLSearchParams();
		paramsString.append('itemsPerPage', 12);
		if (stylesUrl.length) {
			paramsString.append('styles', stylesUrl);
		}
		
		const pageURLSearchParams = new URLSearchParams()
		pageURLSearchParams.append('itemsPerPage', 200);

		setIsLoadingEditions(true);
		ApiService.getAudioBookEdition(paramsString).then(r => {
			const modArr = r.data['hydra:member'].map(item => item.audioBook)
			setEditions(modArr);
			setIsLoadingEditions(false);
		}).catch(e => {
			setIsLoadingEditions(false);
		})

		setIsLoadingAudioBooks(true)
		ApiService.audioBooks(params).then(r => {
			setAudioBooks(r.data['hydra:member']);
			setIsLoadingAudioBooks(false);
		}).catch(e => {
			setIsLoadingAudioBooks(false);
		})

		let stringParamsSecondEdition = '?itemsPerPage=12';
		if (searchParams.get('styles[]')) {
			searchParams.get('styles[]').split(',').forEach((item) => {
				const id = '&style.id%5B%5D=' + item;
				stringParamsSecondEdition = stringParamsSecondEdition + id;
			});
		}
		setIsLoadingSecondEditions(true)
		ApiService.getSecondEditionsStringParams(stringParamsSecondEdition).then(r => {
			setSecondEditions(r.data['hydra:member']);
			setIsLoadingSecondEditions(false);
		}).catch(e => {
			setIsLoadingSecondEditions(false);
		})

		ApiService.categories(pageURLSearchParams).then((r) => {
			setAllCategoriesAudioBooks([])

			r.data['hydra:member'].forEach((item, index) => {
				if (item.visible) {
					ApiService.getAudioBooksWithCategory(item.id, params).then(r => {
						if (r.data['hydra:member'].length) {
							setAllCategoriesAudioBooks(prevState => {
								return prevState.concat({
									[r.data['hydra:member'][0].category.name]: {weight: item.weight, data: r.data['hydra:member']}
								})
							})
						}
					}).catch(e => {
						console.log('e', e)
					})
				}
			})
		});

	}, [location.search])

	const sortedList = allCategoriesAudioBooks.sort((a, b) => Object.values(a).weight - Object.values(b).weight);

	return (
		<>
			<PageMeta>
                <title>Spiracle | Free Audiobooks</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

			<StyledBrowse>
				<Intro />

				<StyleFilters />

				{
					isLoadingEditions || 
					isLoadingAudioBooks || 
					isLoadingSecondEditions ? 
						<Loader /> :
						<>
							<AudioBookSingleCarousel 
								data={editions.slice(0, 8)} 
								title={<>Spiracle <span className='font-italic'>editions</span></>}
								gtmTitle='spiracle-editions'
								link='/editions'
							/>

							<AudioBookSingleCarousel 
								data={audioBooks.slice(0, 8)} 
								title={<>Spiracle <span className='font-italic'>Collection</span></>}
								gtmTitle='spiracle-collection'
								link='/audiobooks'
							/>

							<AudioBookSingleCarousel 
								data={secondEditions.slice(0, 8)} 
								title={<>Current <span className='font-italic'>Highlights</span></>}
								gtmTitle='current-highlights'
								link='/subscriber-selection'
							/>
							{
								sortedList.map((item, index) => {
									return 	<AudioBookSingleCarousel
												key={`item-${index}`} 
												data={Object.values(item)[0].data.slice(0, 8)} 
												title={<span className='font-italic'>{Object.keys(item)}</span>}
												gtmTitle={Object.keys(item)}
												link={`/audiobooks?page=1&itemsPerPage=12&categories%5B%5D=${Object.values(item)[0].data[0].category.id}`}
											/>
								})
							}
						</>
				}

				<FeaturedPublishers />

				<DownloadApp />
			</StyledBrowse>
		</>
	)
}

export default Browse