import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import Grid from "@material-ui/core/Grid";

import ApiService from '../../services/api.service';
import PageMeta from '../../shared/PageMeta/PageMeta'
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import TitleTextBlock from 'shared/PageElements/TitleTextBlock';
import SkeletonPublisher from '../../components/UI/Skeleton/SkeletonPublisher/SkeletonPublisher';
import { PublisherHolder } from "pages/Publisher/style";

class Publisher extends Component {
    state = {
        _page: 1,
        itemsPerPage: 12,
        loadButton: false,
        moreAudiobooks: false, 
        publisher: {},
        audiobooks: null,
        isLoading: true,
    }

    componentDidMount() {
        this.getPublisher()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                isLoading: true,
                _page: 1
            })

            this.getPublisher()
        }
    }

    getPublisher = () => {
         ApiService.getPublisherBySlug(this.props.match.params.slug).then(r => {
            this.setState({
                publisher: r.data,
            }, () => {
                this.getPublisherAudioBooks()
            })
        }).catch(err => {
            this.props.history.push('/404');
            this.setState({
                isLoading: false
            })
        })
    }

    handleLoadMoreAudiobooks = () => {
        this.setState({
            _page: this.state._page + 1,
            loadButton: true,
        }, () => {
            this.getPublisherAudioBooks(true)
        })
    }

    getPublisherAudioBooks = (newPage) => {
        const params = new URLSearchParams();

        params.append('page', this.state._page);
        params.append('itemsPerPage', this.state.itemsPerPage);

        this.props.history.replace(`/publishers/${this.props.match.params.slug}?${params.toString()}`)

        ApiService.getPublisherAudioBooks(this.props.match.params.slug, params).then(r => {
            if (r.data['hydra:member'].length) {
                this.setState({
                    audiobooks: newPage ? [...this.state.audiobooks, ...r.data['hydra:member']] : r.data['hydra:member'],
                    loadButton: false,
                    isLoading: false,
                }, () => {
                    if (this.state.audiobooks.length < r.data['hydra:totalItems']) {
                        this.setState({
                            moreAudiobooks: true,
                        })
                    } else {
                        this.setState({
                            moreAudiobooks: false,
                        })
                    }
                })
            } else {
                this.setState({
                    moreAudiobooks: false,
                    loadButton: false,
                    isLoading: false,
                    audiobooks: null
                })
            }
        })
    }

    render() {
        return (
            <>
                <PageMeta>
                    <title>{this.state.publisher.name ? this.state.publisher.name + ' | ' : ''}Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {this.state.isLoading ? <SkeletonPublisher /> : (
                
                    <PublisherHolder>                    
                        <div className="container container__big">
                            <TitleTextBlock 
                                fontItalic
                                title={this.state.publisher.name}
                                text={ReactHtmlParser(this.state.publisher.about)}
                                type="Publisher"
                                link={this.state.publisher.name}
                                href={this.state.publisher.website}
                                className="mt-0 mb-0"
                            />  
                        </div> 
                    
                        <div className="container container__big">
                            <Grid container spacing={2} justifyContent='center'>
                                {this.state.audiobooks && this.state.audiobooks.map((book, index) => {
                                    return <Grid item xs={6} md={4} lg={3} key={`audiobook-${index}`}>
                                                <AudioBookListItem 
                                                    title={book.title}
                                                    imgSrc={book.cover}
                                                    imgAlt={book.coverAlt}
                                                    price={book.price}
                                                    subscriptionPrice={book.subscriptionPrice}
                                                    authors={book.authors}
                                                    type='audio-book'
                                                    audioBook={book.audioBook}
                                                    eBook={book.eBook}
                                                    link={`/audiobooks/${book.slug}`}
                                                />
                                            </Grid>
                                })}
                            </Grid>

                            {this.state.moreAudiobooks && (
                                <div className="text-center">
                                    <button 
                                        id='gtm-publishers-load-more'
                                        onClick={this.handleLoadMoreAudiobooks} 
                                        disabled={this.state.loadButton}
                                        className={`c-btn c-btn__ghost c-btn__pagination font-22 font-secondary font-italic mb-50 ls-2 ${this.state.loadButton ? 'submitting' : ''}`}>
                                            load more
                                            <SpiracleLogoSmall />
                                    </button>
                                </div>
                            )}
                        </div>
                    </PublisherHolder>                
                )
            }
            </>
        )
    }

}

export default Publisher;