import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import spiracleHq from '../../../../images/spiracle-hq.png';

import { StyledAboutSpiracle } from './style';

function AboutSpiracle({title}) {
    return (
        <StyledAboutSpiracle>
            <q className='font-italic'>
                {ReactHtmlParser(title)}
            </q>
            <span className='font-uppercase'>
                – The Irish Times
            </span>

            <p>
                We offer expertly curated literary fiction and non fiction audiobooks derived from writing that delights, excites, inspires, 
                is memorable and unique and which is otherwise lost, unavailable or buried in the algorithms of the big audiobook marketplaces.
            </p>

            <Link id='gtm-homepage-browse-spiracle-collection' to='/audiobooks' className='sa-button-red sa-button-rounded'>Browse <span className='font-primary'>Spiracle </span><span className='font-italic font-primary'>Collection</span></Link>

            <img src={spiracleHq} alt="Spiracle HQ" />
        </StyledAboutSpiracle>
    )
}

export default AboutSpiracle