import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Paragraph = styled.p``;

export const StyledSecondEditions = styled.div`
    ${Subtitle} {
        font-family: 'Gill Sans Nova';
        font-size: 18px;
        line-height: 43px;
        letter-spacing: 6px;
        text-transform: uppercase;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
        }
    }

    ${Title} {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 43px;
        margin-top: 5px;
        margin-bottom: 18px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
        }
    }

    ${Paragraph} {
        font-family: 'Gill Sans Nova';
        font-size: 22px;
        line-height: 37px;
        margin-bottom: 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 18px;
        }

        span {
            font-weight: 700;
        }

         a {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 1px;
        }
    }

    .c-audio-book-single-carousel-container {
        margin-top: 40px;

        & + a {
            ${(props) => props.theme.breakpoints.down('sm')} {
                width: 100% !important;
            }
        }
    }
`;

