import * as libraryActionTypes from "../../../store/actions/libraryActions";
import * as userActionTypes from "../../../store/actions/userActions";

import React, { Component } from "react";

import ApiService from "../../../services/api.service";
import { Link } from "react-router-dom";
import Loader from "../../../shared/Loader/Loader";
import PageMeta from "../../../shared/PageMeta/PageMeta";
import { connect } from "react-redux";
import confirmSuccessBooks from '../../../images/confirm-success-books.webp';

class ConfirmUser extends Component {
    state = {
        isLoading: true,
        succes: false,
        user: null,
    };

    componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) {
            this.props.history.push('/settings')
        }

        const data = {
            confirmationToken: this.props.match.params.token,
        };
        ApiService.confirmUser(data)
            .then((r) => {
                localStorage.setItem("token", r.data.token);
                this.setState(
                    {
                        succes: true,
                        user: r.data.user,
                    },
                    () => {
                        ApiService.getUser().then((r) => {
                            this.props.onSetUser(r.data);
                            const params = new URLSearchParams();
                            params.append('page', 1);
                            params.append('itemsPerPage', 10);
                            ApiService.getLibrary(params).then(
                                library => {
                                    this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                    this.setState({
                                        isLoading: false,
                                    })
                                }
                            )
                        });
                    }
                );
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                });
        });
    }

    render() {
        let content;

        if (this.state.isLoading) {
            content = <Loader />;
        } else {
            content = (
                <div className="container container__basic-page">
                    <div className="c-confirm-user">
                        <PageMeta>
                            <title>Confirm User | Spiracle</title>
                            <link rel="canonical" href={window.location.href} />
                        </PageMeta>
                        {this.state.succes ? (
                            <div className="c-confirm-user__success">
                                <p className="font-28">Hi <span className="font-uppercase">{this.state.user ? this.state.user.firstName : ""}</span></p>
                                <p className="font-28">Thank you for signing up to Spiracle. We can’t wait to introduce you to new stories.</p>

                                <div className="text-center">
                                    <img src={confirmSuccessBooks} alt="Books" />
                                </div>

                                <p className="font-28">To get started with Spiracle, you can browse the audiobook <Link id='gtm-confirm-user-collection' to="/audiobooks" className="underlined">Collection</Link> to buy individual titles.</p>
                                <p className="font-28 mb-0">You can take the plunge (and make the most of Spiracle), by becoming a subscriber. <br /> Subscribers receive</p>

                                <ol>
                                    <li>Two audiobooks from the <Link id='gtm-confirm-user-subscriber-selection' to="/subscriber-selection" className="underlined">Subscriber Selection</Link> each month 
                                    (this includes <Link id='gtm-confirm-user-editions' to="/editions" className="underlined">Spiracle Editions</Link> and a special choice of audiobooks from the Collection)</li>
                                    <li>Invitations to Spiracle Events</li>
                                    <li>Full access to all articles and podcasts on <Link id='gtm-confirm-user-murmurations' to="/murmurations" className="underlined">Murmurations</Link></li>
                                </ol>

                                <div className="text-center">
                                    <Link id='gtm-confirm-user-collection' to="/audiobooks" className="c-btn c-btn__black c-confirm-user__spiracle-member font-22">
                                        Browse the Collection
                                    </Link>
                                </div>
                                <div className="text-center">
                                    <Link id='gtm-confirm-user-subscription' to="/subscription" className="c-btn c-btn__black c-confirm-user__spiracle-member font-22">
                                        Discover the benefits of Subscription
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="c-confirm-user__error">
                                <p>We’re sorry, there seems to have been an error verifying your account. This could be because...</p>
                                <p>
                                    Please give it another try, and if the problem perists{" "}
                                    <Link to="/" className="c-confirm-user__underline underline">
                                        get in touch
                                    </Link>{" "}
                                    and our team will help you out.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user
})

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onGetLibraryItems: (libraryItems, total) => dispatch(libraryActionTypes.onGetLibraryItems(libraryItems, total)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser);
