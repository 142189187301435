import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { getNamesFromArray } from 'shared/Functions/GetNamesFromArray';

import { StyledFeaturedAudiobook, Image, Content, Subtitle, Title, Author, Paragraph } from './style';

function FeaturedAudiobook({ data }) {
    const img = data.cover;
    const title = data.title;
    const authors = getNamesFromArray(data.authors)
    const summary = data.summary;
    const price = data.price;
    const subscriptionPrice = data.subscriptionPrice;
    const entityType = data.entityType;
    const slug = data.slug;

    return (
        <StyledFeaturedAudiobook>
            <Link to={`/${entityType}/${slug}`}>
                <Image>
                    <img src={img} alt={title} />
                </Image>
            </Link>
            <Content>
                <Subtitle className='font-uppercase'>Featured</Subtitle>
                <Title className='font-italic'>{title}</Title>
                <Author className='font-italic'>{authors}</Author>
                <Paragraph>
                    {ReactHtmlParser(summary)}
                </Paragraph>

                <Link to={`/${entityType}/${slug}`}>
                    <button className='c-btn c-btn__ghost c-btn__ghost--square full-width font-20 font-300'>
                        <i className="font-16 mr-8 icon-audio-book"></i>
                        <span className="mr-3">£</span>
                        <span>{price}  <span className="price-with-subscription"> / <span className="mr-3">£</span>{subscriptionPrice} <span>for Premium Members</span></span></span>
                    </button>
                </Link>
            </Content>
        </StyledFeaturedAudiobook>
    )
}
export default FeaturedAudiobook