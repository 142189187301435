import styled from 'styled-components/macro';

export const FreeBookContainer = styled.div`
    width: max-content;
    background: #47507C;
    color: #fff;
    font-size: 16px;
    padding: 12px 10px 33px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 75%, 0 100%);
    position: absolute;
    top: -10px;
    right: 55px;
    z-index: 1;

    ${(props) => props.theme.breakpoints.down('md')} {
      ${props => {
            if (!props.small) {
                return `
                    right: 10px;
                `
            }
        }}
    }

    ${props => {
        if (props.small) {
            return `
                font-size: 12px;
                top: -6px;
                right: 6px;
            `
        }
    }}
`;