import React, { Component } from 'react';

import PageMeta from '../../shared/PageMeta/PageMeta'
import Payment from '../Ecommerce/Payment/Payment';

class PromotionXmasGift extends Component {
    state = {
        xmasPlan: this.props.location.search.includes('6monthly') ? 3 : 2
    }

    componentDidMount() {
        const isPlanExist = this.props.location.search === '?plan=6monthly' || this.props.location.search === '?plan=annually'

        if (!isPlanExist) {
            this.props.history.push('/404')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location !== this.props.location) {
            this.setState({
                xmasPlan: this.props.location.search.includes('6monthly') ? 3 : 2
            })
        }
    }

    render() {
        return (
            <>
                <PageMeta>
                    <title>Xmas Gift | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

   
                <Payment promotion xmas xmasGift xmasPlan={this.state.xmasPlan} />
            </>
        )
    }

}

export default PromotionXmasGift;