import styled  from "styled-components/macro";

export const CurrentCard = styled.div``;
export const Description = styled.span``;
export const User = styled.span``;

export const MembershipGiftButtonContainer = styled.div`
    text-align: center;

    input[type="checkbox"] {
        display: none;

        & + label {
            cursor: pointer;
            text-transform: none;
            letter-spacing: 0;
            margin: 0 0 35px;
            font-family: 'JJannon';
            font-size: 16px;

            i {
                font-size: 16px;
                margin-left: 10px;

                &.icon-checked {
                    display: none;
                }

                &.icon-not-checked {
                    display: inline-block;
                }
            }
        }

        &:checked {
            & + label {
                i {
                    &.icon-checked {
                        display: inline-block;
                    }

                    &.icon-not-checked {
                        display: none;
                    }
                }
            }
        }
    }
`

export const Title = styled.div`
    position: relative;
    font-family: 'Gill Sans Nova';
    max-width: calc(100% - 27px);
    display: flex;
    cursor: pointer;
    transition: .3s ease;

    ${(props) => props.theme.breakpoints.down("md")} {
        flex-wrap: wrap;
    }

    &:hover {
        color: #721b00;
    }

    &.show {
        .icon-arrow {
            transform: translateY(-50%) rotateZ(90deg);
        }
    }

    .icon-arrow {
        position: absolute;
        top: 50%;
        right: -27px;
        transform: translateY(-50%);
        transition: .3s ease;
    }

    ${Description} {
        min-width: max-content;
        margin-right: 8px;
    }

    ${User} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const Details = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: .3s ease;

    &.show {
        max-height: 600px;
    }

    .old-price {
        text-decoration: line-through;
    }

    ${CurrentCard} {
        letter-spacing: 0.10em;
    }
`;