import React, { useState, useEffect } from 'react';

import ApiService from 'services/api.service';

import TextCarousel from 'components/UI/TextCarousel/TextCarousel';

function FeaturedPublishers() {
    const [publishers, setPublishers] = useState([]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('itemsPerPage', 50);

        ApiService.publishers(urlSearchParams).then(r => {
            setPublishers(r.data['hydra:member']);
        });
    }, []);

    return (
        publishers?.length ?
            <div>
                <TextCarousel 
                    data={publishers} 
                    title={<span className='font-italic'>Featured Publishers</span>}
                    gtmTitle='featured-publishers'
                    link='/publishers'
                />
            </div> : null   
    )
}
export default FeaturedPublishers