import React from 'react';
import { Link } from 'react-router-dom/';
import ReactHtmlParser from 'react-html-parser';

import { StyledHowSpiracleWorks, Subtitle, Title, Content } from './style';

function HowSpiracleWorks({title}) {
    return (
        <StyledHowSpiracleWorks className='text-center'>
            <Subtitle className='font-uppercase'>Getting started</Subtitle>
            <Title className='font-italic'>{ReactHtmlParser(title)}</Title>

            <Content>
                <div>
                    <div>
                        <i className='icon-account font-22'></i>
                    </div>
                    <h3 className='font-uppercase'>Join Spiracle</h3>
                    <p>
                        Sign up for free audiobooks, articles and podcasts or become a Premium Member which includes exclusive audiobooks and special discounts. You can also gift books or membership to your loved ones.
                    </p>
                </div>
                
                <div>
                    <div>
                        <i className='icon-library font-22'></i>
                    </div>
                    <h3 className='font-uppercase'>Add to Library</h3>
                    <p>
                        Add audiobooks to your Library and enjoy unlimited access to your collection forever whether they were purchased, given to you as a gift or free.
                    </p>
                </div>

                <div>
                    <div>
                        <i className='icon-audio-book font-22'></i>
                    </div>
                    <h3 className='font-uppercase'>Listen anywhere</h3>
                    <p>
                        Whether online or through the Spiracle Player mobile app, your Library synchronises effortlessly, ensuring you can enjoy your audiobooks anytime, anywhere, with ease.
                    </p>
                </div>
            </Content>

            <Link id='gtm-homepage-find-an-audiobook' to='/audiobooks' className='sa-button-red sa-button-rounded mt-15'>Find an audiobook</Link>
        </StyledHowSpiracleWorks>
    )
}

export default HowSpiracleWorks