
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import Grid from "@material-ui/core/Grid";

import ApiService from '../../services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';


import PageMeta from "shared/PageMeta/PageMeta";
import SkeletonAudioBooks from "components/UI/Skeleton/SkeletonAudioBooks/SkeletonAudioBooks";
import TitleTextBlock from '../../shared/PageElements/TitleTextBlock';

import { EmptyPage } from "pages/AudioBooks/style";

function WritersDilemma() {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
       ApiService.getHomepagePanels().then(r => {
            const res = r.data['hydra:member'][0].writersDilemmaAudioBooks
            const title = r.data['hydra:member'][0].writersDilemmaTitle

            setData(res)
            setTitle(title)
            setIsLoading(false)
        }).catch(e => {
            setIsLoading(false)
        })
    }, [])

    let audioBooks;

    if (data.length) {
        audioBooks = data.map((book, index) => {
            return (
                <Grid item xs={6} md={4} lg={3} key={`audioBook-${index}`}>
                    <AudioBookListItem
                        title={book.title}
                        imgSrc={book.cover}
                        imgAlt={book.coverAlt}
                        price={book.price}
                        subscriptionPrice={book.subscriptionPrice}
                        authors={book.authors}
                        type='audio-book'
                        audioBook={book.audioBook}
                        eBook={book.eBook}
                        link={`/audiobooks/${book.slug}`}
                    />
                </Grid>
            );
        });
    }

    return (
        <div className="container container__big container__big--withPadding pb-10">
            <PageMeta>
                <title>Writers Dilemma | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            {isLoading ? <SkeletonAudioBooks /> : (
                <>

                    <TitleTextBlock 
                        audiobooks
                        murmurations
                        fontItalic  
                        title={ReactHtmlParser(title)}
                    /> 

                    {
                        data?.length > 0 ? 
                            <Grid container spacing={2}>
                                {audioBooks}
                            </Grid> 
                            : 
                            <EmptyPage>
                                <p>Sorry, no results.</p>
                                <p>
                                Try again or visit our{" "}
                                <Link id='gtm-writers-dilemma-collection' to="/audiobooks" className="font-secondary">
                                    collection
                                </Link>
                                </p>
                            </EmptyPage>
                    }
                </>
            )}
        </div>
    );
}

export default WritersDilemma
