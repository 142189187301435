import styled from "styled-components/macro";

export const StyledAboutSpiracle = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    max-width: 1080px;
    margin: 100px auto 100px;
    font-family: 'Gill Sans Nova';

    ${(props) => props.theme.breakpoints.down('lg')} {
        grid-template-columns: 360px 1fr;
        gap: 0 38px;
        max-width: 750px;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        grid-template-columns: 1fr;
        text-align: center;
        justify-items: center;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 125px auto 37px;
    }

    q {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 36px;
        max-width: 430px;
        display: block;
        margin-bottom: 20px;
        position: relative;

        ${(props) => props.theme.breakpoints.down('lg')} {
            max-width: 375px;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
            line-height: 26px;
        }

        &::before {
            content: '“';
            position: absolute;
            top: -30px;
            left: -110px;
            font-size: 200px;
            line-height: 244px;
            color: rgba(0, 0, 0, 0.05);

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 127px;
                line-height: 155px;
                top: -60px;
                left: 47%;
                transform: translateX(-50%);
            }
        }

        & + span {
            display: block;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 3px;
            margin-bottom: 35px;
            grid-column: 1/2;
            grid-row: 2/3;

            ${(props) => props.theme.breakpoints.down('md')} {
                grid-column: initial;
                grid-row: initial;
            }

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 13px;
                line-height: 14px;
            }
        }
    }

    p {
        max-width: 430px;
        font-size: 22px;
        line-height: 32px;
        grid-column: 1/2;

        ${(props) => props.theme.breakpoints.down('lg')} {
            max-width: 340px;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 18px;
            line-height: 26px;
            max-width: initial;
            padding: 0 10px;
        }
    }

    a {
        margin-top: 35px;
        grid-column: 1/2;

        ${(props) => props.theme.breakpoints.down('md')} {
            margin: 20px auto 0;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100% !important;
        }
    }

    img {
        display: block;
        max-width: 465px;
        grid-column: 2/3;
        grid-row: 1/5;

        ${(props) => props.theme.breakpoints.down('md')} {
            max-width: 100%;
            grid-column: 1/2;
            grid-row: 3/4;
            margin-bottom: 15px;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            max-width: 177px;
        }
    }

    .sa-button-red {
        padding: 12px 45px 12px !important;
    }
`;

