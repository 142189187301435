import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { EmptyPage } from 'pages/AudioBooks/style';

import ApiService from '../../services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import PageMeta from '../../shared/PageMeta/PageMeta';
import SkeletonAudioBooks from 'components/UI/Skeleton/SkeletonAudioBooks/SkeletonAudioBooks';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import TitleTextBlock from 'shared/PageElements/TitleTextBlock';


class SecondEdition extends Component {
    state = {
        page: 1,
        itemsPerPage: 12,
        loadButton: false,
        moreBooks: false, 
        editions: [],
        isLoading: true
    }

    componentDidMount() {
        this.getEditions()
    }

    handleLoadMoreBooks = () => {
        this.setState({
            page: this.state.page + 1,
            loadButton: true,
        }, () => {
            this.getEditions()
        })
    }

    handleResponse = (r) => {
        if (r.data['hydra:member'].length) {
            this.setState({
                editions: [...this.state.editions, ...r.data['hydra:member']],
                loadButton: false,
                isLoading: false,
            }, () => {
                if (this.state.editions.length < r.data['hydra:totalItems']) {
                    this.setState({
                        moreBooks: true,
                    })
                } else {
                    this.setState({
                        moreBooks: false,
                    })
                }

                if (window.dataLayer) {
                    const items = [];
                    r.data['hydra:member'].map((book, index) => {
                        const locations = []
                        if (book.locations && book.locations.length) {
                            book.locations.map((location) => {
                                return locations.push(location.title)
                            })
                        }
                        const tags = []
                        if (book.tags && book.tags.length) {
                            book.tags.map((tag) => {
                                return tags.push(tag.title)
                            })
                        }
                        const authors = []
                        if (book.authors && book.authors.length) {
                            book.authors.map((author) => {
                                return authors.push(author.fullName)
                            })
                        }
                        const narrators = []
                        if (book.narrators && book.narrators.length) {
                            book.narrators.map((narrator) => {
                                return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                            })
                        }

                        return items.push({
                            item_id: book.slug,
                            item_name: book.title,
                            item_brand: book.publisher ? book.publisher?.name : '',
                            item_category: book.category ? book.category?.name : '',
                            item_locations: locations?.length ? locations.join(', ') : '',
                            item_tags: tags?.length ? tags.join(', ') : '',
                            item_price: book.price,
                            item_author: authors?.length ? authors.join(', ') : '',
                            item_narrator: narrators?.length ? narrators.join(', ') : '',
                            item_translator: book.translator ? `${book.translator.firstName} ${book.translator.lastName}` : '',
                            item_length: `${book.lengthHours}:${book.lengthMin}`,
                        })
                    })
                    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                    window.dataLayer.push({
                        event: "view_item_list",
                        ecommerce: {
                            item_list_id: "current_highlights",
                            item_list_name: "Current Highlights",
                            items: items
                        }
                    });
                }
            })
        } else {
            this.setState({
                moreBooks: false,
                loadButton: false,
                isLoading: false,
            })
        }
    }

    getEditions = () => {
        const params = new URLSearchParams();

        params.append('page', this.state.page);
        params.append('itemsPerPage', this.state.itemsPerPage);

        this.props.history.replace(`/subscriber-selection?${params.toString()}`)

        ApiService.getSecondEditions(params).then(r => this.handleResponse(r))
    }

    render() {
        return (
            <>
                <PageMeta>
                    <title>Subscriber Selection | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                <div className="container__big--withPadding">
                    <TitleTextBlock 
                        editions
                        title={<>Current <span className="font-italic">highlights</span></>}
                        text="This month’s highlights are available for everyone to buy; free with Premium Membership (be sure to add them to your Library in time)."
                        subtext={<><span className="font-italic">I kept always two books in my pocket &#8212;</span> Robert Louis Stevenson</>}
                    /> 
                </div>
                <div className="container__big container__big--withPadding" >                   
                    {this.state.isLoading ? <SkeletonAudioBooks /> : (
                        this.state.editions.length > 0 ?
                            <Grid container spacing={2} justifyContent='center'>
                                {this.state.editions.map((book, index) => (                            
                                    <Grid item xs={6} md={4} lg={3} key={`audioBook-${index}`}>
                                        <AudioBookListItem
                                            title={book.title}
                                            imgSrc={book.cover}
                                            imgAlt={book.coverAlt}
                                            price={book.price}
                                            subscriptionPrice={book.subscriptionPrice}
                                            authors={book.authors}
                                            type={book.entityType}
                                            link={`/${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}/${book.slug}`}
                                        />
                                    </Grid>                          
                                ))}
                            </Grid> : 
                            <EmptyPage>
                                <p>Sorry, no results.</p>
                                <p>
                                Try again or visit our{" "}
                                <Link id='gtm-second-editions-collection' to="/audiobooks" className="font-secondary">
                                    collection
                                </Link>
                                </p>
                            </EmptyPage>
                    )}
                 </div>

                {this.state.moreBooks && <div className="text-center">
                    <button 
                        id='gtm-second-editions-load-more'
                        onClick={this.handleLoadMoreBooks} 
                        disabled={this.state.loadButton}
                        className={`c-btn c-btn__ghost font-secondary mt-30 ${this.state.loadButton ? 'submitting' : ''}`}>
                            load more
                            <SpiracleLogoSmall />
                    </button>
                </div>}               
            </>
        );
    }
}

export default SecondEdition;