import styled from "styled-components/macro";

export const Dash = styled.span``;

export const StyledBanner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 75;
    padding: 6px 20px;
    background: #F1E5A3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.72px;
    transition: all 0.3s ease-in-out;

    &.hide {
        ${(props) => props.theme.breakpoints.down('md')} {
            transform: translateY(-100%);
        }
    }

    ${(props) => props.theme.breakpoints.up('md')} {
        flex-direction: row;
        height: 60px;
        line-height: 60px;
    }

    img {
        height: 49px;

        ${(props) => props.theme.breakpoints.up('md')} {
            margin-left: auto;
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            color: #721b00;
        }

        ${(props) => props.theme.breakpoints.up('lg')} {
            margin-right: 110px;
        }
    }

    i {
        position: absolute;
        top: 20px;
        right: 20px;

        ${(props) => props.theme.breakpoints.up('md')} {
            position: static;
            margin-left: auto;
        }
    }

    ${Dash} {
        display: none;

        ${(props) => props.theme.breakpoints.up('md')} {
            display: inline;
            margin-top: 6px;
        }
    }
`;

