import React from 'react'
import { Helmet } from 'react-helmet'

export default function PageMeta(props) {
    return (
        <Helmet>
            { props.children }
        </Helmet>
    )
}
