import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import AudioBookSingleCarousel from 'components/UI/AudiobookSingle/AudioBookSingleCarousel';

import { Author, Content, Container, Image, Paragraph, StyledWritersDilemma, Subtitle, Title } from './style';

import writersDilemmaImg from '../../../../images/writers-dilemma.png';
import susie from '../../../../images/susie-orbach.webp';

function WritersDilemma({ data, title }) {
    return (
        <StyledWritersDilemma>
            <Container>
                <Content>
                    <img src={susie} alt="Susie Orbach" />
                    <Subtitle className='font-uppercase'>Podcast</Subtitle>
                    <Title className='font-italic'>{ReactHtmlParser(title)}</Title>
                    <Author>with Susie Orbach</Author>
                    <Paragraph>
                        Leading psychotherapist, Susie Orbach meets the writers who populate the world of Spiracle – who join her for a session in her consulting room.

                        <span className='font-italic'>Add the full series to your Library for free and listen at your own pace.</span>
                    </Paragraph>
                </Content>

                <Image>
                    <img src={writersDilemmaImg} alt="" />
                </Image>
            </Container>

            <AudioBookSingleCarousel data={data.slice(0, 8)} />

            <div className='text-center'>
                <Link id='gtm-homepage-view-writers-dilemma' to='/writers-dilemma' className='sa-button-red sa-button-rounded'>View The Writer’s Dilemma series</Link>
            </div>
        </StyledWritersDilemma>
    )
}
export default WritersDilemma;