import { PublishersHolder, StyledPublisherItem, Title } from "pages/Publishers/style";
import React, { Component } from 'react';

import ApiService from '../../services/api.service';
import { Link } from "react-router-dom";
import Loader from '../../shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta';

class Publishers extends Component {
    state = {
        publishers: null,
        isLoading: true,
    }

    componentDidMount() {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('itemsPerPage', 50);
        ApiService.getAllPublishers(urlSearchParams).then(r => {            
            this.setState({
                publishers: r.data["hydra:member"],
                isLoading: false,
            })            
        })
    }

    render() {
        let publishers;

        if (this.state.publishers && this.state.publishers.length) {
            publishers = this.state.publishers.map((publisher, index) => {
                return (
                    <StyledPublisherItem key={index}>
                        <Link id='gtm-publishers-publisher' to={`/publishers/${publisher.slug}`}>{publisher.name}</Link>
                    </StyledPublisherItem>
                )
            });
        }

        return (
            <>
                <PageMeta>
                    <title>Publishers | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
            
                {this.state.isLoading ? <Loader /> : (
                    <PublishersHolder>
                        <div className="container container__big">
                            <Title>Publishers</Title>
                            {publishers}
                        </div>
                    </PublishersHolder>
                )}
            </>
        );
    }
}

export default Publishers;