import * as bookActionTypes from '../../store/actions/bookActions';

import {
    ImageHolder,
    StyledPlayer,
    StyledPlayerBody
} from "pages/AudioBookPlayer/style"
import React, { Component } from 'react';
import axios from 'axios';

import ApiService from '../../services/api.service';
import Countdown from 'react-countdown';
import CoverImage from "shared/CoverImage/CoverImage";
import FooterPlayer from "layout/Footer/FooterPlayer";
import HeaderPlayer from 'layout/Header/HeaderPlayer';
import Loader from 'shared/Loader/Loader';
import MediaPlayer from "components/UI/MediaPlayer/MediaPlayer";
import PageMeta from 'shared/PageMeta/PageMeta';
// import PageMeta from 'shared/PageMeta/PageMeta';
import PlayerChaptersDrawer from 'components/UI/AudioBookPlayer/PlayerChaptersDrawer';
import PlayerSleepDrawer from 'components/UI/AudioBookPlayer/PlayerSleepDrawer';
import PlayerSpeedDrawer from 'components/UI/AudioBookPlayer/PlayerSpeedDrawer';
import { SwiperSlide } from 'swiper/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export let cancelToken;

class AudioBookPlayer extends Component {

    constructor(props) {
        super(props);
        this.player = React.createRef();

        this.state = {
            isLoading: true,
            audioBook: null,
            audioFiles: [],
            currentAudioPlaying: 0,
            playbackRateOptions: [0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2],
            playbackRate: 1,
            playbackSwiperRate: 0.5,
            playbackMenuOpen: false,
            lastPlayedId: 0,
            srcList: [],
            chapterMenuOpen: false,
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
            sleepTimerMenuOpen: false,
            sleepTimerMinute: 0,
            sleepTimerHours: 0,
            sleepTimer: false,
            savedPlayTime: 0,
            currentId: 0,
            slidedChapter: -2,
            currentTime: 0,
            timeExist: false,
            sleepTimerLength: 0,
            desktopPlayer: false,
            miniPlayer: true,
            isFetchingAudioFiles: false,
            isFetchingOnEnd: false,
            downloadedFiles: [],
            isOnline: window.navigator.onLine,
            isFetchingAudioFiles2: false,
            isFetchingOnEnd2: false,
            downloadingFilesInProgress: {},
            patchAudioTime: {
                chapter: 1,
                seconds: 0
            },
            isPlaying: true,
            isFailed: false,
            showMessage: false,
        }
    }

    componentDidMount() {
        if (this.props.book) {
            this.props.onTurnOnWidgetPlayer();
            this.setState({
                ...this.state,
                audioBook:this.props.book,
                audioFiles: this.props.book.audioFiles['hydra:member']
            }, () => {
                document.body.classList.add('player-visible');
                if (!this.props.book.download) {
                    this.setState({
                        ...this.state,
                        srcList: [this.props.book.sample]
                    })
                } else {
                    const params = new URLSearchParams();
                    params.append('itemsPerPage', 50);
                    params.append('page', 1);
                    params.append('audioBook.id', this.props.book.id)
                    ApiService.getAudioTimes().then(
                        r => {
                            let counter = 0;
                            const hydraMemberFiltered = r.data['hydra:member'].filter(time => time.audioBook.includes(`${this.props.match.params.slug}`))
                            if (hydraMemberFiltered.length === 0) {
                                    this.state.audioFiles.forEach((item, index) => {
                                        if (counter < 1) {
                                            counter ++;

                                            this.setState({                               
                                                currentTime: 0,
                                                timeExist: false,
                                                currentId: this.props.match.params.slug,
                                                currentAudioPlaying: 0,
                                                srcList: this.state.audioFiles[0].url
                                            }, () => {
                                                this.fetchNextAudioFile()
                                            })
                                        }
                                    })
                            } else {
                                const currentAudioBookPlaying = r.data['hydra:member'].filter(time => time.audioBook.includes(`${this.props.match.params.slug}`));
                                this.setState({
                                    currentAudioPlaying: currentAudioBookPlaying[0].chapter - 1,
                                    currentId: currentAudioBookPlaying[0].id,
                                    currentTime: currentAudioBookPlaying[0].seconds,
                                    srcList: this.state.audioFiles[currentAudioBookPlaying[0].chapter - 1].url
                                }, () => {
                                    this.fetchNextAudioFile()
                                })
                            }
                        }
                    )
                }
            })
        } else {
            if (!this.props.desktopPlayer) {
                ApiService.getAudioBookBySlug(this.props.match.params.slug).then((r)=>{
                    this.setState({
                        ...this.state,
                        audioBook:r.data,
                        audioFiles: r.data.audioFiles['hydra:member'],
                        desktopPlayer: true
                    }, () => {
                        this.props.onGetBook(this.state.audioBook)
                        document.body.classList.add('player-visible');
                    })
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.book && this.props.book.download) {
            if (prevProps.book !== this.props.book) {
                this.handleOpenFullPlayer()
                this.setState({
                    ...this.state,
                    audioBook:this.props.book,
                    audioFiles: this.props.book.audioFiles['hydra:member'],
                    isLoading: true,
                    srcList: [],
                    downloadedFiles: [],
                    downloadingFilesInProgress: {},
                    isFetchingAudioFiles: false,
                    isFetchingOnEnd: false,
                    isFetchingAudioFiles2: false,
                    isFetchingOnEnd2: false,
                }, () => {       
                    if (this.props.book && this.props.book.download) {
                        const params = new URLSearchParams();
                        params.append('itemsPerPage', 50);
                        params.append('page', 1);
                        params.append('audioBook.id', this.props.book.id)
                        ApiService.getAudioTimes().then(
                            r => {
                                let counter = 0;
                                const hydraMemberFiltered = r.data['hydra:member'].filter(time => time.audioBook.includes(`${this.props.match.params.slug}`))
                                if (hydraMemberFiltered.length === 0) {
                                    this.state.audioFiles.forEach((item, index) => {
                                        if (counter < 1) {
                                            counter ++;

                                            this.setState({                               
                                                currentTime: 0,
                                                timeExist: false,
                                                currentId: this.props.match.params.slug,
                                                currentAudioPlaying: 0,
                                                srcList: this.state.audioFiles[0].url
                                            }, () => {
                                                this.fetchNextAudioFile()
                                            })
                                        }
                                    })
                                } else {
                                    const currentAudioBookPlaying = r.data['hydra:member'].filter(time => time.audioBook.includes(`${this.props.match.params.slug}`));
                                    this.setState({
                                        currentAudioPlaying: currentAudioBookPlaying[0].chapter - 1,
                                        currentId: currentAudioBookPlaying[0].id,
                                        currentTime: currentAudioBookPlaying[0].seconds,
                                        srcList: this.state.audioFiles[currentAudioBookPlaying[0].chapter - 1].url
                                    }, () => {
                                        this.fetchNextAudioFile()
                                    })
                                }
                            }
                        )
                    } else {
                        this.setState({
                            ...this.state,
                            srcList: [this.props.book.sample]
                        })
                    }
                })

                setInterval(() => {
                    if (this.state.isPlaying) {
                        ApiService.patchAudioTime(this.state.patchAudioTime, this.state.currentId)
                    }
                }, 5000)
            }
        } else {
            if (prevProps.book !== this.props.book) {
                if (this.props.book) {
                    this.setState({
                        ...this.state,
                        srcList: [this.props.book.sample],
                        audioBook: this.props.book
                    })
                }
            }
        }

        if (prevProps.user !== this.props.user) {
            this.handleClosePlayer();
        }
    }

    activeChapter = (clicked) => {
        setTimeout(() => {
            if (clicked) {
                const chapterWrapper = document.querySelector('.chapters-swiper .swiper-wrapper');
                chapterWrapper.style.marginTop = '80px';
            } else {
                const activeChapterTitle = document.querySelector('.style__StyledPlayerBody-sc-1v6uy9v-1 pre');
                const chapterSlides = document.querySelectorAll('.chapters-swiper .swiper-slide');

                if (activeChapterTitle && chapterSlides) {
                    chapterSlides.forEach((item, index) => {
                        const title = item.querySelector('pre').textContent

                        if (activeChapterTitle.textContent === title) {
                            item.classList.add('swiper-slide-active')
                            item.scrollIntoView({block: 'center'})
                        } else {
                            item.classList.remove('swiper-slide-active')
                        }
                    })
                }
            }
        }, 200)
    }

    handleOpenFullPlayer = () => {
        document.body.classList.add('noScroll');
        this.props.onTurnOnWidgetPlayer()
    }

    handleClosePlayer = () => {
        if (this.player && this.player.current && this.player.current.audio && this.player.current.audio.current)
        this.player.current.audio.current.pause();
    }

    checkIsOnline = (func) => {
        let myPromise = new Promise(function(myResolve, myReject) {
            const getRandomString = () => {
                return Math.random().toString(36).substring(2, 15)
            }

            // avoid CORS errors with a request to your own origin
            const url = new URL(window.location.origin)

            // random value to prevent cached responses
            url.searchParams.set('random_call', getRandomString())

            fetch(url.toString(), { method: 'HEAD' }).then(
                (res) => {
                    if (res.ok) {
                        myResolve(true);
                    } else {
                        myReject(false);
                    }
                    
                }
            ).catch(
                (e) => {
                    myReject(false)
                }
            )
        });

        myPromise.then(
            function(value) {func(value);},
            function(error) {func(error);}
        );
    }

    handleClickNext = () => {
        const isNextExist = this.state.audioFiles[this.state.currentAudioPlaying + 1] && this.state.audioFiles[this.state.currentAudioPlaying + 1].url;
        const currentAudioPlaying = this.state.currentAudioPlaying;
        const downloadedFiles = this.state.downloadedFiles;
        const urlSrc = this.state.audioFiles[currentAudioPlaying + 1].url;

        if (isNextExist) {

            const setPlay = (isOnline) => {
                this.setState({
                    isOnline: isOnline
                })

                const next = downloadedFiles.find(file => file.chapter === currentAudioPlaying + 2)

                if (next) {
                    this.setState({
                        currentAudioPlaying: currentAudioPlaying + 1,
                        currentTime: 0,
                        srcList: next['blob']
                    }, () => {
                        if (!this.state.isFailed) {
                            this.fetchNextAudioFile()
                        }
                        this.activeChapter()
                    })
                } else {
                    if (isOnline) {
                        this.setState({
                            currentAudioPlaying: currentAudioPlaying + 1,
                            currentTime: 0,
                            srcList: urlSrc
                        }, () => {
                            this.fetchNextAudioFile()
                            this.activeChapter()
                        })
                    } else {
                        this.setState({
                            isFetchingOnEnd: true,
                            isFetchingOnEnd2: true,
                            showMessage: true,
                        })
                    }
                }
            }

            this.checkIsOnline(setPlay)  
        }
    }

    handleClickPrevious = () => {
        if (this.state.currentAudioPlaying !== 0) {
            this.setState({
                currentAudioPlaying: this.state.currentAudioPlaying - 1,
                currentTime: 0,
                srcList: this.state.audioFiles[this.state.currentAudioPlaying - 1].url
            }, () => {
                this.activeChapter()
            })
        }
    }

    handlePlaybackRateSwipe = slide => {
        this.setState({
            playbackSwiperRate: this.state.playbackRateOptions[slide.activeIndex]
        })
    }

    handleOpenPlaybackRateMenu = () => {
        this.setState({
            playbackMenuOpen: !this.state.playbackMenuOpen
        })
    }

    handleApplyPlaybackRate = () => {
        this.setState({
            playbackRate: this.state.playbackSwiperRate
        }, () => {
            this.player.current.audio.current.playbackRate = this.state.playbackRate
        })
    }

    handleOpenChapterMenu = () => {
        this.setState({
            chapterMenuOpen: !this.state.chapterMenuOpen,
            slidedChapter: -2,
        }, () => {
            this.activeChapter()
        })
    }

    loadMetaData = (time) => {
        if (time >= 5) {
            this.player.current.audio.current.currentTime = time - 5
            this.player.current.audio.current.play();
        }
    }

    fetchNextAudioFile = () => {
        // check if there are any previous requests
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled. New request.")
        }

        // save the cancel token for the current request
        cancelToken = axios.CancelToken.source()

        if (this.props.book && this.props.book.download) {
            if (this.state.audioFiles[this.state.currentAudioPlaying + 1]) {
                const downloadAudioFile = async () => {                                
                    const apiCall = () => {
                        return new Promise((resolve, reject) => {
                            this.setState({
                                isFetchingAudioFiles: true,
                                downloadingFilesInProgress: {
                                    'chapter': parseInt(this.state.audioFiles[this.state.currentAudioPlaying + 1].chapterOrderNumber)
                                },
                                isFailed: false,
                            })

                            ApiService.downloadAudioFile(this.state.audioFiles[this.state.currentAudioPlaying + 1].url).then(
                                r => {
                                    let downloadedFiles = this.state.downloadedFiles;
                                    var binaryData = [];
                                    binaryData.push(r.data);
                                    downloadedFiles.push({
                                        'chapter' : parseInt(this.state.audioFiles[this.state.currentAudioPlaying + 1].chapterOrderNumber), 
                                        'blob' : window.URL.createObjectURL(new Blob(binaryData, {type: "audio/mp3"}))
                                    })
                                    this.setState({
                                        downloadedFiles: downloadedFiles,
                                        isFetchingAudioFiles: false,
                                        downloadingFilesInProgress: {}
                                    }, () => {
                                        resolve()

                                        // loading next chapter
                                        if (this.state.isFetchingOnEnd) {
                                            this.setState({
                                                isFetchingOnEnd: false,
                                            })
                                            this.player.current.audio.current.currentTime = this.player.current.audio.current.currentTime - 0.1
                                            this.player.current.audio.current.play();
                                        }

                                        this.fetchNextAudioFile2(this.state.currentAudioPlaying + 2);
                                    })
                                }
                            ).catch((err) => {
                                console.log(err)
                                this.setState({
                                    isFailed: true
                                })
                                if (!err.message.includes('Operation canceled. New request.')) {
                                    setTimeout(() => {
                                        apiCall()
                                    }, 5000);
                                }
                            })
                        }) 
                    }

                    await apiCall()
                }

                // is already downloaded
                const isAlreadyDownloaded = this.state.downloadedFiles.find(file => file.chapter === this.state.currentAudioPlaying + 2);

                if (!isAlreadyDownloaded) {
                    downloadAudioFile()
                } else {
                    this.fetchNextAudioFile2(this.state.currentAudioPlaying + 2);
                }
            }
        }

    }

    fetchNextAudioFile2 = (param) => {
        if (this.props.book && this.props.book.download) {
            if (this.state.audioFiles[param]) {
                const downloadAudioFile = async () => {                                
                    const apiCall = () => {
                        return new Promise((resolve, reject) => {
                            this.setState({
                                isFetchingAudioFiles2: true,
                                downloadingFilesInProgress: {
                                    'chapter': parseInt(this.state.audioFiles[param].chapterOrderNumber)
                                },
                                isFailed: false,
                            })
                            ApiService.downloadAudioFile(this.state.audioFiles[param].url).then(
                                r => {
                                    let downloadedFiles = this.state.downloadedFiles;
                                    var binaryData = [];
                                    binaryData.push(r.data);
                                    downloadedFiles.push({
                                        'chapter' : parseInt(this.state.audioFiles[param].chapterOrderNumber), 
                                        'blob' : window.URL.createObjectURL(new Blob(binaryData, {type: "audio/mp3"}))
                                    })
                                    this.setState({
                                        downloadedFiles: downloadedFiles,
                                        isFetchingAudioFiles2: false,
                                        downloadingFilesInProgress: {}
                                    }, () => {
                                        resolve()

                                        // loading next chapter
                                        if (this.state.isFetchingOnEnd2) {
                                            this.setState({
                                                isFetchingOnEnd2: false,
                                            })
                                            this.player.current.audio.current.currentTime = this.player.current.audio.current.currentTime - 0.1
                                            this.player.current.audio.current.play();
                                        }
                                    })
                                }
                            ).catch((err) => {
                                console.log(err)
                                this.setState({
                                    isFailed: true
                                })
                                if (!err.message.includes('Operation canceled. New request.')) {
                                    setTimeout(() => {
                                        apiCall()
                                    }, 5000);
                                }
                            })
                        }) 
                    }

                    await apiCall()
                }

                downloadAudioFile()
            }
        }

    }

    handleListen = e => {
        const id = this.state.currentId
        if (this.props.book && this.props.book.download) {
            this.setState({
                isPlaying: true,
                showMessage: false,
            })

            if (e.path) {
                if (Math.round(e.path[0].currentTime) === 1 && !this.state.timeExist) {
                    const data = {
                        audioBook: `api/audio_books/${id}`,
                        chapter: this.state.currentAudioPlaying + 1,
                        seconds: 5
                    }
                    ApiService.createAudioTime(data).then(
                        r => {
                            this.setState({
                                currentId: r.data.id
                            })
                        }
                    )
                } else if (Math.round(e.path[0].currentTime) > 5){
                    let data = {
                        chapter: this.state.currentAudioPlaying + 1,
                        seconds: Math.round(e.path[0].currentTime)
                    }
                    // ApiService.patchAudioTime(data, id);

                    this.setState({
                        patchAudioTime: data
                    })
                }
            } else {
                if (Math.round(e.target.currentTime) === 1 && !this.state.timeExist) {
                    const data = {
                        audioBook: `api/audio_books/${id}`,
                        chapter: this.state.currentAudioPlaying + 1,
                        seconds: 5
                    }
                    ApiService.createAudioTime(data).then(
                        r => {
                            this.setState({
                                currentId: r.data.id
                            })
                        }
                    )
                } else if (Math.round(e.target.currentTime) > 5){
                    let data = {
                        chapter: this.state.currentAudioPlaying + 1,
                        seconds: Math.round(e.target.currentTime)
                    }
                    // ApiService.patchAudioTime(data, id);
                    this.setState({
                        patchAudioTime: data
                    })
                }
            }
        }
    }

    handleChapterSlide = item => {    
        //index start with -2 ?!    
        this.setState({
            slidedChapter: item.activeIndex,
        }, () => {
            this.activeChapter(true)
        })
    }

    handleChapterApplyClick = item => {
        let slidedChapter = this.state.slidedChapter;
        const downloadedFiles = this.state.downloadedFiles;
        const srcList = this.state.srcList;
        const currentAudioPlaying = this.state.currentAudioPlaying;
        const currentTime = this.state.currentTime;
        const isFetchingOnEnd = this.state.isFetchingOnEnd;
        const isFetchingOnEnd2 = this.state.isFetchingOnEnd2;

        if (slidedChapter < 0) {
            slidedChapter = 0
        }
        
        const urlSrc = this.state.audioFiles[slidedChapter].url

        const setPlay = (isOnline) => {
            this.setState({
                isOnline: isOnline
            })

            this.player.current.audio.current.pause();
            
            const alreadyListened = downloadedFiles.find(file => file.chapter === (slidedChapter + 1));

            this.setState({
                srcList: alreadyListened ? alreadyListened.blob : isOnline ?  urlSrc: srcList,
                currentAudioPlaying: isOnline || alreadyListened || alreadyListened ? slidedChapter : currentAudioPlaying,
                currentTime: isOnline || alreadyListened ? 0 : currentTime,
                isFetchingOnEnd: isOnline || alreadyListened ? false : isFetchingOnEnd,
                isFetchingOnEnd2: isOnline || alreadyListened ? false : isFetchingOnEnd2,
            }, () => {
                if (alreadyListened) {
                    this.activeChapter()
                }

                if (isOnline) {
                    this.fetchNextAudioFile(this.state.currentAudioPlaying)
                }
            })
        }

        this.checkIsOnline(setPlay)      
    }

    handleSleepTimerMenuOpen = () => {
        this.setState({
            sleepTimerMenuOpen: !this.state.sleepTimerMenuOpen,
        })
    }

    handleMinutesChange = (item) => {
        this.setState({
            sleepTimerMinute: item.activeIndex
        })
    }

    handleHoursChange = (item) => {
        this.setState({
            sleepTimerHour: item.activeIndex
        })
    }

    handleSleepTimerApply = () => {
        let hourMs = 0
        let minuteMs = 0
        if (this.state.sleepTimerHour) {
            hourMs = this.state.sleepTimerHour * 3600000
        }
        if (this.state.sleepTimerMinute) {
            minuteMs = this.state.sleepTimerMinute * 5 * 60000
        }

        const sleepTimer = hourMs + minuteMs
        this.setState({
            sleepTimerLength: sleepTimer
        })
    }

    handleMinuteClick = (val) => {
        let sleepTimer = val * 60000;
        this.setState({
            sleepTimerLength: sleepTimer
        })
    }

    handleSpeedClick = (val) => {
        this.setState({
            playbackRate: val
        }, () => {
            this.player.current.audio.current.playbackRate = this.state.playbackRate
        })
    }

    handleCountdownComplete = () => {
        this.setState({
            sleepTimerLength: 0
        }, () => {
            this.player.current.audio.current.pause()
        })
    }

    handlePlayerPause = () => {
        this.setState({
            sleepTimerLength: 0,
            isPlaying: false,
        })
    }

    renderer = ({ hours, minutes }) => {
        return <span>-{hours}h:{minutes}m</span>;
    };

    render() {
        let content
        let audioPlayer;
        let chapters;
        let hours;
        let minutes;
        let playbackOptions = this.state.playbackRateOptions.map( (option, index) => {
            let decimalNumber = option.toFixed(1);
            let str = decimalNumber + '';
            let optionName = str.replace('.', ',');
            return (
                <SwiperSlide
                    key={`option-${index}`}
                >
                    {optionName}
                </SwiperSlide>
            );
        })

        if (this.state.audioFiles?.length) {
            chapters = this.state.audioFiles.map((item, index) => {
                return (
                    <SwiperSlide
                        key={`chapter-${index}`}
                    >
                        <div key={`chapter-${index}`} className="h-flex h-flex-between">
                            <span><pre>{item.chapterTitle ? item.chapterTitle.replaceAll('-', ' ') : ''}</pre></span>
                            <span>{item.playtime}</span>
                        </div>
                    </SwiperSlide>
                );
            })
        }

        if (this.state.hours.length) {
            hours = this.state.hours.map((item, index) => {
                return (
                    <SwiperSlide
                        key={`hour-${index}`}
                    >
                        <div><span>{item} hrs</span></div>
                    </SwiperSlide>
                );
            })
        }

        if (this.state.minutes.length) {
            minutes = this.state.minutes.map((item, index) => {
                return (
                    <SwiperSlide
                        key={`minute-${index}`}
                    >
                        <div><span>{item} min</span></div>
                    </SwiperSlide>
                );
            })
        }

        if (this.state.srcList.length > 0 && this.state.audioBook) {
            audioPlayer = (
              <MediaPlayer
                //preload='metadata'
                playerRef={this.player}                
                src={this.state.srcList}
                onLoadedMetaData={() => this.loadMetaData(this.state.currentTime)}
                onEnded={() => this.handleClickNext()}
                onClickNext={() => this.handleClickNext()}
                onClickPrevious={() => this.handleClickPrevious()}
                onListen={(e) => this.handleListen(e)}
                onPause={this.handlePlayerPause}
                isEdition={this.props.book ? this.props.book.edition : null}
                miniPlayer={this.props.widgetPlayer}
              />
            );
    
            content = (
                <StyledPlayer
                    isEdition={this.state.audioBook.edition}                    
                >
                    <StyledPlayerBody className={`${this.props.widgetPlayer ? 'mini-player' : ''}`}>
                        {this.props.widgetPlayer ?<PageMeta>
                            <title>Player | {this.state.audioBook.title}</title>
                            <link rel="canonical" href={window.location.href} />
                        </PageMeta> : null }
                        {!this.props.widgetPlayer ? <HeaderPlayer desktopPlayer={this.props.desktopPlayer} stopPlaying={() => this.handleClosePlayer()} /> : null}
                    
                        <ImageHolder onClick={() => this.handleOpenFullPlayer()}>
                            <CoverImage
                                src={this.state.audioBook.cover }
                                retinaSrc={this.state.audioBook.cover900}
                                alt={this.state.audioBook.coverAlt}                                                
                            />
                        </ImageHolder>                

                        {!this.props.widgetPlayer ? <div className='ls-1 font-12 text-center mt-30 font-uppercase font-secondary underline' style={{color: this.props.book && this.props.book.edition ? "#831F00" : "#000000"}}>
                                <pre style={{cursor: this.state.audioBook && this.state.audioBook.download !== undefined ? 'pointer' : 'initial'}} onClick={this.handleOpenChapterMenu}>{this.state.audioBook && this.state.audioBook.download !== undefined ? 
                                    this.state.showMessage ? 'loading next chapter' : `${this.state.audioFiles[this.state.currentAudioPlaying] && this.state.audioFiles[this.state.currentAudioPlaying].chapterTitle ? 
                                        this.state.audioFiles[this.state.currentAudioPlaying].chapterTitle.replaceAll('-', ' ') : ''}` : 'sample'}
                                </pre>
                            </div> : null}
                        {this.props.widgetPlayer ? 
                            <div onClick={() => this.handleOpenFullPlayer()} className="info-wrapper">
                                <p>{this.state.audioBook ? this.state.audioBook.title : null}</p> 
                                <p>{this.state.audioBook && this.state.audioBook.authors['hydra:member'].length ? this.state.audioBook.authors['hydra:member'][0].fullName : null}</p> 
                            </div>
                        : null}
                        
                        {audioPlayer}
                    </StyledPlayerBody>

                    {!this.props.widgetPlayer ? 
                    <>
                        <FooterPlayer
                            onClickChapters={() => this.handleOpenChapterMenu()}
                            onClickPlaybackRate={() => this.handleOpenPlaybackRateMenu()}
                            onClickSleep={() => this.handleSleepTimerMenuOpen()}
                            playbackRate={this.state.playbackRate}
                            isEdition={this.props.book ? this.props.book.edition : null}
                            isSample={!this.props.user}
                            countDownComponent={
                                this.state.sleepTimerLength > 0 ?<Countdown 
                                date={Date.now() + this.state.sleepTimerLength - 1000}
                                zeroPadTime={2}
                                renderer={this.renderer}
                                onComplete={this.handleCountdownComplete}
                            >
                            </Countdown> : null
                            }
                        />

                        <div>
                            <PlayerSleepDrawer 
                                open={this.state.sleepTimerMenuOpen}
                                onHoursSlideChange={(slide) => this.handleHoursChange(slide)}
                                onMinutesSlideChange={(slide) => this.handleMinutesChange(slide)}
                                handleMinuteClick={(val) => this.handleMinuteClick(val)}
                                onClickApply={() => this.handleSleepTimerApply()}
                                onClickClose={() => this.handleSleepTimerMenuOpen()}
                                hours={hours}
                                minutes={minutes}
                            />
                        </div>

                        {this.props.user ? <PlayerChaptersDrawer 
                            open={this.state.chapterMenuOpen}
                            onSlideChange={(slide) => this.handleChapterSlide(slide)} 
                            onClick={() => this.handleChapterApplyClick()}
                            hours={this.state.audioBook.lengthHours}
                            minutes={this.state.audioBook.lengthMin}
                            chapters={chapters}
                            onClickClose={() => this.handleOpenChapterMenu()}
                        /> : null}

                        <PlayerSpeedDrawer 
                            open={this.state.playbackMenuOpen}
                            onSlideChange={(slide) =>
                                this.handlePlaybackRateSwipe(slide)
                            }
                            onClick={() => this.handleApplyPlaybackRate()}
                            handleSpeedClick={(val) => this.handleSpeedClick(val)}
                            playbackOptions={playbackOptions}
                            onClickClose={() => this.handleOpenPlaybackRateMenu()}
                        /> 
                    </>    
                    : null }             
                </StyledPlayer>
            );
        } else {
            content = <StyledPlayer><StyledPlayerBody><Loader fullSize /></StyledPlayerBody></StyledPlayer>
        }
        return content
    }
}

const mapStateToProps = state => ({
    book: state.bookReducer.book,
    user: state.userReducer.user,
    widgetPlayer: state.bookReducer.widgetPlayer
})
  
const mapDispatchToProps = dispatch => {
    return {
        onGetBook: (book) => dispatch(bookActionTypes.onGetBook(book)),
        onTurnOnWidgetPlayer: () => dispatch(bookActionTypes.onTurnOnWidgetPlayer())
    }
}

const audioBookPlayerWithRouter = withRouter(AudioBookPlayer)

export default connect(mapStateToProps, mapDispatchToProps)(audioBookPlayerWithRouter)
