export const SET_LIBRARY = 'SET_LIBRARY';
export const SET_ORDER_ITEMS = 'SET_ORDER_ITEMS';
export const REMOVE_LIBRARY_LOGOUT = 'REMOVE_LIBRARY_LOGOUT';
export const ADD_SINGLE_BOOK_TO_LIBRARY = 'ADD_SINGLE_BOOK_TO_LIBRARY';
export const LOAD_MORE_LIBRARY = 'LOAD_MORE_LIBRARY';

export const onAddSingleBookToLibrary = (payload) => {
    return dispatch => {
        dispatch({
            type: ADD_SINGLE_BOOK_TO_LIBRARY,
            libraryItem: payload
        })
    }
}

export const onGetLibraryItems = (payload, total) => {
    return dispatch => {
        dispatch({ 
            type: SET_LIBRARY,
            libraryItems: payload,
            libraryTotalItems: total
        })
    }
}

export const onLoadMoreLibraryItems = (payload, total) => {
    return dispatch => {
        dispatch({ 
            type: LOAD_MORE_LIBRARY,
            libraryItems: payload,
            libraryTotalItems: total
        })
    }
}

export const onAddOrderToLibrary = (payload) => {
    return dispatch => {
        dispatch({
            type: SET_ORDER_ITEMS,
            orderItems: payload
        })
    }
}

export const onRemoveLibrary = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_LIBRARY_LOGOUT
        })
    }
}
