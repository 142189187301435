import { StyledCheckoutForm } from 'components/UI/Ecommerce/CheckoutForm/style';
import styled from "styled-components/macro";

export const InfoText = styled.div``;
export const StyledGiftButton = styled.div``;

export const CartItemHolder = styled.div`
    margin-bottom: 20px;   

    &:last-of-type {
        margin-bottom: 100px;
    }
    
`;

export const CtaHolder = styled.div`
    background-color: #F9F7ED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 0 20px;
    opacity: ${(props) => props.disabled &&  '60%' };
    pointer-events: ${(props) => props.disabled && 'none'};

    ${StyledCheckoutForm} {
        margin-top: 23px;

        input.bg-white, textarea.bg-white {
            border: 1px solid #DEDEDE;
        }
        
        textarea {
            height: 250px;
            display: block;
            margin-bottom: 20px;
        }
    }
`;

export const Cta = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;        
    height: 60px;
    padding: 0 0 0 15px;
    margin-right: -5px;
    font-family: Gill Sans Nova;
    font-size: 14px;        
    line-height: 17px;
    letter-spacing: 0.03em; 

    span {      
        cursor: pointer;
        text-decoration: underline;  
    }

    .remove-btn {
        cursor: pointer;
        text-decoration: underline; 
        font-size: 14px;
        border: none;
        outline: none;
        background: none;
    }

     ${InfoText} {
        text-transform: uppercase;        
    }


    ${StyledGiftButton} {
        height: 18px;

        input[type="checkbox"] {
            display: none;
        }

        input[type="checkbox"] + label, input[type="radio"] + label {
            cursor: pointer;
            i {
                font-size: 25px;
                margin-left: 10px;
                vertical-align: middle;
            }
        }

        input[type="checkbox"] + label {
            .icon-checked {display: none;}
            .icon-not-checked {display: inline-block;}
        }

        input[type="checkbox"]:checked + label {
            .icon-checked {display: inline-block;}
            .icon-not-checked {display: none;}
        }
    }
`;