import React from 'react';
import { Link } from 'react-router-dom';

import { StyledTextSlide } from './style';

function TextSlide(props) {
    return (
        <StyledTextSlide><Link id='gtm-text-slide-publisher-item' to={`/publishers/${props.slug}`}>{props.text}</Link></StyledTextSlide>
    )
}

export default TextSlide