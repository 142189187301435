import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import Grid from "@material-ui/core/Grid";
import TextSlide from '../BrowsePage/TextSlide/TextSlide';

import { StyledTextCarousel, Heading } from './style';

  class TextCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth});
    }
    
    render() {

        // number of slides

        let numOfSlidesTablet = 4

        if(this.state.width <= 1024 && this.props.data?.length === 1) {
            numOfSlidesTablet = 1
        }

        let numOfSlidesMobile = 2.2

        if(this.state.width <= 768 && this.props.data?.length === 1) {
            numOfSlidesMobile = 1
        }

        // show shadow

        let showShadow = true;

        if(this.state.width <= 768 && this.props.data?.length <= 2) {
            showShadow = false
        }
        if(this.state.width > 768 && this.props.data?.length <= 4) {
            showShadow = false
        }
        if(this.state.width > 1279 && this.props.data?.length <= 6) {
            showShadow = false
        }

        const settings = {
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            swipeToSlide: true,
            responsive: [{
                breakpoint: 1280,

                settings: {
                    swipeToSlide: false,
                    slidesToShow: numOfSlidesTablet,
                }
            },
            {
                breakpoint: 767,

                settings: {
                    swipeToSlide: false,
                    slidesToShow: numOfSlidesMobile,
                }
            }]
        };

        return (
            <StyledTextCarousel>
				<Heading>
					<h2>{this.props.title ? this.props.title : ''}</h2>
					<Link id={`gtm-text-carousel-view-all-${this.props.gtmTitle ? this.props.gtmTitle.toString().toLowerCase().replace(/ /g, '-') : 'no-gtm-title'}`} to={this.props.link} className='font-italic'>View all</Link>
				</Heading>
                <div className={`c-audio-book-single-carousel ${showShadow && 'c-audio-book-single-carousel--show-shadow'}`}>
                    {this.state.width > 1279 ? 
                        this.props.data.length > 6 ? (
                            <Slider {...settings}>
                                {this.props.data.map((item, index)=>(                      
                                	<TextSlide key={`item-${index}`} text={item.name} slug={item.slug} />                
                                ))}
                            </Slider>
                        ) : (
                            <Grid container spacing={2} justifyContent='center'>
                                {this.props.data.map((item, index) => {
                                    return <Grid item xs={6} md={2} key={`item-${index}`}>
                                                <TextSlide text={item.name} slug={item.slug} /> 
                                            </Grid>
                                })}
                            </Grid>
                        ) : this.state.width > 768 ? (
                                this.props.data.length > 4 ? (
                                    <Slider {...settings}>
                                        {this.props.data.map((item, index)=>(                      
                                            <TextSlide key={`item-${index}`} text={item.name} slug={item.slug} />                
                                        ))}
                                    </Slider>
                                ) : (
                                    <Grid container spacing={2} justifyContent='center'>
                                        {this.props.data.map((item, index) => {
                                            return <Grid item xs={6} md={3} key={`item-${index}`}>
                                                        <TextSlide text={item.name} slug={item.slug} /> 
                                                    </Grid>
                                        })}
                                    </Grid>
                                )
                            ) : this.props.data.length > 2 ? (
                                    <Slider {...settings}>
                                        {this.props.data.map((item, index)=>(                      
                                            <TextSlide key={`item-${index}`} text={item.name} slug={item.slug} />          
                                        ))}
                                    </Slider>
                                ) : (
                                    <Grid container spacing={2} justifyContent='center'>
                                        {this.props.data.map((item, index) => {
                                            return <Grid item xs={6} md={2} key={`item-${index}`}>
                                                        <TextSlide text={item.name} slug={item.slug} /> 
                                                    </Grid>
                                        })}
                                    </Grid>
                                )}
                </div>
            </StyledTextCarousel>
        );
    }
  }

  export default withRouter(TextCarousel)