import styled from "styled-components/macro";

export const Heading = styled.div``;

export const StyledTextCarousel = styled.div`
    ${Heading} {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 30px;
        margin: 63px 5px 18px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            margin: 29px 0 18px;
        }

        h2 {
            flex: 1;
            font-size: 36px;
            margin: 0;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 22px;
            }
        }

        a {
            font-size: 22px;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 1px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 18px;
            }
        }
    }

    .c-audio-book-single-carousel {
        max-height: 150px; // fix clone issues

        &--show-shadow {
            &::before {
                height: 100%;
            }
        }
    }

    .slick-next {
        top: 60%;
    }
`;

