import styled from "styled-components/macro";

export const Content = styled.div``;
export const Icons = styled.div``;
export const Images = styled.div``;

export const StyledDownloadApp = styled.div`
    display: flex;
    justify-content: center;
    gap: 35px;
    margin: 132px auto 0;
    max-width: 1160px;

    ${(props) => props.theme.breakpoints.up('xl')} {
        justify-content: space-between;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        margin-top: 75px;
        flex-direction: column-reverse;
        align-items: center;
        gap: 45px;
    }

    ${Content} {
        max-width: 440px;
        margin-top: 65px;

        ${(props) => props.theme.breakpoints.down('md')} {
            max-width: 320px;
            margin-top: 0;
        }

        h2 {
            font-size: 18px;
            letter-spacing: 6px;
            font-family: 'Gill Sans Nova', sans-serif;
            margin-bottom: 20px;

            ${(props) => props.theme.breakpoints.down('md')} {
                font-size: 13px;
                letter-spacing: 2px;
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 36px;
            margin-bottom: 32px;

            ${(props) => props.theme.breakpoints.down('md')} {
                font-size: 26px;
                margin-bottom: 22px;
            }
        }

        p {
            font-size: 22px;
            font-family: 'Gill Sans Nova', sans-serif;
            line-height: 37px;
            margin-bottom: 50px;

            ${(props) => props.theme.breakpoints.down('md')} {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 30px;
            }
        }
    }

    ${Icons} {
        display: flex;
        justify-content: center;
        gap: 15px;

        ${(props) => props.theme.breakpoints.down('md')} {
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

         a {
            width: 170px;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    ${Images} {
        display: flex;
        align-items: center;
        gap: 35px;

        img {
            &:first-of-type {
                max-width: 242px;

                ${(props) => props.theme.breakpoints.down('md')} {
                    max-width: 118px;
                }
            }

            &:last-of-type {
                max-width: 188px;

                ${(props) => props.theme.breakpoints.down('md')} {
                    max-width: 92px;
                }
            }
        }
    }
`;