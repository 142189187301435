import React, { Component } from "react";
import { FiltersRow } from "pages/AudioBooks/style";
import Collapse from "@material-ui/core/Collapse";

export default class AudioBookSingleFilter extends Component {
    constructor(props) {
        super(props);
    
        this.filtersWrapper = React.createRef();

        this.state = {
            allFiltersShown: true,
            moreFiltersLinkVisible: false,
            filtersId: this.props.filtersId
        }
    }

    componentDidMount() {        
        this.handleFiltersVisibility();
        window.addEventListener("resize", this.handleFiltersVisibility);          
    }    

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleFiltersVisibility);
    }

    handleFiltersVisibility = () => {
        if (this.filtersWrapper.current) {
            this.layoutFilters(this.filtersWrapper.current);            
        }    
    }

    layoutFilters = (filterWrapper) => {
        const filterMargin = 3;        
        const moreFiltersLinkWidth = 40 + 2 * filterMargin;
        const filtersAvailableWidth = filterWrapper.offsetWidth;
        const filters = filterWrapper.children;
        if(document.body.contains(document.getElementById(this.state.filtersId))) {
            document.getElementById(this.state.filtersId).remove();     
        }
        let filtersCombinedWidth = 0;
        const filtersArray = [...filters];       
        filtersArray.some((filter, index) => {         
            filtersCombinedWidth += filter.offsetWidth;
            
            if (filtersAvailableWidth <= filtersCombinedWidth) {    
                const newFiltersCombinedWidth = filtersCombinedWidth - filter.offsetWidth;  
                if (newFiltersCombinedWidth >= filtersAvailableWidth - moreFiltersLinkWidth) {
                    this.createExpandFiltersButton(filter.previousSibling);
                    this.setState({ allFiltersShown: false });

                } else {
                    this.createExpandFiltersButton(filter);
                    this.setState({ allFiltersShown: false });
                }
                
                return true;
            }            
            return false;
        });
    }

    createExpandFiltersButton = (filter) => {      
        this.setState({moreFiltersLinkVisible: true });
        const moreFiltersLink = document.createElement('div');
        moreFiltersLink.setAttribute("id", this.state.filtersId);
        moreFiltersLink.classList.add('show-filters');
        moreFiltersLink.addEventListener('click', this.toggleFilters.bind(null, filter));
        filter.before(moreFiltersLink);       
    }

    toggleFilters = () => {    
        this.setState({ allFiltersShown: !this.state.allFiltersShown });
        if(this.state.allFiltersShown) {
            document.getElementById(this.state.filtersId).classList.add('active');
            this.filtersWrapper.current.appendChild( document.getElementById(this.state.filtersId));     
        } else {
            document.getElementById(this.state.filtersId).classList.remove('active'); 
            this.handleFiltersVisibility();
        }
    }  
    

    render() {       
        return (
            <Collapse
                in={this.state.allFiltersShown}    
                collapsedSize={46}                
           >          
                <FiltersRow ref={this.filtersWrapper}>
                    {this.props.filters}
                </FiltersRow>
            </Collapse>
         
        );
    }

}