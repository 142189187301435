import * as userActionTypes from '../../store/actions/userActions';
import * as discountActionTypes from '../../store/actions/discountActions';
import * as cartActionTypes from '../../store/actions/cartActions';

import { Heading, MembershipDetailsList, OrderDetails, OrderFooter, Paragraph, StyledOrderConfirmation, Terms } from '../Order/style';
import React, { Component } from 'react';

import ApiService from 'services/api.service';
import { connect } from 'react-redux';
import DateFormater from '../../services/format-date.service';
import DownloadIcons from 'shared/DownloadIcons/DownloadIcons';
import MembershipCard from '../../shared/MembershipCard/MembershipCard';
import { Link } from 'react-router-dom';
import Loader from 'shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta';
import PaypalProblem from 'components/UI/PaypalProblem/PaypalProblem';
import image from '../../images/hand-apple.webp';

class MembershipConfirmation extends Component {

    state = {
        subscription: null,
        isLoadingUserCart: false,
        isPaypalFailed: new URLSearchParams(this.props.location.search).get('redirect_status') === 'failed',
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            if (!this.props.user && localStorage.getItem('token')) {
                ApiService.getUser().then(
                    user => {
                        this.props.onSetUser(user.data);
                    }
                )
            }
        
            if (!this.state.isPaypalFailed) {
            const id = this.props.location.state ? this.props.location.state.subscriptionId : parseInt(new URLSearchParams(this.props.location.search).get('id'))
            ApiService.getSubscriptionsInvoice(id).then(
                r => {
                    this.setState({
                        subscription: r.data
                    })
                    
                    this.props.onSetSubscription(r.data)

                    // get price with subscription discount
                    const cartId = this.props.user && this.props.user.cart && this.props.user.cart.uuid
                    const cartIdFromCart = this.props.cart && this.props.cart[0] && this.props.cart[0].cart.uuid

                    if (cartId) {
                        this.setState({
                            isLoadingUserCart: true
                        })

                        ApiService.getCartItems(cartId).then(
                            cart => {
                                this.props.onGetCartItems(cart.data['hydra:member'])
                                this.setState({
                                    isLoadingUserCart: false
                                })
                            }
                        )
                    } else if (cartIdFromCart) {
                        this.setState({
                            isLoadingUserCart: true
                        })

                        ApiService.updateCart(cartIdFromCart).then(
                            cart => {
                                ApiService.getCartItems(cartIdFromCart).then(
                                    cart => {
                                        this.props.onGetCartItems(cart.data['hydra:member'])
                                        ApiService.getUser().then(
                                            user => {
                                                this.props.onSetUser(user.data);
                                                this.setState({
                                                    isLoadingUserCart: false
                                                })
                                            }
                                        )
                                    }
                                )
                            }
                        )
                    }

                    if (window.dataLayer) {
                        const plan = this.state.subscription.plan === 1 ? 'Monthly' : 'Annually';
                        const transactionId = this.state.subscription.orderItem.orderCart.transactions && 
                            this.state.subscription.orderItem.orderCart.transactions[0] && 
                            this.state.subscription.orderItem.orderCart.transactions[0]['@id'] ? 
                            this.state.subscription.orderItem.orderCart.transactions[0]['@id'].split('/api/transactions/')[1] : 
                            'no data'

                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                            event: "purchase",
                            ecommerce: {
                                transaction_id: transactionId,
                                value: this.state.subscription.orderItem.orderCart.amount,
                                tax: Number((this.state.subscription.orderItem.orderCart.amount * 0.2).toFixed(2)),
                                currency: "GBP",
                                coupon: this.state.subscription.orderItem.couponCode || '',
                                discount: this.state.subscription.orderItem.percentDiscount || 0,
                                items: {
                                    item_id: `${plan.toLowerCase()}_subscription`,
                                    item_name: `${plan} subscription`
                                }
                            }
                        });
                    }
                }
            )

            this.props.onRemoveDiscount()
            }
        }
    }

    render() {
        let content = <Loader />

        if (this.state.subscription && !this.state.isLoadingUserCart) {
            content = (
                <StyledOrderConfirmation className="container container__big">
                    <PageMeta>
                        <title>Successful | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                    
                    <Heading className="font-20 lh-29 font-italic">
                        Thank you for becoming a fully <br />  fledged member of Spiracle, <br /> The Sound of Great Writing.

                        <img src={image} alt="" />

                        <p>Your Journey Begins Here.</p>
                    </Heading>

                    <MembershipDetailsList>
                        Your subscription includes

                        <ol>
                            <li>Choice of two books from <Link id='gtm-membership-confirmation-subscriber-selection' to='/subscriber-selection'>current monthly selection</Link>*</li>
                            <li>Entry to Spiracle events and classes</li>
                            <li>Discounts on Guest Picks</li>
                            <li>Access to all articles and podcasts in <Link id='gtm-membership-confirmation-murmurations' to='/murmurations'>Murmurations</Link></li>
                        </ol>

                        *Don’t forget to put them in your Library to ensure they stay yours – or they will expire!
                    </MembershipDetailsList>

                    <DownloadIcons />

                    <Paragraph className='mb-53'>An order confirmation has <br /> been sent to your email</Paragraph>

                    {this.state.subscription.orderItem ?
                        <>
                            <OrderDetails>
                                <span>ORDER No. {this.state.subscription.orderItem.orderCart.orderNumber}</span>
                                <span>{DateFormater.formatDateMonthYear(this.state.subscription.orderItem.createdAt)}</span>
                            </OrderDetails>
                            
                            <MembershipCard 
                                type={this.state.subscription.plan} 
                                originalPrice={this.state.subscription.orderItem.originalPrice} 
                                price={this.state.subscription.orderItem.price} 
                                gift={this.state.subscription.gift} 
                                giftEmail={this.state.subscription.giftEmail} 
                                discountCode={this.state.subscription.orderItem.couponCode}
                                discountPercent={this.state.subscription.orderItem.percentDiscount}
                                discountPrice={this.state.subscription.orderItem.percentDiscount ? this.state.subscription.orderItem.price : null}
                            />
                        </> 
                    : null}

                    <OrderFooter className="text-center">
                        <p className="font-18 mt-45 mb-30">If you have any questions, <br /> please contact our <Link id='gtm-membership-confirmation-contact-support' to="/info/support">support team</Link></p>
                        <Terms>
                            <Link id='gtm-membership-confirmation-terms-conditions' to='/info/terms-conditions'>Terms &amp; Conditions</Link>
                            <p>powered by <strong>stripe</strong></p>
                        </Terms>
                    </OrderFooter>
                </StyledOrderConfirmation>
            );
        }

        if (this.state.isPaypalFailed) {
            content = <PaypalProblem />
        }
        
        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    cart: state.cartReducer.cart,
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onSetSubscription: (data) => dispatch(userActionTypes.onSetSubscription(data)),
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
        onGetCartItems: (cartItems) => dispatch(cartActionTypes.onGetCartItems(cartItems)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipConfirmation);