import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { StyledAudiobookItem } from "components/UI/AudioBook/style";

export const StyledEditions = styled.div`         
    background-color: ${(props) => props.containerBgColor ? props.containerBgColor: "#EBDEDB"};
    margin-bottom: 27px;    
    color: ${(props) => props.textColor ? props.textColor: "#721B00"};
    min-height: 0;
    text-align: center;

    ${(props) => props.theme.breakpoints.down("md")} {        
        margin-top: 0;
        margin-bottom: 20px;
    }  
    
    .c-btn {
        margin: 95px auto 30px;

        ${(props) => props.theme.breakpoints.down("md")} {        
            margin-top: 0;
        }
    }
`;

export const DetailsHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: ${(props) => props.extended ? "80px 110px 0" : "80px 110px"};

    ${(props) => props.theme.breakpoints.down("lg")} { 
        padding: ${(props) => props.extended ? "80px 80px 0" : "80px"};
    } 

    ${(props) => props.theme.breakpoints.down("md")} {           
        padding: ${(props) => props.extended ? "40px 40px 30px" : "40px"};  
    }

    ${(props) => props.theme.breakpoints.down("sm")} {         
        padding: 25px 20px 30px;  
    }

    ${StyledAudiobookItem} {
        max-width: 495px;
        margin: 42px auto 0; 

        ${(props) => props.theme.breakpoints.down("sm")} {
            max-width: none;
            width: auto;
            margin: 42px -12px 0;
        }
    }    
`;

export const TextHolder = styled.div`
    text-align: center;
    font-family: JJannon;
    font-size: 22px;        
    line-height: 28px;
    letter-spacing: 0.02em;  

    ${(props) => props.theme.breakpoints.down("md")} {    
        font-size: 18px;        
        line-height: 24px;      
    }

    ${(props) => props.theme.breakpoints.down("sm")} { 
        font-style: italic;
    }
`;



export const StyledLink = styled(Link)`
    display: block;
`;