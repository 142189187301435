import styled from "styled-components/macro";

export const PopupHeader = styled.div``;
export const ParsedText = styled.div``;

export const StyledPopupContainer = styled.div`
    display: ${(props) => (props.isOpen ? "block" : "none")};
`;

export const StyledPopup = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 700px;
    background-color: #f6f2e6;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    z-index: 100;
    overflow-y: scroll;

    ${(props) => props.theme.breakpoints.down("sm")} {
        width: 100%;
        height: 100%;
    }

    ${PopupHeader} {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }

    ${ParsedText} {
        max-width: 375px;
        margin: 0 auto;
        padding: 0 20px 56px;
        font-size: 18px;
        line-height: 24px;
        font-family: 'JJannon', serif;
        letter-spacing: 0.015em;

        p {
            margin-bottom: 20px;
        }
    }
`;
export const PaddingTop = styled.div`
    position: fixed;
    top: calc(50% - 240px);
    left: calc(50% - 8px);
    transform: translate(-50%, -50%);
    height: 20px;
    width: 684px;
    background-color: #f6f2e6;
    z-index: 101;

    ${(props) => props.theme.breakpoints.down("sm")} {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
    }
`;

export const PaddingBottom = styled.div`
    position: fixed;
    top: calc(50% + 240px);
    left: calc(50% - 8px);
    transform: translate(-50%, -50%);
    height: 20px;
    width: 684px;
    background-color: #f6f2e6;
    z-index: 100;

    ${(props) => props.theme.breakpoints.down("sm")} {
        top: initial;
        left: 0;
        bottom: 0;
        transform: none;
        width: 100%;
    }
`;