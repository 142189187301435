import * as libraryActionTypes from "../../store/actions/libraryActions";

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PageMeta from '../../shared/PageMeta/PageMeta';
import DownloadIcons from 'shared/DownloadIcons/DownloadIcons';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Buttons, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';

class RedemptionWelcome extends Component {

    componentDidMount() {
        if (!this.props.redemptionAudioBook?.spiSIN) {
            this.props.history.push(`/redemption/${this.props.match.params.spisin}`)
        }
    }

    render() {
        let content = 
            <StyledRedemptionContainer>
                <PageMeta>
                    <title>Redemption Welcome | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                <AudioBook data={this.props.redemptionAudioBook} />

                    <StyledContent>
                        <Title>Welcome {this.props.user?.firstName}</Title>
                        <Paragraph>{this.props.redemptionAudioBook?.title} <span className='font-secondary'>is now in your Library. Just tap on the title when you’re ready to listen</span>.</Paragraph>

                        <ParagraphSmall>Most Spiracle Editions are available from {this.props.redemptionBookshop} and <span>other bookshops around the UK</span>. 
                            When you purchase in this way, we split the income between the bookshop and the publisher.
                            If you purchase audiobooks directly from Spiracle, we split the income with the publisher. 
                        </ParagraphSmall>

                        <Buttons className='mt-50'>
                            <Link id='gtm-redemption-welcome-library' to='/library' className="c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center">View your library <i className='icon-library font-12 font-black'></i></Link>
                        </Buttons>

                        <ParagraphSmall className='mt-50 mb-15'><span>Explore More Titles</span>: Spiracle offers a curated collection of audiobooks from literary publishers. </ParagraphSmall>
                        <Buttons>
                            <Link id='gtm-redemption-welcome-collection' to='/audiobooks' className="c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center mb-50">View the collection</Link>
                        </Buttons>

                        <DownloadIcons />

                        <ParagraphSmall className='mt-10 mb-15'>Discover new voices, free audiobooks, articles, and events in Spiracle’s Murmurations area.</ParagraphSmall>
                        <Buttons>
                            <Link id='gtm-redemption-welcome-murmurations' to='/murmurations' className="c-btn c-btn__ghost font-secondary font-14 letter-spacing-15 font-uppercase text-center">Murmurations</Link>
                        </Buttons>
                    </StyledContent>
                    
                </StyledRedemptionContainer>
        return content
    }
}

const mapStateToProps = state => ({
    redemptionAudioBook: state.redemptionReducer.redemptionAudioBook,
    redemptionBookshop: state.redemptionReducer.redemptionCode.bookshop,
    user: state.userReducer.user
})

const mapDispatchToProps = (dispatch) => {
    return {
        onGetLibraryItems: (libraryItems, total) => dispatch(libraryActionTypes.onGetLibraryItems(libraryItems, total)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionWelcome);