import styled from "styled-components/macro";

export const StyledSelect = styled.div`
    font-family: 'Gill Sans Nova';

    .react-select-container {
        height: 46px;

        .react-select {
            &__control {
                box-shadow: none;
                border: none;
                border-radius: 0;
                height: 46px;

                &--menu-is-open {
                    .react-select__dropdown-indicator {
                        &::before {
                            transform: rotate(135deg);
                            margin-top: 3px;
                        }
                    }
                }
            }

            &__placeholder {
                color: #bdbab1;
                letter-spacing: 0.02em;
            }

            &__indicator-separator {
                display: none;
            }

            &__indicator {
                padding: 4px;
                color: #000;
            }

            &__dropdown-indicator {
                svg {
                    display: none;
                }

                &::before {
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border: 1px solid #bdbab1;
                    border-top: 0;
                    border-right: 0;
                    transform: rotate(-45deg);
                    margin-right: 6px;
                    margin-top: -5px;
                }
            }

            &__input-container {
                margin: 0;
                padding: 0;
            }

            &__menu {
                border: none;
                border-top: 1px solid rgba(0, 0, 0, .3);
                border-radius: 0;
                box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
                margin: 0;
            }

            &__option {
                &--is-selected {
                    background: transparent;
                    color: #000;
                }

                &--is-focused {
                    background: #f4eedc;
                }
            }
        }
    }
`;

