import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import bookReducer from './store/reducers/bookReducer';
import cartReducer from './store/reducers/cartReducer';
import discountReducer from './store/reducers/discountReducer';
import libraryReducer from './store/reducers/libraryReducer';
import membershipPriceReducer from 'store/reducers/membershipPriceReducer';
import orderReducer from 'store/reducers/reducerActions';
import thunk from 'redux-thunk';
import userReducer from './store/reducers/userReducer';
import redemptionReducer from './store/reducers/redemptionReducer';

const rootReducer = combineReducers({
    userReducer: userReducer,
    bookReducer: bookReducer,
    cartReducer: cartReducer,
    libraryReducer: libraryReducer,
    orderReducer: orderReducer,
    membershipPriceReducer: membershipPriceReducer,
    discountReducer: discountReducer,
    redemptionReducer: redemptionReducer,
});

const logger = (store) => {
    return (next) => {
        return (action) => {
            const result = next(action);
            return result;
        };
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
