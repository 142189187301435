import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from 'react-redux';

import ApiService from "../../../services/api.service";
import { Link } from "react-router-dom";
import PageMeta from "../../../shared/PageMeta/PageMeta";
import { ReactComponent as SpiracleLogoSmall } from "../../../images/Spiral.svg";

class ResetPassword extends Component {
    state = {
        passwordEye: true,
        uuid: "",
        resetToken: "",
        error: false,
        passwordChanged: false,
    };

    componentDidMount() {
        // if (localStorage.getItem("token") == null) {
        //     this.props.history.push("/access-denied");
        // } else {
            this.setState({
                uuid: this.props.location.pathname.split("/")[2],
                resetToken: this.props.location.search.split("=")[1],
            });
        //}
    }

    /**
     * Handle toggle password eye
     */
    togglePassword = () => {
        this.setState({
            passwordEye: !this.state.passwordEye,
        });
    };

    /**
     * Handle change password
     */
    submitChangePassword = (values, callbacks) => {
        this.setState(
            {
                error: false,
            },
            () => {
                const data = {
                    resetToken: this.state.resetToken,
                    password: values.password,
                };

                const { setSubmitting } = callbacks;

                ApiService.forgotPasswordChange(this.state.uuid, data)
                    .then((r) => {
                        this.setState(
                            {
                                passwordChanged: true,
                            },
                            () => {
                                setSubmitting(false);
                            }
                        );
                    })
                    .catch((e) => {
                        this.setState(
                            {
                                error: true,
                            },
                            () => {
                                setSubmitting(false);
                            }
                        );
                    });
            }
        );
    };

    render() {
        //** FORMIK CHANGE PASSWORD */
        const changePasswordValidationSchema = Yup.object().shape({
            password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long").max(60, "Password must be less than 60 characters long"),
        });

        const changePasswordInitialValues = {
            password: "",
        };
        //** FORMIK CHANGE PASSWORD END */

        return (
            <div className="container container__basic-page">
                <PageMeta>
                    <title>Reset password | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                {!this.state.passwordChanged ? (
                    <div className="c-form">
                        <h1 className="c-form__choose-password-heading font-uppercase font-secondary font-400">CHOOSE A NEW PASSWORD</h1>
                        <Formik initialValues={changePasswordInitialValues} validateOnMount={true} validationSchema={changePasswordValidationSchema} onSubmit={this.submitChangePassword}>
                            {(form) => {
                                return (
                                    <Form className="c-form">
                                        <div className="password-field mb-20">
                                            <Field
                                                className={`bg-white ${form.errors && form.errors.password && form.touched && form.touched.password ? "invalid" : ""}`}
                                                placeholder="Choose password"
                                                type={this.state.passwordEye ? "password" : "text"}
                                                name="password"
                                                required
                                                id="password"
                                            />
                                            <i onClick={() => this.togglePassword()} className={`icon-eye password-field--eye ${this.state.passwordEye ? "" : "show"}`}></i>
                                            <div className="c-error-message mt-3">
                                                {(form.errors && !form.errors.password) || (form.touched && !form.touched.password) ? (
                                                    <span className="font-12 font-gray font-italic font-secondary">Passwords should be at least 8 characters long</span>
                                                ) : null}
                                                <ErrorMessage name="password" component="div" />
                                                {this.state.error ? <div>Something went wrong, please try again</div> : null}
                                            </div>
                                        </div>
                                        <div className="mt-22">
                                            <button
                                                id='gtm-reset-password'
                                                className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${form.isSubmitting ? "submitting" : ""}`}
                                                type="submit"
                                                disabled={form.isSubmitting || !form.isValid}
                                            >
                                                Reset Password
                                                <SpiracleLogoSmall />
                                            </button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                ) : (
                    this.props.user ?
                        <div className="font-italic text-center">
                            Your new password is set.
                        </div>
                        :
                        <div className="font-italic text-center">
                            Your new password is set, please proceed to the{" "}
                            <Link id='gtm-reset-password-page-login' to="/login" className="underline">Log in</Link>{" "}
                            page.
                        </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
});

export default connect(mapStateToProps)(ResetPassword);
