import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Paragraph = styled.div``;

export const StyledSpecial = styled.div`
    padding: 50px 22px 50px 0;
    border-bottom: 1px solid #000;

    &:first-of-type {
        padding-top: 0;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 30px 0;
    }

    a {
        display: flex;
        gap: 48px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            flex-direction: column;
            gap: 25px;
        }

        > div {
            text-align: left;

            ${(props) => props.theme.breakpoints.down('sm')} {
                text-align: center;
            }
        }
    }

    img {
        display: block;
        width: 227px;
        height: 227px;
        object-fit: contain;

        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100%;
            height: auto;
        }
    }

    ${Subtitle} {
        font-size: 22px;
        line-height: 38px;
        margin-bottom: 12px;
    }

    ${Title} {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 15px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 22px;
            line-height: 22px;
        }
    }

    ${Paragraph} {
        font-family: 'Gill Sans Nova';
        font-size: 20px;
        line-height: 29px;
        margin: 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 18px;
            line-height: 26px;
        }

        p {
            margin: 0;
        }
    }
`;

