import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;

export const StyledAstonishingMemoirs = styled.div`
    margin: 90px auto 65px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 36px auto;
    }

    ${Subtitle} {
        font-size: 18px;
        line-height: 43px;
        letter-spacing: 6px;
        margin-bottom: 6px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
            margin-bottom: 0;
        }
    }

    ${Title} {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 43px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
            margin-bottom: 18px;
        }
    }

    div {
        a.sa-button-red {
            margin-top: 40px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 18px !important;
                margin-top: 5px;
                width: 100% !important;
                padding: 10px 20px 15px !important;
            }
        }
    }
`;

