import React, { Component } from 'react';

import PageMeta from '../../shared/PageMeta/PageMeta';
import classes from './Page500.module.scss';
import image from '../../images/payment-problem.webp';

export default class Page500 extends Component {
    state = {
        isLoading: true,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 300);
    }

    render() {
        let content = <div></div>;

        if (!this.state.isLoading) {
            content = (
                <div className={`${classes.Page500} text-center`}>
                    <PageMeta>
                        <title>Server Error | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>

                    <h1>Internal Server Error</h1>
                    <h2 className="font-italic">Oops, looks like there is a problem on our server.</h2>
                    <img src={image} alt="" className="mt-20 mb-40" />
                    <p className="font-12 lh-18 font-uppercase letter-spacing-15 font-secondary">
                        Please try later <br /> or <br /> contact our <a href="mailto:support@spiracleaudiobooks.com">support team</a> to solve the problem.
                    </p>
                </div>
            );
        }

        return content;
    }
}
