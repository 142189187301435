import * as bookActionTypes from '../../store/actions/bookActions';
import * as orderActionTypes from '../../store/actions/orderActions';
import * as userActionTypes from '../../store/actions/userActions';

import React, { Component } from 'react'

import { EventSourcePolyfill } from 'event-source-polyfill';
import config from '../../config';
import { connect } from 'react-redux';

let eventSource = null;


class Mercury extends Component {
  componentDidUpdate(prevProps, prevState) {
      if (prevProps.uuid !== this.props.uuid) {
        if (this.props.uuid) {
          this.mercurySubscribe();
        }
      } else {
        // if (eventSource) {
        //   eventSource.close();
        //   eventSource = null;
        // }
      }
  }

  mercurySubscribe = () => {
    let url = '';
    if (process.env.REACT_APP_STAGE) {
      url = new URL(`${config.apiGateway.URL}/.well-known/mercure`);
    } else {
      url = new URL(`${config.apiGateway.URL}/.well-known/mercure`);
    }

    url.searchParams.append('topic', `user/${this.props.uuid}`);
    eventSource = new EventSourcePolyfill(`${url}`, {
      heartbeatTimeout: 3600000,
      headers: {
        Authorization: `Bearer ${this.props.jwtToken}`
      }
    });

    eventSource.onmessage = message => {
      const parsedData = JSON.parse(message.data);
      switch (parsedData.type) {
        case 'new_order':
          this.props.onSetOrder(parsedData.order_uuid);
          break;
        case 'epub':
          setTimeout(() => {
            this.props.onEpubFinish(parsedData);
          }, 5000)
          break;
        default:
          break;
      }
    };
  }

  render() {
      return <></>
  }
}

const mapStateToProps = (state) => ({
    jwtToken: state.userReducer.user ? state.userReducer.user.jwtMercuryToken : null,
    uuid: state.userReducer.user ? state.userReducer.user.uuid : null
});
  
const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onSetOrder: (order) => dispatch(orderActionTypes.onSetOrder(order)),
        onEpubFinish: (epub) => dispatch(bookActionTypes.onEpubFinish(epub))
    }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Mercury)
