import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;

export const StyledMurmuration = styled.div`
    padding: 38px 0;
    border-bottom: 1px solid #000;

    ${(props) => props.theme.breakpoints.down('lg')} {
        border-bottom: 0;
        padding: 33px 0;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 0;
    }

    &:first-of-type {
        padding-top: 0;
    }

    &:nth-of-type(2) {
        ${(props) => props.theme.breakpoints.down('lg')} {
            padding-top: 0;
        }
    }

    a {
        display: flex;
        gap: 25px;

        > div {
            text-align: left;
        }
    }

    img {
        display: block;
        width: 103px;
        height: 103px;
        object-fit: contain;
    }

    ${Subtitle} {
        font-family: 'Gill Sans Nova';
        font-size: 16px;
        letter-spacing: 3px;
        margin-bottom: 18px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
            letter-spacing: 2px;
        }
    }

    ${Title} {
        font-size: 26px;
        line-height: 26px;
        margin: 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 22px;
            line-height: 22px;
        }
    }
`;
