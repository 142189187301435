import styled from "styled-components/macro";

export const StyledTextSlide = styled.div`
    width: 94%;
    height: 130px;
    padding: 10px;
    background: #f9f7ed;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans Nova', sans-serif;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        width: calc(100% - 6px);
        font-size: 14px;
        line-height: 20px;
    }
`;

