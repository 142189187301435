import styled from 'styled-components/macro';

export const StyledMediaPlayer = styled.div`
    position: relative;
    margin: ${(props) =>
        props.embeddedPlayer ? "0 auto 40px" : "0 auto 30px"};;
    background-color: ${(props) =>
        props.embeddedPlayer ? "#f9f7ed" : "transparent"};
    border-radius: ${(props) =>
        props.embeddedPlayer ? "2px" : "none"};
    box-shadow: ${(props) =>
        props.embeddedPlayer ? "0px 4px 4px rgba(0, 0, 0, 0.1)" : "none"};
    max-width: ${(props) =>
        props.embeddedPlayer ? "494px" : "initial"};
    
    ${(props) => props.theme.breakpoints.down("md")} {       
        ${props => {
            if (props.embeddedPlayer) {
                return `
                    width: calc(100% + 24px);
                    margin-left: -12px;
                `
            }
            if (props.miniPlayer) {
                return `
                    margin: 0 0 0 auto;
                
                `
            } else {
                return `
                    margin: 0 auto 30px;        
                `
            }
        }}
    }

    .rhap_container {
        padding: ${(props) =>
            props.embeddedPlayer ? "50px 0 15px" : "70px 0 50px"};        
        background-color: ${(props) =>
        props.isEdition ? "#EBDEDB" : "transparent"};
        box-shadow: none;

       @media (max-height: 811px), (max-width: 374px) {
            padding: ${(props) =>
                props.embeddedPlayer ? "50px 0 15px" : "30px 0"};
        }

        &:focus {
            outline: none;
        }
    }

    .rhap_time {
        position: absolute;
        top: ${(props) =>
            props.embeddedPlayer ? "76px" : "97px"};
        position: absolute;
        font-family: Gill Sans Nova;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.1em;
        color: ${(props) => (props.isEdition ? "#721B00" : "#000000")};

        @media (max-height: 811px), (max-width: 374px) {
            top: ${(props) =>
                props.embeddedPlayer ? "76px" : "57px"};
        }
    }

    .rhap_total-time {
        right: ${(props) =>
            props.embeddedPlayer ? "20px" : "22px"};        
    }

    .rhap_current-time {
        left: ${(props) =>
            props.embeddedPlayer ? "20px" : "22px"};      
    }

    .rhap_progress-section {
        padding: ${(props) =>
            props.embeddedPlayer && "0 36px"};

        ${(props) => props.theme.breakpoints.down("md")} {
            padding: ${(props) =>
                props.embeddedPlayer && "0 22px"};
        }
    }

    .rhap_progress-container {
        margin: ${(props) =>
            props.embeddedPlayer ? "0 auto" : "0 38px"};

        &:focus {
            outline: none;
        }

        @media (hover: hover) {
            &:hover {
                &::after, &::before {                   
                    background-color: ${(props) => (props.isEdition ? "#000000" : "#721B00")};                   
                }
            }
        }

        

        &::after, &::before {
            content: " ";
            position: absolute;
            height: 1px;
            width: 38px;
            background-color: ${(props) => (props.isEdition ? "#721B00" : "#000000")};
            display: ${(props) =>
                props.embeddedPlayer && "none"};
            pointer-events: none;
        }

        &::after {
            right: 0;
        }

        &::before {
            left: 0;
        }
    }

    .rhap_progress-bar {
        height: 1px;
        background-color: ${(props) =>
            props.isEdition ? "#721B00" : "#000000"};

        &:focus {
            outline: none;
        }
    }

    .rhap_progress-indicator {
        background-color: ${(props) =>
            props.isEdition ? "#721B00" : "#000000"};
        width: 6px;
        height: 6px;
        margin-left: 0;
        top: -3px;
        //transition: all 0.2s ease-in-out;
        transform: translate(-50%);
    }

    .rhap_volume-container,
    .rhap_repeat-button {
        display: none;
    }

    .rhap_main-controls {
        margin-top: ${(props) =>
            props.embeddedPlayer ? "17px" : "52px"};
            //props.embeddedPlayer ? "17px" : props.isEdition ? '52px' : '82px'};       
            transform: scale(1);       

        @media (max-height: 811px), (max-width: 374px) {
            transform: ${(props) =>
                !props.embeddedPlayer && "scale(0.8)"};
            margin-top: ${(props) =>
                props.embeddedPlayer ? "17px" : "35px"}; 
        }
        
    }

    .rhap_main-controls-button {
        margin: 0 18px;
        svg {
            path,
            polygon {
                fill: ${(props) => (props.isEdition ? "#721B00" : "#000000")};
            }
        }
    }

    @media (hover: hover) {
        .rhap_main-controls-button {
            &:hover {
                svg {
                    path,
                    polygon {
                    fill: ${(props) =>
                        props.isEdition ? "#000000" : "#721b00"};
                    }
                }
            }
        }

        .rhap_progress-container {
            &:hover {
                .rhap_progress-indicator {
                    transform: scale(1.5) translateX(-2px);
                }

                .rhap_progress-bar,
                .rhap_progress-indicator {
                    background-color: ${(props) =>
                    props.isEdition ? "#000000" : "#721b00"};
                }
            }
        }
    }

    @media (hover: none) {
        .rhap_progress-indicator {
            transform: scale(2) translate(-50%);
            margin-left: 3px;
        }
    }

    .rhap_play-pause-button {
        width: 80px;
        height: 80px;
        transform: ${(props) =>
            props.embeddedPlayer ? "scale(.75)" : "none"};

        &:hover {
            opacity: 1;
        }
    }

    .rhap_rewind-button {
        width: 38px;
        height: 38px;
    }

    .rhap_forward-button {
        width: 38px;
        height: 38px;
    }
`;
