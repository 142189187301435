import React, { Component } from "react";

import AudioBookPlayer from "pages/AudioBookPlayer/AudioBookPlayer";
import Footer from "layout/Footer/Footer";
import Header from "layout/Header/Header";
import PropTypes from "prop-types";

class LayoutRedemption extends Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Header redemptionLayout />
        <main className="full-height">
          {children}
          {window.innerWidth < 768 ? <AudioBookPlayer className={'widget-player'} desktopPlayer={true}/> : null}
        </main>
         <Footer />
      </>
    );
  }
}

LayoutRedemption.propTypes = {
  children: PropTypes.any,  
};

export default LayoutRedemption;
