import styled from "styled-components/macro";

export const StyledHomepage = styled.div`
    max-width: 1425px;
    margin: 0 auto 20px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        padding: 0 16px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin-top: 60px;
    }

    * + .style__StyledIntro-sc-mntnwt-8 {
        margin-top: 220px;

        ${(props) => props.theme.breakpoints.down('lg')} {
            margin-top: 100px;
        }
    }
`;