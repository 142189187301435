import React, { useState, useEffect } from 'react';

import ApiService from 'services/api.service';

import SecondEditions from 'components/UI/Homepage2/SecondEditions/SecondEditions';
import AboutSpiracle from 'components/UI/Homepage2/AboutSpiracle/AboutSpiracle';
import HowSpiracleWorks from 'components/UI/Homepage2/HowSpiracleWorks/HowSpiracleWorks';
import AstonishingMemoirs from 'components/UI/Homepage2/AstonishingMemoirs/AstonishingMemoirs';
import FeaturedAudiobook from 'components/UI/Homepage2/FeaturedAudiobook/FeaturedAudiobook';
import SpiracleEditions from 'components/UI/Homepage2/SpiracleEditions/SpiracleEditions';
import WritersDilemma from 'components/UI/Homepage2/WritersDilemma/WritersDilemma';
import DownloadApp from 'shared/DownloadApp/DownloadApp';

import PageMeta from 'shared/PageMeta/PageMeta';
import Loader from 'shared/Loader/Loader';

import { StyledHomepage } from './style2';
import Murmurations from 'components/UI/Homepage2/Murmurations/Murmurations';
import Intro from 'components/UI/Homepage2/Intro/Intro';

function Homepage2() {
    const [homepagePanels, setHomepagePanels] = useState([])
	const [isLoadingHomepagePanels, setIsLoadingHomepagePanels] = useState(true);

    const [secondEditions, setSecondEditions] = useState([])
	const [isLoadingSecondEditions, setIsLoadingSecondEditions] = useState(true);

    const [editions, setEditions] = useState([]);
    const [isLoadingEditions, setIsLoadingEditions] = useState(true);

    const [intro, setIntro] = useState([]);
    const [isLoadingIntro, setIsLoadingIntro] = useState(true);

    const [sortedList, setSortedList] = useState([]);

    useEffect(() => {
        const paramsCat = new URLSearchParams()
        paramsCat.append('itemsPerPage', 200);

        const params = new URLSearchParams();
        params.append('itemsPerPage', 12);

        ApiService.getHomepagePanels().then(r => {
            const res = r.data['hydra:member'][0]
            setHomepagePanels(res)

            setIsLoadingHomepagePanels(false)

            const list = [
                {
                    weight: res.curatedAudioBookWeight,
                    visible: res.curatedAudioBookVisible,
                    name: 'Intro'
                },
                {
                    weight: res.currentHighlightsWeight,
                    visible: res.currentHighlightsVisible,
                    name: 'Second Editions'
                },
                {
                    weight: res.spiracleLocalShopWeight,
                    visible: res.spiracleLocalShopVisible,
                    name: 'About Spiracle'
                },
                {
                    weight: res.howSpiracleWorksWeight,
                    visible: res.howSpiracleWorksVisible,
                    name: 'How Spiracle Works'
                },
                {
                    weight: res.astonishingMemoirsWeight,
                    visible: res.astonishingMemoirsVisible,
                    name: 'Astonishing Memoirs'
                },
                {
                    weight: res.featuredAudioBookWeight,
                    visible: res.featuredAudioBookVisible,
                    name: 'Featured Audiobook'
                },
                {
                    weight: res.spiracleEditionsWeight,
                    visible: res.spiracleEditionsVisible,
                    name: 'Spiracle Editions'
                },
                {
                    weight: res.writersDilemmaWeight,
                    visible: res.writersDilemmaVisible,
                    name: 'Writers Dilemma'
                },
                {
                    weight: res.spiracleMurmurationsWeight,
                    visible: res.spiracleMurmurationsVisible,
                    name: 'Murmurations'
                },
                {
                    weight: res.spiraclePlayerAppWeight,
                    visible: res.spiraclePlayerAppVisible,
                    name: 'Download App'
                },
            ]

            const sorted = list.sort((a, b) => a.weight - b.weight);
            setSortedList(sorted);
        }).catch(e => {
            setIsLoadingHomepagePanels(false)
        })

        ApiService.getStaffPicks().then(r => {
			setIntro(r.data['hydra:member']);
			setIsLoadingIntro(false);
		}).catch(e => {
            setIsLoadingIntro(false);
        })

        ApiService.getSecondEditions(params).then(r => {
			setSecondEditions(r.data['hydra:member']);
			setIsLoadingSecondEditions(false);
		}).catch(e => {
            setIsLoadingSecondEditions(false);
        })

        ApiService.getAudioBookEdition(params).then(r => {
            const modArr = r.data['hydra:member'].map(item => item.audioBook)
			setEditions(modArr);
            setIsLoadingEditions(false);
        }).catch(e => {
            setIsLoadingEditions(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageMeta>
                <title>Spiracle | Free Audiobooks</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            {
                isLoadingHomepagePanels ||
                isLoadingIntro ||
                isLoadingSecondEditions ||
                isLoadingEditions  
                    ? <Loader /> : 
                        <StyledHomepage>
                            {
                                sortedList.map((item, index) => {
                                    return item.name === 'Intro' && item.visible ? <Intro key='homepage-intro' data={intro} title={homepagePanels.curatedAudioBookTitle} /> : 
                                        item.name === 'Second Editions' && item.visible && secondEditions?.length ? <SecondEditions key='homepage-second-editions' data={secondEditions} title={homepagePanels.currentHighlightsTitle} /> :
                                        item.name === 'About Spiracle' && item.visible ? <AboutSpiracle key='homepage-about-spiracle' title={homepagePanels.spiracleLocalShopTitle} /> :
                                        item.name === 'How Spiracle Works' && item.visible ? <HowSpiracleWorks key='homepage-how-spiracle-works' title={homepagePanels.howSpiracleWorksTitle} /> :
                                        item.name === 'Astonishing Memoirs' && item.visible && homepagePanels.astonishingMemoirsAudioBooks?.length ? <AstonishingMemoirs key='homepage-astonishing-memoirs' data={homepagePanels.astonishingMemoirsAudioBooks} title={homepagePanels.astonishingMemoirsTitle} /> :
                                        item.name === 'Featured Audiobook' && item.visible && homepagePanels.featuredAudioBooks[0] ? <FeaturedAudiobook key='homepage-featured-audiobook' data={homepagePanels.featuredAudioBooks[0]} /> :
                                        item.name === 'Spiracle Editions' && item.visible && editions?.length ? <SpiracleEditions key='homepage-spiracle-editions' data={editions} title={homepagePanels.spiracleEditionsTitle} /> :
                                        item.name === 'Writers Dilemma' && item.visible ? <WritersDilemma key='homepage-writers-dilemma' data={homepagePanels.writersDilemmaAudioBooks} title={homepagePanels.writersDilemmaTitle} /> :
                                        item.name === 'Murmurations' && item.visible && (homepagePanels.featuredMurmurations?.length || homepagePanels.specialFeaturedMurmurations?.length) ? 
                                            <Murmurations key='homepage-murmurations' murmurations={homepagePanels.featuredMurmurations} specialMurmurations={homepagePanels.specialFeaturedMurmurations} title={homepagePanels.spiracleMurmurationsTitle} /> :
                                        item.name === 'Download App' && item.visible ? <DownloadApp key='homepage-download-app' title={homepagePanels.spiraclePlayerAppTitle} /> : null
                                })
                            }
                        </StyledHomepage>
            }
        </>
    )
}

export default Homepage2