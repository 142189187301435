import styled from "styled-components/macro";

export const StyledSpeed = styled.div``;
export const StyledSleep = styled.div``;

export const StyledPlayerLayout = styled.div`
    
`;

export const StyledFooter = styled.div`
    background-color: ${(props) => (props.isEdition ? "#EBDEDB" : "#F4EEDC")};
    color: ${(props) => (props.isEdition ? "#831F00" : "#000000")};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;  // 30px - 5px icon padding;
    height: 62px;
    align-items: center;
    visibility: hidden;

    .player-visible & {
        visibility: visible;
    }

    i {
        padding: 5px;

        &:hover {
            color: ${(props) => (props.isEdition ? "#831F00" : "#000000")};
        }

        &.disabled {
            color: ${(props) => (props.isEdition ? "rgba(114, 27, 0, 0.3)" : "rgba(0, 0, 0, 0.3)")};
        }
    }

    ${StyledSpeed} {
        font-family: Gill Sans Nova;
        font-size: 14px;     
        cursor: pointer;
        padding: 3px;

        span {
            margin-left: 3px;
        }

        /*
        i {
            font-size: 8px;
            vertical-align: middle;
            padding: 0;
        }
        */

        &.timer-on {
            transform: translateX(-25px);
        }

        &:hover {
            color: #721b00;
        }

        
    }

    ${StyledSleep} {
        display: flex;
        align-items: center;
        span {
            font-family: Gill Sans Nova;
            letter-spacing: 0.01em;
            font-size: 12px;
            width: 50px;
        }
    }

`;

