import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Buttons, StyledIntro } from './style';

function Intro() {
    const user = useSelector(state => state.userReducer.user);
    const subscription = user?.currentSubscription
    return (
        subscription ? 
            null : 
            <StyledIntro>
                <div>
                    <h1 className='font-italic mb-10'>
                        {user ? 'Unlock the full Spiracle experience with a Premium Membership.' : 'Sign up for free audiobooks, articles and podcasts.'}
                    </h1>
                    <p className='font-secondary mb-0'>
                        {user ? 'Enjoy discounted prices, get free audiobooks every month and much more!' : 'Upgrade to a Premium Membership for discounted prices, free audiobooks and more!'}
                    </p>
                </div>

                
                <Buttons>
                    {user ?
                        <>
                            <div>
                                <Link id='gtm-browsepage-trial-user' to='/free-trial' className='sa-button-red sa-button-rounded'>Start 30-day Premium trial</Link>
                                <p className='font-11 font-secondary font-uppercase text-center mt-7 mb-0'>No credit card required</p>
                            </div>
                            <Link id='gtm-browsepage-gift-membership-user' to='/subscription' className='sa-button-ghost sa-button-rounded'>See Membership plans & gifts</Link>
                        </>
                    :  <>
                            <Link id='gtm-browsepage-sign-up' to='/sign-up' className='sa-button-red sa-button-rounded'>Join Spiracle for free</Link>
                            <div>
                                <Link id='gtm-browsepage-trial' to='/free-trial' className='sa-button-ghost sa-button-rounded'>Start 30-day Premium trial</Link>
                                <p className='font-11 font-secondary font-uppercase text-center mt-7 mb-0'>No credit card required</p>
                            </div>
                        </>}
                    
                </Buttons>
            </StyledIntro>
    )
}

export default Intro;