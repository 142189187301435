import React, { Component } from 'react';
import { withRouter } from 'react-router';

class BackButton extends Component {    
    render() {       
        return (            
           <button id='gtm-audiobook-back' className='c-back-button' onClick={() => this.props.history.goBack()}>
                <i className='icon-left-arrow' /> Back
           </button>
        )
    }
}

export default withRouter(BackButton);
