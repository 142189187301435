import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Paragraph = styled.p``;
export const Container = styled.div``;

export const StyledMurmurations = styled.div`
    position: relative;
    margin: 155px 0 290px;
    z-index: 0;
    padding: 0 20px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        margin: 155px 0 215px;
        padding: 0;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 105px 0 110px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -60px;
        left: -50%;
        width: 200%;
        height: calc(100% + 185px);
        background: #f9f7ed;
        z-index: -1;

        ${(props) => props.theme.breakpoints.down('lg')} {
            height: calc(100% + 170px);
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            height: calc(100% + 105px);
        }
    }

    ${Subtitle} {
        font-family: 'Gill Sans Nova';
        font-size: 18px;
        line-height: 43px;
        letter-spacing: 6px;
        text-transform: uppercase;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
        }
    }

    ${Title} {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 43px;
        margin: 5px 0 11px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
            margin: 0 0 5px;
        }
    }

    ${Paragraph} {
        font-family: 'Gill Sans Nova';
        font-size: 22px;
        line-height: 37px;
        max-width: 880px;
        margin: 0 auto;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 18px;
        }
    }

    ${Container} {
        display: flex;
        gap: 66px;
        margin-top: 80px;

        ${(props) => props.theme.breakpoints.down('lg')} {
            flex-direction: column;
            gap: 55px;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            margin-top: 50px;
        }

        > div {
            &:first-of-type {
                flex: 1.12;
            }

            &:last-of-type {
                flex: 0.88;

                ${(props) => props.theme.breakpoints.down('lg')} {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 0 30px;
                }

                ${(props) => props.theme.breakpoints.down('sm')} {
                    grid-template-columns: 1fr;
                    gap: 30px 0;
                }
            }
        }
        
        + a.sa-button-red {
            margin: 70px auto 0;
            padding: 12px 45px 12px !important;

            ${(props) => props.theme.breakpoints.down('sm')} {
                width: 100% !important;
                margin-top: 28px;
                padding: 12px 20px 12px !important;
            } 
        }
    }
`;

