import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';

import ApiService from '../../services/api.service';

import PageMeta from '../../shared/PageMeta/PageMeta';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { StyledCheckoutForm } from 'components/UI/Ecommerce/CheckoutForm/style';
import { Form, Paragraph,  StyledContent, StyledRedemptionContainer, Title } from './style';

import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import { onSetUser } from 'store/actions/userActions';
import { onGetCartItems } from 'store/actions/cartActions';
import { onGetLibraryItems } from 'store/actions/libraryActions';


function RedemptionLogin(props) {
    const [loginError, setLoginError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        eMail: "",
        password: "",
    });
    const [validationState, setValidationState] = useState({
        eMail: true,
        password: true,
    })

    const [passwordVisible, setPasswordVisible] = useState(false);

    const redemptionCode = useSelector(state => state.redemptionReducer.redemptionCode.code);
    const redemptionAudioBook = useSelector(state => state.redemptionReducer.redemptionAudioBook);

    const dispatch = useDispatch();

    const history = useHistory();

    const spisin = props.match.params.spisin;

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!redemptionAudioBook?.spiSIN || token) {
            history.push(`/redemption/${spisin}`)
        }
    }, [redemptionAudioBook, history, spisin])

    const handleSubmit = (e) => {
        e.preventDefault()

        const dataLogin = {
            email: state.eMail,
            password: state.password,
        }

        setIsLoading(true)

        ApiService.login(dataLogin)
            .then((r) => {
                localStorage.setItem("token", r.data.token);
                localStorage.setItem("refresh_token", r.data.refresh_token);
                ApiService.getUser().then((r) => {
                   
                    let cartId = cookie.load('cartId')
                    if (cartId) {
                        const data = {
                            cartUuid: cartId
                        }
                        ApiService.updateCartId(r.data.uuid, data).then(
                            r => {
                                dispatch(onSetUser(r.data))
                                cookie.remove('cartId');

                                ApiService.getCartItems(cartId).then(
                                    cart => {
                                        dispatch(onGetCartItems(cart.data['hydra:member']))
                                        const data = {
                                            audioBook: {
                                                spiSIN: spisin
                                            }
                                        }
                                        ApiService.addToLibraryWithRedemptionCode(redemptionCode, data).then((r) => {
                                            ApiService.getLibrary().then(
                                                library => {
                                                    setIsLoading(false)
                                                    dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))
                                                    history.push(`/redemption/${spisin}/welcome`)
                                                }
                                            ).catch((e) => {
                                                console.log(e);
                                                setIsLoading(false)
                                                history.push(`/redemption/${spisin}/welcome`)
                                            })
                                        }).catch((eAdToL) => {
                                            console.log(eAdToL)
                                            ApiService.getLibrary().then(
                                                library => {
                                                    setIsLoading(false)
                                                    dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))

                                                    if (eAdToL.response.status === 422) {
                                                        history.push({
                                                            pathname: `/redemption/${spisin}/error`,
                                                            state: {error: 422}
                                                        })
                                                    }
                                                }
                                            ).catch((er) => {
                                                console.log(er);
                                                if (eAdToL.response.status === 422) {
                                                    history.push({
                                                        pathname: `/redemption/${spisin}/error`,
                                                        state: {error: 422}
                                                    })
                                                }
                                            })
                                        })
                                    }
                                )
                            }
                        )
                    } else {
                        if (r.data.cart && r.data.cart.uuid) {
                            ApiService.getCartItems(r.data.cart.uuid).then(
                                cart => {
                                    dispatch(onGetCartItems(cart.data['hydra:member']))
                                    const data = {
                                        audioBook: {
                                            spiSIN: spisin
                                        }
                                    }
                                    ApiService.addToLibraryWithRedemptionCode(redemptionCode, data).then((r) => {
                                        ApiService.getLibrary().then(
                                            library => {
                                                setIsLoading(false)
                                                dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))
                                                history.push(`/redemption/${spisin}/welcome`)
                                            }
                                        ).catch((e) => {
                                            console.log(e);
                                            setIsLoading(false)
                                            history.push(`/redemption/${spisin}/welcome`)
                                        })
                                    }).catch((eAdToL) => {
                                        console.log(eAdToL)
                                        ApiService.getLibrary().then(
                                            library => {
                                                setIsLoading(false)
                                                dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))

                                                if (eAdToL.response.status === 422) {
                                                    history.push({
                                                        pathname: `/redemption/${spisin}/error`,
                                                        state: {error: 422}
                                                    })
                                                }
                                            }
                                        ).catch((er) => {
                                            console.log(er);
                                            if (eAdToL.response.status === 422) {
                                                history.push({
                                                    pathname: `/redemption/${spisin}/error`,
                                                    state: {error: 422}
                                                })
                                            }
                                        })
                                    })
                                }
                            )
                        } else {
                            const data = {
                                audioBook: {
                                    spiSIN: spisin
                                }
                            }
                            ApiService.addToLibraryWithRedemptionCode(redemptionCode, data).then((r) => {
                                ApiService.getLibrary().then(
                                    library => {
                                        setIsLoading(false)
                                        dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))
                                        history.push(`/redemption/${spisin}/welcome`)
                                    }
                                ).catch((e) => {
                                    console.log(e);
                                    setIsLoading(false)
                                    history.push(`/redemption/${spisin}/welcome`)
                                })
                            }).catch((eAdToL) => {
                                console.log(eAdToL)
                                ApiService.getLibrary().then(
                                    library => {
                                        setIsLoading(false)
                                        dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))

                                        if (eAdToL.response.status === 422) {
                                            history.push({
                                                pathname: `/redemption/${spisin}/error`,
                                                state: {error: 422}
                                            })
                                        }
                                    }
                                ).catch((er) => {
                                    console.log(er);
                                    if (eAdToL.response.status === 422) {
                                        history.push({
                                            pathname: `/redemption/${spisin}/error`,
                                            state: {error: 422}
                                        })
                                    }
                                })
                            })
                        }

                        dispatch(onSetUser(r.data))
                    }
                });
            })
            .catch((e) => {
                setLoginError(true)
                setIsLoading(false);
            });
    }

    const handleChange = e => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });

        if (e.target.name === 'password' && e.target.value.length > 7) {
            document.querySelector('#password-length').style.visibility = 'hidden';
        }
    }

    const handleBlur = e => {
        const value = e.target.value
        if (e.target.name === 'eMail') {
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setValidationState({
                ...validationState,
                [e.target.name]: regex.test(String(e.target.value).toLowerCase())
            })
        } else if (e.target.name === 'password') {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 7 ? true : false
            })
        } 

        if (e.target.name === 'password' && e.target.value.length < 8) {
            document.querySelector('#password-length').style.visibility = 'visible';
        }
    }

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    }
    
    return (
        <StyledRedemptionContainer>
            <PageMeta>
                <title>Redemption Login | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <AudioBook data={redemptionAudioBook} />

            <StyledContent center>
                <Title>Congratulations</Title>
                <Paragraph>{redemptionAudioBook?.title} <span className='font-secondary'>is ready for you</span>.</Paragraph>

                <Form>
                    <StyledCheckoutForm>
                        <div>
                            <label>Your email</label>
                            <input className={`bg-white mb-30 ${!validationState.eMail ? 'invalid' : ''}`}
                                type="eMail"
                                name="eMail"
                                id="eMail"
                                value={state.eMail}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="relative">
                            <label>Password</label>
                            <input className={`bg-white ${!validationState.password ? 'invalid' : ''}`}
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                id="password"
                                value={state.password}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            <i onClick={handlePasswordVisibility} className={`icon-eye password-field--eye ${passwordVisible && "show"}`}></i>
                            <span id="password-length" className="font-12 font-italic font-secondary">Password must be at least 8 characters long</span>
                            <div className='mt-18 input-description'>A combination of letters, numbers and special charactures are best</div>
                        </div>
                        <button
                            id='gtm-redemption-log-in'
                            type="submit"
                            disabled={
                                isLoading ||
                                !validationState.eMail ||
                                !validationState.password
                            }
                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30 letter-spacing-15 ${isLoading ? 'submitting' : ''}`}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Log in
                            <SpiracleLogoSmall />
                        </button>
                        {loginError ? <div className="c-error-message mt-5"><div>Invalid credentials</div></div> : null}
                    </StyledCheckoutForm>
                </Form>
            </StyledContent>
        </StyledRedemptionContainer>
    )
}

export default RedemptionLogin;