import styled from "styled-components/macro";

export const StyledCoverImage = styled.div``;

export const CoverImageHolder  = styled.div`
    position: relative;
    text-align: initial; 
    transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;   
    opacity: ${(props) => props.loaded ? "1" : "0"};
    
    ${(props) => props.theme.breakpoints.up("sm")} {
        max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "100%")};
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }    

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;      
    }
`;
