import styled from "styled-components/macro";

export const StyledIntro = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 90px;
    background: #f9f5e9;
    padding: 25px 32px 22px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        flex-direction: column;
        gap: 38px;
        text-align: center;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 17px 17px 25px;
    }

    h1 {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 20px;
            line-height: 32px;
        }

        & + p {
            font-size: 18px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 16px;
            }
        }
    }
`;

export const Buttons = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 18px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        flex-direction: column;
    }

    a {
       ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100% !important;
        } 
    }

    .sa-button-ghost {
        font-style: italic !important;
    }

    p {
        opacity: 0.7;
    }
`;