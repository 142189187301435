import styled from "styled-components/macro";

export const Image = styled.div``;
export const Price = styled.div``;
export const Title = styled.div``;
export const Type = styled.div``;
export const Gift = styled.div``;
export const Discount = styled.div``;

export const MembershipCardContainer = styled.div`
    position: relative;
    max-width: 360px;
    height: 120px;
    background: #F9F7ED;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    font-family: 'JJannon';
    display: flex;
    transition: .3s ease-in-out;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);    
    } 

    ${Image} {
        display: flex;
        width: 120px;
        margin-right: 10px;

        img {
            display: block;
            width: 35px;
            height: 28px;
            margin: auto;
        }
    }

    ${Title} {
        font-size: 18px;
        margin: 12px 0 10px;

        span { 
            font-style: italic;
        }
    }

    ${Type} {
        font-style: italic;
        font-size: 18px;
    }

    ${Price} {
        font-family: 'Gill Sans Nova';
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        margin-top: 7px;

        span {
            &:not(.bracket, .old-price) {
                margin-right: 3px;
            }

            &.old-price {
                text-decoration: line-through;
            }

            &.bracket {
                vertical-align: text-top;
            }
        }
    }

    ${Gift} {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    ${Discount} {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
`;