import styled from "styled-components/macro";

export const StyledBrowse = styled.div`
    max-width: 1425px;
    margin: 0 auto 20px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        padding: 0 48px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin-top: 60px;
        padding: 0 16px;
    }
`;