import React, { Component } from 'react';

import { StyledGiftButton } from "components/UI/ShoppingBag/style";

class GiftButton extends Component {
    render() {
        return (
            <StyledGiftButton key={`styled-gift-button-${this.props.uuid}`}>                                   
                <input 
                    type="checkbox" 
                    id={`gtm-send-as-a-gift-${this.props.uuid}`}
                    key={`gtm-send-as-a-gift-${this.props.uuid}`}
                    className="muted--total" 
                    name="_gtm-send-as-a-gift"                                                        
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                />
                {
                    this.props.inLibrary ? 
                    <label htmlFor={`gtm-send-as-a-gift-${this.props.uuid}`}>
                    already in library, send as a gift? 
                        <i className="icon-checked" />
                        <i className="icon-not-checked" />                                   
                    </label>    
                    : 
                    <label htmlFor={`gtm-send-as-a-gift-${this.props.uuid}`} id='gtm-send-as-a-gift'>
                        send as a gift? 
                        <i className="icon-checked" />
                        <i className="icon-not-checked" />                                   
                    </label>   
                }
                                
            </StyledGiftButton>
        );
    }
}

export default GiftButton;