import React, { Component } from 'react';

import AudioBookListItem from 'components/UI/AudioBook/AudioBookListItem';
import { StyledOrderListHistory } from 'pages/Order/style';
import { connect } from 'react-redux';
import PageMeta from '../../shared/PageMeta/PageMeta';

class Library extends Component {
    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/login");
        }
    }

    render() {
        let libraryItems
        if (this.props.library) {
            libraryItems = this.props.library.map((book, index)=> {
                let bookInfo                    
                if (book.audioBook) {
                    bookInfo = book.audioBook
                } else {
                    bookInfo = book.eBook
                }    
                return (
                    <AudioBookListItem 
                        key={`book-${index}`}       
                        title={bookInfo.title}
                        authors={bookInfo.authors}
                        imgSrc={bookInfo.cover}
                        imgAlt={bookInfo.coverAlt}
                        link={`/${book.audioBook ? 'audiobooks' : 'ebooks'}/${bookInfo.slug}`}
                        eBook={bookInfo.eBook}
                        isEdition={bookInfo.edition}
                        bookBgColor={bookInfo.bookBackgroundColour}
                        containerBgColor={bookInfo.containerBackgroundColour}
                        textColor={bookInfo.textColour}
                        type={book.audioBook ? 'audiobook' : 'ebooks'}
                        horizontal
                        mobileOnly
                        hidePrice
                        className="mt-12"
                    />  
                );
            })
        }

        return (
            <StyledOrderListHistory className="container container__big">
                <PageMeta>
                    <title>Library | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {libraryItems}
            </StyledOrderListHistory>
        )
    }
}

const mapStateToProps = state => ({
    library: state.libraryReducer.library
})

export default connect(mapStateToProps)(Library)
