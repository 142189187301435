import Chip from "@material-ui/core/Chip";
import styled from "styled-components/macro";

export const Details = styled.div``;
export const Summary = styled.div``;
export const Genre = styled.div``;
export const Cta = styled.div``;
export const InfoButton = styled.div``;
export const StyledChip = styled(Chip)``;
export const ImageHolder = styled.div``;

export const Headline = styled.h2`
    max-width: 655px;
    margin: 0 auto 15px;

    ${(props) => props.theme.breakpoints.up("lg")} {
      text-align: center;
      font-size: 36px;
      line-height: 24px;
      letter-spacing: 0.02em;
      margin-bottom: 36px;
    }
`;

export const BgWrapperMob = styled.div`
    ${(props) => props.theme.breakpoints.down("md")} {
      background: ${(props) => (props.isEdition ? props.containerBgColor ? props.containerBgColor : "#EBDEDB" : "#f9f7ed")};
      padding: 18px;
      position: relative;

      &:after {
        content: "";
        height: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    .c-btn__brown {
        padding: 14px 16px 10px;
        margin: auto;
        height: 46px;        
    }
  `;

  export const BgWrapperDesktop = styled.div`
    ${(props) => props.theme.breakpoints.up("md")} {
      background: ${(props) => (props.isEdition ? props.containerBgColor ? props.containerBgColor : "#EBDEDB" : "#f9f7ed")};
      padding: 18px 0 16px;
      margin: 0 6px;
      position: relative;

      &:after {
        content: "";
        height: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    .c-btn__brown {
        padding: 14px 16px 10px;
        margin: auto;
        height: 46px;        
    }
  `;

export const AudiobookDetails = styled.div`
  font-family: "Gill Sans Nova";
  border-radius: 2px;
  margin: 20px 6px 0 6px;
  position: relative;

  .detached {
    ${(props) => props.theme.breakpoints.up("lg")} {
      margin-top: 50px;
    }
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 57px 76px;
    background: ${(props) => (props.isEdition ? props.containerBgColor ? props.containerBgColor : "#EBDEDB" : "#f9f7ed")};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);    
    z-index: ${(props) => (props.isEdition ? "1" : null)};
    margin-top: 30px;
  }

  ${Details} {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-right: 20px;

    h1 {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
      font-style: italic;
      line-height: normal;
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      margin: 0;
      ${BgWrapperMob} {
        padding: 10px 30px 40px;
      }
    }
  }

  ${Summary} {
    font-size: 14px;
    ${(props) => props.theme.breakpoints.up("lg")} {
      width: 313px;
      font-size: 18px;
      line-height: 23px;
      margin-left: 25px;
    }

    span {
      display: block;
      margin-top: 15px;
      text-align: right;
      font-size: 14px;
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      ${BgWrapperMob} {
        padding: 25px 30px 40px;
      }
    }
  }

  ${Genre} {
    position: relative;

    small {
      font-size: 14px;
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      margin-left: 0;

      ${BgWrapperMob} {
        padding: 27px 30px;
      }
    }

    ${StyledChip} {
      margin-left: 20px;
      transition: .3s ease-in-out;
      ${(props) => props.theme.breakpoints.down("md")} {
        position: absolute;
        left: 50%;
        margin-left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        color: #f9f7ed;
      }
    }
  }

  .icon-arrow-down {
    display: none;

    &.rotated {
      transform: rotate(180deg);
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      display: block;
      position: absolute;
      bottom: 10px;
      left: 50%;
      margin-left: -13px;
    }
  }
`;




export const AudiobookImage = styled.div`
  margin-right: 30px;

  ${ImageHolder} {   
    max-width: 450px;   
    margin: 0 auto 0 0;
    position: relative;
    overflow: hidden;

    ${(props) => props.theme.breakpoints.down("md")} {
      margin: 0 auto;
    }


      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      button {
        background: #f9f7ed;

        i {
          transition: none;
          pointer-events: none;

          &:hover {
            color: #f9f7ed;
        }
        }

        &:hover {
          background: #000 !important;
          color: #f9f7ed !important;
        }
      }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    margin: 0;

    ${BgWrapperMob} {
      padding: 30px 30px 0;
    }

    ${ImageHolder} {
      max-width: 300px;
    }
  }

  ${BgWrapperMob} {
    ${(props) => props.theme.breakpoints.down("md")} {
      &:after {
        display: none;
      }
    }
  }
`;

export const ContainerOrder = styled.div`
  display: flex;
  flex-direction: column;

`;

export const ContainerRelated= styled.div`
  width: 100%;
  padding: 0 5px;
  margin-top: 110px;

    ${(props) => props.theme.breakpoints.down("md")} {
      margin-top: 80px;
    }
`;

export const StyledButton = styled.button`
  padding: 0 18px;
  border: 1px solid #000;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  height: 40px;
  line-height: 38px;
  text-transform: uppercase;
  letter-spacing: .15em;
  min-width: auto;
  transition: .3s ease-in-out;
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.up("lg")} {
    top: 90px;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    margin-left: -30px;
  }

  &:hover {
    background: #000;
    color: #fff;
  }
  
  i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 22px;
    transition: none;

    &:hover {
      color: #fff;
    }
  }
`;

export const PhysicalBookLink = styled.div`
    font-family: Gill Sans Nova;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 25px;

    a {
        line-height: 17px;
        margin-top: 5px;
        text-decoration : underline;
    }
`;

export const GiftInfo = styled.div`
  margin: 32px auto 0;
  text-align: center;

  i {
    margin-bottom: 14px;
    font-size: 15px;
  }

  p {
    font-family: 'JJannon';
    font-style: italic;
    font-size: 24px;
    line-height: 120%;
    max-width: 211px;
  }
`;

export const CtaHolder = styled.div`   
    ${(props) => props.theme.breakpoints.down("sm")} {      
        position: fixed;
        bottom: ${(props) => props.positionBottom && props.positionBottom + "px"};
        padding-bottom: ${(props) => props.positionBottom && props.positionBottom < 100 ? "4px" : "0"};
        left: 0;
        right: 0;
        z-index: 60;
        background: #f4eedc;     
    } 

    ${Cta} {
        display: flex;
        gap: 15px;
        // max-width: 320px;
        max-width: 456px; // price with subscription
        margin-left: 25px;

        ${(props) => props.theme.breakpoints.up("md")} {
          flex-direction: column; // price with subscription
        }

        @media screen and (max-width: 467px) {
          flex-direction: column; // price with subscription
          justify-content: center;
          align-items: center;
        }

        ${(props) => props.theme.breakpoints.down("sm")} {
          justify-content: center;
          align-items: center;
        }

        .c-btn {
            max-width: 320px;
            min-width: 155px;
            display: flex;
            justify-content: center;
            align-items: center;

            /* &:hover {
                background: #000;
                color: #f9f7ed;
                transition: background .3s ease-in-out, color .3s ease-in-out;
            } */

            i {
                transition: none;
                display: inline-block;
                pointer-events: none;
                height: 23px;
                line-height: 23px;

                /* &:hover {
                    color: #f9f7ed;
                } */

                & ~ span {
                  pointer-events: none;
                  height: 23px;
                }
            }

            .price-with-subscription {
              color: #721b00;

              span {
                &:nth-of-type(2) {
                  font-size: 12px;
                }
              }
            }

            &.submitting {
              .price-with-subscription {
                  color: transparent;
              }
            }
        }

        ${InfoButton} {
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0, 0, 0, 0.3);
            font-family: Gill Sans Nova;
            font-size: 12px;            
            line-height: 13px;
            letter-spacing: 0.09em;            
            text-transform: uppercase;
            border: 1px solid rgba(0, 0, 0, 0.3);
            min-height: 49px;
            height: 49px;
            width: 100%;
            min-width: 155px;
            max-width: 320px;
            padding: 0 5px 0 10px;

            i {
                font-size: 16px;
                margin-right: 10px;
            }
        }

        /* ${(props) => props.theme.breakpoints.down("md")} {
            max-width: none;
        } */

        ${(props) => props.theme.breakpoints.only("md")} {
            flex-direction: column;
            align-items: center;
            margin: 30px auto;
        } 
        
        ${(props) => props.theme.breakpoints.down("sm")} {      
            padding: 9px 0;     
            margin: 0 auto;

            &:after {
                content: "";
                height: 4px;
                width: 100%;
                position: absolute;
                bottom: 4px;
                left: 0;
                right: 0;
                box-shadow: ${(props) => props.positionBottom && props.positionBottom < 100 && " 0px 2px 2px rgba(0, 0, 0, 0.1)"};
            }
           

            .c-btn, ${InfoButton} {
                flex: 1 1 50%;
            }
        }
    }
`;




