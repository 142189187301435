import React, { Component } from 'react'

import ApiService from '../../../services/api.service';
import ArticleItem from '../../../components/UI/ArticleItem/ArticleItem';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import SkeletonArticleList from '../../../components/UI/Skeleton/SkeletonArticleList/SkeletonArticleList';
import { ReactComponent as SpiracleLogoSmall } from '../../../images/Spiral.svg';

export default class Articles extends Component {

    state = {
        articles: [],
        isLoading: true,
        _page: 1,
        buttonLoading: false,
        moreArticles: true,
        type: 'articles',
        itemsPerPage: 5
    }

    componentDidMount() {
        /**
         * Check type of page..to see what API to use
         */
        if (this.props.history.location.pathname.includes('discussions') || this.props.history.location.pathname.includes('interviews')) {
            this.setState({
                type: this.props.history.location.pathname.split('/')[1]
            }, () => {
                this.getArticlesApi()
            })
        } else {
            this.getArticlesApi();
        }
    }

    /**
     * Handle load more articles
     */
    handleLoadMoreArticles = () => {
        this.setState({
            _page: this.state._page + 1,
            buttonLoading: true,
        }, () => {
            this.getArticlesApi()
        })
    }

    /**
     * Handle articles response
     */
     handleResponse = (r) => {
        if (r.data['hydra:member'].length) {
            this.setState({
                articles: [...this.state.articles, ...r.data['hydra:member']],
                buttonLoading: false,
                isLoading: false,
            }, () => {
                if (this.state.articles.length < r.data['hydra:totalItems']) {
                    this.setState({
                        moreArticles: true,
                    })
                } else {
                    this.setState({
                        moreArticles: false
                    })
                }
            })
        } else {
            this.setState({
                moreArticles: false,
                buttonLoading: false,
                isLoading: false,
            })
        }
    }

    /**
     * Get articles API
     */
    getArticlesApi = () => {
        const params = new URLSearchParams();
        params.append('page', this.state._page)
        params.append('itemsPerPage', this.state.itemsPerPage)
        let functionApi = () => ApiService.getArticles(params).then(
            r => {
                this.handleResponse(r)
            }
        )

        /**
         * Check article type
         */
        if (this.state.type === 'discussions') {
            functionApi = () => ApiService.getDiscussions(params).then(
                r => {
                    this.handleResponse(r)
                }
            )
        } else if (this.state.type === 'interviews') {
            functionApi = () => ApiService.getInterviews(params).then(
                r => {
                    this.handleResponse(r)
                }
            )
        }

        /**
         * Call API
         */
        functionApi();
    }

    render() {

        let articles;
        let content = <SkeletonArticleList />
        if (this.state.articles.length) {
            articles = this.state.articles.map((item, index) => {
                return (
                    <ArticleItem 
                        key={index}
                        image={item.image}
                        alt={item.altText}
                        author={item.author ? `${item.author.firstName} ${item.author.lastName}` : null}
                        category={item.category ? item.category.name : null}
                        title={item.title}
                        slug={item.slug}
                        type={item.type ? item.type.title : null}
                        articleType={this.state.type}
                        featured={item.featured}
                        summary={item.summary}
                    />
                );
            })
        }

        if (!this.state.isLoading) {
            content = (
                <>
                    <div className={`${!this.state.moreArticles ? 'mb-50' : ''}`}>
                    {articles}
                    </div>
                    {this.state.moreArticles ? <div className="text-center">
                        <button 
                            id='gtm-articles-load-more'
                            onClick={() => this.handleLoadMoreArticles()} 
                            disabled={this.state.buttonLoading}
                            className={`c-btn c-btn__ghost c-btn__pagination font-22 font-secondary font-italic mb-50 ls-2 ${this.state.buttonLoading ? 'submitting' : ''}`}>
                                load more
                                <SpiracleLogoSmall />
                        </button>
                    </div> : null }
                </>
            );
        }

        return (
            <>
                <div className="container container__wide container__wide--articles">
                    <PageMeta>
                        <title>{this.state.type === 'discussions' ? 'Discussions' : this.state.type === 'interviews' ? 'Interviews' : 'Articles'} | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                    <h1 className="font-72 font-italic font-400 ls-2 text-center mb-63">{this.state.type === 'discussions' ? 'Events' : this.state.type === 'interviews' ? 'Podcasts' : 'Articles'}</h1>
                    {/* <p className="font-32 font-400 ls-2 mb-88 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut porttitor lectus et feugiat tempor. Maecenas consequat eu ex a accumsan. Fusce id turpis viverra, condimentum mi sed, euismod dui. Sed metus ipsum, molestie, porttitor efficitur ipsum.</p> */}
                    {content}
                </div>
            </>
        );
    }
}
