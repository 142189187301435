import styled from "styled-components/macro";

export const Container = styled.div``;
export const Content = styled.div``;
export const Image = styled.div``;
export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Author = styled.div``;
export const Paragraph = styled.p``;

export const StyledWritersDilemma = styled.div`
    font-family: 'Gill Sans Nova';
    
    margin: 187px 0 90px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 77px 0 44px;
    }

    ${Container} {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 1200px;
        margin: 0 auto;

        ${(props) => props.theme.breakpoints.down('lg')} {
            max-width: 950px;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            justify-content: center;
        }
    }

    ${Content} {
        text-align: center;

        ${(props) => props.theme.breakpoints.down('lg')} {
            flex: 1;
        }

        img {
            display: inline-block;
            width: 125px;
            height: 125px;
        }

        ${Subtitle} {
            font-size: 18px;
            line-height: 43px;
            letter-spacing: 6px;
            margin-top: 27px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 13px;
            }
        }

        ${Title} {
            font-family: 'JJannon';
            font-size: 36px;
            line-height: 36px;
            margin: 0 0 12px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 26px;
            }
        }

        ${Author} {
            font-family: 'JJannon';
            font-size: 22px;
            line-height: 22px;
            color: #721b00;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 20px;
            }
        }

        ${Paragraph} {
            font-size: 22px;
            line-height: 28px;
            max-width: 420px;
            margin: 25px 0 80px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 18px;
                max-width: initial;
                margin-bottom: 40px;
                padding: 0 10px;
            }

            span {
                display: block;
                font-weight: 500;
                margin-top: 30px;
            }
        }
    }

    ${Image} {
        max-width: 589px;

        ${(props) => props.theme.breakpoints.down('lg')} {
            flex: 1;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            display: none;
        }
        
        img {
            display: block;
            width: 100%;
        }
    }

    div {
        a.sa-button-red {
            margin-top: 40px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 18px !important;
                margin-top: 5px;
                width: 100% !important;
                padding: 10px 20px 15px !important;
            }
        }
    }
`;

