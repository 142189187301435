import React, { Component } from 'react'

export default class SkeletonAudioBookSingle extends Component {
    render() {
        return (
            <div className="c-skeleton__audio-book-edition margin-center h-flex h-flex-between">
                <div className="c-skeleton__audio-book-edition--image"></div>
                
                <div className="c-skeleton__audio-book-edition--details">
                    <div className="c-skeleton__audio-book-edition--summary"></div>
                    <div className="c-skeleton__audio-book-edition--card"></div>
                </div>
            </div>
        )
    }
}