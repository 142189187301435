import * as actionTypes from '../actions/libraryActions';

const initialState = {
    library: []
}

const libraryReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.SET_LIBRARY:
            return { 
                ...state,
                //not sure why was initial library copied
                //removed it because of duplicate library items
                // library: [...state.library, ...action.libraryItems],
                library: [...action.libraryItems],
                libraryTotalItems: action.libraryTotalItems
            };
        case actionTypes.LOAD_MORE_LIBRARY:
            let copiedLibraryLoadMore = [...state.library];
            let loadMore = copiedLibraryLoadMore.concat([...action.libraryItems])
            return { 
                ...state,
                library: loadMore,
                libraryTotalItems: action.libraryTotalItems
            };
        case actionTypes.SET_ORDER_ITEMS:
            let copiedLibrary = [...action.orderItems, ...state.library]
            return {
                ...state,
                library: copiedLibrary
            }
        case actionTypes.REMOVE_LIBRARY_LOGOUT:
            return {
                ...state,
                library: []
            }
        case actionTypes.ADD_SINGLE_BOOK_TO_LIBRARY:
            let copiedLibraryForSingleBook = [...state.library];
            copiedLibraryForSingleBook.unshift(action.libraryItem)
            return {
                ...state,
                library: copiedLibraryForSingleBook
            }
        default:
            return state;
    }
}

export default libraryReducer;