import React, { Component } from "react";
import PropTypes from "prop-types";
import { CoverImageHolder } from "shared/CoverImage/style";
import defaultImage from "images/defaultCoverImage.svg";
import OnImagesLoaded from 'react-on-images-loaded';

class CoverImage extends Component {
    state = {
        isHighResolutionScreen: false,
        imagesLoaded: false
    }

    componentDidMount() {
        this.isHighResolutionScreen();
    }

    isHighResolutionScreen = () => {
        if(window.devicePixelRatio > 1) {
            this.setState({ isHighResolutionScreen: true })
        }
    }

    
    render() {           
        return (       
            <OnImagesLoaded
                onLoaded={() => this.setState({ imagesLoaded: true })}
            >     
                <CoverImageHolder 
                    maxWidth={this.props.width}  
                    loaded={this.state.imagesLoaded}                  
                >                
                    <img src={this.props.src || defaultImage} alt={this.props.alt} title={this.props.title} />     
                </CoverImageHolder>  
            </OnImagesLoaded>        
        )
    }
}

CoverImage.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    margin: PropTypes.string,
    src: PropTypes.string,
    retinaSrc: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,   
};

export default CoverImage;
