import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import PageMeta from '../../shared/PageMeta/PageMeta';
import classes from './Page404.module.scss';
import ill404 from '../../images/404.svg';

export default class Page404 extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 300);
  }

  render() {
    let content = <div></div>;

    if (!this.state.isLoading) {
      content = (
        <div className={`${classes.Page404} container text-center`}>
          <PageMeta>
            <title>Page not found | Spiracle</title>
            <link rel="canonical" href={window.location.href} />
          </PageMeta>

          <img alt='Page not found' src={ill404} />

          <div className={classes.Page404__Actions}>
            <Link id='gtm-404-go-to-homepage' className='c-btn c-btn__link font-16 margin-center font-secondary letter-spacing-15' to='/'>
              Go to the Homepage
            </Link>
          </div>
        </div>
      );
    }

    return content;
  }
}
