import styled from "styled-components/macro";

export const Heading = styled.h2``;
export const Paragraph = styled.p``;
export const Container = styled.div``;
export const ParagraphBig = styled.p``;

export const StyledPaypalProblem = styled.div`
    max-width: 375px;
    padding: 0 8px;

    ${Heading} {
        margin: 0 auto 33px;
        font-size: 24px;
        text-align: center;
        line-height: 29px;
    }

    ${Container} {
        background: #E8E2CF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 32px 28px 40px;

        a {
            text-decoration: underline;
        }
    }

    img {
        max-width: 100%;
        margin-top: -15px;
    }

    ${ParagraphBig} {
        font-size: 24px;
        text-align: center;
        margin: 30px 0 27px;
    }

    ${Paragraph} {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        text-align: center;
        font-style: normal;
        font-family: 'Gill Sans Nova';
        margin: 0 auto;
    }
`