import {
    EmptyPage,
    FilterResultsInfo,
    Filters,
    FiltersToggle,
    StyledChipHolder,
    StyledChip,
} from '../AudioBooks/style';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import ArticleItem from '../../components/UI/ArticleItem/ArticleItem';
import AudioBookSingleFilter from '../../components/UI/AudioBook/AudioBookSingleFilter';
import { debounce } from 'shared/Functions/Debounce';
import Hidden from '@material-ui/core/Hidden';
import Input from '../../components/UI/Input/Input';
import PageMeta from '../../shared/PageMeta/PageMeta'
import SkeletonArticleList from '../../components/UI/Skeleton/SkeletonArticleList/SkeletonArticleList';
import { InputContainer, SearchContainer, SortButton, SortMenu } from './style';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';

class SearchArticles extends Component {
    constructor(props) {
        super(props);

        this.filtersWrapper = React.createRef();

        this.state = {
            page: 1,
            itemsPerPage: 5,
            articles: [],
            isLoading: true,
            filtersVisible: false,
            types: [],
            activeTypes: [],
            typesFilter: [],
            allTypesShown: false,
            allFilters: [],
            isLoadingMore: false,
            numberOfArticles: 0,
            haveMore: false,
            filtersHeight: null,
            isLoadMore: false,
            searchKeyword: '',
            isTyping: false,
            sort: 'title',
            direction: null,
            isSorting: false,
            isSortMenu: false,
            sortValue: 'relevance'
        }

        this.apiCallSearch = debounce(() => this.filterApi(false, false, this.state.direction), 400);
    }

    componentDidMount() {
        this.getArticles();
    }

    componentDidUpdate(prevProps, prevState) {       
        if (this.props.location.search !== prevProps.location.search) {
            if (this.props.location.state && this.props.location.state.fromQuick) {
                this.setState({
                    allFilters: [],
                    activeTypes: [],
                    isLoadMore: true,
                    direction: null,
                    sortValue: 'relevance'
                }, () => {
                    this.getArticles();                  
                })
            }
            if (!this.state.isLoadMore && !this.state.isTyping && !this.state.isSorting && (this.props.location.state && !this.props.location.state.fromQuick)) {
                this.setState({
                    allFilters: [],
                    activeTypes: [],
                    isLoadMore: true
                }, () => {
                    this.getArticles();
                })
            }
        }
    }
    
    /**
     * decode query params from URL
     */
    decodeParams = (param) => {
        return decodeURIComponent(param.replace(/\+/g, ' '));
    }
    
    getArticles = () => {
        const searchParams = new URLSearchParams(this.props.history.location.search);
    
        this.setState({
            searchKeyword: this.state.isTyping ? this.state.searchKeyword : this.props.location.search ? this.decodeParams(this.props.location.search.split('=')[1].split('&')[0]) : '',
            page: 1,
            typesFilter: searchParams.get('types') ? searchParams.get('types').split(',').map(item => {return parseInt(item, 10)}) : [],
        }, () => {
            this.filterApi()
            }
        )

        /**
         * Get types filter
         */
        ApiService.typesMurmurations().then((r) => {
        this.setState({
            types: r.data["hydra:member"],
        }, () => {
            //fill state and active state with type 
            if (searchParams.get('types')) {
            const categories = this.state.types;
            const actCategories = [];
            searchParams.get('types').split(',').forEach(id => {
                let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                let index = categories.indexOf(filteredCat[0])
                let allFilt = this.state.allFilters;
                allFilt.push(filteredCat[0]);
                actCategories.push(index)
                this.setState({
                activeTypes: actCategories,
                allFilters: allFilt
                })
            })
            }
        });
        });
    
        window.addEventListener("resize", this.handleFiltersDimensions);
    }

    /**
     * API call for articles with filter
     */  
    filterApi = (newPage, reset, direction) => {
        this.setState({
            isLoadingMore: true
        }, () => {
            const searchParams = new URLSearchParams();
            searchParams.append('keyword', reset ? '' : this.state.searchKeyword);
            searchParams.append("_page", this.state.page);
            searchParams.append("itemsPerPage", this.state.itemsPerPage);
            searchParams.append('search[]', 'articles')
            searchParams.append('search[]', 'interviews')
            searchParams.append('search[]', 'discussions')

            if (direction) {
                searchParams.append("sort", this.state.sort);
                searchParams.append("direction", direction);
            }

            if (this.state.typesFilter.length) {
                searchParams.append("types", this.state.typesFilter);
            }
        
            this.props.history.replace(`/search-articles?${searchParams.toString()}`)

            ApiService.getMurmurations(searchParams).then((r) => {
                if (newPage) {
                    this.setState(
                        {
                        articles: [...this.state.articles, ...r.data['hydra:member']],
                        numberOfArticles: r.data['hydra:totalItems'],
                        isLoading: false,
                        isLoadingMore: false,
                        isLoadMore: false
                        },
                        () => {
                        this.setState({
                            haveMore: r.data['hydra:member'].length > 0 ? r.data['hydra:member'][0].totalItems > this.state.articles.length ? true : false : false,
                        });
                        }
                    );
                } else {
                    this.setState(
                        {
                        articles: r.data['hydra:member'],
                        numberOfArticles: r.data['hydra:totalItems'],
                        isLoading: false,
                        isLoadingMore: false,
                        isLoadMore: false,
                        },
                        () => {
                        this.setState({
                            haveMore: r.data['hydra:member'].length > 0 ? r.data['hydra:member'][0].totalItems > this.state.articles.length ? true : false : false,
                        });
                        }
                    );
                }
            });
        })
    };

    /**
     * handle sort menu
     */ 
    handleSortMenu = () => {
        this.setState({
            isSortMenu: !this.state.isSortMenu,
            filtersVisible: false,
        })
    }

    /**
     * handle sort by title
     */ 
    handleSort = (direction) => {
        this.setState({ 
            isSorting: true,
            isSortMenu: false,
            filtersVisible: false,
            direction: direction,
            page: 1,
            isLoading: true
        }, () => {
            if (direction) {
                this.setState({
                    sortValue: 'name'
                })
            } else {
                this.setState({
                    sortValue: 'relevance'
                })
            }
            this.filterApi(false, false, this.state.direction)
        })
    }

    /**
     * handle search
     */
    handleSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
            isLoading: true,
            page: 1
        }, () => {
            if (e.target.value.length > 2) {
                this.apiCallSearch()
            }
        })

        if (e.target.value.length === 0) {
            this.handleResetSearchKeyword()
        }
    }

    /**
     * reset keyword
     */
    handleResetSearchKeyword = () => {
        this.setState({ 
            searchKeyword: '',
            isLoading: true 
        }, () => {
            this.filterApi(false, true, this.state.direction)
        });
    }

    /**
     * is typing
     */
    handleIsTyping = () => {
        this.setState({ 
            isTyping: !this.state.isTyping,
            filtersVisible: false,
            isSortMenu: false
         })
    }

    /**
     * load more articles
     */  

    loadMore = () => {
        this.setState({
            page: parseInt(this.state.page) + 1,
            isLoadingMore: true,
            isLoadMore: true,
        }, () => {
            if (this.state.isSorting) {
                this.filterApi(true, false, this.state.direction);
            } else {
                this.filterApi(true);
            }
        });
    };

    /**
     * Handle click on filter options
     */  

    handleFilter = (type, i, filterOption) => {
        this.setState({
            isLoadMore: true
        }, () => {
            let typeArr = [];
            let filtArr = [];
            let allFilters = this.state.allFilters;
            
            //set typeArr and filtArr depending on type
            if (type === "type") {
                typeArr = this.state.activeTypes;
                filtArr = this.state.typesFilter;
            } 
        
            //if options already exists then remove it
            if (filtArr.includes(filterOption.id)) {
                const index = filtArr.indexOf(filterOption.id);
                const newArr = [...filtArr];
                const allFiltersIndex = allFilters.indexOf(filterOption);
                newArr.splice(index, 1);
                allFilters.splice(allFiltersIndex, 1);
                this.setState({
                allFilters: allFilters
                })
                if (type === "type") {
                this.setState({ typesFilter: newArr }, () => {
                    this.filterApi(false, false, this.state.direction);
                });
                } 
            } else {
                //if options doesn't exist, push to all filters and typeFilter
                const newArr = [...filtArr];
                newArr.push(filterOption.id);
                allFilters.push(filterOption);
                this.setState({
                allFilters: allFilters
                })
        
                if (type === "type") {
                this.setState({ typesFilter: newArr }, () => {
                    this.filterApi(false, false, this.state.direction);
                });
                }
            }
            if (typeArr.includes(i)) {
                //active state push if it doesn't exist
                const index = typeArr.indexOf(i);
                const newArr = [...typeArr];
                newArr.splice(index, 1);
                if (type === "type") {
                this.setState({ activeTypes: newArr, page: 1 });
                }
            } else {
                //active state push if it doesn't exist
                const newArr = [...typeArr];
                newArr.push(i);

                if (type === "type") {
                this.setState({ activeTypes: newArr, page: 1 });
                }
            }
        })
    };

    /**
     * Remove filter clicking on list of filters
     */  
    
    handleRemoveFilter = item => {
        this.setState({
            isLoadMore: true
        }, () => {
            const type = item['@type'];
            let typeArr = [];
            let filtArr = [];
            let allArr = [];
            let allFilters = this.state.allFilters;
            let filteredArr;
            if (type === 'Type') {
                typeArr = this.state.activeTypes;
                filtArr = this.state.typesFilter;
                allArr = this.state.types;
            }
        
            filteredArr = allArr.filter(filteredItem => filteredItem.id === item.id)
            const index = allArr.indexOf(filteredArr[0])
            const index1 = filtArr.indexOf(item.id)
            const index2 = allFilters.indexOf(item);
            const typeArrIndex = typeArr.indexOf(index)
            typeArr.splice(typeArrIndex, 1);
            filtArr.splice(index1, 1);
            allFilters.splice(index2, 1);
        
            if (type === 'Type') {
                this.setState({
                activeTypes: typeArr,
                typesFilter: filtArr,
                allFilters: allFilters
                }, () => {
                this.filterApi(false, false, this.state.direction);
                })
            }
        })
    }

    /**
     * Reset all filters
     */  
    handleClearAllFilters = () => {
        this.setState({
            typesFilter: [],
            activeTypes: [],
            allFilters: [],
            isLoadMore: true
        }, () => {
            this.filterApi(false, false, this.state.direction);
        })
    }

    /**
     * Handle click on type filter
     */  
    handleTypeFilter = (type, i) => {
        this.handleFilter("type", i, type);
    };

    /**
     * Handle toggle filters
     */  
    toggleFilters = () => {
        this.setState({ 
            filtersVisible: !this.state.filtersVisible,
            isSortMenu: false
         });
    };   

    render() {
        let types;
        let allFilters;

        if (this.state.types.length) {
            types = this.state.types.map((type, index) => {
                return (
                    <StyledChipHolder key={`type-${index}`}>
                        <StyledChip                            
                            className={this.state.activeTypes.includes(index) ? "active" : ""}
                            onClick={() => this.handleTypeFilter(type, index, type.id)}
                        >
                            {type.title}
                        </StyledChip>
                    </StyledChipHolder>
                );
            });
        }

        if (this.state.allFilters.length) {
            allFilters = this.state.allFilters.map((item, index) => {
                if (item) {
                return (
                    <StyledChip
                        labelWithIcon
                        detached
                        key={`filter-${index}`}
                        onClick={() => this.handleRemoveFilter(item)}
                    >
                        {item.title ? item.title : item.name ? item.name : `${item === 9 ? '<' : '>'} ${item} hrs `}
                        <i className="icon-close-small no-animation" />
                    </StyledChip>
                );
                } else {
                    return null
                }
            })
        }

        return (
            <SearchContainer className="container container__big container__big--withPadding" direction={!this.state.isSortMenu} hide={this.state.filtersVisible}>
                <PageMeta>
                    <title>Searched Articles | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                <InputContainer className='c-navigation__search-navigation__input margin-center'>
                    <Input type='text' placeholder='SEARCH' value={this.state.searchKeyword}
                            onChange={(e) => this.handleSearch(e)}  onFocus={this.handleIsTyping} onBlur={this.handleIsTyping} />

                    {this.state.searchKeyword.length > 0 && 
                        <i className='icon-close font-11 font-black' onClick={this.handleResetSearchKeyword}></i>}
                </InputContainer>

                <FiltersToggle className='h-flex-between' style={{position: 'relative'}}>
                    <SortButton onClick={this.handleSortMenu}>sort by {this.state.sortValue}<i className='icon-arrow no-hover'></i></SortButton>
                    {this.state.isSortMenu && <SortMenu>
                        <div onClick={() => this.handleSort()}>Relevance</div>
                        <div onClick={() => this.handleSort('asc')}>Name</div>
                    </SortMenu>}
                    {(!this.state.filtersVisible || this.state.isTyping) ? (
                        <StyledChip small detached onClick={() => this.toggleFilters()}>
                            Filter
                        </StyledChip>
                        ) : (
                            <>
                                <Hidden mdUp>
                                    <span className="pl-42">Filter</span>
                                </Hidden>
                                <i
                                    className="icon-close mr-24"
                                    style={{ verticalAlign: "bottom" }}
                                    onClick={() => this.toggleFilters()}
                                />
                            </>
                        )}
                    </FiltersToggle>
                <Filters
                    ref={this.filtersWrapper}
                    className={this.state.filtersVisible ? "visible" : "hidden"}
                    height={this.state.filtersHeight}
                >
                    <div className="filters-wrapper">
                        <h2>Types</h2>
                        {types && (
                            <AudioBookSingleFilter
                                filtersId={'typesContainer'}
                                filters={types}
                            />
                        )}
                    </div>
                </Filters>
                <FilterResultsInfo>                    
                    {this.state.allFilters.length > 0 &&
                    <StyledChip onClick={() => this.handleClearAllFilters()} circle className="mr-12" style={{marginLeft: '0'}}> 
                        <i className="icon-close-small no-animation" />
                    </StyledChip>
                    }
                
                    {allFilters}
                </FilterResultsInfo>

                {(this.state.searchKeyword.length > 2 && this.state.isLoading) ? <SkeletonArticleList/> : (
                    <>
                        {(this.state.isLoadMore || this.state.isLoading) && <SkeletonArticleList/>}
                        
                            {this.state.articles.length > 0 ? 
                            <div className={`${window.innerWidth > 768 && 'mt-35'}`}>
                                {this.state.articles.map((item, index) => {
                                    const articleType=(item.article && 'article') || (item.interview && 'interview') || (item.discussion && 'discussion');
                                    const article=item[articleType];

                                    return <ArticleItem 
                                        key={`article-${index}`}
                                        image={article.image}
                                        alt={article.altText}
                                        author={article.author ? `${article.author.firstName} ${article.author.lastName}` : null}
                                        category={article.category ? article.category.name : null}
                                        title={article.title}
                                        slug={article.slug}
                                        type={article.type ? article.type.title : null}
                                        articleType={articleType + 's'}
                                        featured={article.featured}
                                        summary={article.summary}
                                        />
                                
                                })}
                            </div> : ((this.state.searchKeyword.length > 2 || !this.state.isTyping) && this.state.numberOfArticles < 1) && !this.state.isLoading &&
                                    <EmptyPage>
                                        <p>Sorry, no results.</p>
                                        <p>
                                        Try again or visit our{" "}
                                        <Link id='gtm-search-articles-murmurations' to="/murmurations" className="font-secondary">
                                            murmurations
                                        </Link>
                                        </p>
                                    </EmptyPage>
                        }

                        {this.state.haveMore && (
                            <div className="text-center mt-27">
                                <button
                                    id='gtm-search-articles-load-more'
                                    onClick={() => this.loadMore()}
                                    className={`c-btn c-btn__ghost font-22 font-secondary font-italic mb-50 ls-2 ${this.state.isLoadingMore ? 'submitting' : ''}`}>
                                    load more
                                    <SpiracleLogoSmall />
                                </button>
                            </div>
                        )}
                    </>
                )}
            </SearchContainer>
        );
    }
}

export default SearchArticles;
