import styled from "styled-components/macro";

export const PublisherHolder = styled.div`
    min-height: calc(100vh - 388px);

    .container {        
        padding: 0 12px;
        min-height: initial;

        &:first-of-type {
            background-color: #F6F2E6;
            padding: 70px 12px 100px;
            margin-bottom: 100px;

            ${(props) => props.theme.breakpoints.down("md")} {   
                background: transparent;
                padding: 40px 12px 0;
                margin-bottom: 50px;
            }
        }

        
    }
`;