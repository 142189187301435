import { Cta, Note, Price, Holder, List, ListItem, StyledMembership, Text, Title } from 'pages/Membership/style';
import React, { Component } from 'react';

import { Link } from "react-router-dom";
import PageMeta from '../../shared/PageMeta/PageMeta';
import { connect } from 'react-redux';
import image from 'images/membership2.webp';

class Membership extends Component {

    renderCta() {
        return (
            <Cta>
                <Link id='gtm-membership-payment-month' to={{ pathname: "/payment", state: { subscription: 'month' }}} className={`c-btn c-btn__ghost c-btn__ghost--square full-width`}> 
                    <Price>£{this.props.membership.month}</Price>
                    <span>per month</span>
                </Link>
                <Link id='gtm-membership-payment-year' to={{ pathname: "/payment", state: { subscription: 'year' }}} className={`c-btn c-btn__ghost c-btn__ghost--square full-width`}> 
                    <Price>£{this.props.membership.year}</Price>
                    <span>per year</span>
                </Link>
            </Cta>
        )        
    }

    render() {               
        let ListItems = [
            {
                title: "2 free audiobooks each month",
                text: "Choose 2 audiobooks from the Monthly Highlights for free each month"
            },
            {
                title: "20% off audiobooks",
                text: "As a premium member you receive up to 20% off all audiobooks"
            },
            {
                title: "Unique discounts",
                text: "Unique discounts on Spiracle Collection titles"
            },
            {
                title: "Subscriber-only invitations",
                text: "Subscriber-only invitations to Spiracle events"
            },       
        ]

        const isMemberOnlyBlock = this.props.isMemberOnlyBlock;
    
        let content = (
            <Holder isMemberOnlyBlock={isMemberOnlyBlock}>
                {!isMemberOnlyBlock &&
                    <PageMeta>
                        <title>Subscription | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                }

                <StyledMembership>
                    <h1>Subscribe to spiracle</h1>
                    <h2 className="mb-28">Treat yourself or make a wonderful gift</h2>
                    <p className="font-italic mb-0">The holy grail for book lovers is a reliable recommendation. Think of Spiracle as the friend who pulls out a book and says, <br /> “No, really. I know you’ll love this.”</p>
                    <img src={image} alt="Spiracle" />

                    <h2>Premium Membership benefits</h2>   
                    {/* <span>the sound of great writing</span> */}

                    <List>
                        {ListItems.map((item, index) => {
                            return (
                                <ListItem key={index}>
                                    <i className="icon-check-circle no-hover" />
                                    <div>
                                        <Title>{item.title}</Title>
                                        <Text>{item.text}</Text>
                                    </div>
                                </ListItem>
                            );
                        })}

                    </List>

                    <h5 className="mt-24">Subscription and Gift Subscription</h5>

                    {this.renderCta()}

                    <h5 className="mt-20 mb-30">(CANCEL AT ANY TIME - SUBSCRIPTIONS ARE SET TO AUTO-RENEW)</h5>

                    <Note>You will now be directed to the payment page where the option to send as a gift will be available</Note>

                    <h5 className="grey">NEED ASSISTANCE?<br /> CONTACT THE <Link id='gtm-membership-contact-support' to={"/info/support"} className="underline">SPIRACLE TEAM</Link></h5>

                    <p className="mt-73">
                        If you’re not ready to dive in, why not take off your shoes and paddle in our collection? Enjoy articles, podcasts and interviews free, 
                        in <Link id='gtm-membership-murmurations' to="/murmurations"><span className="font-italic">Murmurations</span></Link>.
                        Visitors can buy audiobooks at the cover price for their own library or as gifts for friends, family and other animals...
                    </p>
                </StyledMembership>
            </Holder>            
        )
        
        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,  
    membership: state.membershipPriceReducer
})

export default connect(mapStateToProps)(Membership);
