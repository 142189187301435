import * as actionTypes from '../actions/userActions';

const initialState = {
    user: null
}

const userReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.LOG_IN:
            return { 
                ...state,
                user: action.user 
            };

        case actionTypes.LOGOUT:
            return {
                ...state,
                user: null
            }
        
        case actionTypes.UPDATE_USER:
            const settingsUser = {...state.user, ...action.user}
            return {
                ...state,
                user: settingsUser
            }
        case actionTypes.ADD_CART:
            const updatedUser = {
                ...state.user,
                cart: action.cart
            }
            return {
                ...state,
                user: updatedUser
            }
        case actionTypes.REMOVE_CART:
            const updatedRemoveCartUser = {
                ...state.user,
                cart: null
            }

            return {
                ...state,
                user: updatedRemoveCartUser
            }
        case actionTypes.ADD_BOOK_TO_LIBRARY: {
            const removedCreditUser = {
                ...state.user,
                hasCredit: false
            }
            return {
                ...state,
                user: removedCreditUser
            }
        }
        case actionTypes.SET_SUBSCRIPTION: {
            const setSubscriptionUser = {
                ...state.user,
                currentSubscription: action.subscription,
                hasCredit: true,
                paysSomeone: state.user.paysSomeone + 1
            }
            return {
                ...state,
                user: setSubscriptionUser
            }
        }
        case actionTypes.REMOVE_SUBSCRIPTION: {
            const removeSubscriptionUser = {
                ...state.user,
                currentSubscription: null,
                hasCredit: false,
                paysSomeone: state.user.paysSomeone - 1
            }
            return {
                ...state,
                user: removeSubscriptionUser
            }
        }
        case actionTypes.SET_PAYS_SOMEONE_SUBSCRIPTION: {
            const setSubscriptionUser = {
                ...state.user,
                paysSomeone: state.user.paysSomeone + 1
            }
            return {
                ...state,
                user: setSubscriptionUser
            }
        }
        case actionTypes.REMOVE_PAYS_SOMEONE_SUBSCRIPTION: {
            const removeSubscriptionUser = {
                ...state.user,
                paysSomeone: state.user.paysSomeone - 1
            }
            return {
                ...state,
                user: removeSubscriptionUser
            }
        }
        default:
            return state;
    }
}

export default userReducer;