export const SET_ORDER = 'SET_ORDER';

export const onSetOrder = (payload) => {
    return dispatch => {
        dispatch({ 
            type: SET_ORDER,
            order: payload 
        })
    }
}
