import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

 class Pagination extends Component {
    render() {
        return (
            <div className='c-pagination'>
                <ReactPaginate
                    forcePage={parseInt(this.props.page - 1)} 
                    onPageChange={this.props.onPageChange} 
                    pageCount={this.props.pageCount}
                    onPageActive={this.props.onPageChange}
                    pageRangeDisplayed={this.props.page === 1 ? 4 : this.props.page === 2 ? 3 : 2}
                    marginPagesDisplayed={1}
                    previousLabel={<span><i className='icon-arrow '/> Previous</span>}
                    nextLabel={<span>Next <i className='icon-arrow '/></span>}
                    containerClassName='c-pagination__list'
                    pageLinkClassName='gtm-audiobooks-load-more'
                    previousClassName='previous gtm-audiobooks-load-more'
                    nextClassName='next gtm-audiobooks-load-more'
                /> 
            </div>
        );
    }
}

export default Pagination;