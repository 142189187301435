import React, { Component } from "react";

export default class SkeletonSearch extends Component {
  render() {
    return (
      <div className="c-skeleton">
        <div className="c-skeleton__audio-book--listing">
          <div className="c-skeleton__audio-book--listing--bookNumber mt-25 ml-26"></div>
        </div>
        <div className="c-skeleton__audio-book-single--related-items mt-9 h-flex h-flex-between">
          <div className="c-skeleton__audio-book-single--related-item c-skeleton__audio-book-single--related-item--mobile">
            <div className="c-skeleton__audio-book-single--image c-skeleton__audio-book-single--image--mobile"></div>
            <div className="c-skeleton__audio-book-single--wrapper">
              <div className="c-skeleton__audio-book-single--title"></div>
              <div className="c-skeleton__audio-book-single--author"></div>
              <div className="c-skeleton__audio-book-single--price"></div>
            </div>
          </div>
          <div className="c-skeleton__audio-book-single--related-item c-skeleton__audio-book-single--related-item--mobile">
            <div className="c-skeleton__audio-book-single--image c-skeleton__audio-book-single--image--mobile"></div>
            <div className="c-skeleton__audio-book-single--wrapper">
              <div className="c-skeleton__audio-book-single--title"></div>
              <div className="c-skeleton__audio-book-single--author"></div>
              <div className="c-skeleton__audio-book-single--price"></div>
            </div>
          </div>
        </div>

        <div className="c-skeleton__audio-book--listing">
          <div className="c-skeleton__audio-book--listing--bookNumber mt-55 ml-26"></div>
        </div>
        <div className="c-skeleton__article-list c-skeleton__article-list--mobile mt-15">
            <div className="h-flex h-flex-wrap h-flex-between">
                <div className="c-skeleton__article-list--img c-skeleton__article-list--img--mobile"></div>
                <div className="c-skeleton__article-list__wrapper c-skeleton__article-list__wrapper--mobile h-flex h-flex-center h-flex-middle h-flex-column">
                    <div className="c-skeleton__article-list--category c-skeleton__article-list--category--mobile"></div>
                    <div className="c-skeleton__article-list--title c-skeleton__article-list--title--mobile"></div>
                    <div className="c-skeleton__article-list--author c-skeleton__article-list--author--mobile"></div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}