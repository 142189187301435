import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageMeta from '../../shared/PageMeta/PageMeta';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Buttons, List, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';


class RedemptionError extends Component {

    constructor(props) {
        super(props);

        this.state = {
            is404: this.props.location?.state?.error === 404
        }
    }

    componentDidMount() {
        if (!this.props.redemptionAudioBook?.spiSIN) {
            this.props.history.push(`/redemption/${this.props.match.params.spisin}`)
        }
    }

    render() {
        return (
            <StyledRedemptionContainer>
                <PageMeta>
                    <title>Redemption Fail | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

               <AudioBook data={this.props.redemptionAudioBook} />

                <StyledContent>
                    <Title>Oops!</Title>
                    <Paragraph>There has been a problem redeeming your audiobook.</Paragraph>

                    <ParagraphSmall>The redemption code you've entered has {this.state.is404 ? 'not been recognised' : 'already been redeemed'}.</ParagraphSmall>

                    <ParagraphSmall className='mb-15'><span>Please try the following steps:</span></ParagraphSmall>
                    <List>
                        <li>{this.state.is404 ? 
                                <><span>Double-check your Code:</span> Ensure there are no typos in the redemption code.</> : 
                                <><span>Already Used?</span> If you've received this audiobook as a gift, the code may have already been redeemed. Please check with the gift giver.</>}
                        </li>
                        <li><span>Still Stuck?</span> Contact us at <a href="mailto:support@spiracleaudiobooks.com">support@spiracleaudiobooks.com</a> and we'll get to the bottom of this mystery.</li>
                    </List>

                    <Buttons>
                        <button id='gtm-redemption-error-try-again' className='c-btn c-btn__black c-btn__ghost--square font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30' onClick={() => this.props.history.goBack()}>Try again</button>
                    </Buttons>
                </StyledContent>
                
            </StyledRedemptionContainer>
        )
    }
}

const mapStateToProps = state => ({
    redemptionAudioBook: state.redemptionReducer.redemptionAudioBook,
})

export default connect(mapStateToProps)(RedemptionError);