import {
  FilterResultsInfo,
  Filters,
  FiltersToggle,
  StyledChipHolder,
  StyledChip,
} from '../AudioBooks/style';
import React, { Component } from 'react';

import ApiService from '../../services/api.service';
import ArticleItem from '../../components/UI/ArticleItem/ArticleItem';
import AudioBookSingleFilter from '../../components/UI/AudioBook/AudioBookSingleFilter';
import Hidden from '@material-ui/core/Hidden';
import PageMeta from '../../shared/PageMeta/PageMeta'
import SkeletonArticleList from '../../components/UI/Skeleton/SkeletonArticleList/SkeletonArticleList';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import TitleTextBlock from '../../shared/PageElements/TitleTextBlock';

class Murmurations extends Component {
    constructor(props) {
        super(props);

        this.filtersWrapper = React.createRef();

        this.state = {
            _page: 1,
            itemsPerPage: 5,
            murmurations: [],
            isLoading: true,
            filtersVisible: false,
            types: [],
            activeTypes: [],
            typesFilter: [],
            allTypesShown: false,
            allFilters: [],
            isLoadingMore: false,
            numberOfMurmurations: 0,
            haveMore: false,
            filtersHeight: null,
            isLoadMore: false,
        }
    }

    componentDidMount() {
        this.getMurmurations();
    }

    componentDidUpdate(prevProps, prevState) {       
        if (this.props.location.search !== prevProps.location.search) {
            if (!this.state.isLoadMore) {
                this.setState({
                    allFilters: [],
                    activeTypes: [],
                    isLoadMore: true
                }, () => {
                    this.getMurmurations();
                })
            }
        }
    }
    
    getMurmurations = () => {
        const searchParams = new URLSearchParams(this.props.history.location.search);
    
        this.setState({
            _page: 1,
            itemsPerPage: searchParams.get('itemsPerPage') ? searchParams.get('itemsPerPage') : 5,
            typesFilter: searchParams.get('types') ? searchParams.get('types').split(',').map(item => {return parseInt(item, 10)}) : [],
        }, () => {
            this.filterApi();
            }
        )

        /**
         * Get types filter
         */
        ApiService.typesMurmurations().then((r) => {
        this.setState({
            types: r.data["hydra:member"],
        }, () => {
            //fill state and active state with type 
            if (searchParams.get('types')) {
            const categories = this.state.types;
            const actCategories = [];
            searchParams.get('types').split(',').forEach(id => {
                let filteredCat = categories.filter(cat => cat.id === parseInt(id))
                let index = categories.indexOf(filteredCat[0])
                let allFilt = this.state.allFilters;
                allFilt.push(filteredCat[0]);
                actCategories.push(index)
                this.setState({
                activeTypes: actCategories,
                allFilters: allFilt
                })
            })
            }
        });
        });
    
        window.addEventListener("resize", this.handleFiltersDimensions);
    }

    /**
     * API call for murmuration with filter
     */  

    filterApi = (newPage) => {
        this.setState({
            isLoadingMore: true
        }, () => {
            const searchParams = new URLSearchParams();
            searchParams.append("_page", this.state._page);
            searchParams.append("itemsPerPage", this.state.itemsPerPage);
            searchParams.append('search[]', 'articles')
            searchParams.append('search[]', 'interviews')
            searchParams.append('search[]', 'discussions')
            searchParams.append('sort', 'publishedAt')

            if (this.state.typesFilter.length) {
                searchParams.append("types", this.state.typesFilter);
            }
        
            this.props.history.replace(`/murmurations?${searchParams.toString()}`)

            ApiService.getMurmurations(searchParams).then((r) => {
                if (newPage) {
                    this.setState(
                        {
                        murmurations: [...this.state.murmurations, ...r.data["hydra:member"]],
                        numberOfMurmurations: r.data["hydra:member"].length > 0 ? r.data["hydra:member"][0].totalItems : 0,
                        isLoading: false,
                        isLoadingMore: false,
                        isLoadMore: false
                        },
                        () => {
                        this.setState({
                            haveMore:
                            r.data["hydra:member"][0]?.totalItems > this.state.murmurations.length
                                ? true
                                : false,
                        });
                        }
                    );
                } else {
                    this.setState(
                        {
                        murmurations: r.data["hydra:member"],
                        numberOfMurmurations: r.data["hydra:member"].length > 0 ? r.data["hydra:member"][0].totalItems : 0,
                        isLoading: false,
                        isLoadingMore: false,
                        isLoadMore: false,
                        },
                        () => {
                        this.setState({
                            haveMore:
                            r.data["hydra:member"][0]?.totalItems > this.state.murmurations.length
                                ? true
                                : false,
                        });
                        }
                    );
                }
            });
        })
    };

    /**
     * load more murmurations
     */  

    loadMore = () => {
        this.setState({
        _page: parseInt(this.state._page) + 1,
        isLoadingMore: true,
        isLoadMore: true,
        }, () => {
        this.filterApi(true);
        });
    };

    /**
     * Handle click on filter options
     */  

    handleFilter = (type, i, filterOption) => {
        this.setState({
            isLoadMore: true
        }, () => {
            let typeArr = [];
            let filtArr = [];
            let allFilters = this.state.allFilters;
            
            //set typeArr and filtArr depending on type
            if (type === "type") {
                typeArr = this.state.activeTypes;
                filtArr = this.state.typesFilter;
            } 
        
            //if options already exists then remove it
            if (filtArr.includes(filterOption.id)) {
                const index = filtArr.indexOf(filterOption.id);
                const newArr = [...filtArr];
                const allFiltersIndex = allFilters.indexOf(filterOption);
                newArr.splice(index, 1);
                allFilters.splice(allFiltersIndex, 1);
                this.setState({
                allFilters: allFilters
                })
                if (type === "type") {
                this.setState({ typesFilter: newArr }, () => {
                    this.filterApi();
                });
                } 
            } else {
                //if options doesn't exist, push to all filters and typeFilter
                const newArr = [...filtArr];
                newArr.push(filterOption.id);
                allFilters.push(filterOption);
                this.setState({
                allFilters: allFilters
                })
        
                if (type === "type") {
                this.setState({ typesFilter: newArr }, () => {
                    this.filterApi();
                });
                }
            }
            if (typeArr.includes(i)) {
                //active state push if it doesn't exist
                const index = typeArr.indexOf(i);
                const newArr = [...typeArr];
                newArr.splice(index, 1);
                if (type === "type") {
                this.setState({ activeTypes: newArr, _page: 1 });
                }
            } else {
                //active state push if it doesn't exist
                const newArr = [...typeArr];
                newArr.push(i);

                if (type === "type") {
                this.setState({ activeTypes: newArr, _page: 1 });
                }
            }
        })
    };

    /**
     * Remove filter clicking on list of filters
     */  

    handleRemoveFilter = item => {
        this.setState({
            isLoadMore: true
        }, () => {
            const type = item['@type'];
            let typeArr = [];
            let filtArr = [];
            let allArr = [];
            let allFilters = this.state.allFilters;
            let filteredArr;
            if (type === 'Type') {
                typeArr = this.state.activeTypes;
                filtArr = this.state.typesFilter;
                allArr = this.state.types;
            }
        
            filteredArr = allArr.filter(filteredItem => filteredItem.id === item.id)
            const index = allArr.indexOf(filteredArr[0])
            const index1 = filtArr.indexOf(item.id)
            const index2 = allFilters.indexOf(item);
            const typeArrIndex = typeArr.indexOf(index)
            typeArr.splice(typeArrIndex, 1);
            filtArr.splice(index1, 1);
            allFilters.splice(index2, 1);
        
            if (type === 'Type') {
                this.setState({
                activeTypes: typeArr,
                typesFilter: filtArr,
                allFilters: allFilters
                }, () => {
                this.filterApi()
                })
            }
        })
    }

    /**
     * Reset all filters
     */  
    handleClearAllFilters = () => {
        this.setState({
            typesFilter: [],
            activeTypes: [],
            allFilters: [],
            isLoadMore: true
        }, () => {
            this.filterApi();
        })
    }

    /**
     * Handle click on type filter
     */  
    handleTypeFilter = (type, i) => {
        this.handleFilter("type", i, type);
    };

    /**
     * Handle toggle filters
     */  
    toggleFilters = () => {
        this.setState({ filtersVisible: !this.state.filtersVisible });
    };   

    render() {
        let types;
        let allFilters;

        if (this.state.types.length) {
            types = this.state.types.map((type, index) => {
                return (
                    <StyledChipHolder key={`type-${index}`}>
                        <StyledChip                            
                            className={this.state.activeTypes.includes(index) ? "active" : ""}
                            onClick={() => this.handleTypeFilter(type, index, type.id)}
                        >
                            {type.title}
                        </StyledChip>
                    </StyledChipHolder>
                );
            });
        }

        if (this.state.allFilters.length) {
            allFilters = this.state.allFilters.map((item, index) => {
                if (item) {
                return (
                    <StyledChip
                        labelWithIcon
                        detached
                        key={`filter-${index}`}
                        onClick={() => this.handleRemoveFilter(item)}
                    >
                        {item.title ? item.title : item.name ? item.name : `${item === 9 ? '<' : '>'} ${item} hrs `}
                        <i className="icon-close-small no-animation" />
                    </StyledChip>
                );
                } else {
                    return null
                }
            })
        }

        return (
            <div className="container container__big container__big--withPadding">
                <PageMeta>
                    <title>Murmurations | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {this.state.types.length > 0 &&
                    <>
                        <FiltersToggle>
                            {!this.state.filtersVisible ? (
                                <StyledChip small detached onClick={() => this.toggleFilters()}>
                                    Filter
                                </StyledChip>
                                ) : (
                                    <>
                                        <Hidden mdUp>
                                            <span className="pl-42">Filter</span>
                                        </Hidden>
                                        <i
                                            className="icon-close mr-24"
                                            style={{ verticalAlign: "bottom" }}
                                            onClick={() => this.toggleFilters()}
                                        />
                                    </>
                                )}
                            </FiltersToggle>
                        
                        <Filters
                            ref={this.filtersWrapper}
                            className={this.state.filtersVisible ? "visible" : "hidden"}
                            height={this.state.filtersHeight}
                        >
                            <div className="filters-wrapper">
                                <h2>Types</h2>
                                {types && (
                                    <AudioBookSingleFilter
                                        filtersId={'typesContainer'}
                                        filters={types}
                                    />
                                )}
                            </div>
                        </Filters>
                    </>
                }
                {this.state.allFilters.length > 0 && this.state.allFilters[0] !== undefined &&
                <FilterResultsInfo>  
                     
                    <>                  
                        <StyledChip onClick={() => this.handleClearAllFilters()} circle className="mr-12" style={{marginLeft: '0'}}> 
                            <i className="icon-close-small no-animation" />
                        </StyledChip>
                        
                        {allFilters}
                    </>
                </FilterResultsInfo>
                }

                <TitleTextBlock 
                    murmurations
                    fontItalic 
                    title='Murmurations' 
                    text="Related articles, podcasts, interviews."
                    subtext={<><span className="font-italic">A classic is a book that has never finished saying <br/> what it has to say &#8212;</span> Italo Calvino</>}
                />             
                
                {this.state.isLoading ? <SkeletonArticleList/> : (
                    <>
                        {this.state.isLoadMore && <SkeletonArticleList/>}
                        
                        {
                            <div>
                                {this.state.murmurations.length > 0 && this.state.murmurations.map((item, index) => {
                                    const murmurationType=(item.article && 'article') || (item.interview && 'interview') || (item.discussion && 'discussion');
                                    const murmuration=item[murmurationType];

                                    return <ArticleItem 
                                        key={`murmuration-${index}`}
                                        image={murmuration.image}
                                        alt={murmuration.altText}
                                        author={murmuration.author ? `${murmuration.author.firstName} ${murmuration.author.lastName}` : null}
                                        category={murmuration.category ? murmuration.category.name : null}
                                        title={murmuration.title}
                                        slug={murmuration.slug}
                                        type={murmuration.type ? murmuration.type.title : null}
                                        articleType={murmurationType + 's'}
                                        featured={murmuration.featured}
                                        summary={murmuration.summary}
                                        />
                                })}
                            </div>
                        }

                        {this.state.haveMore ? (
                            <div className="text-center mt-27">
                                <button
                                id='gtm-murmurations-load-more'
                                onClick={() => this.loadMore()}
                                className={`c-btn c-btn__ghost font-22 font-secondary font-italic mb-50 ls-2 ${this.state.isLoadingMore ? 'submitting' : ''}`}>
                                load more
                                <SpiracleLogoSmall />
                                </button>
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        );
    }
}

export default Murmurations;
