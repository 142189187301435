import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import authorImage from '../../../images/author.webp';

export default class Author extends Component {
  render() {
    return (
      <div className='c-author text-center'>
        <img className='c-author__image' src={this.props.info.image && this.props.info.image ? this.props.info.image : authorImage} alt={`${this.props.info.firstName} ${this.props.info.lastName}`} />
        <div className='c-author__bio font-italic'>{ReactHtmlParser(this.props.info.biography)}</div>
      </div>
    );
  }
}
