export const ADD_DISCOUNT = "ADD_DISCOUNT";
export const REMOVE_DISCOUNT = "REMOVE_DISCOUNT";


export const onAddDiscount = (payload) => {
    return dispatch => {
        dispatch({
            type: ADD_DISCOUNT,
            relativeDiscount: payload.relativeDiscount,
            discountCode: payload.discountCode
        })
    }
}

export const onRemoveDiscount = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_DISCOUNT
        })
    }
}