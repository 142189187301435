import styled from "styled-components/macro";


export const Title = styled.h1``;
export const StyledPublisherItem = styled.div``;

export const PublishersHolder = styled.div`
    text-align: center;
    min-height: calc(100vh - 388px);

    .container {
        background-color: #F6F2E6;
        padding: 80px 25px 65px;

        ${(props) => props.theme.breakpoints.down("md")} {   
            background: transparent;
            padding-top: 40px;
        }
    }

    ${Title} {        
        font-size: 48px;
        font-style: italic;        
        line-height: 58px;
        letter-spacing: 0.02em;   
        margin-bottom: 33px;    
        
        ${(props) => props.theme.breakpoints.down("md")} {   
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0em;
            margin-bottom: 25px;
        }
    }

    ${StyledPublisherItem} {  
        font-size: 18px;        
        line-height: 36px;
        letter-spacing: 0em;  
        
        a {
            &:hover {
                color: #721B00;
            }
        }        
    }
`;