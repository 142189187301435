import styled from "styled-components/macro";

export const StyledRadio = styled.span``;
export const FiltersRow = styled.div``;


export const Filters = styled.div`
    height: ${(props) => (props.height ? props.height + "px" : "auto")};
    transition: all 0.5s ease;
    overflow: hidden;

    ${(props) => props.theme.breakpoints.up("md")} {
        text-align: center;
    }

    &.hidden {
        height: 0;
    }

    .filters-wrapper {
        padding: 0 17px 70px;
        border-bottom: 1px solid #000000;
    }

    h2 {
        font-family: Gill Sans Nova;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        margin: 30px 0 18px;

        ${(props) => props.theme.breakpoints.down("sm")} {
            margin: 26px 0 8px;
        }
    }

    ${FiltersRow} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        position: relative;

        ${(props) => props.theme.breakpoints.down("sm")} {
            justify-content: flex-start;
            margin-left: -3px;
        }
    }

    ${StyledRadio} label {
        display: inline-block;
        border-radius: 20px;
        font-size: 22px;
        font-style: italic;
        font-family: Gill Sans Nova;
        height: 40px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #000000;
        background-color: transparent;
        border: 1px solid #000000;
        padding: 0 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        text-transform: lowercase;

        &:hover {
            border-color: #721b00;
            color: #721b00;
        }

        &.active {
            background-color: #000000;
            color: #f4eedc;
        }
    }

    ${StyledRadio} {
        input[type="radio"],
        input[type="checkbox"] {
            display: none;
        }
        input[type="radio"]:checked + label {
            background-color: #000000;
            color: #f4eedc;
        }
        input[type="checkbox"]:checked + label {
            background-color: #000000;
            color: #f4eedc;
        }
    } 
`;

export const StyledChipHolder = styled.div`
    padding: 3px;
`;

export const StyledChip = styled.div`
    display: inline-block;    
    border-radius: 20px;
    font-size: ${(props) => (props.small ? "16px" : "22px")};
    font-style: ${(props) => (props.small ? "normal" : "italic")};
    font-family: Gill Sans Nova;
    height: ${(props) => (props.small ? "30px" : "40px")};
    width: ${(props) => (props.circle ? "40px" : "auto")};
    line-height: ${(props) => (props.small ? "23px" : "32px")};
    position: ${(props) => (props.fixed ? "absolute" : "")};
    left: ${(props) => (props.left ? props.left + "px" : "")};
    top: ${(props) => (props.fixed ? "0" : "")};
    margin: ${(props) => (props.detached && "3px")};
    letter-spacing: 0.02em;
    color: #000000;
    background-color: transparent;
    border: 1px solid #000000;
    padding: ${(props) => (props.circle ? "0" : "0 16px")};
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    text-transform: lowercase;    

    &:hover {
        border-color: #721b00;
        color: #721b00;
    }

    &.active {
        background-color: #000000;
        color: #f4eedc;
    }   

    i {
        font-size: ${(props) => (props.labelWithIcon ? "12px" : "15px")};  
        margin-left:  ${(props) => (props.labelWithIcon ? "10px" : "0")};
    }
`;

export const EmptyPage = styled.div`
    font-family: Gill Sans Nova;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0em;
    margin-bottom: 150px;
    margin-top: -47px;

    ${(props) => props.theme.breakpoints.up("md")} {
        margin-top: -4px;
    }

    p {
        margin-bottom: 10px;
    }

    a {
        text-decoration: underline;
    }
`;

export const FiltersToggle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 36px;

    span {    
        font-family: Gill Sans Nova;   
        font-size: 12px;        
        line-height: 13px;
        letter-spacing: 0.15em;
        margin: auto;
        text-transform: uppercase;
    }

`;

export const FilterResultsInfo = styled.div`
    margin: 30px 0 12px 23px;
    position: relative;
    padding-bottom: 60px;

    ${(props) => props.theme.breakpoints.up("lg")} {
        margin: 35px 0 30px 32px;
        padding-bottom: 0;
    }

    span {
        ${(props) => props.theme.breakpoints.down("md")} {
            position: absolute;
            bottom: 5px;
        }    
    }
`;


