import { PaddingTop, PaddingBottom, ParsedText, PopupHeader, StyledPopup, StyledPopupContainer } from './style';
import React, { Component } from 'react'

import ReactHtmlParser from 'react-html-parser';

export default class Popup extends Component {

    state = {
        isOpen: this.props.isOpen,
    }

    handleToggleOpen = () => {
        this.props.toggleModalOpen();
    }

    render() {
        return (
            <StyledPopupContainer isOpen={this.props.isOpen}>
                <PaddingTop />
                <StyledPopup>
                    <PopupHeader>
                        <i onClick={() => this.handleToggleOpen()} className="icon-close"></i>
                    </PopupHeader>
                    <ParsedText>
                        {ReactHtmlParser(this.props.text)}
                    </ParsedText>
                </StyledPopup>
                <PaddingBottom />
            </StyledPopupContainer>
        )
    }
}
