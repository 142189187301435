import * as bookActionTypes from '../../../store/actions/bookActions';
import * as cartActionTypes from '../../../store/actions/cartActions';

import React, { Component } from 'react';

import ApiService from '../../../services/api.service';
import { ReactComponent as SpiracleLogoSmall } from '../../../images/Spiral.svg';
import { connect } from 'react-redux';

class RemoveButton extends Component {
    state = {
        isRemoving: false,
    }

    handleRemoveBookFromCart = uuid => {
        const cartUuid = this.props.cart[0].cart.uuid

        this.setState({ isRemoving: true })
        ApiService.deleteCartItem(uuid).then(
            r => {
                    this.props.onRemoveBookFromCart(this.props.book)
                    this.props.onRemoveCartItem(uuid);
                    ApiService.getCartItems(cartUuid).then((r) => {
                        this.setState({
                            isRemoving: false
                        })
                        this.props.onGetCartItems(r.data['hydra:member'])
                    })
                    if (this.props.checked) {
                        this.props.onRemoveCheckbox()
                    }

                    if (this.props.emailValid) {
                        this.props.onRemoveEmail();
                    }

                    if (window.dataLayer) {
                        const locations = []
                        if (this.props.book.audioBook.locations && this.props.book.audioBook.locations.length) {
                            this.props.book.audioBook.locations.map((location) => {
                                return locations.push(location.title)
                            })
                        }
                        const tags = []
                        if (this.props.book.audioBook.tags && this.props.book.audioBook.tags.length) {
                            this.props.book.audioBook.tags.map((tag) => {
                                return tags.push(tag.title)
                            })
                        }
                        const authors = []
                        if (this.props.book.audioBook.authors && this.props.book.audioBook.authors.length) {
                            this.props.book.audioBook.authors.map((author) => {
                                return authors.push(author.fullName)
                            })
                        }
                        const narrators = []
                        if (this.props.book.audioBook.narrators && this.props.book.audioBook.narrators.length) {
                            this.props.book.audioBook.narrators.map((narrator) => {
                                return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                            })
                        }
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                            event: "remove_from_cart",
                            ecommerce: {
                                currency: "GBP",
                                value: this.props.user && this.props.user.currentSubscription ? this.props.book.audioBook.subscriptionPrice : this.props.book.audioBook.price,
                                items: [
                                    {
                                        item_id: this.props.book.audioBook.slug,
                                        item_name: this.props.book.audioBook.title,
                                        item_brand: this.props.book.audioBook.publisher ? this.props.book.audioBook.publisher?.name : '',
                                        item_category: this.props.book.audioBook.category ? this.props.book.audioBook.category?.name : '',
                                        item_locations: locations?.length ? locations.join(', ') : '',
                                        item_tags: tags?.length ? tags.join(', ') : '',
                                        item_price: this.props.book.audioBook.price,
                                        item_author: authors?.length ? authors.join(', ') : '',
                                        item_narrator: narrators?.length ? narrators.join(', ') : '',
                                        item_translator: this.props.book.audioBook.translator ? `${this.props.book.audioBook.translator.firstName} ${this.props.book.audioBook.translator.lastName}` : '',
                                        item_length: `${this.props.book.audioBook.lengthHours}:${this.props.book.audioBook.lengthMin}`,
                                    }
                                ]
                            }
                        });
                    }
            }
        )
    }

    render() {
        return (            
            <button id='gtm-remove-from-shopping-bag' className='remove-btn' onClick={() => this.handleRemoveBookFromCart(this.props.uuid)}>
                {!this.state.isRemoving ? 'remove' : <SpiracleLogoSmall style={{marginTop: '8px'}} />}
            </button>           
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    cart: state.cartReducer.cart,
});

const mapDispatchToProps = dispatch => {
    return {
        onRemoveBookFromCart: (book) => dispatch(bookActionTypes.onRemoveBookFromCart(book)),
        onRemoveCartItem: (uuid) => dispatch(cartActionTypes.onRemoveCartItem(uuid)),
        onRemoveCheckbox: () => dispatch(cartActionTypes.onRemoveCheckbox()),
        onRemoveEmail: () => dispatch(cartActionTypes.onRemoveEmail()),
        onGetCartItems: (cartItems) => dispatch(cartActionTypes.onGetCartItems(cartItems)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveButton)
