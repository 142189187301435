import { ApplyButton, ButtonsHolder, CloseButton, Cta, StyledDrawer, SwipersHolder } from 'components/UI/AudioBookPlayer/style';
import React, { Component } from "react";

import PropTypes from "prop-types";
import { Swiper } from 'swiper/react';

class PlayerSpeedDrawer extends Component {

    handleClick = () => {
        this.props.onClick();
        this.props.onClickClose();
    }

    handleSpeedClick = (val) => {
        this.props.handleSpeedClick(val);
        this.props.onClickClose();
    }

    render() {   
        return (
            <StyledDrawer anchor='bottom' open={this.props.open}>
                <h2>Change speed</h2>
                <SwipersHolder style={{ marginBottom: "280px"}}>
                    <Swiper
                        grabCursor                    
                        slidesPerView={'auto'}
                        slideToClickedSlide
                        centeredSlides
                        direction={"vertical"}
                        className="speed-swiper"
                        onSlideChange={this.props.onSlideChange}
                    >
                        {this.props.playbackOptions}
                    </Swiper> 
                </SwipersHolder>
                <ButtonsHolder>
                    <button onClick={() => this.handleSpeedClick(0.5)}>0,5</button>
                    <button onClick={() => this.handleSpeedClick(1)}>1,0</button>
                    <button onClick={() => this.handleSpeedClick(1.5)}>1,5</button>
                    <button onClick={() => this.handleSpeedClick(2)}>2,0</button>
                </ButtonsHolder> 
                <Cta>                
                    <ApplyButton onClick={() => this.handleClick()}>
                        Apply
                    </ApplyButton>
                    <CloseButton onClick={this.props.onClickClose}>
                        Close
                    </CloseButton>
                </Cta>
            </StyledDrawer>
        );
    }
}

PlayerSpeedDrawer.propTypes = {
    open: PropTypes.bool,
    onSlideChange: PropTypes.func,    
    onClick: PropTypes.func,
    onClickClose: PropTypes.func,    
    playbackOptions: PropTypes.array,
};

export default PlayerSpeedDrawer;
