import { ApplyButton, ButtonsHolder, CloseButton, Cta, StyledDrawer, SwipersHolder } from 'components/UI/AudioBookPlayer/style';
import React, { Component } from "react";

import PropTypes from "prop-types";
import { Swiper } from 'swiper/react';

class PlayerSleepDrawer extends Component {

    state = {
        minVal: 0
    }

    handleApplyClick = () => {
        this.props.onClickApply();
        this.props.onClickClose();
    }
    
    handleMinuteClick = (val) => {
        this.props.handleMinuteClick(val);
        this.props.onClickClose();
        // if (val === this.state.minVal) {
        //     this.setState({
        //         minVal: 0
        //     })
        // } else {
        //     this.setState({
        //         minVal: val
        //     })
        // }
    }

    handleClickClose = () => {
        this.setState({
            minVal: 0
        }, () => {
            this.props.onClickClose();
        })
    }

    render() {   
        return (
            <StyledDrawer anchor='bottom' open={this.props.open}>
                <h2>Sleep timer</h2>
                <SwipersHolder style={{ marginBottom: "280px"}}>
                    <Swiper                                              
                        direction={"vertical"}
                        className="hours-swiper"
                        slidesPerView={"auto"}
                        centeredSlides
                        grabCursor
                        slideToClickedSlide
                        onSlideChange={(slide) => this.props.onHoursSlideChange(slide)}
                    >
                        {this.props.hours}
                    </Swiper>
                    <Swiper                                            
                        direction={"vertical"}
                        className="minutes-swiper"
                        slidesPerView={"auto"}
                        centeredSlides
                        grabCursor
                        slideToClickedSlide
                        onSlideChange={(slide) => this.props.onMinutesSlideChange(slide)}
                    >
                        {this.props.minutes}
                    </Swiper>
                </SwipersHolder>
                <ButtonsHolder>
                    <button className={`${this.state.minVal === 15 ? 'active' : ''}`} onClick={() => {this.handleMinuteClick(15)}}>15 min</button>
                    <button className={`${this.state.minVal === 30 ? 'active' : ''}`} onClick={() => {this.handleMinuteClick(30)}}>30 min</button>
                    <button className={`${this.state.minVal === 45 ? 'active' : ''}`} onClick={() => {this.handleMinuteClick(45)}}>45 min</button>
                    <button className={`${this.state.minVal === 60 ? 'active' : ''}`} onClick={() => {this.handleMinuteClick(60)}}>60 min</button>
                </ButtonsHolder>
                <Cta>                    
                    <ApplyButton onClick={() => this.handleApplyClick()}>
                        Apply
                    </ApplyButton>
                    <CloseButton onClick={() => this.handleClickClose()} >
                        Close
                    </CloseButton>
                </Cta>
            </StyledDrawer>
        );
    }
}

PlayerSleepDrawer.propTypes = {
    open: PropTypes.bool,
    onHoursSlideChange: PropTypes.func,
    onMinutesSlideChange: PropTypes.func,
    onClickApply: PropTypes.func,
    onClickClose: PropTypes.func,
    hours: PropTypes.array,
    minutes: PropTypes.array,
};

export default PlayerSleepDrawer;
