import React, { Component } from 'react'

export default class SkeletonArticleList extends Component {

    state = {
        skeleton: [1, 2, 3, 4, 5]
    }

    render() {

        let skeleton = this.state.skeleton.map( (skelet, index) => (
            <div key={index} className="c-skeleton__article-list">
                <div className="h-flex h-flex-wrap h-flex-between">
                    <div className="c-skeleton__article-list--img"></div>
                    <div className="c-skeleton__article-list__wrapper  h-flex h-flex-center h-flex-middle h-flex-column">
                        <div className="c-skeleton__article-list--category"></div>
                        <div className="c-skeleton__article-list--title"></div>
                        {/*<div className="c-skeleton__article-list--title-secondary mb-21"></div>*/}
                        <div className="c-skeleton__article-list--author"></div>
                    </div>
                </div>
            </div>
        ))

        return skeleton
    }
}
