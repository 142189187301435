import styled from "styled-components/macro";

export const InputContainer = styled.div``;
export const SortButton = styled.button``;
export const SortMenu = styled.div``

export const SearchContainer = styled.div`

    ${InputContainer} {
        position: relative;
        padding: 0 18px;
        max-width: 375px;

        input {
            margin-top: 30px;
            margin-bottom: 22px;
            //margin-left: 7px;
            //width: calc(100% - 14px);
            font-size: 22px;
            font-style: italic;
            padding: 8px 0;
            height: auto;

            @include mq('to', 768) {
                //margin-top: 35px;
            }

            &::placeholder {
                letter-spacing: .15em;
            }

            &:focus {
                border-bottom: 1px solid $black;
            }
        }

        i {
            top: 50px;
        }
    }

    ${SortButton} {
        position: relative;
        border: none;
        outline: none;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        margin-left: 25px;
        padding-right: 23px;
        cursor: pointer;
        display: ${(props) => props.hide ? 'none' : 'initial'};
        transition: .3s ease-in-out;

        ${(props) => props.theme.breakpoints.up("md")} {             
            margin-left: 32px; 
            display: initial   
        }

        @media (hover: hover) {
            &:hover {
                color: #721b00;
            }
        }

        i {
            position: absolute;
            bottom: ${(props) => props.direction ? '0' : '1px'};
            font-size: 15px;
            margin-left: 7px;
            cursor: pointer;
            transition: none;
            transform: ${(props) => props.direction ? 'rotate(90deg)' : 'rotate(-90deg)'};

            ${(props) => props.theme.breakpoints.up("md")} { 
                 margin-left: 13px;
            }
        }
    }

    ${SortMenu} {
        position: absolute;
        top: calc(100% - 8px);
        left: -8px;
        width: 280px;
        background-color: #F4EEDC;
        padding: 12px 33px 17px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        font-family: 'Gill Sans Nova';
        z-index: 1;
        visibility: ${(props) => props.hide ? 'hidden' : 'visible'};

        ${(props) => props.theme.breakpoints.up("md")} {             
            left: -12px;  
            width: 280px;
            padding: 12px 43px 17px;
            visibility: visible;  
        }

        div {
            position: relative;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.15em;
            margin: 21px 0;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            i {
                position: absolute;
                //right: 13px;
                right: 35px;
                font-size: 12px;
                cursor: pointer;

                ${(props) => props.theme.breakpoints.up("md")} { 
                    //right: 7px;
                    right: 29px;
                }
            }

            &:nth-of-type(2) {
                i {
                    transform: rotate(-90deg);
                    top: 2px;
                }
            }

            &:nth-of-type(3) {
                i {
                    transform: rotate(90deg);
                    top: 4px;
                }
            }
        }
    }
`;