import * as bookActionTypes from '../../store/actions/bookActions'

import {
  BookInfo,
  Cta,
  ImageHolder,
  StyledDrawer,
  StyledHeader
} from "layout/Header/styledHeaderPlayer";
import { Link, withRouter } from 'react-router-dom';
import React, { Component } from "react";

import CoverImage from "shared/CoverImage/CoverImage";
import DateFormater from "services/format-date.service";
import PropTypes from "prop-types";
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import { connect } from 'react-redux';
import { getNamesFromArray } from 'shared/Functions/GetNamesFromArray';
import ApiService from 'services/api.service';

class HeaderPlayer extends Component {

  state = {
    drawerOpen: false,
    isDownloading: false,
  }

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    })
  }

  minimizePlayer = () => {
    document.body.classList.remove('noScroll');
    this.props.onSwitchWidgetPlayer();
  }

  handleTurnOffPlayer = (e) => {
    e.preventDefault();
    this.setState({
      drawerOpen: !this.state.drawerOpen
    }, () => {
      document.body.classList.remove('player-visible');
      document.body.classList.remove('noScroll');
      this.props.onClearBook();
      this.props.stopPlaying();
    })
  }

  handleDownload = e => {
    e.preventDefault();

    this.setState({
      isDownloading: true
    })

    ApiService.downloadAudioBook(this.props.book.download).then(
      r => {
        let blob = new Blob([r.data], {type: "application/zip"})
        let downloadUrl = URL.createObjectURL(blob)
        let a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${this.props.book.title}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.setState({
          isDownloading: false
        })
      }
    )
  }

  render() {
    let content = null;
    const publisherSlug = this.props.book && this.props.book.publisher && this.props.book.publisher['@id'].split('/')[3];

    if (this.props.book) {
      content = (
        <>
          <StyledHeader reverseHeader={this.props.desktopPlayer} isEdition={this.props.book.edition}>
              {this.props.desktopPlayer ? <i className="icon-arrow" onClick={() => this.minimizePlayer()}/> : null}
              <i className="icon-dots" onClick={() => this.toggleDrawer()}/>
          </StyledHeader>
          <StyledDrawer
            anchor="bottom"
            open={this.state.drawerOpen}
            onClose={this.toggleDrawer}
          >
            <BookInfo>
                <ImageHolder>
                    <CoverImage
                        src={this.props.book.cover330 }            
                        alt={this.props.book.coverAlt}                        
                    />
                </ImageHolder>
              <p className="">{this.props.book.title}</p>
              <p className="font-italic">{getNamesFromArray(this.props.book.authors['hydra:member'])}</p>
              {this.props.book.narrators['hydra:member'].length ? <p>Narrated by: <span className="font-italic">{getNamesFromArray(this.props.book.narrators['hydra:member'])}</span></p> : null }
              {this.props.book.firstPublished ? <p>First published: {DateFormater.formatDate(this.props.book.firstPublished)}</p> : null}
              {this.props.book.language ? <p>Language: {this.props.book.language.title}</p> : null}
              {this.props.book.translator ? <p>Translator: <span className="font-italic">{this.props.book.translator.firstName} {this.props.book.translator.lastName}</span></p> : null }
              {window.innerWidth <= 375 ? (
                this.props.book.publisher && <p>Publisher: <Link id='gtm-player-publisher' onClick={() => this.minimizePlayer()} to={`/publishers/${publisherSlug}`}>{this.props.book.publisher.name}</Link></p>
                ) : (
                  this.props.book.publisher && <p>Publisher: {this.props.book.publisher.name}</p>
                  )}
            </BookInfo>
            
              <Cta>
              {this.props.desktopPlayer ? <div>
                  <Link id='gtm-player-go-to-book-page' onClick={this.minimizePlayer} to={`/audiobooks/${this.props.book.slug}`}>
                    <i className="icon-book-page"></i>go to book page
                  </Link>
                </div> : null}
                {!window.location.href.includes('sample') ? 
                <div className={this.state.isDownloading ? 'downloading-audiobook' : ''}>
                  <a href={this.props.book.download} onClick={(e) => this.handleDownload(e)}>{this.state.isDownloading ? <><SpiracleLogoSmall /><span>Downloading...</span></> : <><i className="icon-download"></i>Download</>}</a>
                </div> : null}
                {this.props.desktopPlayer ? <div>
                  <a href={this.props.book.download} onClick={(e) =>this.handleTurnOffPlayer(e)}><i className="icon-close"></i>TURN OFF PLAYER</a>
                </div> : null}
              </Cta>
          </StyledDrawer>
        </>
      );
    }

    return content
  }
}

HeaderPlayer.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = state => ({
  book: state.bookReducer.book,
})

const mapDispatchToProps = dispatch => {
  return {
      onGetBook: (book) => dispatch(bookActionTypes.onGetBook(book)),
      onSwitchWidgetPlayer: () => dispatch(bookActionTypes.onSwitchWidgetPlayer()),
      onClearBook: () => dispatch(bookActionTypes.onClearBook())
  }
}

const headerPlayerWithRouter = withRouter(HeaderPlayer)

export default connect(mapStateToProps, mapDispatchToProps)(headerPlayerWithRouter);
