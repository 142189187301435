import Drawer from '@material-ui/core/Drawer';
import styled from "styled-components/macro";

export const StyledDrawer = styled(Drawer)`
    .MuiPaper-root {
        background-color: #f9f7ed;
        padding: 20px 26px;
        text-align: center;
    }
`

export const BookInfo = styled.div`
    margin-bottom: 54px;

    p, h2, span {
        font-family: 'Gill Sans Nova';
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        margin-bottom: 4px;
    }

    p > a {
        text-decoration: underline;
    }
`

export const Cta = styled.div`
    div {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        transition: all .3s ease-in-out;

        &.downloading-audiobook {
            a {
                display: flex;
                align-items: center;
                height: 20px;

                svg {
                    transform: scale(0.7);
                    margin-left: -8px;
                }

                span {
                    color: #000;
                    margin-left: 22px;
                }
            }
        }

        i {
            margin-right: 32px;
        }
        
        a {
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'Gill Sans Nova';
            letter-spacing: 0.2em;

            &::before {
                display: none;
            }
        }

        &:hover {
            i, a {
                color: #721b00
            } 
        }
    }
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 30px 22px 20px;
    flex-direction: ${(props) => props.reverseHeader ? '' : 'row-reverse'};

    ${(props) => props.theme.breakpoints.up("sm")} {
        padding: ${(props) => (props.isEdition ? "40px 22px 20px" : "40px 22px 40px")};
    }


    i {
        font-size: 20px;
        color: ${(props) => (props.isEdition ? "#831F00" : "#000000")};

        &.icon-arrow {
            transform: rotate(90deg);
        }
    }
`;

export const ImageHolder = styled.div`
    width: 80px;
    margin: 0 auto 30px;
`;