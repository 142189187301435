const initialState = {
    month: 12,
    year: 120,
    sixMonth: 60,
}

const membershipPriceReducer = ( state = initialState ) => {
    return state;
}

export default membershipPriceReducer;