import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import Special from './Special/Special';
import Murmuration from './Murmuration/Murmuration';

import { StyledMurmurations, Subtitle, Title, Paragraph, Container } from './style';

function Murmurations({ murmurations, specialMurmurations, title }) {
  
    return (
		<StyledMurmurations className='text-center'>
			<Subtitle>Explore</Subtitle>
			<Title>{ReactHtmlParser(title)}</Title>
			<Paragraph>
                Related author, actor and publisher interviews, reviews and rare recordings.
			</Paragraph>

            <Container>
                <div>
                    {specialMurmurations?.length ? specialMurmurations.map((item, index) => (
                        <Special key={`item-${index}`} data={item} />
                    )) : null}
                </div>

                <div>
                    {murmurations?.length ? murmurations.map((item, index) => (
                        <Murmuration key={`item-${index}`} data={item} />
                    )) : null}
                </div>
            </Container>

            
            <Link id='gtm-homepage-browse-murmurations' to='/murmurations' className='sa-button-red sa-button-rounded'>Browse <span className='font-primary'>Spiracle </span><span className='font-italic font-primary'>Murmurations</span></Link>
		</StyledMurmurations>
    )
}
export default Murmurations