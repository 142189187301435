import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Content, StyledDownloadApp, Icons, Images } from './style';

import appleStoreIcon from '../../images/apple-store-icon.webp';
import googlePlayIcon from '../../images/google-play-icon.webp';
import phonePlayerAppBig from '../../images/phone-player-app-big.png';
import phonePlayerAppSmall from '../../images/phone-player-app-small.png';

function DownloadApp({title}) {          
    return (
        <StyledDownloadApp>
            <Content>
                <h2 className='text-center font-uppercase'>Spiracle Player App</h2>
                <h3 className='text-center'>{ReactHtmlParser(title)}</h3>
                <p className='text-center'>Download the Spiracle Player mobile app to seamlessly synchronise with your Spiracle Library and enjoy your favourite audiobooks on the go.</p>

                <Icons>
                    <a href="https://apps.apple.com/app/spiracle-player/id1666694522" target="_blank" rel="noreferrer">
                        <img id='gtm-download-apple-store' src={appleStoreIcon} alt="App Store Icon" />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=ed.archdroid.spiracleplayer" target="_blank" rel="noreferrer">
                        <img id='gtm-download-google-store' src={googlePlayIcon} alt="Google Play Icon" />
                    </a>
                </Icons>
            </Content>
            <Images>
                <img src={phonePlayerAppBig} alt="Phone" />
                <img src={phonePlayerAppSmall} alt="Phone" />
            </Images>
        </StyledDownloadApp>
        )
}

export default DownloadApp;
