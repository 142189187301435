import styled from "styled-components/macro";

export const Cover = styled.div``;
export const Amount = styled.p``;
export const Button = styled.button``;;

export const StyledPayment = styled.div`
    max-width: 360px;
    padding: 0 20px;
    margin-top: ${(props) => props.promotion ? "0" : "46px"};
    margin-left: auto;
    margin-right: auto;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: ${(props) => props.promotion ? "0" : "101px"}
    }

    ${Cover} {
        position: relative;
        margin: 0 auto;
        width: 153px;  
        height: 122px;

        img {
            position: absolute;
            width: 110px;
            height: 110px;
            object-fit: cover;
            filter: ${(props) => props.membership ? "drop-shadow(-2px 0px 5px rgba(0, 0, 0, 0.25));" : "drop-shadow(-2px 0px 20px rgba(0, 0, 0, 0.2))"};

            &:first-of-type {
                top: ${(props) => props.oneImage ? "6px" : "0"};  
                left: ${(props) => props.oneImage ? "22px" : "0"};
            }

            &:last-of-type {
                bottom: 0;
                right: 0;
            }
        }
    }

    ${Amount} {
        font-size: 22px;
        font-family: 'Gill Sans Nova';
        letter-spacing: .03em;
        font-weight: 300;
        text-align: center;
        margin: 25px auto 35px;
        width: max-content;

        span {
            margin-right: 3px;
        }

        &.old-price {
            text-decoration: line-through;

            &:nth-of-type(2) {
                margin-top: -25px;
            }
        }

        &.new-price {
            margin-top: -25px;
        }
    }

    ${Button} {
        position: relative;
        display: block;
        width: max-content;
        margin: 0 auto 33px;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover::before {
            width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: #000;
            transition: .3s ease-in-out;
        }
    }
`;