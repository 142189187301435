import styled from "styled-components/macro";

export const Carousel = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    
    .c-audio-book-single-carousel-container {
        h2 {
            margin-top: 0;
            margin-bottom: 17px;
            font-size: 18px;
            line-height: 22px;
            text-align: center;

            ${(props) => props.theme.breakpoints.up("lg")} {    
                margin-bottom: 23px;
                font-size: 36px;
                line-height: 43px;
            }
        }
    }
`;

export const SmallText = styled.div`
    max-width: 360px;
    margin: 30px auto 55px;
    padding: 0 22px;
`;

export const Button = styled.button`
    background: transparent;
    margin-top: 28px;
    cursor: pointer;
`

export const StyledXmasContainer = styled.div`
    max-width: 360px;
    padding: 0 20px;
    margin-top: 46px;
    margin-left: auto;
    margin-right: auto;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 101px
    }
`