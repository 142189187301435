import React, { Component } from 'react';

import { EmptyCart } from "shared/Pages/EmptyOrdersPage/style";
import { Link } from "react-router-dom";

class EmptyOrdersPage extends Component {
    render() {
        return (
            <EmptyCart inDropdown={this.props.inDropdown} bag={this.props.bag} library={this.props.library}>
                {this.props.pageName === 'order history' ? 
                    <span className="font-italic">You have no order history</span> : 
                    <span style={{textTransform: this.props.pageName === 'library' && 'capitalize', fontStyle: this.props.pageName === 'bag' && 'italic'}}>Your {this.props.pageName} is empty</span>}
                <br />
                {this.props.pageName === "bag" && <p className="font-italic font-15">The more that you read, the more things you will know. The more that you learn, the more places you’ll go</p>}
                {this.props.pageName === "bag" && <p className="font-15"> &#8212; Dr Seuss</p>}
                {this.props.pageName === "library" && <span><span className="font-italic">A room without books is like a body without a soul</span> Cicero</span>}
                <Link id='gtm-empty-orders-page-collection' to="/audiobooks" className="c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase">Browse the collection</Link>
            </EmptyCart>
        );
    }
}

export default EmptyOrdersPage;