import React, { Component } from 'react';

import { DownloadIconsContainer, Note } from './style';

import appleStoreIcon from '../../images/apple-store-icon.webp';
import googlePlayIcon from '../../images/google-play-icon.webp';

class DownloadIcons extends Component {
    render() {           
        return (
            <DownloadIconsContainer orderConfirmation={this.props.orderConfirmation}>
                {this.props.trialSubscription ? 
                    <Note>Also available to enjoy via the mobile apps - search for <span>Spiracle Player</span></Note>
                    : 
                    <p>Also available to enjoy via the <br /> <span>Spiracle Player App</span></p>
                }

                <div>
                    <a href="https://apps.apple.com/app/spiracle-player/id1666694522" target="_blank" rel="noreferrer">
                        <img src={appleStoreIcon} alt="App Store Icon" />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=ed.archdroid.spiracleplayer" target="_blank" rel="noreferrer">
                        <img src={googlePlayIcon} alt="Google Play Icon" />
                    </a>
                </div>
            </DownloadIconsContainer>
        )
    }
}

export default DownloadIcons;
