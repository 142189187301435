import React, { Component } from 'react'

export default class SkeletonArticleItem extends Component {
    render() {
        return (
            <div className="c-skeleton c-skeleton__article-single text-center">
                <div className="c-skeleton__article-single--category margin-center mb-28"></div>
                <div className="c-skeleton__article-single--title margin-center mb-31"></div>
                <div className="c-skeleton__article-single--author margin-center mb-26"></div>
                <div className="c-skeleton__article-single--image margin-center mb-33"></div>
                <div className="c-skeleton__article-single--content margin-center">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}
