import React from 'react';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import classes from './Loader.module.scss';

export default function Loader({fullSize, small}) {
    return <div className={`${fullSize ? classes.LoaderContainerBig : small ? classes.LoaderContainerSmall : classes.LoaderContainer}`}><SpiracleLogoSmall className={classes.Loader}/></div>
}

// export default function Loader({fullSize, logoTop}) {
//     return <div className={`${fullSize ? classes.LoaderContainerBig : logoTop ? classes.LoaderTop : classes.LoaderContainer}`}><SpiracleLogoSmall className={classes.Loader}/></div>
// }

// import React from 'react';

// export default function Loader() {
//     return (
//         <div className="book">
//             <div className="inner">
//                 <div className="left"></div>
//                 <div className="middle"></div>
//                 <div className="right"></div>
//             </div>
//             <ul>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//             </ul>
//         </div>
//     );
// }


