import React from 'react'

import Image from '../../../images/homepage.webp';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import CoverImage from "shared/CoverImage/CoverImage";
import FeaturedArticleItem from "components/UI/ArticleItem/FeaturedArticleItem";

function ArticleItem(props) {    
    return (               
        <div className={`c-article ${props.mobileOnly && 'c-article--mobile'}`}>
            { props.category ? <div className={`c-article__type ls-15 font-secondary font-400 font-uppercase ${props.mobileOnly && 'c-article__type--mobile'}`}>{props.category}</div> : null }
            {props.featured ? 
                <FeaturedArticleItem {...props} />
            :
                <div className={`h-flex h-flex-wrap c-article__wrapper ${props.mobileOnly && 'c-article__wrapper--mobile'}`}>
                    <Link id='gtm-article-item-image' to={`/${props.articleType === 'discussions' ? 'discussions' : props.articleType === 'interviews' ? 'interviews' : 'articles'}/${props.slug}`} className={`c-article__image ${props.mobileOnly && 'c-article__image--mobile'}`}>
                        <CoverImage 
                            src={props.image ? props.image : Image} 
                            alt={props.alt} 
                            width={223}                            
                        />    
                    </Link>
                    <div className={`c-article__flex-wrapper h-flex h-flex-center h-flex-middle h-flex-column ${props.mobileOnly && 'c-article__flex-wrapper--mobile'}`}>
                        { props.type ? <span className={`c-article__category font-secondary ls-15 font-18 font-uppercase ${props.mobileOnly && 'c-article__category--mobile'}`}>{props.type}</span> : null }
                        <Link id='gtm-article-item-title' to={`/${props.articleType === 'discussions' ? 'discussions' : props.articleType === 'interviews' ? 'interviews' : 'articles'}/${props.slug}`} className={`c-article__title font-primary font-italic font-400 font-32 ls-2 lh-40 text-center margin-reset mb-10 ${props.mobileOnly && 'c-article__title--mobile'}`}>{props.title}</Link>
                        {props.author ? <span className={`c-article__author font-secondary ls-15 font-18 font-uppercase ${props.mobileOnly && 'c-article__author--mobile'}`}>{props.author}</span> : null}
                    </div>
                </div>
            }
        </div>                     
    )   
}

export default withRouter(ArticleItem);