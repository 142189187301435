import * as discountActionTypes from '../../store/actions/discountActions';

import { Container, Heading, Paragraph, ParagraphBig, StyledPaymentProblem } from './style';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageMeta from '../../shared/PageMeta/PageMeta';
import image from '../../images/payment-problem.webp';

class OrderConfirmationIssue extends Component {
    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            this.props.onRemoveDiscount();
        }
    }
    
    render() {
        return (
            <StyledPaymentProblem className="container container__big">
                <PageMeta>
                    <title>Problem | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                
                {/* <TryAgain onClick={() => this.props.history.goBack()}><i className="arrow-back"></i><span>Try again</span></TryAgain> */}

                <Container>
                        <Heading className="font-italic">Oops, looks like there was <br /> a problem with order confirmation.</Heading>
                        <img src={image} alt="" />
                        <ParagraphBig>Please check your <Link id='gtm-order-confirmation-issue-library' to="/library">library</Link></ParagraphBig>
                        <Paragraph>VIEW OR <Link id='gtm-order-confirmation-issue-faq' to="/faq">FAQ</Link> OR CONTACT OUR <br /> <Link id='gtm-order-confirmation-issue-contact-support' to="/info/support">SUPPORT TEAM</Link> TO SOLVE THE PROBLEM.</Paragraph>
                </Container>
            </StyledPaymentProblem>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
    }
}

export default connect(null, mapDispatchToProps)(OrderConfirmationIssue);