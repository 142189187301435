import styled from "styled-components/macro";

export const Note = styled.p``;

export const DownloadIconsContainer = styled.div`
    margin-bottom:  ${(props) => props.orderConfirmation ? '57px' : '48px'};

    p {
        text-align: center;
        font-family: 'Gill Sans Nova', sans-serif;
        font-size: 18px;
        margin-bottom: 25px;

        span {
            display: block;
            margin-top: 10px;
            font-style: italic;
            letter-spacing: 0.02em;
        }
    }

    div {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    a {
        width: 136px;
    }

    img {
        display: block;
        width: 100%;
    }

    ${Note} {
        span {
            display: inline-block;
            margin-top: 0;
        }
    }
`;