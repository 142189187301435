import { Container, Heading, Paragraph, ParagraphBig, StyledPaymentProblem, TryAgain } from './style';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import PageMeta from '../../shared/PageMeta/PageMeta';
import image from '../../images/payment-problem.webp';

class PaymentProblem extends Component {
    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        }
    }
    
    render() {
        return (
            <StyledPaymentProblem className="container container__big">
                <PageMeta>
                    <title>Problem | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                
                <TryAgain onClick={() => this.props.history.goBack()}><i className="arrow-back"></i><span>Try again</span></TryAgain>

                <Container className="font-italic">
                        <Heading>Oops, looks like there was <br /> a problem with the payment.</Heading>
                        <img src={image} alt="" />
                        <ParagraphBig>Please try again or another card.</ParagraphBig>
                        <Paragraph>VIEW OUR <Link id='gtm-payment-problem-faq' to="/faq">FAQ</Link> OR CONTACT OUR <Link id='gtm-payment-problem-contact-support' to="/info/support">SUPPORT TEAM</Link> TO SOLVE THE PROBLEM.</Paragraph>
                </Container>
            </StyledPaymentProblem>
        );
    }
}

export default PaymentProblem;