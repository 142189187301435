import React, { Component } from "react";
import { StyledFooter, StyledSleep, StyledSpeed } from "layout/Player/style";

import PropTypes from "prop-types";
import { connect } from 'react-redux';

class FooterPlayer extends Component {
    render() {   
        return (            
            <StyledFooter isEdition={this.props.isEdition}>
                <StyledSleep>
                    <i
                        className={`icon-sleep${this.props.countDownComponent ? '-active' : ''}`}
                        onClick={this.props.onClickSleep}
                    />
                    {this.props.countDownComponent}
                </StyledSleep>
                <StyledSpeed className={this.props.countDownComponent ? 'timer-on' : ''} onClick={this.props.onClickPlaybackRate}>
                    {this.props.playbackRate.toString().replace('.', ',')}<span>x</span>
                </StyledSpeed>
                <i
                    className={`icon-chapters ${this.props.book && this.props.book.download ? '' : 'disabled'}`}
                    onClick={this.props.onClickChapters}
                />
            </StyledFooter>
        );
    }
}

FooterPlayer.propTypes = {
    onClickChapters: PropTypes.func,
    onClickPlaybackRate: PropTypes.func,
    onClickSleep: PropTypes.func,
    playbackRate: PropTypes.number,
};

const mapStateToProps = state => ({
    book: state.bookReducer.book
});

export default connect(mapStateToProps)(FooterPlayer);
