import { Note, Price, Holder, StyledMembership, XmasCta, XmasList, XmasListItem, XmasTitle } from 'pages/Membership/style';
import React, { Component } from 'react';

import { Link } from "react-router-dom";
import PageMeta from '../../shared/PageMeta/PageMeta';
import { connect } from 'react-redux';
import image from 'images/membership2.webp';

class XmasMembership extends Component {

    renderCta() {
        return (
            <XmasCta>
                <Link id='gtm-xmas-membership-trial' to='/free-trial' className={`c-btn c-btn__ghost c-btn__ghost--square full-width ml-0`}> 
                    <Price>1 month <div>FREE TRIAL</div></Price>
                    <span>no credit card required</span>
                </Link>
                <Link id='gtm-xmas-membership-month' to='/xmas?plan=monthly' className={`c-btn c-btn__ghost c-btn__ghost--square full-width ml-0`}> 
                    <Price>£{this.props.membership.month} <div>PER MONTH</div></Price>
                    <span>cancel at any time</span>
                </Link>
                <Link id='gtm-xmas-membership-year' to='/xmas?plan=annually' className={`c-btn c-btn__ghost c-btn__ghost--square full-width ml-0`}> 
                    <Price>£{this.props.membership.year} <div>PER YEAR</div></Price>
                    <span>2 months free</span>
                </Link>
            </XmasCta>
        )        
    }

    render() {               
        let ListItems = [
            {
                title: "2 free audiobooks every month",
            },
            {
                title: "20% off our collection of audiobooks",
            },
            {
                title: "Spiracle Editions - our monthly audiobook releases",
            },
            {
                title: "DRM free - you own your audiobooks forever",
            },
            {
                title: "Book Club membership",
            },
            {
                title: "Exclusive events throughout the year",
            },
            {
                title: "Writing and Performing masterclasses",
            },       
        ]

        const isMemberOnlyBlock = this.props.isMemberOnlyBlock;
    
        let content = (
            <Holder isMemberOnlyBlock={isMemberOnlyBlock}>
                {!isMemberOnlyBlock &&
                    <PageMeta>
                        <title>Subscription | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                }

                <StyledMembership>
                    <h1>Join Spiracle</h1>
                    <h2 className="mb-28">Treat yourself or make a wonderful gift</h2>
                    <p className="font-italic mb-0">The holy grail for book lovers is a reliable recommendation. Think of Spiracle as the friend who pulls out a book and says, <br /> “No, really. I know you’ll love this.”</p>
                    <img src={image} alt="Spiracle" />

                    <h2>Premium Membership benefits</h2>   
                    {/* <span>the sound of great writing</span> */}

                    <XmasList>
                        {ListItems.map((item, index) => {
                            return (
                                <XmasListItem key={index}>
                                    <i className="icon-check-circle no-hover font-20" />
                                    <XmasTitle>{item.title}</XmasTitle>
                                </XmasListItem>
                            );
                        })}

                    </XmasList>

                    <h5 className="mt-24">Membership and <Link id='gtm-xmas-membership-gift' to='/xmas-gift?plan=6monthly' style={{textDecoration: 'underline'}}>Gift Membership</Link></h5>

                    {this.renderCta()}

                    <h5 className="mt-20 mb-30">(CANCEL AT ANY TIME - PREMIUM MEMBERSHIP IS SET TO AUTO-RENEW)</h5>

                    <Note>You will now be directed to the payment page where the option to send as a gift will be available</Note>

                    <h5 className="grey">NEED ASSISTANCE?<br /> CONTACT THE <Link id='gtm-xmas-membership-contact-support' to={"/info/support"} className="underline">SPIRACLE TEAM</Link></h5>

                    <p className="mt-73">
                        If you’re not ready to dive in, why not take off your shoes and paddle in our collection? Enjoy articles, podcasts and interviews free, 
                        in <Link id='gtm-xmas-membership-murmurations' to="/murmurations"><span className="font-italic">Murmurations</span></Link>.
                        Visitors can buy audiobooks at the cover price for their own library or as gifts for friends, family and other animals...
                    </p>
                </StyledMembership>
            </Holder>            
        )
        
        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,  
    membership: state.membershipPriceReducer
})

export default connect(mapStateToProps)(XmasMembership);
