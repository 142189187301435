import React, { Component } from 'react';

import ApiService from '../../../services/api.service';
import Author from '../../../components/UI/Author/Author';
import MediaPlayer from '../../../components/UI/MediaPlayer/MediaPlayer';
import Membership from "pages/Membership/Membership";
import OnImagesLoaded from 'react-on-images-loaded';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import ReactHtmlParser from 'react-html-parser';
import SkeletonArticleItem from '../../../components/UI/Skeleton/SkeletonArticleItem/SkeletonArticleSingle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class ArticlesSingle extends Component {
    state = {
        article: null,
        isLoading: true,
        type: 'article',
        imagesLoaded: false
    };

    componentDidMount() {
        this.cdm();
    }

    cdm = () => {
    /**
    * Check type of page..to see what API to use
    */
        if (this.props.history.location.pathname.includes('discussions') || this.props.history.location.pathname.includes('interviews')) {
            this.setState(
                {
                    type: this.props.history.location.pathname.split('/')[1],
                },
            () => {
                if (this.state.type === 'discussions') {
                    this.setState(
                        {type: 'discussions',},
                        () => {
                            this.getArticlesSingle(this.state.type);
                        }
                    );
                    } else if (this.state.type === 'interviews') {
                        this.setState(
                            {type: 'interviews',},
                            () => {
                                this.getArticlesSingle(this.state.type);
                            }
                        );
                    } else {
                        this.setState({type: 'articles',});
                    }
                }
            );
        } else {
            this.getArticlesSingle();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user || prevProps.location.pathname !== this.props.location.pathname) {
            this.cdm();
        }  
    }

    /**
    * Get articles single API
    */
    getArticlesSingle = (type) => {
        this.setState({
            isLoading: true
        })
        if (type === 'discussions') {
            ApiService.getDiscussionsSingle(this.props.match.params.slug)
            .then((r) => {
                this.setState(
                    {
                        article: r.data,
                        isLoading: false,
                    },
                    () => {
                        if (window.twttr?.widgets) {
                            window.twttr.widgets.load();
                        }
                        window.scrollTo(0, 0);
                    }
                )
            })
            .catch((e) => {
                this.props.history.push('/404');
            });
        } else if (type === 'interviews') {
            ApiService.getInterviewsSingle(this.props.match.params.slug)
                .then((r) => {
                    this.setState(
                        {
                            article: r.data,
                            isLoading: false,
                        },
                        () => {
                            if (window.twttr?.widgets) {
                                window.twttr.widgets.load();
                            }
                            window.scrollTo(0, 0);
                        }
                    );
                })
                .catch((e) => {
                    this.props.history.push('/404');
                });
        } else {
            ApiService.getArticleSingle(this.props.match.params.slug)
                .then((r) => {
                    this.setState(
                        {
                            article: r.data,
                            isLoading: false,
                        },
                        () => {
                            if (window.twttr?.widgets) {
                                window.twttr.widgets.load();
                            }
                            window.scrollTo(0, 0);
                        }
                    );
                })
                .catch((e) => {
                    this.props.history.push('/404');
                });
            }
        };

    /**
     * Handle optional link click
     */
    handleLinkClick = (url) => {
        window.open(url);
    };

    render() {
        let content = <SkeletonArticleItem />;
        let videoIframe;
        let parsedBody;


        if (!this.state.isLoading && this.state.article) {
            if (this.state.article.videoLink) {
                let url;
                if (this.state.article.videoLink.includes('vimeo')) {
                    url = this.state.article.videoLink.replace('vimeo.com', 'player.vimeo.com/video');
                } else {
                    if (this.state.article.videoLink.includes('youtu.be')) {
                        url = this.state.article.videoLink.replace('youtu.be', 'youtube.com/embed/');
                    } else {
                        let baseUrl;
                        if (this.state.article.videoLink.includes('&ab_channel')) {
                            baseUrl = this.state.article.videoLink.split('&ab_channel')[0];
                        }
                        if (this.state.article.videoLink > 45) {
                            baseUrl = this.state.article.videoLink.substring(0, 43);
                        }
                        if (!baseUrl) {
                            url = this.state.article.videoLink.replace('watch?v=', 'embed/');
                        } else {
                            url = baseUrl.replace('watch?v=', 'embed/');
                        }
                    }
                }
                videoIframe = (
                    <div className='video-player mb-32'>
                        {this.state.article.videoLink.includes('youtu') || this.state.article.videoLink.includes('vimeo') ? (
                            <iframe height='362px' width='100%' title='videO' src={`${url}?autoplay=1`} />
                        ) : (
                            <div>Invalid URL</div>
                        )}
                    </div>
                );
            }

            parsedBody = (
                <div className='container__small-padding container__small-margin container__basic-page'>
                    {this.state.article.memberOnly ? 
                        this.state.article.audioFile && this.props.user && this.props.user.currentSubscription && <MediaPlayer embeddedPlayer src={this.state.article.audioFile} />
                        : 
                        this.state.article.audioFile && <MediaPlayer embeddedPlayer src={this.state.article.audioFile} />
                    }
                    <div className='parsed-body'>{ReactHtmlParser(this.state.article.main)}</div>
                    {this.state.article.author && !this.state.article.hideAuthor && <Author info={this.state.article.author} />}
                    {this.state.article.link && (
                        <div className='text-center'>
                            <button onClick={() => this.handleLinkClick(this.state.article.link)} className='c-btn c-btn__ghost c-btn__pagination mb-50 mt-50 font-22 font-secondary font-italic'>
                                {this.state.article.linkTitle}
                            </button>
                        </div>
                    )}
                </div>
            );

            content = (
                <div className='container__small-padding container__small-margin container__basic-page'>
                    <div className={`text-center font-uppercase font-secondary opacity-3 ls-15 ${window.innerWidth >= 768 ? 'mb-100 font-18' : 'mb-27 font-10'}`}>
                        {this.state.article.category ? this.state.article.category.name : null}
                    </div>
                    <div className='text-center c-articles c-articles__header'>
                        <h2 className='font-32 font-400 margin-reset font-primary font-italic'>{this.state.article.type ? this.state.article.type.title : null}</h2>
                        <h1 className='font-32 font-400 margin-reset font-uppercase'>{this.state.article.title}</h1>
                        {this.state.article.author && (
                            <h2 className='font-32 font-400 margin-reset font-primary font-italic'>
                                {this.state.article.author.firstName} {this.state.article.author.lastName}
                            </h2>
                        )}
                    </div>

                    {
                        (this.state.type === 'article' || (this.state.article.memberOnly && this.props.user && !this.props.user.currentSubscription) || (this.state.article.memberOnly && !this.props.user) || (this.state.article && !this.state.article.videoLink && !this.state.article.audioFile)) &&
                            <OnImagesLoaded onLoaded={() => this.setState({ imagesLoaded: true })}>                                
                                <div className='text-center mb-20'>
                                    <img className={`animated ${this.state.imagesLoaded && "loaded"}`} src={this.state.article.image && this.state.article.image} alt={this.state.article.alt} />
                                </div>                                
                            </OnImagesLoaded>
                    }

                    {/* show video for subscribed members or image from non-subscribed members */}
                    {this.state.article.memberOnly && this.props.user && this.props.user.currentSubscription && videoIframe}
                    {!this.state.article.memberOnly && videoIframe}
                    
                    {this.state.article.shortDescription && <div className='parsed-body summary'>{ReactHtmlParser(this.state.article.shortDescription)}</div>}
                    
                    {/* show main text only on interviews and discussions preview */}
                    {(this.state.article.memberOnly && ( this.state.type === 'interviews' || 'discussions' ) && !this.props.user) || 
                    (this.state.article.memberOnly && ( this.state.type === 'interviews' || 'discussions' ) && this.props.user && !this.props.user.currentSubscription) ? 
                        <div className='parsed-body'>{ReactHtmlParser(this.state.article.main)}</div> 
                        : 
                        null
                    }
                </div>
            );
        }

        return (
            <div>
                <PageMeta>
                    <title>{this.state.article ? this.state.article.title + ' | ' : ''}Spiracle</title>
                    <meta name="description" content={this.state.article && this.state.article.metaDescription ? this.state.article.metaDescription : 'Ambitious, imaginative, original audiobooks, selected by authors and small publishers, fed by years of delighted reading.'} />
                    <meta name="keywords" content={this.state.article && this.state.article.metaKeywords ? this.state.article.metaKeywords : 'spiracle audiobooks'} />
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                {content}
                {/* check for member only */}
                {(this.state.article && this.state.article.memberOnly && !this.props.user) || (this.state.article && this.state.article.memberOnly && this.props.user && !this.props.user.currentSubscription) ? 
                    <Membership isMemberOnlyBlock /> : parsedBody
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
});

const articlesSingleWithRouter = withRouter(ArticlesSingle);

export default connect(mapStateToProps)(articlesSingleWithRouter);
