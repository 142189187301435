import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Paragraph = styled.p``;

export const StyledEditions = styled.div`
    margin: 90px auto;

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 36px auto;
    }

    ${Subtitle} {
        font-size: 18px;
        line-height: 43px;
        letter-spacing: 6px;
        margin-bottom: 6px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
            margin-bottom: 0;
        }
    }

    ${Title} {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 18px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
        }
    }

    ${Paragraph} {
        font-family: 'Gill Sans Nova';
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 18px;
            text-align: center;
        }
    }
    
    .c-audio-book-single-carousel-container {
        margin-top: 40px;
    }

    .sa-button-red {
        padding: 12px 45px 12px !important;

        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100% !important;
        }
    }
`;

