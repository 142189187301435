import React, { Component } from "react";
import { StyledFeaturedItem, StyledDescription } from 'components/UI/ArticleItem/style';
import Image from 'images/homepage.webp';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import OnImagesLoaded from 'react-on-images-loaded';

class FeaturedArticleItem extends Component { 
    state = {
        imagesLoaded: false
    };   

    render() {  
        const singlePageLink =  (`/${this.props.articleType === 'discussions' ? 'discussions' : this.props.articleType === 'interviews' ? 'interviews' : 'articles'}/${this.props.slug}`)        
        return (   
            <OnImagesLoaded
                    onLoaded={() => this.setState({ imagesLoaded: true })}
                >           
                <StyledFeaturedItem mobileOnly={this.props.mobileOnly} imagesLoaded={this.state.imagesLoaded}>          
                    <Link id='gtm-featured-article-item-image' to={singlePageLink}>            
                        <img src={this.props.image ? this.props.image : Image} alt={this.props.alt}/>
                    </Link>
                    
                    { this.props.type &&
                        <span>{this.props.type}</span>
                    }
                    <Link id='gtm-featured-article-item-title' to={singlePageLink}>
                        <h2>{this.props.title}</h2>
                    </Link>
                    <span>{this.props.author}</span>
                    <StyledDescription>{ReactHtmlParser(this.props.summary)}</StyledDescription>                
                </StyledFeaturedItem>    
            </OnImagesLoaded>  
        )
    }
}

export default FeaturedArticleItem;
