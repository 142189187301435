
class DateFormater {
    static formatDate(timestamp) {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        let stringDate
        let month
        let year
        if (timestamp) {
            let date = timestamp.toString()
            month = monthNames[parseInt(date.split('-')[1]) - 1];
            year = date.split('-')[0];
            stringDate = `${month} ${year}`
        }
        return stringDate
    }

    static formatDateMonthYear(timestamp) {
        let date
        let month
        let year
        let stringDate
        if(timestamp) {
            let array = timestamp.toString().split('-');
            year = array[0]
            month = array[1]
            date = array[2].slice(0,2)
            stringDate = `${year}.${month}.${date}`
        }
        return stringDate
    }

    static formatUnix(timestamp) {
        if(timestamp) {
            let milliseconds = timestamp * 1000;
            const dateObject = new Date(milliseconds).toLocaleDateString();
            let date = dateObject.split('/')[1]
            let month = dateObject.split('/')[0]
            let year = dateObject.split('/')[2]
            let stringDate = `${year}.${month}.${date}`
            return stringDate
        }
    }

    static formatUnixLong(timestamp) {
        if(timestamp) {
            let milliseconds = timestamp * 1000;
            const dateObject = new Date(milliseconds);
            let date = dateObject.toLocaleString('en-US', {day: 'numeric'})
            let month = dateObject.toLocaleString('en-US', {month: 'long'})
            let year = dateObject.toLocaleString('en-US', {year: 'numeric'})

            let type
            let number = date.charAt(date.length - 1)

            if (number === '1') {
                type = 'st'
            } else if (number === '2') {
                type = 'nd'
            } else if (number === '3') {
                type = 'rd'
            } else {
                type = 'th'
            }

            let stringDate = `${date}${type} ${month} ${year}`
            return stringDate
        }
    }
}

export default DateFormater