import React, { Component } from 'react';
import { connect } from 'react-redux';

import ApiService from '../../services/api.service';
import AudioBookSingleCarousel from '../../components/UI/AudiobookSingle/AudioBookSingleCarousel';
import Loader from '../../shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta'
import Payment from '../Ecommerce/Payment/Payment';

import { Holder, List, ListItem, Note, StyledMembership, Text } from '../Membership/style';
import { Button, Carousel, SmallText } from './style';

class PromotionPayment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subscriberSelections: [],
            isLoadingSelections: true,
        }

        this.scrollInto = React.createRef();  
    }

    componentDidMount() {
        const params = new URLSearchParams();

        params.append('page', 1);
        params.append('itemsPerPage', 6);

        ApiService.getSecondEditions(params).then(r => {
            this.setState({
                subscriberSelections: r.data['hydra:member'],
                isLoadingSelections: false,
            })
        })
    
    }

    handleScroll = () => {
        if (this.scrollInto.current) {
            this.scrollInto.current.scrollIntoView({
                behavior: 'smooth', 
                block: 'start'
            });
        }
    }

    render() {
        let ListItems = [
            {
                text: "Broad range of audiobooks, including special editions available only on Spiracle."
            },
            {
                text: "Select two audiobooks each month… and keep them forever."
            },
            {
                text: "Exclusive access to content and events, including our Murmurations podcasts and interviews."
            },
            {
                text: "Independent, always. Thanks for supporting truly unique stories."
            },       
        ]
        
        return (
            <>
                <PageMeta>
                    <title>Promo | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {this.state.isLoadingSelections ? <Loader /> : 
                    <>
                        <Holder promotion>
                            <StyledMembership>
                                <h1>Your Free Trial starts here</h1>
                                <p className='font-italic font-primary mb-28' ref={this.scrollInto}>Looking for your next all-consuming read? <br /> At Spiracle we believe in good stories, well told.</p>
                                <h2 className='mb-0'>Let's start listening</h2>
                            </StyledMembership>

                        {this.props.user && this.props.user.currentSubscription ? null : 
                            <>
                                <Payment promotion membershipPromo membershipPromoCode={this.props.match.params.slug} />

                                <SmallText className='text-center font-12 lh-18 font-secondary font-uppercase letter-spacing-15'>
                                    Subscription auto-renews. <br /> You can cancel at any time
                                </SmallText>
                            </>
                        }

                        {this.state.subscriberSelections && this.state.subscriberSelections.length ? 
                            <Carousel>
                                <AudioBookSingleCarousel 
                                    data={this.state.subscriberSelections} 
                                    title='This month’s highlights'
                                    promotion 
                                />
                            </Carousel>
                        : null}

                            <StyledMembership>
                                <h2>Why subscribe?</h2>

                                <List>
                                    {ListItems.map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <i className="icon-check-circle no-hover" />
                                                <div>
                                                    <Text>{item.text}</Text>
                                                </div>
                                            </ListItem>
                                        );
                                    })}

                                </List>

                                <Note className='mb-15'>Note the usual price is £12 per month or £120 annually</Note>

                                {this.props.user && this.props.user.currentSubscription ? null : 
                                    <Button id='gtm-promotion-payment-subscribe' className='sa-button-ghost sa-button-rounded' onClick={this.handleScroll}>Subscribe</Button>
                                }
                            </StyledMembership>
                        </Holder>
                    </>
                }
            </>
        )
    }

}
const mapStateToProps = state => ({
    user: state.userReducer.user,
  })

export default connect(mapStateToProps)(PromotionPayment);