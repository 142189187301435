export const BOOK_INFO = "BOOK_INFO";
export const WIDGET_PLAYER = "WIDGET_PLAYER";
export const TURN_OFF_WIDGET_PLAYER = "TURN_OFF_WIDGET_PLAYER";
export const CLEAR_BOOK = "CLEAR_BOOK";
export const GET_CURRENT_BOOK = "GET_CURRENT_BOOK";
export const REMOVE_CURRENT_BOOK = "REMOVE_CURRENT_BOOK";
export const EPUB_DOWNLOAD_READY = "EPUB_DOWNLOAD_READY";
export const BOOK_ADDED_TO_CART = "BOOK_ADDED_TO_CART";
export const BOOK_REMOVED_TO_CART = "BOOK_REMOVED_TO_CART";
export const REMOVE_ALL_IN_CART_ITEMS = "REMOVE_ALL_IN_CART_ITEMS";
export const ADD_AUDIO_FILES = "ADD_AUDIO_FILES";
export const REMOVE_BOOK_ON_LOGOUT = "REMOVE_BOOK_ON_LOGOUT" 

export const onRemoveBook = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_BOOK_ON_LOGOUT
        })
    }
}

export const removeInCartItems = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_ALL_IN_CART_ITEMS
        })
    }
}

export const onAddBookToCart = payload => {
    return dispatch => {
        dispatch({
            bookType: payload,
            type: BOOK_ADDED_TO_CART
        })
    }
}

export const onRemoveBookFromCart = (book, type) => {
    return dispatch => {
        dispatch({
            book: book,
            type: BOOK_REMOVED_TO_CART
        })
    }
}

export const onGetBook = payload => {
    return dispatch => {
        dispatch({
            type: BOOK_INFO,
            book: payload
        })
    }
}

export const onSwitchWidgetPlayer = payload => {
    return dispatch => {
        dispatch({
            type: WIDGET_PLAYER,
            book: payload
        })
    }
}

export const onTurnOnWidgetPlayer = payload => {
    return dispatch => {
        dispatch({
            type: TURN_OFF_WIDGET_PLAYER,
            book: payload
        })
    }
}

export const onClearBook = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_BOOK,
        })
    }
}

export const onGetCurrentBook = payload => {
    return dispatch => {
        dispatch({
            type: GET_CURRENT_BOOK,
            book: payload
        })
    }
}

export const onRemoveCurrentBook = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_CURRENT_BOOK
        })
    }
}

export const onEpubFinish = payload => {
    return dispatch => {
        dispatch({
            type: EPUB_DOWNLOAD_READY,
            ePub: payload
        })
    }
}

export const onAddAudioFiles = (audioFiles, download) => {
    return dispatch => {
        dispatch({
            type: ADD_AUDIO_FILES,
            audioFiles: audioFiles,
            download: download,
        })
    }
}
 
