import styled from "styled-components/macro";

export const OrderStatus = styled.div``;
export const PriceHolder = styled.div``;
export const Vat = styled.div``;

export const CartContainer = styled.div`    
    max-width: 375px;
    min-height: calc(100vh - 475px);
    margin: 0 auto;
    padding: 0 8px;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 80px;
    }

    h3 {        
        font-size: 18px;       
        line-height: 24px;        
        text-align: center;
        margin: 42px 0;
    }

    
    ${OrderStatus} {
        padding: 25px 20px;
        font-family: Gill Sans Nova;

        h4 {            
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        hr {
            background-color: black;
            border-style: solid;
            border-top: 0;
            margin: 0 -20px 17px;
        }

        .c-btn {
            margin-bottom: 15px;
        }

        span {       
            display: block;
            font-size: 14px;            
            line-height: 17px;
            letter-spacing: 0.03em;
            text-align: center;
        }

        strong {
            font-weight: 500;
        }

    }

    ${PriceHolder} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;         
        margin-bottom: 30px;
        
        span + span {
            font-size: 22px;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 0.03em;
        }

    }

    ${Vat} {
        margin-bottom: 25px;

        div {
            display: flex;
            justify-content: space-between;

            + div {
                margin-top: 18px;

                span {
                    color: #BDBAB1;

                    &:first-of-type {
                        letter-spacing: 0.10em;
                    }
                }
            }

            span + span {
                font-size: 16px;
                font-weight: 300;
            }
        }
    }
`;