import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import * as Yup from 'yup';
import * as userActionTypes from '../../../store/actions/userActions';

import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Component } from 'react'

import ApiService from '../../../services/api.service';
import ConfirmationDialog from 'shared/PageElements/ConfirmationDialog';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import ReactFlagsSelect from 'react-flags-select';
import { ReactComponent as SpiracleLogoSmall } from '../../../images/Spiral.svg';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class Settings extends Component {

    state = {
        newsletter: false,
        promotions: false,
        saved: false,
        newsletterUpdating: false,
        promotionsUpdating: false,
        error: false,
        countryCode: this.props.user ? this.props.user.countryCode : ''
    }

    componentDidMount() {
        if (localStorage.getItem('token') == null) {
            this.props.history.push('/access-denied')
        } else {
            if (this.props.user) {
                this.setState({
                    newsletter: this.props.user.newsletter,
                    promotions: this.props.user.promotions
                });
            }
        }
    }

    handleNewsletterChange = () => {
        this.setState({
            newsletter: !this.state.newsletter,
            newsletterUpdating: true
        }, () => {
            const data = {
                newsletter: !this.props.user.newsletter
            }
            ApiService.newsletterUpdate(data, this.props.user.uuid).then(
                r => {
                    this.setState({
                        newsletterUpdating: false
                    }, () => {
                        this.props.onUpdateUser(r.data)
                    })
                }
            )
        })
    }

    handlePromotionsChange = () => {
        this.setState({
            promotions: !this.state.promotions,
            promotionsUpdating: true
        }, () => {
            const data = {
                promotions: this.state.promotions
            }
            ApiService.promotionsUpdate(data, this.props.user.uuid).then(
                r => {
                    this.setState({
                        promotionsUpdating: false
                    }, () => {
                        this.props.onUpdateUser(r.data)
                    })
                }
            )
        })
    }

    submitEditSettingsForm = (values, callbacks, form) => {
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            county: values.county,
            countryCode: this.state.countryCode,
            postCode: values.postCode,
        }
        
        const { 
            resetForm,
            setSubmitting,
        } = callbacks;

        ApiService.updateSettings(data, this.props.user.uuid).then(
            r => {
                
                this.props.onUpdateUser(r.data);
                setSubmitting(false);
                resetForm();
                this.setState({
                    saved: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            saved: false
                        })
                    }, 5000);
                })
            }
        ).catch(
            e => {
                setSubmitting(false);
                 this.setState({
                    error: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            error: false
                        })
                    }, 5000);
                })
            }
        )
    }

    /**
     * Handle delete account
     */
    handleDelete = () => {
        ApiService.deleteAccount(this.props.user.uuid).then(
            r => {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('tokenRefreshedDate');
                this.props.onLogout();
                this.props.history.push('/')
            }
        ) 
    }

    /**
     * Handle open delete account popup
     */
    handleOpenDelete = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmationDialog 
                    handleClose={() => { onClose()}}                    
                    title="Are you sure you want to delete your account?"
                    text="Deleting will mean you will lose access to any books in your library. We will also safely delete all information we can."
                    quote={
                        <>
                            <div className="font-italic font-24 mb-28">“We’ll eat you up - we love you so!“</div>
                            <span className="font-18">Where The Wild Things Are</span> &#8212; Maurice Sendak
                        </>
                    }
                    firstButtonLabel="Yes"
                    onClickFirstButton={() => {this.handleDelete(); onClose()}}
                    secondButtonLabel="Cancel"
                    onClickSecondButton={() => { onClose()}}
                    firstButtonPrimary
                    deleteAccount
                />   
            )
        })
    }

    /**
     * Handle cancel all form changes
     */
    handleCancelChanges = form => {
        form.resetForm();
        form.setValues({
            firstName: this.props.user && this.props.user.firstName ? this.props.user.firstName : '',
            lastName: this.props.user && this.props.user.lastName ? this.props.user.lastName : '',
            address1: this.props.user && this.props.user.address1 ? this.props.user.address1 : '',
            address2: this.props.user && this.props.user.address2 ? this.props.user.address2 : '',
            city: this.props.user && this.props.user.city ? this.props.user.city : '',
            county: this.props.user && this.props.user.county ? this.props.user.county : '',
            country: this.props.user && this.props.user.countryCode ? this.props.user.countryCode : '',
            postCode: this.props.user && this.props.user.postCode ? this.props.user.postCode : '',
        });
    }

    handleSetFlag = countryCode => {
        this.setState({
            countryCode: countryCode
        })
    }

    render() {

        /** FORMIK SETTINGS */
        const settingsValidationSchema = Yup.object().shape({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            address1: Yup.string(),
            address2: Yup.string(),
            city: Yup.string(),
            county: Yup.string(),
            country: Yup.string(),
            postCode: Yup.string()
        })
        
        const settingsInitialValues = {
            firstName: this.props.user && this.props.user.firstName ? this.props.user.firstName : '',
            lastName: this.props.user && this.props.user.lastName ? this.props.user.lastName : '',
            address1: this.props.user && this.props.user.address1 ? this.props.user.address1 : '',
            address2: this.props.user && this.props.user.address2 ? this.props.user.address2 : '',
            city: this.props.user && this.props.user.city ? this.props.user.city : '',
            county: this.props.user && this.props.user.county ? this.props.user.county : '',
            country: this.props.user && this.props.user.countryCode ? this.props.user.countryCode : '',
            postCode: this.props.user && this.props.user.firstName ? this.props.user.postCode : '',
        };
        //** FORMIK SETTINGS END */

        return (
            <div className="container">
                <PageMeta>
                    <title>Settings | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                <div className="c-form">
                <h2 className="font-uppercase font-secondary font-400 ml-5">Settings</h2>
                    <Formik 
                            enableReinitialize={true}
                            initialValues={settingsInitialValues}
                            validateOnMount={true}
                            validationSchema={settingsValidationSchema}
                            onSubmit={this.submitEditSettingsForm}
                            >
                        { 
                            form => (
                            <>
                                <Form >
                                    <div>
                                        <div className="c-error-message">
                                            <ErrorMessage className="ml-5 mb-4" name="firstName" component="div" />
                                        </div>
                                        {form.errors && form.errors.firstName && form.touched && form.touched.firstName ? null : <div className="font-12 font-secondary mb-6 ml-5">First name</div>}
                                        <Field  className={`bg-white mb-20 ${form.errors && form.errors.firstName && form.touched && form.touched.firstName ? 'invalid' : ''}`}
                                                placeholder="First name"
                                                type="text"
                                                name="firstName"
                                                required
                                                id="firstName" />
                                    </div>
                                    <div>
                                        <div className="c-error-message">
                                            <ErrorMessage className="ml-5 mb-4" name="lastName" component="div" />
                                        </div>
                                        {form.errors && form.errors.lastName && form.touched && form.touched.lastName ? null : <div className="font-12 font-secondary mb-6 ml-5">Last name</div>}
                                        <Field  className={`bg-white mb-20 ${form.errors && form.errors.lastName && form.touched && form.touched.lastName ? 'invalid' : ''}`}
                                                placeholder="Last name"
                                                type="text"
                                                name="lastName"
                                                required
                                                id="lastName" />
                                    </div>
                                    <div>
                                        <label className="font-12 font-secondary mb-6 ml-5">Address 1</label>
                                        <Field  className={`bg-white mb-20`}
                                                placeholder="Address 1"
                                                type="text"
                                                name="address1"
                                                id="address1" />
                                    </div>
                                    <div>
                                        <label className="font-12 font-secondary mb-6 ml-5">Address 2</label>
                                        <Field  className={`bg-white mb-20`}
                                                placeholder="Address 2"
                                                type="text"
                                                name="address2"
                                                id="address2" />
                                    </div>
                                    <div>
                                        <label className="font-12 font-secondary mb-6 ml-5">City</label>
                                        <Field  className={`bg-white mb-20`}
                                                placeholder="City"
                                                type="text"
                                                name="city"
                                                id="city" />
                                    </div>
                                    <div>
                                        <label  className="font-12 font-secondary mb-6 ml-5">County</label>
                                        <Field  className={`bg-white mb-20`}
                                                placeholder="County"
                                                type="text"
                                                name="county"
                                                id="county" />
                                    </div>
                                    <div className="country-flags country-flags--settings mb-21 ">
                                        <label className="font-12 font-secondary mb-6 ml-5">Country</label>
                                        {/* <Field  className={`bg-white mb-20`}
                                                placeholder="Country"
                                                type="text"
                                                name="country"
                                                id="country" /> */}
                                        <ReactFlagsSelect
                                            selected={this.state.countryCode ? this.state.countryCode : null}
                                            onSelect={code => this.handleSetFlag(code)}
                                            placeholder="Your country"
                                            searchable
                                            searchPlaceholder="Search country"
                                            selectedSize={16}
                                        />
                                    </div>
                                    <div>
                                        <label className="font-12 font-secondary mb-6 ml-5">Post code</label>
                                        <Field  className={`bg-white mb-25`}
                                                placeholder="Post code"
                                                type="text"
                                                name="postCode"
                                                id="postCode" />
                                    </div>
                                    <div>
                                        <button 
                                            id='gtm-settings-save-changes'
                                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase ${form.dirty ? 'mb-18' : 'mb-34'} ${form.isSubmitting ? 'submitting' : ''} ${this.state.saved ? 'c-btn__ghost--saved' : ''} ${this.state.error ? 'c-btn__ghost--error' : ''}`} 
                                            type="submit" 
                                            disabled={form.isSubmitting || !form.isValid}>
                                            {this.state.saved ? 'Changes saved!' : this.state.error ? 'Ups! Error!' : 'Save changes'}
                                            <SpiracleLogoSmall />
                                        </button>
                                    </div>
                                    {form.dirty ? <div>
                                        <button 
                                            id='gtm-settings-cancel-changes'
                                            onClick={() => this.handleCancelChanges(form)}
                                            className={`c-btn c-btn__ghost c-btn__ghost--cancel full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28`} 
                                            type="button" >
                                            Cancel changes
                                        </button>
                                    </div> : null }
                                    <div className="h-flex h-flex-middle mb-32">
                                        <div className="c-switch position-relative mr-18">
                                            <input
                                                onChange={() => {}}
                                                checked={this.props.user?.newsletter} 
                                                className="c-switch__input cursor-pointer position-absolute height-full width-full z-index-1" 
                                                type="checkbox" 
                                                name="newsletter" 
                                                id="newsletter"
                                                disabled={this.state.newsletterUpdating} 
                                            />
                                            <label onClick={() => this.handleNewsletterChange()} id='gtm-settings-newsletter' className={`c-switch__label o-flex o-flex-middle o-flex-between ${this.props.user?.newsletter ? 'checked' : ''}`} htmlFor="newsletter">
                                                <span className="c-switch__label--off"></span>
                                                <span className="c-switch__label--on"></span>
                                            </label>
                                        </div>
                                        <div >
                                            <span className="font-16 font-secondary">Newsletter</span>
                                        </div>
                                    </div>
                                    <div className="h-flex h-flex-middle mb-72">
                                        <div className="c-switch position-relative mr-18">
                                            <input
                                                onChange={() => {}}
                                                checked={this.props.user?.promotions} 
                                                className="c-switch__input cursor-pointer position-absolute height-full width-full z-index-1" 
                                                type="checkbox" 
                                                name="promotions" 
                                                id="promotions" 
                                                disabled={this.state.promotionsUpdating}
                                            />
                                            <label onClick={() => this.handlePromotionsChange()} id='gtm-settings-promotions' className={`c-switch__label o-flex o-flex-middle o-flex-between ${this.props.user?.promotions ? 'checked' : ''}`} htmlFor="promotions">
                                                <span className="c-switch__label--off"></span>
                                                <span className="c-switch__label--on"></span>
                                            </label>
                                        </div>
                                        <div >
                                            <span className="font-16 font-secondary">Promotions</span>
                                        </div>
                                    </div>
                                </Form>
                                <div className="mb-17">
                                    <button id='gtm-settings-delete-account' onClick={() => this.handleOpenDelete()} className="c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase">
                                            Delete account
                                    </button>
                                </div>
                                <div className="text-center">
                                    <p className="font-12 font-secondary">We fully respect there may be reasons why you may wish to delete your account, but please consider this carefully as all your information will be lost.</p>
                                </div>   
                            </>
                            )
                        }
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUser: (user) => dispatch(userActionTypes.onUpdateUser(user)),
        onLogout: () => dispatch(userActionTypes.onLogout()),
    }
  }
  
const settingsWithRouter = withRouter(Settings)

export default connect(mapStateToProps, mapDispatchToProps)(settingsWithRouter)