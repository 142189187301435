import 'react-h5-audio-player/lib/styles.css';
import 'react-h5-audio-player/src/styles.scss';

import React, { Component } from 'react';

import AudioPlayer from 'react-h5-audio-player';
import { ReactComponent as Forward } from '../../../images/Player/forward-button.svg';
import { ReactComponent as Pause } from '../../../images/Player/pause-button.svg';
import { ReactComponent as PauseMini } from '../../../images/Player/pause-button-mini.svg';
import { ReactComponent as Play } from '../../../images/Player/play-button.svg';
import { ReactComponent as PlayMini } from '../../../images/Player/play-button-mini.svg';
import { ReactComponent as Rewind } from '../../../images/Player/rewind-button.svg';
import { StyledMediaPlayer } from 'components/UI/MediaPlayer/style';

export default class MediaPlayer extends Component {

    constructor(props) {
        super(props);
        this.player = React.createRef();
    }

    render() {
        return (
          <StyledMediaPlayer 
            embeddedPlayer={this.props.embeddedPlayer} 
            isEdition={this.props.isEdition}
            miniPlayer={this.props.miniPlayer}
          >
            <AudioPlayer
              ref={this.props.playerRef}
              src={this.props.src}
              showFilledVolume={true}
              showFilledProgress={false}
              showDownloadProgress={false}
              showSkipControls={this.props.showSkipControls}
              progressJumpStep={15000}
              onLoadedMetaData={this.props.onLoadedMetaData}
              onEnded={this.props.onEnded}
              onClickNext={this.props.onClickNext}
              onClickPrevious={this.props.onClickPrevious}
              onListen={this.props.onListen}
              autoPlayAfterSrcChange={true}
              autoPlay={true}
              onPause={this.props.onPause}
              customIcons={this.props.miniPlayer ?
                {
                    play: <PlayMini />,
                    pause: <PauseMini />,
                    forward: <Forward />,
                    rewind: <Rewind />,         

                }
              :
              {
                play: <Play />,
                forward: <Forward />,
                rewind: <Rewind />,
                pause: <Pause />,
              }
              }
            ></AudioPlayer>
          </StyledMediaPlayer>
        );
    }
}
