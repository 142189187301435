const local = {
  apiGateway: {
    //URL: "http://localhost"
    URL: "https://dev.spiracle.etondigital.com"
  },
  stripe: {
    STRIPE_PUBLISH_KEY: 'pk_test_BspcGa2H7qbw25rKobemLxYf00GWLVTGwj'
  }
};

const development = {
    apiGateway: {
      URL: "https://dev.spiracle.etondigital.com"
    },
    stripe: {
      STRIPE_PUBLISH_KEY: 'pk_test_BspcGa2H7qbw25rKobemLxYf00GWLVTGwj'
    }
};


const staging = {
    apiGateway: {
      URL: "https://st.spiracle.etondigital.com"
    },
    stripe: {
      STRIPE_PUBLISH_KEY: 'pk_test_BspcGa2H7qbw25rKobemLxYf00GWLVTGwj'
    }
};

const production = {
  apiGateway: {
    URL: "https://www.spiracleaudiobooks.com"
  },
  stripe: {
    STRIPE_PUBLISH_KEY: 'pk_live_51JzN9TAmr95sJ9Z8VhNiyxIrvUR5I1np2FbAu8jayvfDHhCFPMyZ5r5MMScWxUqHUxlSi32rQXPF5hZP1wPiEyDB00gGWpj6IE'
  }
};

let config;

switch (process.env.REACT_APP_STAGE) {
    case 'development':
        config = development;
        break;
    case 'staging':
        config = staging;
        break;
    case 'production':
      config = production;
      break;
    default:
        config = local;
        break;
}

export default {
  ...config
};