import styled from "styled-components/macro";

export const StyledDescription = styled.div``;

export const StyledFeaturedItem = styled.div`
    text-align: center;
    font-family: JJannon;
    margin: ${(props) => props.mobileOnly ? "16px 0 27px" : "32px 0 50px"};
    a {
        display: block;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin: 16px 0 27px;
    }

    img {
        width: 100%;
        margin-bottom: ${(props) => props.mobileOnly ? "15px" : "27px"};
        transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
        opacity: ${(props) => props.imagesLoaded ? "1" : "0"};
        ${(props) => props.theme.breakpoints.down("sm")} {
            margin-bottom: 15px;
        }

        &:hover {
            opacity: 1;
            animation: flash 2s;
        }
    }

    h2, span {
        font-size: ${(props) => props.mobileOnly ? "18px" : "32px"};
        line-height: ${(props) => props.mobileOnly ? "24px" : "42px"};
    }

    h2 {
        margin: ${(props) => props.mobileOnly ? "6px auto 8px" : "12px auto 10px"}; 
        text-transform: uppercase;
        max-width: 405px;
        letter-spacing: 0.15em;
        ${(props) => props.theme.breakpoints.down("sm")} {
            margin: 6px auto 8px;
        }   
    }

    span {
        font-style: italic;
        letter-spacing: 0em;  
    }

    ${StyledDescription} {        
        font-size: 22px;       
        line-height: 28px;
        letter-spacing: 0.02em;   
        margin: ${(props) => props.mobileOnly ? "17px auto 0" : "30px auto 0"}; 
        padding: ${(props) => props.mobileOnly && "0 10px"};   
        max-width: 620px;      
        ${(props) => props.theme.breakpoints.down("sm")} {
            margin: 17px auto 0;            
            padding: 0 10px;   
            p {
                margin: 0 !important;
            }         
        }
        p {
            margin: ${(props) => props.mobileOnly && "0 !important"};
            font-size: ${(props) => props.mobileOnly && "18px"};
            line-height: ${(props) => props.mobileOnly && "24px"};
        }    
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
        h2, span, p {
            font-size: 18px;
            line-height: 24px
        }        
    }
`;
