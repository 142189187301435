import styled from "styled-components/macro";
import { StyledChip } from "pages/AudioBooks/style";

export const StyledText = styled.div``;
export const StyledLink = styled.a``;

export const StyledTitleBlock = styled.div`
    max-width: 802px;
    margin: ${(props) => props.murmurations ? "170px auto 105px" : "190px auto 110px"};
    text-align: center;

    ${(props) => props.theme.breakpoints.down("md")} { 
        max-width: 600px;
        margin: ${(props) => props.murmurations ? "25px auto 40px" : "120px auto 40px"};
    }
    
    h1, ${StyledText} {        
        letter-spacing: 0.02em;
    }


    h1 {
        font-size: 50px;
        line-height: 60px; 
        margin-bottom: ${(props) => props.murmurations ? "30px" : "40px"};
        font-style: ${(props) => props.fontItalic ? "italic" : "normal"}; 

        ${(props) => props.theme.breakpoints.down("md")} {                 
            font-size: 36px;            
            line-height: 41px;  
            margin-bottom: ${(props) => props.murmurations ? "20px" : "27px"};      
        }
    }

    ${StyledText}, ${StyledLink} {        
        font-size: 24px;        
        line-height: 34px;   
        margin-bottom: 0; 
        
        p:last-of-type {
            margin-bottom: 0;
        }
        
        a {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 1px;

            &:hover {
                color: #721b00;
            }
        }

        ${(props) => props.theme.breakpoints.down("md")} {                 
            font-size: 18px;            
            line-height: 24px;   
            letter-spacing: normal;   
        }
    }    

    ${StyledText} {
        margin-top: 40px;

        ${(props) => props.theme.breakpoints.down("md")} {                 
            margin-top: 25px;  

            &:first-of-type {
                max-width: ${(props) => props.editions && "260px"};
                margin-left: auto;
                margin-right: auto;
            }

            &:last-of-type {
                max-width: ${(props) => props.audiobooks && "240px"};
                margin-left: auto;
                margin-right: auto;
            }
        }

        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }

    ${StyledLink} {
        font-style: italic;
        margin-top: 40px;

        &:hover {
            color: #721b00;
        }

        &::before {
            bottom: 7px;  
        }

        ${(props) => props.theme.breakpoints.down("md")} { 
            margin-top: 20px;

            &::before {
                bottom: 5px;  
            }
        }
    }
`;

export const StyledChipAlt = styled(StyledChip)`
    text-transform: none;
    font-style: italic;
    margin-bottom: 43px;
    pointer-events: none;

    ${(props) => props.theme.breakpoints.down("md")} { 
        margin-bottom: 20px;
        font-size: 16px;
        height: 30px;
        line-height: 23px; 
    }
`;

export const DialogContainer = styled.div``;
export const StyledConfirmationDialog = styled.div`

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: #f4eedc;
    }

    @media screen and (max-width: 375px) {
        margin-top: ${(props) => props.withImage && "-12px"};
        height: ${(props) => props.withImage && "calc(100vh - 120px) !important"};
    }

    ${DialogContainer} {
        ${props => {
            if (props.withImage) {
                return `
                    height: auto !important;
                    width: auto !important;
                    padding: 55px 20px 70px!important;
                `
            }
        }}
    }

    h3 {
        line-height: 1.4;
        ${props => {
            if (props.withImage) {
                return `
                    font-family: 'JJannon';
                    font-size: 36px;
                    font-style: italic;
                    text-transform: initial;
                    letter-spacing: 0.02em;
                `
            }
        }}
    }

    p {
        ${props => {
            if (props.withImage) {
                return `
                    font-size: 20px;
                    font-style: italic;
                    margin: -8px auto 0;
                    max-width: 250px;
                `
            }
        }}
    }

    button {
        ${props => {
            if (props.withImage) {
                return `
                    width: 313px;
                    padding: 14px 18px;
                    margin: 0 auto;
                    letter-spacing: 0.10em;
                `
            }
        }} 
    }
`;
