import { ApplyButton, CloseButton, Cta, StyledDrawer, SwipersHolder } from 'components/UI/AudioBookPlayer/style';
import React, { Component } from "react";

import PropTypes from "prop-types";
import { Swiper } from 'swiper/react';

class PlayerChaptersDrawer extends Component {

    handeClickApply = () => {
        this.props.onClick();
        this.props.onClickClose();
    }

    render() {   
        return (
            <StyledDrawer anchor='bottom' open={this.props.open}>
                <h2 className="mb-18">Chapters</h2>
                <div className="ls-1 font-14 text-center mb-20">
                    {this.props.hours}hrs{" "}
                    {this.props.minutes}min
                </div>
                <SwipersHolder style={{ marginBottom: "180px", minHeight: "280px"}}>
                    <Swiper                     
                        direction={"vertical"}    
                        grabCursor 
                        centeredSlides
                        slidesPerView={9}
                        slidesOffsetBefore={-80}
                        slideToClickedSlide
                        className="chapters-swiper"
                        onSlideChange={this.props.onSlideChange}
                    >
                        {this.props.chapters}
                    </Swiper>
                </SwipersHolder>
                <Cta>   
                    <ApplyButton onClick={() => this.handeClickApply()}>
                        Start
                    </ApplyButton>
                    <CloseButton onClick={this.props.onClickClose}>
                        Close
                    </CloseButton>
                </Cta>
            </StyledDrawer>
        );
    }
}

PlayerChaptersDrawer.propTypes = {
    open: PropTypes.bool,
    onSlideChange: PropTypes.func,    
    hours: PropTypes.number,
    minutes: PropTypes.number,
    chapters: PropTypes.array,
};

export default PlayerChaptersDrawer;
