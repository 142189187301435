import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import AudioBookSingleCarousel from 'components/UI/AudiobookSingle/AudioBookSingleCarousel';

import { StyledEditions, Subtitle, Title, Paragraph } from './style';

function SpiracleEditions({ data, title }) {
    return (
        <StyledEditions>
            <Subtitle className='font-secondary font-uppercase text-center'>Explore</Subtitle>
            <Title className='text-center'>{ReactHtmlParser(title)}</Title>
            <Paragraph className='text-center'>
                Exclusive audiobooks co-published with the UK’s independent presses.
            </Paragraph>

            <AudioBookSingleCarousel data={data.slice(0, 8)} />

            <div className='text-center mt-40'>
                <Link id='gtm-homepage-browse-spiracle-editions' to='/editions' className='sa-button-red sa-button-rounded'>Browse <span className='font-primary'>Spiracle </span><span className='font-italic font-primary'>editions</span></Link>
            </div>
        </StyledEditions>
    )
}
export default SpiracleEditions