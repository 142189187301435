import * as cartActionTypes from '../../../store/actions/cartActions';

import { Author, ContentHolder, Discount, Gift, ImageHolder, Price, StyledAudiobookItem, Title } from "components/UI/AudioBook/style";
import { Cta, CtaHolder, InfoText } from "../../UI/ShoppingBag/style";
import React, { Component } from 'react';

import ApiService from '../../../services/api.service';
import Collapse from '@material-ui/core/Collapse';
import CoverImage from "shared/CoverImage/CoverImage";
import FreeBook from 'shared/FreeBook/FreeBook';
import GiftButton  from "../../UI/ShoppingBag/GiftButton";
import { Link } from "react-router-dom";
import RemoveButton from '../ShoppingBag/RemoveButton';
import SendAsAGiftForm  from "../../UI/ShoppingBag/SendAsAGiftForm";
import { connect } from 'react-redux';
import { getNamesFromArray } from 'shared/Functions/GetNamesFromArray';
import { withRouter } from 'react-router';

class AudioBookListItem extends Component {

    constructor(props) {
        super(props);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)


        this.state = {
            library: this.props.inLibrary,
            giftCheckboxUpdated: false,
            emailValid: false,
        }

    }

    componentDidMount() {
        if (this.props.shoppingBag) {
            if (this.props.inLibrary) {
                if (!this.props.geoBlocked) {
                    const data = {
                        gift: true
                    }
                    ApiService.setGiftInfo(this.props.uuid, data).then(
                        r => {
                            //setting gift to true
                            this.props.onValidateCheckbox(true)
                        }
                    )
                }
            }
        }
    }

    handleCheckboxChange = e => {
        //if in library, you cannot uncheck it!
        //you can only remove item from cart
        if (!this.props.inLibrary) {
            this.setState({
                library: !this.state.library,
            }, () => {
                //remove gift info
                const data = {
                    gift: this.state.library,
                    giftEmail: '',
                    giftMessage: ''
                }
                ApiService.setGiftInfo(this.props.uuid, data).then(
                    r => {
                        this.props.onValidateCheckbox(this.state.library)
                        if (!this.state.library) {
                            this.setState({
                                giftCheckboxUpdated: true
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        giftCheckboxUpdated: false
                                    })
                                }, 1000)
                            })
                        }
                    }
                )
            })
        }
    }

    emailValidChange = valid => {
        this.setState({
            emailValid: valid
        })
    }

	render() {

		return (
            <div>
                <Link 
                    id='gtm-audiobook-item'
                    to={{ 
                        pathname: this.props.link, 
                        state: { 
                            backButton: this.props.backButton, 
                        }
                    }} 
                    style={{ display: "block", pointerEvents: (this.props.disabled || this.props.promotion) && 'none' }}
                >

                    <StyledAudiobookItem                
                        onClick={this.props.onClick}
                        className={this.props.className}
                        horizontal={this.props.horizontal}
                        isEdition={this.props.isEdition}
                        bookBgColor={this.props.bookBgColor}
                        containerBgColor={this.props.containerBgColor}
                        textColor={this.props.textColor}
                        mobileOnly={this.props.mobileOnly}
                        disabled={this.props.disabled}
                        promotion={this.props.promotion}
                        isFree={this.props.price === 0}
                    >                
                        <ImageHolder>
                            <CoverImage
                                src={this.props.imgSrc}    
                                alt={this.props.imgAlt}                         
                            />
                        </ImageHolder>

                        <ContentHolder>
                            <Title title={this.props.title}>
                                {this.props.title &&
                                    this.props.title
                                }
                            </Title>

                            <Author title={getNamesFromArray(this.props.authors)}>
                                {getNamesFromArray(this.props.authors)}
                            </Author>

                            <Price>
                                {this.props.hidePrice && <i className={`icon-${this.props.type === 'ebooks' ? 'e' : 'audio'}-book no-hover`}></i>}
                                
                                {(this.props.price || this.props.price === 0) && !this.props.hidePrice ? (
                                    <div>
                                        <i className={`icon-${this.props.type === 'ebooks' ? 'e' : 'audio'}-book no-hover`}></i>
                                        {this.props.discountPrice ? <span><span className="mr-3">£</span>{this.props.discountPrice.toFixed(2)}</span> : null}
                                        {this.props.discountPrice ? <span className='ml-8 bracket'>(</span> : null}
                                        <span className={this.props.discountPrice ? 'old-price' : ''}>
                                            <span className='mr-3'>£</span>{this.props.price}
                                            {this.props.subscriptionPrice && (this.props.subscriptionPrice !== this.props.price) ? 
                                                <span className='price-with-subscription'><span className='mr-3'>£</span>{this.props.subscriptionPrice} <span>for Premium Members</span></span> 
                                                : null
                                            }
                                        </span>
                                        {this.props.discountPrice ? <span className='bracket'>)</span> : null}
                                    </div>
                                ) : null}

                                {this.props.audioBook && !this.props.hidePrice && (
                                    <div>
                                        <i className="icon-audio-book no-hover"></i>
                                        <span><span className="mr-3">£</span>{this.props.audioBook.price}</span>
                                    </div>
                                )}

                                {this.props.eBook && !this.props.hidePrice && (
                                    <div>
                                        <i className="icon-e-book no-hover"></i>
                                        <span><span className="mr-3">£</span>{this.props.eBook.price}</span>
                                    </div>
                                )}
                            </Price>
                            {
                                this.props.gift ? 
                                <Gift>
                                    <i title={this.props.giftEmail} className="icon-gift no-hover"></i>
                                </Gift> 
                                : null
                            }
                            {    
                                this.props.discountCode ? 
                                <Discount>
                                    <i title={`${this.props.discountCode} - ${this.props.discountPercent}%`} className="icon-discount-2 no-hover font-18"></i>
                                </Discount> 
                                : null
                            }
                        </ContentHolder>
                        {this.props.price === 0 ? <FreeBook small horizontal={this.props.horizontal}/> : null} 
                    </StyledAudiobookItem>
                </Link>
                { this.props.cartItemsList || this.props.shoppingBag ?
                    <>
                        <CtaHolder disabled={this.props.disabled}>
                            <Cta>
                                {this.props.geoBlocked ? 
                                    <InfoText>Not available in your region</InfoText>
                                    : 
                                        <>
                                            <RemoveButton checked={this.state.library} book={this.props.book} uuid={this.props.uuid} onRemoveCartItem={this.props.onRemoveCartItem} />
                                            {
                                                this.props.shoppingBag ? 
                                                    <div
                                                        key={`gift-button-${this.props.uuid}`}
                                                        id={`gift-button-${this.props.uuid}`}
                                                    >
                                                        <GiftButton

                                                            uuid={this.props.uuid}
                                                            checked={this.state.library}
                                                            inLibrary={this.props.inLibrary}
                                                            onChange={() => this.handleCheckboxChange()}                                                
                                                        />
                                                    </div>
                                                : null 
                                            }
                                        </>
                                }                                    
                            </Cta>
                                { this.props.shoppingBag && !this.props.geoBlocked ? 
                                    <Collapse
                                        in={this.state.library}    
                                        collapsedSize={0}                
                                    >   
                                        <SendAsAGiftForm
                                            key={`send-as-gift-form-${this.props.uuid}`} 
                                            id={`send-as-gift-form-${this.props.uuid}`} 
                                            type={this.props.type === 'audiobook' ? 'audio_books' : 'e_books'}
                                            slug={this.props.slug}
                                            itemUuid={this.props.uuid} 
                                            index={this.props.index}
                                            giftCheckboxUpdated={this.state.giftCheckboxUpdated}
                                            emailValid={(validEmail) => this.emailValidChange(validEmail)}
                                        />
                                    </Collapse>
                                : null }                                
                        </CtaHolder> 
                        {this.props.geoBlocked ?
                            <CtaHolder>
                                <Cta>
                                    <RemoveButton emailValid={this.state.emailValid} checked={this.state.library} book={this.props.book} uuid={this.props.uuid} onRemoveCartItem={this.props.onRemoveCartItem} />
                                </Cta>
                            </CtaHolder>
                        : null }
                    </>
                    : null 
                }
            </div>
		);
	}
}

const mapDispatchToProps = dispatch => {
    return {
        onValidateCheckbox: (valid) => dispatch(cartActionTypes.onValidateCheckbox(valid)),
    }
} 

const audioBookListItemWithRouter = withRouter(AudioBookListItem)

export default connect(null, mapDispatchToProps)(audioBookListItemWithRouter)