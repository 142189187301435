import * as actionTypes from '../actions/cartActions';

const initialState = {
    cart: [],
    checkboxArr: 0,
    emailArr: 0,
}

const cartReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.GET_CART_ITEMS:
            return { 
                ...state,
                cart: action.cartItems
            };
        case actionTypes.REMOVE_CART_ITEM:
            const copiedCart = [...state.cart]
            const filteredCart = copiedCart.filter(cartItem => cartItem.uuid !== action.uuid)
            return {
                ...state,
                cart: filteredCart
            }
        case actionTypes.ADD_CART_ITEM:
            const copiedCart1 = [...state.cart]
            copiedCart1.push(action.cartItem);
            return {
                ...state,
                cart: copiedCart1
            }
        case actionTypes.CLEAR_CART:
            return {
                ...state,
                cart: []
            }
        case actionTypes.VALIDATE_CHECKBOX:
            if (action.valid) {
                return {
                    ...state,
                    checkboxArr: state.checkboxArr + 1
                }
            } else {
                return {
                    ...state,
                    checkboxArr: state.checkboxArr > 0 ? state.checkboxArr - 1 : 0
                }
            }
            

        case actionTypes.REMOVE_CHECKBOX:
            return {
                ...state,
                checkboxArr: state.checkboxArr > 0 ? state.checkboxArr - 1 : 0,
                // emailArr: state.emailArr > 0 ? state.emailArr - 1 : 0
            }
        
        case actionTypes.REMOVE_EMAIL:
            return {
                ...state,
                emailArr: state.emailArr > 0 ? state.emailArr - 1 : 0
            }
        case actionTypes.VALIDATE_EMAIL:
            if (action.valid) {
                return {
                    ...state,
                    emailArr: state.emailArr + 1
                }
            } else {
                return {
                    ...state,
                    emailArr: state.emailArr > 0 ? state.emailArr - 1 : 0
                }
            }
        case actionTypes.CLEAR_VALIDATION:
            return {
                ...state,
                checkboxArr: 0,
                emailArr: 0,
            }
        default:
            return state;
    }
}

export default cartReducer;