import React, { Component } from "react";

import { Discount, Gift, Image, MembershipCardContainer, Price, Title, Type } from './style';

import logo from '../../images/Spiracle-small.svg';

import { connect } from 'react-redux';

class MembershipCard extends Component {
    render() {
        let type = this.props.type === 2 || this.props.type === "YEARLY" ? 'Annually' : this.props.type === 1 ? 'Monthly' : '6 month';
        const pricesNotEqual = this.props.price !== this.props.originalPrice

        return (
            <MembershipCardContainer>
                <Image>
                    <img src={logo} alt="Spiracle logo" />
                </Image>
                <div>
                    <Title>Spiracle <span>subscription</span></Title>
                    <Type>{type}</Type>
                    {/* <Price><span>£</span>{this.props.price}</Price> */}
                    <Price>
                        <span className='mr-8'><span>£</span>{this.props.price}</span>
                        {pricesNotEqual ? <span className='bracket'>(</span> : null}
                        {pricesNotEqual ? <span className={`${pricesNotEqual ? 'old-price' : ''}`}><span>£</span>{this.props.originalPrice}</span> : null}
                        {pricesNotEqual ? <span className='bracket'>)</span> : null}
                    </Price>
                </div>
                {this.props.gift ? 
                    <Gift>
                        <i title={this.props.giftEmail} className="icon-gift no-hover"></i>
                    </Gift> 
                    : null}
                {this.props.discountCode ? 
                    <Discount>
                        <i title={`${this.props.discountCode} - ${this.props.discountPercent}%`} className="icon-discount-2 no-hover font-18"></i>
                    </Discount> 
                    : null}
            </MembershipCardContainer>
        )
    }
}

const mapStateToProps = state => ({
    membershipPrice: state.membershipPriceReducer
})

export default connect(mapStateToProps)(MembershipCard);