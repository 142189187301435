import React, { Component } from 'react';

import SpiracleLogo from '../../images/Spiracle-logo.svg';

class HeaderApp extends Component {
    render() {
        return (
            <>
                <header className='c-header h-flex h-flex-between'>
                    <div className={`c-header__wrapper c-header__wrapper--app-sign-up h-flex h-flex-between h-flex-middle`}>
                        <div className={`c-header__logo-wrapper h-flex h-flex-between h-flex-align-end`}>
                            <div className='c-header__logo h-flex h-flex-align-end'>
                                <img src={SpiracleLogo} alt='spiracle-logo' />
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default HeaderApp;
