import React, { Component } from 'react';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";

import { StyledDate, Icon } from './style';

class Date extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            invalidDate: true
        }
    }

    handleDateChange = (date) => {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        const dateString = `${y}-${m}-${d}`;

        this.setState({
            date: date,
            invalidDate: false
        }, () => {
            this.props.pullDate({date: dateString, invalidDate: this.state.invalidDate})
        })
    }

    render() {
        const options = {
            minDate: 'today',
            dateFormat: 'd/m/Y',
            locale: {
                firstDayOfWeek: 1,
            },
            disableMobile: true,
        }

        return (
            <StyledDate className={`${this.props.className} ${this.state.date ? '' : 'show-placeholder'}`}>
                <label>{this.props.label}</label>  
                <Flatpickr
                    options={options}
                    value={this.state.date}
                    onChange={([date]) => this.handleDateChange(date)}
                />
                <Icon><i className="icon-calendar no-hover" /></Icon>
                <div className='input-description'>You can select the day when the we send the email to the recipient. e.g on their birthday or Christmas Eve.</div>
            </StyledDate>
        );
    }
}

export default Date;