import React, { Component } from 'react';
import { DialogContainer, StyledConfirmationDialog } from './style';
import handApple from '../../images/hand-apple.webp';

class ConfirmationDialog extends Component {
    render() {
        return (
            <StyledConfirmationDialog className="popup-content" withImage={this.props.withImage}>                
                <DialogContainer className={`c-popup ${this.props.deleteAccount && "c-popup--delete-account"}`}>
                    <i onClick={this.props.handleClose} className="icon-close-small"></i>
                    <div className="c-popup__header">
                        <h3 className={`font-secondary font-12 font-400 ${this.props.withImage ? 'mb-8' : 'mb-30'} text-center letter-spacing-15 font-uppercase`}>{this.props.title}</h3>
                    </div>
                    {this.props.withImage && <div>
                        <img src={handApple} alt="" />
                    </div>}
                    <div>
                        <p>{this.props.text}</p>
                    </div>
                    <div className={`c-popup__action ${this.props.deleteAccount && "c-popup__action--delete-account"}`}>
                        <button
                            id={`${this.props.deleteAccount ? 'gtm-settings-dialog-delete-account-yes' : this.props.audioBook ? 'gtm-audiobook-dialog-add-to-library' : 'gtm-subscription-dialog-cancel-subscription'}`}
                            onClick={this.props.onClickFirstButton} 
                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase ${this.props.firstButtonPrimary && 'c-btn__ghost--square--full'}`}
                        >
                            {this.props.firstButtonLabel}
                        </button>
                        {this.props.secondButtonLabel && <button 
                            id={`${this.props.deleteAccount ? 'gtm-settings-dialog-delete-account-cancel' : this.props.audioBook ? 'gtm-audiobook-dialog-cancel' : ''}`}
                            onClick={this.props.onClickSecondButton} 
                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase ${this.props.secondButtonPrimary && 'c-btn__ghost--square--full'}`}
                        >
                            {this.props.secondButtonLabel}
                        </button>}
                    </div>
                    {this.props.deleteAccount && <div className="c-popup__quote">
                        {this.props.quote}
                    </div>}
                </DialogContainer>
            </StyledConfirmationDialog>
        );
    }
}

export default ConfirmationDialog;