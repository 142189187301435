import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import AudioBookSingleCarousel from 'components/UI/AudiobookSingle/AudioBookSingleCarousel';

import { StyledAstonishingMemoirs, Subtitle, Title } from './style';

function AstonishingMemoirs({ data, title }) {
    return (
        <StyledAstonishingMemoirs>
            <Subtitle className='font-secondary font-uppercase text-center'>Discover</Subtitle>
            <Title className='font-italic text-center'>{ReactHtmlParser(title)}</Title>

            <AudioBookSingleCarousel data={data.slice(0, 8)} />

            {title ? 
                <div className='text-center'>
                    <Link id='gtm-homepage-astonishing-memoirs' to='/astonishing-memoirs' className='sa-button-red sa-button-rounded'>{ReactHtmlParser(title)}</Link>
                </div> : null}
            
        </StyledAstonishingMemoirs>
    )
}

export default AstonishingMemoirs