import { createTheme } from "@material-ui/core/styles";
const font = "Gill Sans Nova";

export const Theme = createTheme({
  spacing: 6,
  breakpoints: {
    values: { 
      xs: 360,
      sm: 375,
      md: 768,
      lg: 960,
      xl: 1260,
      xxl: 1921
    },
  },
  overrides: {
    MuiChip: {
      root: {
        borderRadius: "20px",
        fontSize: "22px",
        fontStyle: "italic",
        fontFamily: font,
        height: "40px",
        lineHeight: "32px",
        letterSpacing: "0.02em",
        color: "#F4EEDC",
        backgroundColor: "#000000",
      },
      label: {
        paddingTop: "1px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "7px",
      },
      outlined: {
        borderColor: "#000000",
        color: "#000000",
        "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
          backgroundColor: "#000000",
        },
      },
      clickable: {
        "&:focus": {
          color: "#F4EEDC",
          backgroundColor: "#000000",
        },
      },
    },
  },
});
