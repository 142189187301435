import * as Yup from "yup";
import * as userActionTypes from "../../../store/actions/userActions";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";

import ApiService from "../../../services/api.service";
import PageMeta from "../../../shared/PageMeta/PageMeta";
import { ReactComponent as SpiracleLogoSmall } from "../../../images/Spiral.svg";
import { connect } from "react-redux";

class ChangePassword extends Component {
    state = {
        passwordEye: true,
        newPasswordEye: true,
        invalidCredentials: false,
        saved: false,
    };

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        }
    }

    togglePassword = () => {
        this.setState({
            passwordEye: !this.state.passwordEye,
        });
    };

    toggleNewPassword = () => {
        this.setState({
            newPasswordEye: !this.state.newPasswordEye,
        });
    };

    submitChangePassword = (values, callbacks) => {
        const data = {
            oldPassword: values.password,
            newPassword: values.newPassword,
        };

        const { resetForm, setSubmitting } = callbacks;

        ApiService.changePassword(data, this.props.user.uuid)
            .then((r) => {
                localStorage.setItem("token", r.data.token);
                this.setState(
                    {
                        invalidCredentials: false,
                        saved: true,
                    },
                    () => {
                        setSubmitting(false);
                        resetForm();
                        setTimeout(() => {
                            this.setState({
                                saved: false,
                            });
                        }, 5000);
                    }
                );
            })
            .catch((e) => {
                this.setState(
                    {
                        invalidCredentials: true,
                    },
                    () => {
                        setSubmitting(false);
                    }
                );
            });
    };

    handleCancelChanges = (form) => {
        form.resetForm();
        form.setValues({
            password: "",
            newPassword: "",
        });
    };

    render() {
        //** FORMIK CHANGE PASSWORD */
        const changePasswordValidationSchema = Yup.object().shape({
            password: Yup.string().required("Old password is required"),
            newPassword: Yup.string().required("New password is required").min(8, "Password must be at least 8 characters long").max(60, "Password must be less than 60 characters long"),
        });

        const changePasswordInitialValues = {
            password: "",
            newPassword: "",
        };
        //** FORMIK CHANGE PASSWORD END */

        return (
            <div className="container container__basic-page">
                <PageMeta>
                    <title>Change Password | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                <div className="c-form">
                    <h1 className="c-form__change-password-heading font-uppercase font-secondary font-400 ml-5">CHANGE PASSWORD</h1>
                    <Formik enableReinitialize={true} initialValues={changePasswordInitialValues} validateOnMount={true} validationSchema={changePasswordValidationSchema} onSubmit={this.submitChangePassword}>
                        {(form) => {
                            return (
                                <Form className="c-form">
                                    <div className="password-field labeled mb-20">
                                        <div className="font-12 font-secondary mb-6 ml-5">Current password</div>
                                        <Field
                                            className={`bg-white ${form.errors && form.errors.password && form.touched && form.touched.password ? "invalid" : ""}`}
                                            placeholder="Current password"
                                            type={this.state.passwordEye ? "password" : "text"}
                                            name="password"
                                            required
                                            id="password"
                                        />
                                        <i onClick={() => this.togglePassword()} className={`icon-eye password-field--eye ${this.state.passwordEye ? "" : "show"}`}></i>
                                    </div>
                                    <div className="password-field labeled">
                                        <div className="font-12 font-secondary mb-6 ml-5">New password</div>
                                        <Field
                                            className={`bg-white ${form.errors && form.errors.newPassword && form.touched && form.touched.newPassword ? "invalid" : ""}`}
                                            placeholder="Choose password"
                                            type={this.state.newPasswordEye ? "password" : "text"}
                                            name="newPassword"
                                            required
                                            id="newPassword"
                                        />
                                        <i onClick={() => this.toggleNewPassword()} className={`icon-eye password-field--eye ${this.state.newPasswordEye ? "" : "show"}`}></i>
                                    </div>
                                    <div className="c-error-message mt-5">
                                        <ErrorMessage name="password" component="div" />
                                        <ErrorMessage name="newPassword" component="div" />
                                        {this.state.invalidCredentials ? <div>Invalid credentials</div> : null}
                                    </div>
                                    <div className="mt-36">
                                        <button
                                            id='gtm-change-password'
                                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-18 ${form.isSubmitting ? "submitting" : ""} ${
                                                this.state.saved ? "c-btn__ghost--saved" : ""
                                            }`}
                                            type="submit"
                                            disabled={form.isSubmitting || !form.isValid}
                                        >
                                            {this.state.saved ? "Changes saved!" : "Change password"}
                                            <SpiracleLogoSmall />
                                        </button>
                                        {form.dirty ? (
                                            <div>
                                                <button id='gtm-change-password-cancel-changes' onClick={() => this.handleCancelChanges(form)} className={`c-btn c-btn__ghost c-btn__ghost--cancel full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28`} type="button">
                                                    Cancel changes
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
