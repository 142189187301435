import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";

import ApiService from "../../../services/api.service";
import PageMeta from "../../../shared/PageMeta/PageMeta";
import { ReactComponent as SpiracleLogoSmall } from "../../../images/Spiral.svg";

export default class NewsletterSignup extends Component {
    state = {
        subscribed: false,
        error: false,
    };

    /**
     * Handle subscribe to newsletter form
     */
    submitSubscribeToNewsletter = (values, callbacks) => {
        this.setState(
            {
                error: false,
            },
            () => {
                const data = {
                    email: values.newsletterEmail,
                };

                const { setSubmitting } = callbacks;

                ApiService.subscribeToNewsletter(data)
                    .then((r) => {
                        setSubmitting(false);
                        this.setState({
                            subscribed: true,
                        });
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            error: true,
                        });
                    });
            }
        );
    };

    render() {
        //** FORMIK SUBSCRIBE TO NEWSLETTER */
        const forgotPasswordValidationSchema = Yup.object().shape({
            newsletterEmail: Yup.string().email("Email must be valid").required("Email is required"),
        });

        const forgotPasswordInitialValues = {
            newsletterEmail: "",
        };
        //** FORMIK SUBSCRIBE TO NEWSLETTER END */

        return (
            <div className="container container__newsletter c-homepage c-homepage--main-content">
                <PageMeta>
                    <title>Newsletter | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                {!this.state.subscribed ? (
                    <>
                        <h1 className="font-secondary font-16 font-400 mb-28 text-center letter-spacing-15">SIGN UP TO THE SPIRACLE NEWSLETTER</h1>
                        <h4 className="text-center font-14 font-400 margin-reset">Where authors, actors and publishers <br /> light up modern and contemporary writing</h4>
                        <p className="container__newsletter__quote font-14 text-center mb-18">
                            <span className="font-italic">Reading brings us unknown friends</span> <br/> &#8212; Honore de Balzac
                        </p>
                        <div className="h-flex h-flex-center">
                            <div className="full-width">
                                <Formik initialValues={forgotPasswordInitialValues} validateOnMount={true} validationSchema={forgotPasswordValidationSchema} onSubmit={this.submitSubscribeToNewsletter}>
                                    {(form) => {
                                        return (
                                            <Form className="c-form">
                                                <div>
                                                    <Field
                                                        className={`bg-white ${form.errors && form.errors.newsletterEmail && form.touched && form.touched.newsletterEmail ? "invalid" : ""}`}
                                                        placeholder="Your email"
                                                        type="email"
                                                        name="newsletterEmail"
                                                        required
                                                        id="newsletterEmail"
                                                    />
                                                </div>
                                                <div className="c-error-message mt-5">
                                                    <ErrorMessage name="newsletterEmail" component="div" />
                                                    {this.state.error ? <div>Something went wrong, please try again later</div> : null}
                                                </div>
                                                <div className="mt-25">
                                                    <button
                                                        id='gtm-newsletter-sign-up'
                                                        className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase ${form.isSubmitting ? "submitting" : ""}`}
                                                        type="submit"
                                                        disabled={form.isSubmitting || !form.isValid}
                                                    >
                                                        Sign up
                                                        <SpiracleLogoSmall />
                                                    </button>
                                                </div>
                                                {/* <div className="mt-18 text-center">
                                                    <p className="font-12 font-secondary font-gray lh-14 mb-14">* Not valid in conjunction with other offers or discounts. Excludes rare, special editions and Gift Cards</p>
                                                    <p className="font-12 font-secondary font-gray lh-14">
                                                        Every month authors, poets and publishers tell new stories.
                                                        <br />
                                                        <span className="font-italic">Reading brings us unknown friends</span> Honore de Belzac
                                                    </p>
                                                </div> */}
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="container__newsletter__success text-center font-italic">Thank you for subscribing to our newsletter.</p>
                    </>
                )}
            </div>
        );
    }
}
