import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h1``;
export const Paragraph = styled.p``;
export const StyledSlider = styled.div``;
export const Slide = styled.div``;
export const Audiobook = styled.div``;
export const Content = styled.div``;
export const Description = styled.div``;

export const StyledIntro = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 55px;
    // padding: 136px 0 97px;
    position: relative;
    z-index: 0;
    margin: 140px 0 160px;
    padding: 0 20px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        flex-direction: column;
        gap: 38px;
        margin: 10px 0 90px;
        padding: 0;
        text-align: center;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 80px 0 50px;
    }

    > div {
        &:first-of-type {
            max-width: 620px;

            ${(props) => props.theme.breakpoints.down('lg')} {
                max-width: 100%;
                text-align: center;
                padding: 0 60px;
            }

            ${(props) => props.theme.breakpoints.down('sm')} {
                padding: 0;
            }
        }
    }

    ${Subtitle} {
        font-family: 'Gill Sans Nova';
        font-size: 14px;
        line-height: 43.2px;
        letter-spacing: 3px;
        color: #721b00;
        margin-bottom: 17px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
            line-height: 25px;
            letter-spacing: 2px;
        }
    }

    ${Title} {
        font-size: 60px;
        line-height: 60px;
        letter-spacing: 0;
        margin-bottom: 24px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 36px;
            line-height: 36px;
        }
    }

    ${Paragraph} {
        font-family: 'Gill Sans Nova';
        font-size: 22px;
        line-height: 32px;
        max-width: 570px;
        margin-bottom: 40px;

        ${(props) => props.theme.breakpoints.down('lg')} {
            max-width: 100%;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 17px;
            line-height: 24px;
            padding: 0 5px;
        }
    }

    ${StyledSlider} {
        max-width: 750px;
        margin: 0 auto;
        background: #f4eedc;

        ${(props) => props.theme.breakpoints.down('lg')} {
            max-width: 100%;
            min-width: 100%;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            padding: 20px 0 0;
        }

        ${Slide} {
            display: inline-flex !important;
            justify-content: center;
            gap: 36px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                flex-direction: column;
            }
        }

        ${Audiobook} {
            width: 282px;
            min-width: 282px;
            margin: 6px 0;

            ${(props) => props.theme.breakpoints.down('sm')} {
                margin: 6px auto;
                width: 100%;
                max-width: 520px;
            }
        }

        ${Content} {
            max-width: 325px;
            margin-top: 100px;
            font-family: 'Gill Sans Nova';

            ${(props) => props.theme.breakpoints.down('lg')} {
                max-width: 520px;

                ${(props) => props.theme.breakpoints.down('sm')} {
                    margin: 50px auto 0;
                }
            }

            ${Description} {
                font-family: 'JJannon';
                font-size: 19px;
                line-height: 25px;
                margin-bottom: 16px;

                 ${(props) => props.theme.breakpoints.down('sm')} {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        ${Subtitle} {
            color: #000;

            &:first-of-type {
                position: relative;
                margin-bottom: 10px;

                &::before {
                    content: '“';
                    position: absolute;
                    top: -50px;
                    left: 47%;
                    transform: translateX(-50%);
                    font-size: 160px;
                    line-height: 160px;
                    font-family: 'JJannon';
                    color: rgba(0, 0, 0, 0.05);

                    ${(props) => props.theme.breakpoints.down('sm')} {
                        font-size: 127px;
                        line-height: 127px;
                        top: -60px;
                        left: 47%;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        .slick-dots {
            bottom: -80px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                bottom: -30px;
            }

            li {
                &.slick-active {
                    button {
                        &::before {
                            color: #721b00;
                        }
                    }
                }

                button {
                    &::before {
                        color: #e8e1cf;
                        opacity: 1;
                        transform: scale(2);
                    }
                }
            }
        }
    }
`;

export const Buttons = styled.section`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        justify-content: center;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        flex-direction: column;
    }

    div, a {
       ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100% !important;
        } 
    }

    .sa-button-ghost {
        font-style: italic !important;
    }

    p {
        opacity: 0.7;
    }
`;