import * as cartActionTypes from '../../store/actions/cartActions';
import * as libraryActionTypes from '../../store/actions/libraryActions';
import * as userActionTypes from '../../store/actions/userActions';
import * as discountActionTypes from '../../store/actions/discountActions';

import { Heading, OrderDetails, OrderFooter, Paragraph, StyledOrderConfirmation, Terms } from './style';
import React, { Component } from 'react';

import ApiService from 'services/api.service';
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import DateFormater from '../../services/format-date.service';
import DownloadIcons from 'shared/DownloadIcons/DownloadIcons';
import { Link } from 'react-router-dom';
import Loader from 'shared/Loader/Loader';
import MembershipCard from '../../shared/MembershipCard/MembershipCard';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import PaypalProblem from 'components/UI/PaypalProblem/PaypalProblem';

class OrderConfirmation extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            order: null,
            orderItems: [],
            newUser: this.props.location.state ? this.props.location.state.newUser : new URLSearchParams(this.props.location.search).get('newuser') === 'true',
            isPaypalFailed: new URLSearchParams(this.props.location.search).get('redirect_status') === 'failed',
            isPaypal: new URLSearchParams(this.props.location.search).get('redirect_status'),
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            // remove cart id cookie if user was
            // unregistered so it doesn't use it 
            // again and cause error
            cookie.remove('cartId');
            
            if (!this.props.user && localStorage.getItem('token')) {
                ApiService.getUser().then(
                    user => {
                        this.props.onSetUser(user.data);
                    }
                )
            }
        }

        if (!this.state.isPaypal) {
            setTimeout(() => {
                if (!this.state.order) {
                    this.props.history.push("/payment-successful-problem");
                }
            }, 30000)
        }

        if (this.state.isPaypalFailed) {
            this.props.onRemoveDiscount()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.order !== this.props.order) {
            ApiService.getOrder(this.props.order).then(
                r => {
                    this.setState({
                        order: r.data,
                        orderItems: r.data.orderItems
                    }, () => {

                        //filter first to avoid
                        //settings gifts in library
                        let filteredOrderItems = r.data.orderItems.filter(x => x.gift === false);
                        //clean cart from redux
                        //clean cart ID from user 
                        //add order items to library
                        this.props.onRemoveCart();
                        this.props.onClearCart();
                        this.props.onAddOrderToLibrary(filteredOrderItems)

                        this.props.onRemoveDiscount()

                        if (window.dataLayer) {
                            const cartItems = [];
                            if (this.state.orderItems) {
                                this.state.orderItems.map((book, index) => {
                                    const locations = []
                                    if (book.audioBook.locations && book.audioBook.locations.length) {
                                        book.audioBook.locations.map((location) => {
                                            return locations.push(location.title)
                                        })
                                    }
                                    const tags = []
                                    if (book.audioBook.tags && book.audioBook.tags.length) {
                                        book.audioBook.tags.map((tag) => {
                                            return tags.push(tag.title)
                                        })
                                    }
                                    const authors = []
                                    if (book.audioBook.authors && book.audioBook.authors.length) {
                                        book.audioBook.authors.map((author) => {
                                            return authors.push(author.fullName)
                                        })
                                    }
                                    const narrators = []
                                    if (book.audioBook.narrators && book.audioBook.narrators.length) {
                                        book.audioBook.narrators.map((narrator) => {
                                            return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                                        })
                                    }
                                    return cartItems.push({
                                        item_id: book.audioBook.slug,
                                        item_name: book.audioBook.title,
                                        item_brand: book.audioBook.publisher ? book.audioBook.publisher?.name : '',
                                        item_category: book.audioBook.category ? book.audioBook.category?.name : '',
                                        item_locations: locations?.length ? locations.join(', ') : '',
                                        item_tags: tags?.length ? tags.join(', ') : '',
                                        item_price: book.audioBook.price,
                                        item_author: authors?.length ? authors.join(', ') : '',
                                        item_narrator: narrators?.length ? narrators.join(', ') : '',
                                        item_translator: book.audioBook.translator ? `${book.audioBook.translator.firstName} ${book.audioBook.translator.lastName}` : '',
                                        item_length: `${book.audioBook.lengthHours}:${book.audioBook.lengthMin}`,
                                    })
                                })
                            }

                            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                            window.dataLayer.push({
                                event: "purchase",
                                ecommerce: {
                                    transaction_id: this.state.order.transactions[0]['@id'].split('/api/transactions/')[1],
                                    value: this.state.order.amount,
                                    tax: Number((this.state.order.amount * 0.2).toFixed(2)),
                                    currency: "GBP",
                                    coupon: this.state.order.couponCode || '',
                                    discount: this.state.order.percentDiscount || 0,
                                    items: cartItems
                                }
                            });
                        }
                    })
                }
            )
        }
    }

    render() {
        let content = <Loader />
        if (this.state.order || this.state.isPaypal) {
            content = (
                <StyledOrderConfirmation className="container container__big">
                    <PageMeta>
                        <title>Successful | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>

                    {this.state.newUser ? (
                        <>
                            <Heading className="lh-29">
                                <span className="font-italic font-24">Thank you for joining Spiracle, <br /> the sound of great writing.</span> <br /><br />
                                <span className="font-24">Your journey begins here.</span>
                            </Heading>

                            <div className="text-center mt-48 mb-45">
                                <div><Link id='gtm-order-confirmation-events' to="/discussions" className="c-btn c-btn__ghost mb-20 font-22 font-secondary">events</Link></div>
                                <div><Link id='gtm-order-confirmation-editions' to="/editions" className="c-btn c-btn__brown mb-20 font-22">Spiracle <span className="font-italic">editions</span></Link></div>
                                <div>
                                    <Link 
                                        id='gtm-order-confirmation-guest-picks'
                                        to="/search-articles?keyword=guest+pick&_page=1&itemsPerPage=5&search%5B%5D=articles&search%5B%5D=interviews&search%5B%5D=discussions" 
                                        className="c-btn c-btn__ghost mb-20 font-22 font-secondary font-italic">Guest picks</Link>
                                </div>
                                <div><Link id='gtm-order-confirmation-collection' to="/audiobooks" className="c-btn c-btn__black c-btn__black--italic mb-20 font-22 font-secondary">Collection</Link></div>
                            </div>
                        </>
                    ) : (
                        <Heading>
                            <span className="font-italic font-24">Thank you for the purchase!</span>
                        </Heading>
                    )}

                    <Paragraph className={`${(this.state.newUser) ? 'mb-32' : 'mb-57'}`}>
                        {this.state.newUser ? 
                            <div>An order confirmation has <br /> been sent to your email</div> :
                            "Your own books are stored in your Library while any gifts you've made have been sent to your friends, family and other animals. There is a record of all your purchases in your account Order History. An order confirmation has been sent."
                        }
                    </Paragraph>

                    <DownloadIcons orderConfirmation />

                {this.state.order && this.state.orderItems ? 
                 <>
                    <OrderDetails>
                        <span>ORDER No. {this.state.order.orderNumber}</span><span>{DateFormater.formatDateMonthYear(this.state.order.createdAt)}</span>
                    </OrderDetails>

                    {this.state.orderItems.map((book, index) => {
                        let bookInfo                    
                        if (book.audioBook) {
                            bookInfo = book.audioBook
                        } else if (book.eBook){
                            bookInfo = book.eBook
                        }
                        if (bookInfo) {
                            return <AudioBookListItem
                                key={`book-${index}`}                
                                title={bookInfo.title}
                                authors={bookInfo.authors}
                                imgSrc={bookInfo.cover}
                                imgAlt={bookInfo.coverAlt}
                                giftEmail={book.giftEmail}
                                price={book.originalPrice}
                                link={`/${book.audioBook ? 'audiobooks' : 'ebooks'}/${bookInfo.slug}`}
                                eBook={bookInfo.eBook}
                                gift={book.gift}
                                isEdition={bookInfo.edition}
                                bookBgColor={bookInfo.bookBackgroundColour}
                                containerBgColor={bookInfo.containerBackgroundColour}
                                textColor={bookInfo.textColour}
                                type={book.audioBook ? 'audiobook' : 'ebooks'}
                                horizontal
                                mobileOnly
                                discountCode={book.couponCode}
                                discountPercent={book.percentDiscount}
                                discountPrice={book.price !== book.originalPrice ? book.price : null}
                                className="mb-18"
                            />
                            } 
                            
                            if (book.subscription) {
                                return <MembershipCard key={`membership-${index}`} type='monthly' price={book.price} gift={book.gift} giftEmail={book.giftEmail} />
                            }

                            return null
                    })}
                </> : null
                }

                    <OrderFooter className="text-center">
                        <p className="font-18 mt-45 mb-30">If you have any questions, <br /> please contact our <Link id='gtm-order-confirmation-contact-support' to="/info/support">support team</Link></p>
                        <Terms>
                            <Link id='gtm-order-confirmation-terms-conditions' to='/info/terms-conditions'>Terms &amp; Conditions</Link>
                            <p>powered by <strong>stripe</strong></p>
                        </Terms>
                    </OrderFooter>
                </StyledOrderConfirmation>
            );
        }
        if (this.state.isPaypalFailed) {
            content = <PaypalProblem />
        }
        return content
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    user: state.userReducer.user,
    order: state.orderReducer.order,
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onRemoveCart: () => dispatch(userActionTypes.onRemoveCart()),
        onClearCart: () => dispatch(cartActionTypes.onClearCart()),
        onAddOrderToLibrary: (order) => dispatch(libraryActionTypes.onAddOrderToLibrary(order)),
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);