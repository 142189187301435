import styled from "styled-components/macro";

export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Content = styled.div``;

export const StyledHowSpiracleWorks = styled.div`
    font-family: 'Gill Sans Nova';
    background: #F1E5A3;
    padding: 65px 120px;
    margin-top: 92px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        padding: 65px 35px;
        margin-left: -16px;
        width: calc(100% + 32px);
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 25px 20px;
        margin-top: 50px;
    }

    ${Subtitle} {
        font-size: 18px;
        line-height: 43px;
        letter-spacing: 6px;
        margin-bottom: 5px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
            margin-bottom: 0;
        }
    }
    
    ${Title} {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 40px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
            margin-bottom: 30px;
        }
    }

    ${Content} {
        display: flex;
        justify-content: space-between;
        gap: 42px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            flex-direction: column;
            gap: 0;
            align-items: center;
        }

        > div {
            max-width: 310px;
            flex: 1;

            ${(props) => props.theme.breakpoints.down('sm')} {
                max-width: initial;
                margin-bottom: 40px;
                padding: 0 12px;
            }

            &:last-of-type {
                ${(props) => props.theme.breakpoints.down('sm')} {
                    margin-bottom: 5px;
                }   
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 6px;
            margin-top: 30px;
            margin-bottom: 20px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                margin: 15px 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 20px;

            ${(props) => props.theme.breakpoints.down('sm')} {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

    a {
        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100% !important;
        }
    }
`;

