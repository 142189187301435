
import { StyledCheckoutForm } from '../../components/UI/Ecommerce/CheckoutForm/style';
import React, { useState } from "react";
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import ReactFlagsSelect from 'react-flags-select';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import PageMeta from 'shared/PageMeta/PageMeta';

import { StyledXmasContainer } from './style';
import { Terms } from 'pages/Order/style';


export default function PromotionXmasFree() {
    const history = useHistory();
    const user = useSelector(state => state.userReducer.user);
    const [registerError, setRegisterError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState('');
    const [state, setState] = useState({
        eMail: "",
        firstName: "",
        lastName: "",
        password: "",
    });
    const [validationState, setValidationState] = useState({
        eMail: true,
        firstName: true,
        lastName: true,
        password: true,
    })

    const [passwordVisible, setPasswordVisible] = useState(false);

    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isNewsletterSignupChecked, setIsNewsletterSignupChecked] = useState(true);

    const [alreadyHaveSubscription, setAlreadyHaveSubscription] = useState(false);

    const handleCheckedTerms = () => {
        setIsTermsChecked(prev => !prev)
    }

    const handleCheckedNewsletterSignup = () => {
        setIsNewsletterSignupChecked(prev => !prev)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let dataSubscription;

        if (user && user.newsletter) {
            dataSubscription = {
                plan: 1,
            }
        } else {
            dataSubscription = {
                plan: 1,
                newsletter: isNewsletterSignupChecked
            }
        }

        if (user) {
            setIsLoading(true)

            ApiService.createSubscriptionWithoutPayment(dataSubscription).then(r => {
                setIsLoading(false)

                history.push({
                    pathname: '/subscription-successful-trial',
                    state: { subscriptionId: r.data.id }
                })
            }).catch(e => {
                if (e.response && e.response.data['hydra:description'] === 'User already has an active subscription') {
                    setAlreadyHaveSubscription(true)
                    setIsLoading(false)
                }
                if (e.response && e.response.data['hydra:description'] === 'User already has trial subscription') {
                    history.push('/xmas?plan=annually');
                }
            })
        } else {
            const dataRegister = {
                email: state.eMail,
                password: state.password,
                firstName: state.firstName,
                lastName: state.lastName,
                countryCode: selected,
                terms: true
            }

            setIsLoading(true)

            ApiService.ecommerceRegister(dataRegister).then(r => {
                localStorage.setItem('token', r.data.token);
                localStorage.setItem('refresh_token', r.data.refresh_token);

                ApiService.createSubscriptionWithoutPayment(dataSubscription).then(r => {
                    setIsLoading(false)
                    
                    history.push({
                        pathname: '/subscription-successful-trial',
                        state: { subscriptionId: r.data.id }
                    })
                }).catch(e => {
                    if (e.response && e.response.data['hydra:description'] === 'User already has an active subscription') {
                        setAlreadyHaveSubscription(true)
                        setIsLoading(false)
                    }
                    if (e.response && e.response.data['hydra:description'] === 'User already has trial subscription') {
                        history.push('/xmas?plan=annually');
                    }
                })
            }).catch(
                e => {
                    setRegisterError(true)
                    setIsLoading(false);
                }
            )
        }
    }

    const handleChange = e => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });

        if (e.target.name === 'password' && e.target.value.length > 7) {
            document.querySelector('#password-length').style.visibility = 'hidden';
        }
    }

    const handleBlur = e => {
        const value = e.target.value
        if (e.target.name === 'eMail') {
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setValidationState({
                ...validationState,
                [e.target.name]: regex.test(String(e.target.value).toLowerCase())
            })
        } else if (e.target.name === 'password') {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 7 ? true : false
            })
        } else {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 1 ? true : false
            })
        }

        if (e.target.name === 'password' && e.target.value.length < 8) {
            document.querySelector('#password-length').style.visibility = 'visible';
        }
    }

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    }

    return (
        <>
        <PageMeta>
            <title>Free Trial | Spiracle</title>
            <link rel="canonical" href={window.location.href} />
        </PageMeta>
        <StyledXmasContainer>
            <StyledCheckoutForm id="free-membership-form" onSubmit={handleSubmit}>
                <div>
                    <label>Membership type*</label>
                    <input className='bg-white mb-30'
                        readOnly
                        disabled
                        type="text"
                        name="membershipType"
                        id="membershipType"
                        value="Free 1 month trial" />
                </div>
                
                {!user ?
                    <>
                        <div>
                            <label>Your email*</label>
                            <input className={`bg-white mb-30 ${!validationState.eMail ? 'invalid' : ''}`}
                                type="eMail"
                                name="eMail"
                                id="eMail"
                                value={state.eMail}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div>
                            <label>Your first name*</label>
                            <input className={`bg-white ${!validationState.firstName ? 'invalid' : ''}`}
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={state.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                                <div className='mb-30 input-description'>Your first name will be shown in the email</div>
                        </div>
                        <div>
                            <label>Your last name*</label>
                            <input className={`bg-white mb-30 ${!validationState.lastName ? 'invalid' : ''}`}
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={state.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="country-flags mb-30">
                            <label>Country*</label>
                            <ReactFlagsSelect
                                selected={selected}
                                onSelect={code => setSelected(code)}
                                placeholder="Your country"
                                searchable
                                searchPlaceholder="Search country"
                                selectedSize={16}
                            />
                        </div>
                        <div className="relative">
                            <label>Password*</label>
                            <input className={`bg-white ${!validationState.password ? 'invalid' : ''}`}
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                id="password"
                                value={state.password}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            <i onClick={handlePasswordVisibility} className={`icon-eye password-field--eye ${passwordVisible && "show"}`}></i>
                            <span id="password-length" className="font-12 font-italic font-secondary">Password must be at least 8 characters long</span>
                            <div className='mt-18 input-description'>A combination of letters, numbers and special charactures are best</div>
                        </div>
                    </>
                    : null
                }

                {(!user || (user && !user.newsletter)) && 
                    <Terms className='mt-20'>
                        <div>
                            <input className='c-field-checkbox__input' type="checkbox" name="newsletterSignup" id="newsletterSignup" defaultChecked={isNewsletterSignupChecked} onChange={handleCheckedNewsletterSignup} />
                            <label id='gtm-free-subscription-newsletter' className="c-field-checkbox__label" htmlFor="newsletterSignup"></label>
                            <span>Yes, I would like newsletters and other great writing</span>
                        </div> 
                    </Terms>
                }

                <button
                    id='gtm-subscribe-to-your-free-month'
                    type="submit"
                    disabled={
                        isLoading ||
                        (!user && !validationState.firstName) ||
                        (!user && !validationState.lastName) ||
                        (!user && !validationState.eMail) ||
                        (!user && !validationState.password) ||
                        (!user && !selected.length) ||
                        !isTermsChecked
                    }
                    className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30 letter-spacing-15 ${isLoading ? 'submitting' : ''}`}
                >
                    Subscribe to your free month
                    <SpiracleLogoSmall />
                </button>

                {registerError && 
                    <div className="c-error-message mt-5 mb-15">
                        <div>This email already exists with a user account, please sign in</div>
                    </div>
                }

                {alreadyHaveSubscription && 
                    <div className="c-error-message mt-5 mb-15">
                        <div>You already have an active subscription</div>
                    </div>
                }

                <Terms className='mt-12'>
                    <div>
                        <input className='c-field-checkbox__input' type="checkbox" name="termsConditions" id="termsConditions" checked={isTermsChecked} onChange={handleCheckedTerms} />
                        <label className="c-field-checkbox__label" htmlFor="termsConditions"></label>
                        <span>I agree to the <Link id='gtm-promotion-xmas-free-terms-conditions' to='/info/terms-conditions'>T&amp;Cs</Link></span>
                    </div> 
                </Terms>
            </StyledCheckoutForm>
        </StyledXmasContainer>
        </>
    );
}