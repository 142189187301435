import { Container, Heading, Paragraph, ParagraphBig, StyledPaypalProblem } from './style';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import image from '../../../images/payment-problem.webp';

class PaypalProblem extends Component {
    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        }
    }
    
    render() {
        return (
            <StyledPaypalProblem className="container container__big">
                <Container className="font-italic">
                        <Heading>Oops, looks like there was <br /> a problem with Paypal.</Heading>
                        <img src={image} alt="" />
                        <ParagraphBig>Please try again or another card.</ParagraphBig>
                        <Paragraph>VIEW OUR <Link id='gtm-paypal-problem-faq' to="/faq">FAQ</Link> OR CONTACT OUR <Link id='gtm-paypal-problem-contact-support' to="/info/support">SUPPORT TEAM</Link> TO SOLVE THE PROBLEM.</Paragraph>
                </Container>
            </StyledPaypalProblem>
        );
    }
}

export default PaypalProblem;