import styled from "styled-components/macro";

export const Image = styled.div``;
export const Content = styled.div``;
export const Subtitle = styled.div``;
export const Title = styled.h2``;
export const Author = styled.div``;
export const Paragraph = styled.div``;

export const StyledFeaturedAudiobook = styled.div`
    font-family: 'Gill Sans Nova';
    background: #f9f5e9;
    display: flex;
    justify-content: space-between;
    gap: 40px;


    ${(props) => props.theme.breakpoints.up('md')} {
        flex-direction: row;
        padding: 75px 35px;
        align-items: center;
    }

    ${(props) => props.theme.breakpoints.up('xl')} {
        gap: 40px;
        padding: 80px 95px;
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        margin-left: -16px;
        width: calc(100% + 32px);
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        flex-direction: column;
        gap: 0;
        padding: 25px 20px;
    }

    ${Image} {
        min-width: 585px;
        height: 585px;

        ${(props) => props.theme.breakpoints.down('lg')} {
            min-width: initial;
            width: 296px;
            height: 296px;
            margin: 0 auto 30px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    ${Content} {
        text-align: center;
        max-width: 453px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            margin: 0 auto;
        }
    }

    ${Subtitle} {
        font-size: 18px;
        line-height: 43px;
        letter-spacing: 6px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 13px;
        }
    }

    ${Title} {
        font-family: 'JJannon';
        font-size: 36px;
        line-height: 43px;
        margin: 16px 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 26px;
            margin: 7px 0 5px;
        }
    }

    ${Author} {
        font-size: 26px;
        line-height: 43px;
        margin-bottom: 30px;
        font-weight: 500;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 22px;
            margin-bottom: 15px;
        }
    }

    ${Paragraph} {
        font-family: 'JJannon';
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 35px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 18px;
            line-height: 26px;
        }

        span {
            display: block;
            margin: 22px 0;

            ${(props) => props.theme.breakpoints.down('sm')} {
                margin: 30px;
            }
        }
    }

    a {
        display: block;
    }
    
    button {
        max-width: 320px;

        .price-with-subscription {
            color: #721b00;

            span {
                &:nth-of-type(2) {
                    font-size: 13px;
                }
            }
        }
    }
`;
