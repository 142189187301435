import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyledTitleBlock, StyledChipAlt, StyledText, StyledLink } from 'shared/PageElements/style';

class TitleTextBlock extends Component {    
    render() {           
        return (            
           <StyledTitleBlock audiobooks={this.props.audiobooks} murmurations={this.props.murmurations} editions={this.props.editions} fontItalic={this.props.fontItalic} className={this.props.className}>
                {this.props.type && 
                    <StyledChipAlt>{this.props.type}</StyledChipAlt>
                }
                <h1>{this.props.title}</h1>
                <StyledText>{this.props.text}</StyledText>
                <StyledText>{this.props.subtext}</StyledText>
                {this.props.href &&
                    <StyledLink href={this.props.href} target="_blank" rel="noreferrer" className="underlined">{this.props.link}</StyledLink>
                }
            </StyledTitleBlock>      
        )
    }
}

TitleTextBlock.propTypes = {
    type: PropTypes.string,
    title: PropTypes.any,
    text: PropTypes.any,
    href: PropTypes.string, 
    link: PropTypes.string, 
    className: PropTypes.string
};

export default TitleTextBlock;
