import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';

import { StyledSelect } from './style';

class CustomSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subscriptionType: this.props.location.search === '?plan=monthly' ? 1 : this.props.location.search === '?plan=6monthly' ? 3 : this.props.location.search === '?plan=annually' ? 2 : null,
            invalidSubscriptionType: this.props.location.search === '?plan=monthly' ? false : this.props.location.search === '?plan=6monthly' ? false : this.props.location.search === '?plan=annually' ? false : true,
            isXmasGift: this.props.location.pathname === '/xmas-gift',
            isXmas: this.props.location.pathname === '/xmas',
            optionsGift: [
                { value: 3, label: 'GIFT - £60 six month Premium Membership' },
                { value: 2, label: 'GIFT - £120 year Premium Membership' },
            ],
            options: [
                { value: 1, label: '£12 month Premium Membership' },
                { value: 2, label: '£120 year Premium Membership' },
            ]
        }
    }

    handleChange = (data) => {
        this.setState({
            subscriptionType: data.value,
            invalidSubscriptionType: false
        }, () => {
            const plan = this.state.subscriptionType === 3 ? '6monthly' : this.state.subscriptionType === 2 ? 'annually' : 'monthly'
            const searchParams = new URLSearchParams();
            searchParams.set('plan', plan);

            if (this.state.isXmasGift) {
                this.props.history.replace(`/xmas-gift?${searchParams.toString()}`)
            } 
            
            if (this.state.isXmas) {
                this.props.history.replace(`/xmas?${searchParams.toString()}`)
            }
        });
    }

    render() {
        return (
            <StyledSelect className={this.props.className}>                                   
                <label>{this.props.label}</label>
                <Select
                    defaultValue={this.state.isXmasGift ? 
                        this.state.subscriptionType === 3 ? this.state.optionsGift[0] : this.state.optionsGift[1] : 
                        this.state.subscriptionType === 1 ? this.state.options[0] : this.state.options[1]}
                    onChange={data => this.handleChange(data)}
                    options={this.state.isXmasGift ? this.state.optionsGift : this.state.options}
                    isSearchable={false}
                    placeholder='Select Membership type'
                    className="react-select-container"
                    classNamePrefix="react-select"
                />
            </StyledSelect>
        );
    }
}

export default withRouter(CustomSelect);

