import React from 'react';
import { Link } from 'react-router-dom';

import defaultImage from 'images/defaultCoverImage.svg';

import { StyledMurmuration, Subtitle, Title } from './style';

function Murmuration({ data }) {
    const { image, title, type, entityType, slug } = data;

    return (
        <StyledMurmuration>
            <Link id='gtm-murmuration-item' to={`/${entityType}/${slug}`}>
                <div>
                    <img src={image || defaultImage} alt="" />
                </div>
                
                <div>
                    <Subtitle className='font-uppercase'>{type?.title ? type.title : null}</Subtitle>
                    <Title className='font-italic'>{title}</Title>
                </div>
            </Link>
        </StyledMurmuration>
    )
}
export default Murmuration