import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import AudioBookSingleCarousel from 'components/UI/AudiobookSingle/AudioBookSingleCarousel';

import { StyledSecondEditions, Subtitle, Title, Paragraph } from './style';

function SecondEditions({ data, title }) {
    return (
		<StyledSecondEditions className='text-center'>
			<Subtitle>Discover</Subtitle>
			<Title>{ReactHtmlParser(title)}</Title>
			<Paragraph>
				Our recommendations for you this month. <br /> 
				Select your <span>free audiobooks</span> each month with <Link id='gtm-second-editions-go-to-subscription' to='/subscription'>Premium Membership</Link>.
			</Paragraph>

			<AudioBookSingleCarousel data={data.slice(0, 8)} />

			<Link id='gtm-homepage-view-all-current-highlights' to='/subscriber-selection' className='sa-button-red sa-button-rounded mt-40'>View all Current <span className='font-italic'>Highlights</span></Link>
		</StyledSecondEditions>
		
    )
}
export default SecondEditions