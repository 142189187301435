import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";

import ApiService from "../../../services/api.service";
import { Link } from "react-router-dom";
import PageMeta from "../../../shared/PageMeta/PageMeta";
import ReactFlagsSelect from 'react-flags-select';
import { ReactComponent as SpiracleLogoSmall } from "../../../images/Spiral.svg";
import { connect } from 'react-redux';
import HeaderApp from 'layout/Header/HeaderApp';

class Register extends Component {
    state = {
        registrationSuccessful: false,
        passwordEye: true,
        error: false,
        giftEmail: null,
        giftEmailError: false,
        countryCode: '',
        isAppRegisterPage: window.location.href.includes('app-sign-up'),
    };

    componentDidMount() {
        let email = window.location.href.split('&type=')[0].split('?email=')[1];
        let type = window.location.href.split('&type=')[1];

        if (this.props.user && email) {
            if (type) {
                this.props.history.push(`/get-subscription?email=${email}`)
            } else {
                this.props.history.push(`/get-book?email=${email}`)
            }
        } else if (this.props.user) {
            this.props.history.push('/settings')
        }
        if (email) {
            this.setState({
                giftEmail: email
            })
        }
    }

    /**
     * Handle submit register form
     */
    submitRegisterForm = (values, callbacks) => {
        this.setState(
            {
                error: false,
                giftEmailError: false,
            },
            () => {
                const data = {
                    email: values.registerEmail,
                    password: values.registerPassword,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    newsletter: values.registerNewsletter,
                    countryCode: this.state.countryCode,
                };

                const { setSubmitting } = callbacks;
                if (this.state.giftEmail) {
                    if (values.registerEmail === this.state.giftEmail) {
                        ApiService.registerUser(data)
                            .then((r) => {
                                this.setState(
                                    {
                                        registrationSuccessful: true,
                                    },
                                    () => {
                                        setSubmitting(false);
                                    }
                                );
                            })
                        .catch((e) => {
                            setSubmitting(false);
                            this.setState({
                                error: true,
                            });
                        });
                    } else {
                        setSubmitting(false);
                        this.setState({
                            giftEmailError: true,
                        })
                    }
                } else {
                    ApiService.registerUser(data)
                    .then((r) => {
                        this.setState(
                            {
                                registrationSuccessful: true,
                            },
                            () => {
                                setSubmitting(false);
                            }
                        );
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            error: true,
                        });
                    }); 
                }
            }
        );
    };

    /**
     * Handle toggle password eye
     */
    togglePassword = () => {
        this.setState({
            passwordEye: !this.state.passwordEye,
        });
    };

    /**
     * Handle set flag
     */
    handleSetFlag = countryCode => {
        this.setState({
            countryCode: countryCode
        })
    }

    render() {
        /** FORMIK REGISTER */
        const registerValidationSchema = Yup.object().shape({
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            registerEmail: Yup.string().email("Email must be valid").required("Email is required"),
            registerPassword: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long").max(60, "Password must be less than 60 characters long"),
            registerNewsletter: Yup.bool(),
        });

        const registerInitialValues = {
            firstName: "",
            lastName: "",
            registerEmail: window.location.href.split('&type=')[0].split('?email=')[1] ? window.location.href.split('&type=')[0].split('?email=')[1] : "",
            registerPassword: "",
            registerNewsletter: true,
        };
        //** FORMIK REGISTER END */

        return (
            <div className={`container ${this.state.isAppRegisterPage ? 'container--app-sign-up' : ''} c-register`}>
                <PageMeta>
                    <title>Sign Up | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {this.state.isAppRegisterPage ? <HeaderApp /> : null}

                {!this.state.registrationSuccessful ? (
                    <div className={`pre-form ${this.state.isAppRegisterPage ? 'pre-form--app-sign-up' : ''}`}>
                        <h1 className="c-register__h1 font-secondary font-400 text-center letter-spacing-15">
                            SIGN UP FOR THE FULL <span>SPIRACLE EXPERIENCE</span>
                        </h1>
                        <h2 className="c-register__h2 font-italic text-center font-24 font-400 margin-reset">{this.state.isAppRegisterPage ? 'Make a free account' : 'Sign up for the full experience'}... you'll never look back.</h2>
                        <div className="h-flex h-flex-center">
                            <div className="full-width">
                                <Formik initialValues={registerInitialValues} validateOnMount={true} validationSchema={registerValidationSchema} onSubmit={this.submitRegisterForm}>
                                    {(form) => {
                                        return (
                                            <Form className="c-form">
                                                <div>
                                                    <Field
                                                        className={`bg-white mb-11 ${form.errors && form.errors.firstName && form.touched && form.touched.firstName ? "invalid" : ""}`}
                                                        placeholder="First name"
                                                        type="text"
                                                        name="firstName"
                                                        required
                                                        id="firstName"
                                                        onFocus={() => this.setState({error: false})}
                                                    />
                                                </div>

                                                <div>
                                                    <Field
                                                        className={`bg-white mb-11 ${form.errors && form.errors.lastName && form.touched && form.touched.lastName ? "invalid" : ""}`}
                                                        placeholder="Last name"
                                                        type="text"
                                                        name="lastName"
                                                        required
                                                        id="lastName"
                                                        onFocus={() => this.setState({error: false})}
                                                    />
                                                </div>
                                                <div className="country-flags country-flags--settings mb-11">
                                                    <ReactFlagsSelect
                                                        selected={this.state.countryCode ? this.state.countryCode : null}
                                                        onSelect={code => {this.handleSetFlag(code)}}
                                                        placeholder="Your country"
                                                        searchable
                                                        searchPlaceholder="Search country"
                                                        selectedSize={16}
                                                    />
                                                </div>
                                                <div>
                                                    <Field
                                                        className={`bg-white mb-11 ${form.errors && form.errors.registerEmail && form.touched && form.touched.registerEmail ? "invalid" : ""}`}
                                                        placeholder="Email"
                                                        type="email"
                                                        name="registerEmail"
                                                        required
                                                        id="registerEmail"
                                                        onFocus={() => this.setState({error: false})}
                                                        disabled={window.location.href.split('?email=')[1] ? true: false}
                                                    />
                                                </div>
                                                <div className="password-field">
                                                    <Field
                                                        className={`bg-white ${form.errors && form.errors.registerPassword && form.touched && form.touched.registerPassword ? "invalid" : ""}`}
                                                        placeholder="Choose password"
                                                        type={this.state.passwordEye ? "password" : "text"}
                                                        name="registerPassword"
                                                        required
                                                        id="registerPassword"
                                                        onFocus={() => this.setState({error: false})}
                                                    />
                                                    <i onClick={() => this.togglePassword()} className={`icon-eye password-field--eye ${this.state.passwordEye ? "" : "show"}`}></i>
                                                </div>
                                                <div className="c-error-message mt-5">
                                                    {(form.errors && !form.errors.registerPassword) || (form.touched && !form.touched.registerPassword) ? (
                                                        <span className="font-12 font-gray font-italic font-secondary">Passwords should be at least 8 characters long</span>
                                                    ) : null}
                                                    <ErrorMessage name="firstName" component="div" />
                                                    <ErrorMessage name="lastName" component="div" />
                                                    <ErrorMessage name="registerEmail" component="div" />
                                                    <ErrorMessage name="registerPassword" component="div" />
                                                    {this.state.error ? <div>This email already exists with a user account, please sign in</div> : null}
                                                    {this.state.giftEmailError ? <div>Email must be same</div> : null}
                                                </div>
                                                <div className="c-field-checkbox position-relative mt-21">
                                                    <Field className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset" type="checkbox" name="registerNewsletter" id="registerNewsletter" />
                                                    <label id='gtm-sign-up-newsletter' className="c-field-checkbox__label position-relative font-16 font-secondary" htmlFor="registerNewsletter">
                                                        Yes, I would like newsletters
                                                    </label>
                                                </div>
                                                <div className="mt-30">
                                                    <button
                                                        id='gtm-create-account'
                                                        className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-8 ${form.isSubmitting ? "submitting" : ""}`}
                                                        type="submit"
                                                        disabled={form.isSubmitting || !form.isValid || !this.state.countryCode}
                                                    >
                                                        Create account
                                                        <SpiracleLogoSmall />
                                                    </button>
                                                </div>
                                                {this.state.isAppRegisterPage ? null :
                                                    <div className="text-center mt-5">
                                                        <Link id='gtm-signup-page-login' className="c-btn c-btn__link font-12 margin-center font-secondary font-italic letter-spacing-02 display-inline-block" to="/login">
                                                            Already have an account?
                                                        </Link>
                                                    </div>
                                                }
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <p className={`${this.state.isAppRegisterPage ? 'thank-you-app-sign-up' : ''} text-center font-italic font-18`}>Thank you for signing into Spiracle. <br /> Please check your email for verification link to complete sign up.</p>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user
})

export default connect(mapStateToProps)(Register)