import { Heading, OrderFooter, StyledOrderConfirmation } from '../Order/style';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ApiService from 'services/api.service';
import Loader from 'shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta';
import PaypalProblem from 'components/UI/PaypalProblem/PaypalProblem';
import image from '../../images/hand-apple.webp';

import * as userActionTypes from '../../store/actions/userActions';
import * as discountActionTypes from '../../store/actions/discountActions';

class MembershipConfirmationGift extends Component {

    state = {
        isLoading: false,
        isPaypalFailed: new URLSearchParams(this.props.location.search).get('redirect_status') === 'failed'
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            if (!this.props.user && localStorage.getItem('token')) {
                this.setState({
                    isLoading: true
                })

                ApiService.getUser().then(
                    user => {
                        this.props.onSetUser(user.data);

                        this.setState({
                            isLoading: false
                        })
                    }
                )
            } else {
                if (!this.state.isPaypalFailed) {
                this.props.onSetPaysSomeoneSubscription();
                }
            }

            this.props.onRemoveDiscount();
        }
    }

    render() {
        let content = <Loader />

        if (!this.state.isLoading) {
            if (!this.state.isPaypalFailed) {
            content = <StyledOrderConfirmation className="container container__big">
                            <PageMeta>
                                <title>Successful | Spiracle</title>
                                <link rel="canonical" href={window.location.href} />
                            </PageMeta>
            
                            <Heading className="font-20 lh-29 font-italic">
                                Thank you for gifting <br /> a Subscription to Spiracle! <br />

                                <img src={image} alt="" />
                            </Heading>

                            <p className='mb-53 font-18 text-center'>
                                We have sent confirmation of this order to your email address.
                                <br />
                                Your gift recipient will also receive a welcome email with details on how they access their subscription.
                            </p>

                            <OrderFooter className="text-center">
                                <p className="font-18 mt-45 mb-30">If you have any further questions, <br /> please get in touch with Spiracle <Link id='gtm-membership-confirmation-gift-contact-support' to="/info/support">here</Link></p>
                            </OrderFooter>
                       </StyledOrderConfirmation>
            } else {
                content = content = <PaypalProblem />
            }
        }

        return content
          
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onSetPaysSomeoneSubscription: () => dispatch(userActionTypes.onSetPaysSomeoneSubscription()),
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipConfirmationGift);