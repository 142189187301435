export const LOG_IN = 'LOG_IN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_CART = 'ADD_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const ADD_BOOK_TO_LIBRARY = 'ADD_BOOK_TO_LIBRARY';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION';
export const SET_PAYS_SOMEONE_SUBSCRIPTION = 'SET_PAYS_SOMEONE_SUBSCRIPTION';
export const REMOVE_PAYS_SOMEONE_SUBSCRIPTION = 'REMOVE_PAYS_SOMEONE_SUBSCRIPTION';

export const onAddBookToLibrary = () => {
    return dispatch => {
        dispatch({
            type: ADD_BOOK_TO_LIBRARY
        })
    }
}

export const onSetUser = (payload) => {
    return dispatch => {
        dispatch({ 
            type: LOG_IN,
            user: payload 
        })
    }
}

export const onLogout = (payload) => {
    return dispatch => {
        dispatch({ 
            type: LOGOUT,
        })
    }
}

export const onUpdateUser = (payload) => {
    return dispatch => {
        dispatch({
            type: UPDATE_USER,
            user: payload
        })
    }
}

export const onAddCart = (payload) => {
    return dispatch => {
        dispatch({
            type: ADD_CART,
            cart: payload 
        })
    }
}

export const onRemoveCart = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_CART
        })
    }
}

export const onSetSubscription = (data) => {
    return dispatch => {
        dispatch({
            type: SET_SUBSCRIPTION,
            subscription: data
        })
    }
}

export const onRemoveSubscription = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_SUBSCRIPTION
        })
    }
}

export const onSetPaysSomeoneSubscription = () => {
    return dispatch => {
        dispatch({
            type: SET_PAYS_SOMEONE_SUBSCRIPTION,
        })
    }
}

export const onRemovePaysSomeoneSubscription = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_PAYS_SOMEONE_SUBSCRIPTION,
        })
    }
}