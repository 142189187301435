export const SET_REDEMPTION_CODE = "SET_REDEMPTION_CODE";
export const REMOVE_REDEMPTION_CODE = "REMOVE_REDEMPTION_CODE";
export const SET_REDEMPTION_AUDIOBOOK = "SET_REDEMPTION_AUDIOBOOK";
export const REMOVE_REDEMPTION_AUDIOBOOK = "REMOVE_REDEMPTION_AUDIOBOOK";


export const onSetRedemptionCode = (payload) => {
    return dispatch => {
        dispatch({
            type: SET_REDEMPTION_CODE,
            redemptionCode: payload.redemptionCode,
        })
    }
}

export const onRemoveRedemptionCode = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_REDEMPTION_CODE
        })
    }
}

export const onSetRedemptionAudioBook = (payload) => {
    return dispatch => {
        dispatch({
            type: SET_REDEMPTION_AUDIOBOOK,
            redemptionAudioBook: payload.redemptionAudioBook
        })
    }
}

export const onRemoveRedemptionAudioBook = () => {
    return dispatch => {
        dispatch({
            type: REMOVE_REDEMPTION_AUDIOBOOK
        })
    }
}