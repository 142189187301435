import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { useDispatch, useSelector } from 'react-redux';

import ApiService from '../../services/api.service';

import PageMeta from '../../shared/PageMeta/PageMeta';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Form, Paragraph,  StyledContent, StyledRedemptionContainer, Title } from './style';
import { Terms } from 'pages/Order/style';
import { StyledCheckoutForm } from 'components/UI/Ecommerce/CheckoutForm/style';

import { onSetUser } from 'store/actions/userActions';

import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';
import { onGetLibraryItems } from 'store/actions/libraryActions';


function RedemptionSignUp(props) {

    const [registerError, setRegisterError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState('');
    const [state, setState] = useState({
        eMail: "",
        firstName: "",
        lastName: "",
        password: "",
    });
    const [validationState, setValidationState] = useState({
        eMail: true,
        firstName: true,
        lastName: true,
        password: true,
    })

    const [passwordVisible, setPasswordVisible] = useState(false);

    const [isNewsletterSignupChecked, setIsNewsletterSignupChecked] = useState(true);

    const history = useHistory();

    const dispatch = useDispatch();
    const redemptionCode = useSelector(state => state.redemptionReducer.redemptionCode.code);
    const redemptionAudioBook = useSelector(state => state.redemptionReducer.redemptionAudioBook);

    const spisin = props.match.params.spisin;

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!redemptionAudioBook?.spiSIN || token) {
            history.push(`/redemption/${spisin}`)
        }
    }, [redemptionAudioBook, history, spisin])


    const handleCheckedNewsletterSignup = () => {
        setIsNewsletterSignupChecked(prev => !prev)
    }

     const handleSubmit = (e) => {
        e.preventDefault()

        const dataRegister = {
            email: state.eMail,
            password: state.password,
            firstName: state.firstName,
            lastName: state.lastName,
            countryCode: selected,
            newsletter: isNewsletterSignupChecked,
            terms: true
        }

        setIsLoading(true)

        ApiService.ecommerceRegister(dataRegister).then((r) => {
            localStorage.setItem('token', r.data.token);
            localStorage.setItem('refresh_token', r.data.refresh_token);

            ApiService.getUser().then((res) => {
                dispatch(onSetUser(res.data));

                const data = {
                    audioBook: {
                        spiSIN: spisin
                    }
                }

                ApiService.addToLibraryWithRedemptionCode(redemptionCode, data).then((r) => {
                    ApiService.getLibrary().then(
                        library => {
                            setIsLoading(false)
                            dispatch(onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems']))
                            history.push(`/redemption/${spisin}/welcome`)
                        }
                    ).catch((e) => {
                        console.log(e);
                        setIsLoading(false)
                        history.push(`/redemption/${spisin}/welcome`)
                    })
                }).catch((e) => {
                    console.log(e)
                    setIsLoading(false)

                    if (e.response.status === 422) {
                        history.push({
                            pathname: `/redemption/${spisin}/error`,
                            state: {error: 422}
                        })
                    }
                })
            })
        }).catch(
            e => {
                setRegisterError(true)
                setIsLoading(false);
            }
        )
    }

    const handleChange = e => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });

        if (e.target.name === 'password' && e.target.value.length > 7) {
            document.querySelector('#password-length').style.visibility = 'hidden';
        }
    }

    const handleBlur = e => {
        const value = e.target.value
        if (e.target.name === 'eMail') {
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setValidationState({
                ...validationState,
                [e.target.name]: regex.test(String(e.target.value).toLowerCase())
            })
        } else if (e.target.name === 'password') {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 7 ? true : false
            })
        } else {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 1 ? true : false
            })
        }

        if (e.target.name === 'password' && e.target.value.length < 8) {
            document.querySelector('#password-length').style.visibility = 'visible';
        }
    }

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    }
    
    return (
        <StyledRedemptionContainer>
            <PageMeta>
                <title>Redemption Sign Up | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <AudioBook data={redemptionAudioBook} />

            <StyledContent center>
                <Title>Congratulations</Title>
                <Paragraph>{redemptionAudioBook?.title} <span className='font-secondary'>is ready for you</span>.</Paragraph>

                <Form>
                    <StyledCheckoutForm>
                        <div>
                            <label>Your email</label>
                            <input className={`bg-white mb-30 ${!validationState.eMail ? 'invalid' : ''}`}
                                type="eMail"
                                name="eMail"
                                id="eMail"
                                value={state.eMail}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div>
                            <label>Your first name</label>
                            <input className={`bg-white ${!validationState.firstName ? 'invalid' : ''}`}
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={state.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                                <div className='mb-30 input-description'>Your first name will be shown in the email</div>
                        </div>
                        <div>
                            <label>Your last name</label>
                            <input className={`bg-white mb-30 ${!validationState.lastName ? 'invalid' : ''}`}
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={state.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="country-flags mb-30">
                            <label>Country</label>
                            <ReactFlagsSelect
                                selected={selected}
                                onSelect={code => setSelected(code)}
                                placeholder="Your country"
                                searchable
                                searchPlaceholder="Search country"
                                selectedSize={16}
                            />
                        </div>
                        <div className="relative">
                            <label>Password</label>
                            <input className={`bg-white ${!validationState.password ? 'invalid' : ''}`}
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                id="password"
                                value={state.password}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            <i onClick={handlePasswordVisibility} className={`icon-eye password-field--eye ${passwordVisible && "show"}`}></i>
                            <span id="password-length" className="font-12 font-italic font-secondary">Password must be at least 8 characters long</span>
                            <div className='mt-18 input-description'>A combination of letters, numbers and special charactures are best</div>
                        </div>
                        <Terms className='mt-20'>
                            <div>
                                <input className='c-field-checkbox__input' type="checkbox" name="newsletterSignup" id="newsletterSignup" defaultChecked={isNewsletterSignupChecked} onChange={handleCheckedNewsletterSignup} />
                                <label id='gtm-redemption-sign-up-newsletter' className="c-field-checkbox__label" htmlFor="newsletterSignup"></label>
                                <span>Yes, I would like newsletters and other great writing</span>
                            </div> 
                        </Terms>
                        <button
                            id='gtm-redemption-create-account'
                            type="submit"
                            disabled={
                                isLoading ||
                                !validationState.firstName ||
                                !validationState.lastName ||
                                !validationState.eMail ||
                                !validationState.password ||
                                !selected.length
                            }
                            className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30 letter-spacing-15 ${isLoading ? 'submitting' : ''}`}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Create account
                            <SpiracleLogoSmall />
                        </button>
                        {registerError && (
                            <div className="c-error-message mt-3 mb-15">
                                <div>This email already exists with a user account, please sign in</div>
                            </div>
                        )
            }
                    </StyledCheckoutForm>
                </Form>
            </StyledContent>
        </StyledRedemptionContainer>
    )
}

export default RedemptionSignUp;