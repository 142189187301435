import React, { Component } from 'react';

import ApiService from '../../../services/api.service';
// import DateFormater from '../../../services/format-date.service';
import Loader from '../../../shared/Loader/Loader';
import PageMeta from '../../../shared/PageMeta/PageMeta';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router';

class InfoPages extends Component {
    state = {
        infoPage: null,
        isLoading: true,
    };

    componentDidMount() {
        /**
         * Get info pages
         */
        this.getInfoPages();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState(
                {
                    isLoading: true,
                    infoPage: null,
                },
                () => {
                    /**
                     * Get info pages
                     */
                    this.getInfoPages();
                    window.scrollTo(0, 0);
                }
            );
        }
    }

    /**
     * Handle get info pages
     */
    getInfoPages = () => {
        ApiService.infoPages(this.props.match.params.slug)
            .then((r) => {
                this.setState(
                    {
                        infoPage: r.data,
                    },
                    () => {
                        //hackz
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    if (window.twttr?.widgets) {
                                        window.twttr.widgets.load();
                                    }
                                }
                            );
                        }, 1);
                    }
                );
            })
            .catch((e) => {
                window.location.href = '/404';
            });
    };

    render() {
        let content = <Loader />;

        if (this.state.isLoading) {
            content = <Loader />;
        } else if (this.state.infoPage) {
            content = (
                <>
                    <PageMeta>
                        <title>{this.state.infoPage.title} | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                    <div className='container container__basic-page container--info-pages-landing-page'>
                        <div className='c-info-page'>
                            <div className='c-info-page__header mb-30'>
                                <h1 className='c-info-page__title font-secondary letter-spacing-15 font-25 full-width margin-reset pb-7 font-uppercase'>{this.state.infoPage.title}</h1>
                                {/* <h2 className='c-info-page__updated-at font-secondary font-24 margin-reset font-400 font-italic'>
                                    Updated {DateFormater.formatDate(this.state.infoPage.updatedAt)}
                                </h2> */}
                            </div>
                            <div className='c-info-page__content font-24 font-secondary link-underlined'>{ReactHtmlParser(this.state.infoPage.content)}</div>
                        </div>
                    </div>
                </>
            );
        }

        return content
    }
}

export default withRouter(InfoPages);
