import React, { Component } from 'react';

import { MembershipGiftButtonContainer } from './style';

class MembershipGiftButton extends Component {
    render() {
        return (
            <MembershipGiftButtonContainer>                                   
                <input 
                    type="checkbox" 
                    id="gtm-send-as-a-gift"
                    name="gtm-send-as-a-gift"                                                        
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                />
                {
                    this.props.haveSubscription ? 
                        <label htmlFor="gtm-send-as-a-gift">
                        Already have a subscription, send as a gift? 
                            <i className="icon-checked" />
                            <i className="icon-not-checked" />                                   
                        </label>    
                        : 
                        <label htmlFor="gtm-send-as-a-gift" id="gtm-send-as-a-gift">
                            Send as a gift? 
                            <i className="icon-checked" />
                            <i className="icon-not-checked" />                                   
                        </label>   
                }
                                
            </MembershipGiftButtonContainer>
        );
    }
}

export default MembershipGiftButton;