import * as actionTypes from '../actions/redemptionActions';

const initialState = {
    redemptionCode: {
        code: '',
        bookshop: ''
    },
    redemptionAudioBook: {},
}

const redemptionReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_REDEMPTION_CODE:
            return {
                ...state,
                redemptionCode: action.redemptionCode
            }
        case actionTypes.REMOVE_REDEMPTION_CODE:
            return {
                ...state,
                redemptionCode: ''
            }
        case actionTypes.SET_REDEMPTION_AUDIOBOOK:
            return {
                ...state,
                redemptionAudioBook: action.redemptionAudioBook
            }
        case actionTypes.REMOVE_REDEMPTION_AUDIOBOOK:
            return {
                ...state,
                redemptionAudioBook: ''
            }
        default:
            return state;
    }
}

export default redemptionReducer;