import { Link } from 'react-router-dom';
import PageMeta from '../../shared/PageMeta/PageMeta';
import React from 'react';
import classes from './Page403.module.scss';
import ill403 from '../../images/403.svg';

export default function Page403() {
    return (
        <div className={`${classes.Page403} container text-center`}>
            <PageMeta>
                <title>Access denied | Spiracle</title>
                <link rel="canonical" href={window.location.href} />
            </PageMeta>

            <img alt='Page not accessable' src={ill403} />

            <div className={classes.Page403__Actions}>
                <Link id='gtm-403-go-to-homepage' className='c-btn c-btn__link font-16 margin-center font-secondary letter-spacing-15' to='/'>
                    Go to the Homepage
                </Link>
            </div>
        </div>
    );
}
