import { Amount, Button, Cover, StyledPayment } from './style';
import React, { Component } from 'react';

import CheckoutForm from 'components/UI/Ecommerce/CheckoutForm/CheckoutForm';
import Cookies from 'js-cookie';
import { Elements } from "@stripe/react-stripe-js";
import PageMeta from '../../../shared/PageMeta/PageMeta';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import membershipImage from '../../../images/membership-image.svg';
import config from '../../../config';

class Payment extends Component {

    constructor(props) {
        super(props);
        this.stripePromise = loadStripe(config.stripe.STRIPE_PUBLISH_KEY)
    }

    componentDidMount() {
        // if (!this.props.cart.length && !this.props.location.search.includes('membership')) {
        //     //redirect to audiobooks if there is nothing in cart
        //     this.props.history.push('/audiobooks?page=1&itemsPerPage=12')
        // }
    }

    render() {
        let bookTypeOne;
        if (this.props.cart.length > 0) {
            if (this.props.cart[this.props.cart.length - 1].audioBook) {
                bookTypeOne = 'audioBook'
            } else {
                bookTypeOne = 'eBook'
            }
            var lastCartItemImage = this.props.cart[this.props.cart.length - 1][bookTypeOne].cover;
            var lastCartItemImageAlt = this.props.cart[this.props.cart.length - 1][bookTypeOne].coverAlt && this.props.cart[this.props.cart.length - 1][bookTypeOne].coverAlt;
        }

        let bookTypeTwo;
        if (this.props.cart.length > 1) {
            if (this.props.cart[this.props.cart.length - 2].audioBook) {
                bookTypeTwo = 'audioBook'
            } else {
                bookTypeTwo = 'eBook'
            }
            var beforeLastCartItemImage = this.props.cart[this.props.cart.length - 2][bookTypeTwo].cover;
            var beforeLastCartItemImageAlt = this.props.cart[this.props.cart.length - 2][bookTypeTwo].coverAlt && this.props.cart[this.props.cart.length - 2][bookTypeTwo].coverAlt;
        }

        let totalCostShoppingBag = this.props.location && this.props.location.state && this.props.location.state.totalCostShoppingBag

        let paymentAmount = 0
        if (this.props.cart) {
            if (this.props.cart.length) {
                this.props.cart.forEach((book) => {
                    let bookInfo                    
                    if (book.audioBook) {
                        bookInfo = book.audioBook
                    } else {
                        bookInfo = book.eBook
                    }
                    paymentAmount = paymentAmount + bookInfo.price
                })
            }
        }

        let paymentAmountBookDiscount = paymentAmount * ((100 - this.props.discount) / 100)

        const appearance = {
            theme: 'stripe',
        };
        const customFonts = [{
            src: 'url(https://ed-spiracle-public.s3.amazonaws.com/fonts/gill-sans-nova-book.ttf)',
            family: 'Gill Sans Nova',
            style: 'normal',            
            weight: '400',
        }];

        const options = {
            appearance,
            fonts: customFonts
        };

        let membership = (this.props.location && this.props.location.state && this.props.location.state.subscription) || this.props.xmas;
        let month = this.props.location && this.props.history.location.state && this.props.history.location.state.subscription === 'month';
        let updateCard = this.props.location && this.props.location.state && this.props.location.state.update

        let amountMembership;
        if (month) {
            amountMembership = this.props.membership.month * ((100 - this.props.discount) / 100)
        } else {
            if (this.props.xmasPlan === 3) {
                amountMembership = this.props.membership.sixMonth * ((100 - this.props.discount) / 100)
            } else if (this.props.xmasPlan === 1) {
                amountMembership = this.props.membership.month * ((100 - this.props.discount) / 100)
            } else {
                amountMembership = this.props.membership.year * ((100 - this.props.discount) / 100)
            }
        }

        return (
            <>
            {this.props.promotion ? null :
                <PageMeta>
                    <title>Payment | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
            } 
                <StyledPayment oneImage={this.props.cart.length === 1 || membership} membership={membership} promotion={this.props.promotion}>
                    {this.props.membershipPromo ? null : !updateCard ? (
                        (membership) ? (
                            <>
                                <Cover>
                                    <img src={membershipImage} alt='Spiracle logo' />
                                </Cover>
                                
                                <Amount className={`${this.props.discount ? 'old-price' : ''}`}><span>£</span>
                                    {month ? 
                                        this.props.membership.month + '/monthly' : 
                                        this.props.xmasPlan === 3 ? this.props.membership.sixMonth + '/6 month' : this.props.xmasPlan === 1 ? this.props.membership.month + '/monthly' : this.props.membership.year + '/annually'}
                                </Amount>
                                {this.props.discount ? (
                                    <Amount className={`${this.props.discount ? 'new-price' : ''}`}><span>£</span>
                                        {month ? 
                                            amountMembership.toFixed(2) + '/monthly' : 
                                            this.props.xmasPlan === 3 ? amountMembership.toFixed(2) + '/6 month' : this.props.xmasPlan === 1 ? amountMembership.toFixed(2) + '/monthly' : amountMembership.toFixed(2) + '/annually'}
                                    </Amount>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Cover>
                                    {this.props.cart.length > 1 && <img src={beforeLastCartItemImage} alt={beforeLastCartItemImageAlt} />}
                                    {this.props.cart.length > 0 && <img src={lastCartItemImage} alt={lastCartItemImageAlt} />}
                                </Cover>

                                {this.props.user && this.props.user.currentSubscription && !this.props.discount && (paymentAmount !== totalCostShoppingBag) ? (
                                    <>
                                        <Amount className='old-price'><span>£</span>{paymentAmount}</Amount>
                                        <Amount className='new-price'><span>£</span>{totalCostShoppingBag}</Amount>
                                    </>
                                ) : null}

                                {this.props.user && !this.props.user.currentSubscription && !this.props.discount ? (
                                    <Amount><span>£</span>{paymentAmount}</Amount>
                                ) : null}

                                {this.props.user && this.props.user.currentSubscription && this.props.discount && (paymentAmount !== totalCostShoppingBag) ? (
                                    <>
                                        <Amount className='old-price'><span>£</span>{paymentAmount}</Amount>
                                        <Amount className='old-price'><span>£</span>{totalCostShoppingBag}</Amount>
                                    </>
                                ) : null}

                                {!this.props.user ? (
                                    <>
                                        <Amount className={`${this.props.discount ? 'old-price' : ''}`}><span>£</span>{paymentAmount}</Amount>
                                    </>
                                ) : null}

                                {this.props.discount ? (
                                    <>
                                        {this.props.user && !this.props.user.currentSubscription && <Amount className='old-price'><span>£</span>{paymentAmount}</Amount>}
                                        <Amount className='new-price'><span>£</span>{Math.ceil(paymentAmountBookDiscount)}</Amount>
                                    </>
                                ) : null}
                            </>
                        )
                    ) : null}

                    {!this.props.user && !this.props.membershipPromo && <Button id="open-auth" className="font-12 font-italic font-secondary letter-spacing-02">
                                            Already have an account?
                                        </Button>}
                
                    <Elements 
                        options={options} 
                        stripe={this.stripePromise} 
                    >
                        <CheckoutForm 
                            user={this.props.user} 
                            cartUuid={Cookies.get('cartId')} 
                            membershipPromo={this.props.membershipPromo} 
                            membershipPromoCode={this.props.membershipPromoCode} 
                            xmas={this.props.xmas}
                            xmasGift={this.props.xmasGift}
                            xmasPlan={this.props.xmasPlan}
                            cart={this.props.cart}
                        />
                    </Elements> 
                </StyledPayment>
            </>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    user: state.userReducer.user,
    membership: state.membershipPriceReducer,
    discount: state.discountReducer.relativeDiscount,
  })

export default connect(mapStateToProps)(Payment);