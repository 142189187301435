import React, { Component } from "react";

import PropTypes from "prop-types";
import { StyledPlayerLayout } from "layout/Player/style";

class LayoutPlayer extends Component {
    render() {
        const { children } = this.props;

        return (
            <StyledPlayerLayout>
                {children}
            </StyledPlayerLayout>
        );
    }
}

LayoutPlayer.propTypes = {
  children: PropTypes.any,
};

export default LayoutPlayer;
