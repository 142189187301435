import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';

export default class DropdownItem extends Component {
    state = {
        isOpen: false,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeTab !== this.props.activeTab) {
            this.setState({
                isOpen: false,
            });
        }
    }

    /**
     * Handle toggle visibility of item
     */
    handleToggleItem = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    render() {
        return (
            <div className='c-faq__item font-25 font-secondary'>
                <div onClick={() => this.handleToggleItem()} className={`c-faq__item--question ${this.state.isOpen ? 'open' : ''} mb-22 h-flex h-flex-between h-flex-middle`}>
                    {this.props.question} <i className='icon-arrow'></i>
                </div>
                <div className={`c-faq__item--answer font-italic ${this.state.isOpen ? 'open' : ''}`}>{ReactHtmlParser(this.props.answer)}</div>
            </div>
        );
    }
}
