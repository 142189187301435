import * as actionTypes from '../actions/bookActions';

const initialState = {
    book: null,
    widgetPlayer: false,
    currentBook: null,
    audioBookInCart: false,
    audioBookEBookInCart: false,
    eBookInCart: false,
    eBookAudioBookInCart: false,
}

const bookReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.BOOK_INFO:
            return {
                ...state,
                book: action.book
            }
        case actionTypes.WIDGET_PLAYER:
            return {
                ...state,
                widgetPlayer: true
            }
        case actionTypes.TURN_OFF_WIDGET_PLAYER:
            return {
                ...state,
                widgetPlayer: false
            }
        case actionTypes.CLEAR_BOOK:
            return {
                ...state,
                book: null,
            }
        case actionTypes.GET_CURRENT_BOOK:
            return {
                ...state,
                currentBook: action.book
            }
        case actionTypes.REMOVE_CURRENT_BOOK:
            return {
                ...state,
                currentBook: null
            }
        case actionTypes.BOOK_ADDED_TO_CART:
            return {
                ...state,
                [action.bookType]: true
            }
        case actionTypes.BOOK_REMOVED_TO_CART:
            let buttonType = null;
            if (state.currentBook) {
                if (window.location.href.includes('audiobooks')) {
                    if (action.book && action.book.audioBook) {
                        //this is audioBook
                        if (action.book.audioBook.slug === state.currentBook.slug) {
                            buttonType = 'audioBookInCart'
                        }
                    } else {
                        //this is ebook
                        if (action.book && action.book.eBook.slug === state.currentBook.eBook.slug) {
                            buttonType = 'audioBookEBookInCart'
                        }
                    }
                } else {
                    if (state.currentBook) {
                        if (action.book && action.book.eBook) {
                            //this is ebook
                            if (action.book.eBook.slug === state.currentBook.slug) {
                                buttonType = 'eBookInCart'
                            }
                        } else {
                            // this is audiobook
                            if (action.book && action.book.audioBook.slug === state.currentBook.audioBook.slug) {
                                buttonType = 'eBookAudioBookInCart'
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                [buttonType]: false
            }
        case actionTypes.EPUB_DOWNLOAD_READY:
            let copiedCurrentBook
            if (window.location.href.includes(action.ePub.slug)) {
                copiedCurrentBook = {
                    ...state.currentBook,
                    download: action.ePub.download
                }
            } else {
                copiedCurrentBook = {
                    ...state.currentBook
                }
            }
            return {
                ...state,
                currentBook: copiedCurrentBook
            }
        case actionTypes.REMOVE_ALL_IN_CART_ITEMS:
            return {
                ...state,
                audioBookInCart: false,
                audioBookEBookInCart: false,
                eBookInCart: false,
                eBookAudioBookInCart: false,
            }

        case actionTypes.ADD_AUDIO_FILES:
            const copiedAudioFilesCurrentBook = {
                ...state.currentBook,
                audioFiles: action.audioFiles,
                download: action.download
            }
            return {
                ...state,
                currentBook: copiedAudioFilesCurrentBook
            }
        
        case actionTypes.REMOVE_BOOK_ON_LOGOUT:
            return {
                ...state,
                book: null,
                currentBook: null,
                widgetPlayer: false,
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export default bookReducer;