import styled from "styled-components/macro";

export const EmptyCart = styled.div`    
    font-size: 30px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: ${(props) => props.inDropdown ?  '0' : " 20px"};
    padding: ${(props) => props.inDropdown ?  '80px 0 0' : " 100px 20px 0"};

    p {
        line-height: 21px;
        max-width: 320px;
        margin: 0 auto;
    }

    span {
        display: block;
        margin-top: 25px;

        &:last-of-type {
            line-height: 1.2;
        }
    }

    .c-btn {
        margin-top: ${(props) => props.inDropdown ?  '230px' : "240px"};
        ${(props) => props.theme.breakpoints.down("md")} {
            margin-top: ${(props) => props.bag && "186px"}; 
            margin-top: ${(props) => props.library && "83px"}; 
        }
    }
`;