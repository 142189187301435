import styled  from "styled-components/macro";

export const StyledMembership = styled.div``;
export const Price = styled.div``;

export const Cta = styled.div`
    display: flex;
    font-family: Gill Sans Nova;

    .c-btn {
        padding: 11px 16px 9px;

        + .c-btn {
            margin-left: 15px;
        }
    }

    ${Price} {                
        font-size: 20px;                
        line-height: 22px;
        letter-spacing: 0.05em; 
        font-weight: 300;              
    }

    span {
        display: block;                
        font-size: 12px;                
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }            
`;

export const List = styled.div``;
export const ListItem = styled.div``;
export const Note = styled.p``;
export const Title = styled.div``;
export const Text = styled.div``;
export const Type = styled.div``;
export const Details = styled.div``;
export const GiftContainer = styled.div``;
export const Header = styled.div``;

export const Holder = styled.div` 
    background-color: #F6E6B6;
    margin-top: ${(props) => props.isMemberOnlyBlock ? "24px" : "-20px"};    

    ${(props) => props.theme.breakpoints.down("sm")} {
        padding-top: ${(props) => !props.isMemberOnlyBlock && "50px"}; 
    }

    ${StyledMembership} {
        max-width: 375px;
        margin: 0 auto;
        text-align: center;
        padding: 55px 22px;

        &:first-of-type {
            padding-bottom: ${(props) => props.promotion ? "30px" : "55px"};  
        }

        ${(props) => props.theme.breakpoints.down("md")} {
            padding-top: ${(props) => props.isMemberOnlyBlock && "35px"}; 
        }

        img {
            margin: 10px 0 10px;
            width: 100%;
        }

        h1, h5 {
            font-family: Gill Sans Nova;
            font-size: 12px;           
            line-height: 18px;
            letter-spacing: 0.15em;   
            text-transform: uppercase;
            margin-bottom: 28px;

            &.grey {
                color: rgba(0, 0, 0, 0.49);
                a {
                    color: rgba(0, 0, 0, 0.49);
                }
            }
        }

        h2 {           
            font-size: 36px;
            font-style: italic;           
            line-height: 41px;
            letter-spacing: 0.02em;           
            margin-bottom: 42px;

            + span {
                font-size: 12px;
                line-height: 24px;
                font-family: 'Gill Sans Nova', sans-serif;
                text-transform: uppercase;
                letter-spacing: 0.10em;
                display: inline-block;
                margin-bottom: 25px;
            }
        }

        p {           
            font-size: 20px;            
            line-height: 24px;              
            margin-bottom: 15px;  
            max-width: 330px;       
        }

        .c-btn {max-width: 313px;}

        ${List} {
            margin-bottom: 22px;
        }

        ${ListItem} {            
            display: flex;
            text-align: left;
            flex-wrap: wrap;
            padding-top: 16px;

            &:after {
                content: "";
                flex-basis: 100%;
                height: 1px;
                width: 100%;
                border-bottom: 1px solid rgba(0,0,0,0.5);
            }

            &:first-child {
                padding-top: 0;
            }

            &:nth-of-type(2) {
                ${Text} {            
                    height: 46px;  
                }
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }

            i {
                font-size: 28px;
                margin-right: 18px;
            }

            div {
                flex: 1 0 0;
            }
            
        }

        ${Note} {
            font-family: 'Gill Sans Nova', sans-serif; 
            margin: 0 0 40px;
        }

        ${Title} {            
            font-size: 20px;            
            line-height: 24px;             
            margin-bottom: 10px;         
        }

        ${Text} {            
            font-size: 16px;
            font-style: italic;            
            line-height: 22px;           
            margin-bottom: 16px;    
            max-width: 280px;   
        }
    }

`;

export const MembershipDetailsContainer = styled.div`
    margin-top: -20px;
    padding: 37px 0;
    font-family: 'Gill Sans Nova';
    background: #F6E6B6;

    ${(props) => props.theme.breakpoints.down("sm")} {
        margin-top: 80px;
    }

    ${(props) => props.theme.breakpoints.up("xxl")} {
        height: calc(100vh - 389px);
    }

    ${Type} {
        text-transform: lowercase;
        display: inline-block;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    ${Details} {
        max-width: 655px;
        margin: 0 auto;
        padding: 0 22px;

        button {
            display: block;
            width: 313px !important;
            margin: 0 auto;
            padding: 14px 18px;
            letter-spacing: 0.10em;

            + button,
            &.cancel-btn {
                border: none;
                outline: none;
                background: transparent;
                text-decoration: underline;
                letter-spacing: 0.15em;
                cursor: pointer;
            }
        }

        ${Header} {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }
    }

    ${GiftContainer} {
        max-width: 331px;
        margin: 0 auto;

        > span {
            letter-spacing: 0.01em;
        }
    }
`;

/*
* XMAS
*/
export const XmasCta = styled.div`
    font-family: Gill Sans Nova;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 531px;
    margin-left: -100px;

    ${(props) => props.theme.breakpoints.down("sm")} {
        flex-direction: column;
        width: 100%;
        margin-left: 0;
    }

    .c-btn {
        padding: 11px 5px 9px;
    }

    ${Price} {                
        font-size: 20px;                
        line-height: 22px;
        letter-spacing: 0.05em; 
        font-weight: 300;    
        
        div {
            font-size: 12px;
        }
    }

    span {
        display: block;                
        font-size: 10px;                
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }            
`;

export const XmasList = styled.div`
    margin-bottom: 22px;
`;

export const XmasListItem = styled.div`
    display: flex;
    gap: 40px;
    text-align: left;
    flex-wrap: wrap;
    padding-top: 16px;
`;

export const XmasTitle = styled.div`
    font-size: 16px;            
    line-height: 24px;             
    margin-bottom: 10px;   
    flex: 1;
`;