import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    const scrollTop = () => window.scrollTo(0, 0);

    return () => scrollTop()
  }, [history.location.pathname])
  

  return (null);
}

export default withRouter(ScrollToTop);