import Drawer from '@material-ui/core/Drawer';
import styled from "styled-components/macro";

export const ApplyButton = styled.button``;
export const CloseButton = styled.span``;
export const SwipersHolder = styled.div``;
export const ButtonsHolder = styled.div``;
export const Cta = styled.div``;

export const StyledDrawer = styled(Drawer)`
    font-family: Gill Sans Nova;
    color: #F4EEDC;
    background-color: rgba(0, 0, 0, 0.8);

    .MuiPaper-root {
        background-color: transparent;
        color: #F4EEDC;
        top: 0;
    }

    .MuiBackdrop-root {
        background-color: transparent;
    }

    h2 {        
        font-size: 16px;        
        line-height: 19px;
        letter-spacing: 0.14em;
        text-align: center;
        text-transform: uppercase; 
        color: #F4EEDC;
        margin: 30px 0;

        ${(props) => props.theme.breakpoints.up("sm")} {
            margin: 160px 0 50px;
        }
    }

    ${SwipersHolder} {
        display: flex;
        justify-content: center;
        overflow: hidden; 
    }

    .hours-swiper {
        height: 280px;
        width: 171px;
    }

    .minutes-swiper {
        height: 280px;
        width: 171px;
    }

    .speed-swiper {
        height: 280px;
        width: 342px;

        .swiper-slide {
            letter-spacing: 0.14em;
        }   
    }

    .chapters-swiper {
        height: 360px;
        width: 342px;
        .swiper-slide {
            padding: 0 20px;
        }       
    }

    .swiper-slide {     
        line-height: 40px;
        text-align: center;
        font-size: 16px;      
        letter-spacing: 0.03em;   
        align-items: center;
       
        div {
            span {
                &:first-of-type {
                    width: 80%;
                    text-align: left;

                    pre {
                        font-family: "Gill Sans Nova";
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &-active {
            background: rgba(196, 196, 196, 0.3);
        }

    }   

    .minutes-swiper, .hours-swiper, .speed-swiper {
        .swiper-slide {
            color: rgba(244, 238, 220, 0.4);

            &-next + div {
                color: rgba(244, 238, 220, 0.6);
            }
           
            &-next,
            &-prev {
                color: rgba(244, 238, 220, 0.8);
            }            

             &-active {
                color: #F4EEDC;
            }

        }
    }

    button {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
    }

    ${ButtonsHolder} {
        position: fixed;
        bottom: 157px;
        left: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width: 100%;
        height: 104px;
        margin: 0 auto;
        padding: 0 20px;

        button {
            border: 1px solid #F9F7ED;                
            border-radius: 20px;
            color: #F4EEDC;           
            font-size: 16px;              
            width: 46%;       
            letter-spacing: 0.02em; 
            background: transparent;                   

            &:hover, &:active, &.active {
                background: #F9F7ED;   
                color: #000000;             
            }
        }      
        
        ${(props) => props.theme.breakpoints.up("sm")} {
            width: 310px;
            padding: 0;

            button {
                width: 145px;
            }
        }
    }   
    
    ${Cta} {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding-bottom: 45px;

        ${ApplyButton} {
            width: 160px;           
            margin: 28px auto 27px;           
            background: #F9F7ED;
            border: 1px solid #F9F7ED;        
            font-size: 12px;     
            letter-spacing: 0.15em;
            text-transform: uppercase;
        }

        ${CloseButton} {
            color: #F9F7ED;
            text-decoration: underline;
            font-size: 12px;      
            line-height: 17px;
            letter-spacing: 0.1em;   
            text-transform: uppercase;  
            text-align: center;
            cursor: pointer;
        }
    }
    
`;
