export function getNamesFromArray(names) {
    if(names && names.length) {
        const count = names.length;
        if (count === 0) {
            return "";
        }
        if (count === 1) {
            return names[0].firstName + " " + names[0].lastName;
        }
        let outString = "";
        names.map((name, index) => {
            if (index === count - 1) {
                outString = outString + name.firstName + " " + name.lastName;
            } else {
                outString = outString + name.firstName + " " + name.lastName + ", ";
            }

            return null
        });

        return outString;
    }
}