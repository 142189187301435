import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import AudioBookListItem from '../AudioBook/AudioBookListItem';
import Grid from "@material-ui/core/Grid";
import Slider from 'react-slick';
import { withRouter } from 'react-router';

class AudioBookSingleCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth});
    }
    
    render() {

        // number of slides

        let numOfSlidesMobile = 2.2

        if(this.state.width <= 768 && this.props.data?.length === 1) {
            numOfSlidesMobile = 1
        }

        // show shadow

        let showShadow = true;

        if(this.state.width < 768 && this.props.data?.length <= 2) {
            showShadow = false
        }
        if(this.state.width >= 768 && this.props.data?.length <= 4) {
            showShadow = false
        }

        let dragging;
        const settings = {
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            beforeChange: () => dragging = true,
            afterChange: () => dragging = false,
            swipeToSlide: true,
            responsive: [{
                breakpoint: 768,

                settings: {
                    swipeToSlide: false,
                    slidesToShow: numOfSlidesMobile,
                }
            }]
        };

        return (
            this.props.data?.length ? 
            <div className='c-audio-book-single-carousel-container'>
                {this.props.link ? 
                    <div className='c-audio-book-single-carousel-heading'>
                        {this.props.title ? <h2 className='letter-spacing-02'>{this.props.title}</h2> : null}
                        <Link id={`gtm-carousel-view-all-${this.props.gtmTitle ? this.props.gtmTitle.toString().toLowerCase().replace(/ /g, '-') : 'no-gtm-title'}`} to={this.props.link} className='font-italic'>View all</Link>
                    </div> : 
                    this.props.title ? <h2 className='font-italic letter-spacing-02'>{this.props.title}</h2> : null}
                <div className={`c-audio-book-single-carousel ${showShadow && 'c-audio-book-single-carousel--show-shadow'}`}>
                    {this.state.width >= 768 ? (
                        this.props.data.length > 4 ? (
                            <Slider {...settings}>
                                {this.props.data.map((book, index)=>(                      
                                    <AudioBookListItem
                                        key={`book-${index}`}                
                                        title={book.title}
                                        authors={book.authors}
                                        imgSrc={book.cover}
                                        imgAlt={book.coverAlt}
                                        price={book.price}
                                        subscriptionPrice={book.subscriptionPrice}
                                        audioBook={book.audioBook}
                                        eBook={book.eBook}
                                        link={`/${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}/${book.slug}`}
                                        type={`${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}`}
                                        className="carousel-book-item"
                                        onClick={(e) => dragging && e.preventDefault()}
                                        promotion={this.props.promotion}
                                    />                         
                                ))}
                            </Slider>
                        ) : (
                            <Grid container spacing={2} justifyContent='center'>
                                {this.props.data.map((book, index) => {
                                    return <Grid item xs={6} md={3} lg={3} key={`audioBook-${index}`}>
                                                <AudioBookListItem
                                                    key={`book-${index}`}                
                                                    title={book.title}
                                                    authors={book.authors}
                                                    imgSrc={book.cover}
                                                    imgAlt={book.coverAlt}
                                                    price={book.price}
                                                    subscriptionPrice={book.subscriptionPrice}
                                                    audioBook={book.audioBook}
                                                    eBook={book.eBook}
                                                    link={`/${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}/${book.slug}`}
                                                    type={`${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}`}
                                                    className={`carousel-book-item ${this.props.data.length < 5 && 'carousel-book-item--full-width'}`}
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                />
                                            </Grid>
                                })}
                            </Grid>
                        )
                    ) : this.props.data.length > 2 ? (
                            <Slider {...settings}>
                                {this.props.data.map((book, index)=>(                      
                                    <AudioBookListItem
                                        key={`book-${index}`}                
                                        title={book.title}
                                        authors={book.authors}
                                        imgSrc={book.cover}
                                        imgAlt={book.coverAlt}
                                        price={book.price}
                                        subscriptionPrice={book.subscriptionPrice}
                                        audioBook={book.audioBook}
                                        eBook={book.eBook}
                                        link={`/${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}/${book.slug}`}
                                        type={`${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}`}
                                        className={`carousel-book-item ${this.props.data.length > 2 && 'carousel-book-item--slider'}`}
                                        onClick={(e) => dragging && e.preventDefault()}
                                    />                         
                                ))}
                            </Slider>
                        ) : (
                            <Grid container spacing={2} justifyContent='center'>
                                {this.props.data.map((book, index) => {
                                    return <Grid item xs={6} md={4} lg={3} key={`audioBook-${index}`}>
                                                <AudioBookListItem
                                                    key={`book-${index}`}                
                                                    title={book.title}
                                                    authors={book.authors}
                                                    imgSrc={book.cover}
                                                    imgAlt={book.coverAlt}
                                                    price={book.price}
                                                    subscriptionPrice={book.subscriptionPrice}
                                                    audioBook={book.audioBook}
                                                    eBook={book.eBook}
                                                    link={`/${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}/${book.slug}`}
                                                    type={`${book.entityType === 'audiobooks' ? 'audiobooks' : 'ebooks'}`}
                                                    className={`carousel-book-item ${this.props.data.length === 1 && 'carousel-book-item--only-one'}`}
                                                    onClick={(e) => dragging && e.preventDefault()}
                                                />
                                            </Grid>
                                })}
                            </Grid>
                        )}
                </div>
            </div> : null
        );
    }
}

export default withRouter(AudioBookSingleCarousel)