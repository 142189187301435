import * as Yup from "yup";
import * as actionTypes from "../../../store/actions/userActions";
import * as cartActionTypes from '../../../store/actions/cartActions';
import * as libraryActionTypes from '../../../store/actions/libraryActions';

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";

import ApiService from "../../../services/api.service";
import { Link } from "react-router-dom";
import PageMeta from "../../../shared/PageMeta/PageMeta";
import { ReactComponent as SpiracleLogoSmall } from "../../../images/Spiral.svg";
import { connect } from "react-redux";
import cookie from 'react-cookies'
import { withRouter } from "react-router";

class Login extends Component {
    state = {
        passwordEye: true,
        error: false,
    };

    componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) {
            if (window.location.href.split('&type=')[1] === 'subscription') {
                this.props.history.push('/subscription-details')
            } else {
                this.props.history.push('/settings')
            }
        }
    }

    /**
     * Handle submit login form
     */
    submitLoginForm = (values, callbacks) => {
        this.setState(
            {
                error: false,
            },
            () => {
                const data = {
                    email: values.loginEmail,
                    password: values.loginPassword,
                };
                const { setSubmitting } = callbacks;

                ApiService.login(data)
                    .then((r) => {
                        localStorage.setItem("token", r.data.token);
                        localStorage.setItem("refresh_token", r.data.refresh_token);
                        ApiService.getUser().then((r) => {
                            let cartId = cookie.load('cartId')
                            if (cartId) {
                                const data = {
                                    cartUuid: cartId
                                }
                                ApiService.updateCartId(r.data.uuid, data).then(
                                    r => {
                                        this.props.onSetUser(r.data);
                                        cookie.remove('cartId');

                                        ApiService.getCartItems(cartId).then(
                                            cart => {
                                            this.props.onGetCartItems(cart.data['hydra:member'])
                                            ApiService.getLibrary().then(
                                                library => {
                                                    this.setState({
                                                        isLoading: false
                                                    }, () => {
                                                    this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                    this.props.history.push('/')
                                                })
                                                }
                                            )
                                            }
                                        )
                                    }
                                )
                            } else {
                                if (r.data.cart && r.data.cart.uuid) {
                                    ApiService.getCartItems(r.data.cart.uuid).then(
                                        cart => {
                                            this.props.onGetCartItems(cart.data['hydra:member'])
                                            ApiService.getLibrary().then(
                                                library => {
                                                this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                this.setState({
                                                    isLoading: false
                                                }, () => {
                                                    this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                                    this.props.history.push('/')
                                                })
                                                }
                                            )
                                        }
                                    )
                                } else {
                                    ApiService.getLibrary().then(
                                        library => {
                                        this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                        this.setState({
                                            isLoading: false
                                        }, () => {
                                            this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                            this.props.history.push('/')
                                        })
                                        }
                                    )
                                }
                                this.props.onSetUser(r.data);
                            }
                        });
                        setSubmitting(false);
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        this.setState({
                            error: true,
                        });
                    });
            }
        );
    };

    /**
     * Handle toggle password eye
     */
    togglePassword = () => {
        this.setState({
            passwordEye: !this.state.passwordEye,
        });
    };

    render() {
        /** FORMIK LOGIN */
        const loginValidationSchema = Yup.object().shape({
            loginEmail: Yup.string().email("Email must be valid").required("Email is required"),
            loginPassword: Yup.string().required("Password is required"),
        });

        const loginInitialValues = {
            loginEmail: "",
            loginPassword: "",
        };
        //** FORMIK LOGIN END */

        return (
            <div className="c-login container container__basic-page c-homepage c-homepage--main-content">
                <PageMeta>
                    <title>Login | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>
                <h1 className="c-login__heading font-secondary font-400 text-center letter-spacing-15">
                    LOG IN FOR THE FULL <span>SPIRACLE EXPERIENCE</span>
                </h1>
                <div className="h-flex h-flex-center">
                    <div className="full-width">
                        <Formik initialValues={loginInitialValues} validateOnMount={true} validationSchema={loginValidationSchema} onSubmit={this.submitLoginForm}>
                            {(form) => {
                                return (
                                    <Form className="c-form">
                                        <div>
                                            <Field
                                                className={`bg-white mb-11 ${form.errors && form.errors.loginEmail && form.touched && form.touched.loginEmail ? "invalid" : ""}`}
                                                placeholder="Email"
                                                type="email"
                                                name="loginEmail"
                                                required
                                                id="loginEmail"
                                            />
                                        </div>

                                        <div className="password-field">
                                            <Field
                                                className={`bg-white ${form.errors && form.errors.loginPassword && form.touched && form.touched.loginPassword ? "invalid" : ""}`}
                                                placeholder="Password"
                                                type={this.state.passwordEye ? "password" : "text"}
                                                name="loginPassword"
                                                required
                                                id="loginPassword"
                                            />
                                            <i onClick={() => this.togglePassword()} className={`icon-eye password-field--eye ${this.state.passwordEye ? "" : "show"}`}></i>
                                        </div>
                                        <div className="c-error-message mt-5">
                                            <ErrorMessage name="loginEmail" component="div" />
                                            <ErrorMessage name="loginPassword" component="div" />
                                            {this.state.error ? <div>Invalid credentials</div> : null}
                                        </div>
                                        <div className="mt-25">
                                            <button
                                                id='gtm-login-in'
                                                className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-12 letter-spacing-15 font-uppercase mb-28 ${form.isSubmitting ? "submitting" : ""}`}
                                                type="submit"
                                                disabled={form.isSubmitting || !form.isValid}
                                            >
                                                Log in
                                                <SpiracleLogoSmall />
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            <Link id='gtm-login-page-forgot-password' className="c-btn c-btn__link c-btn__link--forgot-password font-12 margin-center font-secondary letter-spacing-15 display-inline-block" to="/forgot-password">
                                                FORGOT PASSWORD?
                                            </Link>
                                        </div>
                                        <div className="text-center">
                                            <Link id='gtm-login-page-signup' className="c-btn c-btn__link font-12 margin-center font-secondary letter-spacing-15 display-inline-block" to="/sign-up">
                                                CREATE ACCOUNT
                                            </Link>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (user) => dispatch(actionTypes.onSetUser(user)),
        onGetCartItems: (cart) => dispatch(cartActionTypes.onGetCartItems(cart)),
        onGetLibraryItems: (lib, total) => dispatch(libraryActionTypes.onGetLibraryItems(lib, total))
    };
};

const loginWithRouter = withRouter(Login);

export default connect(mapStateToProps, mapDispatchToProps)(loginWithRouter);
