import React, { Component } from 'react';

import Loader from 'shared/Loader/Loader';
import PageMeta from 'shared/PageMeta/PageMeta';
import PaymentProblemImage from '../../../images/payment-problem.webp';
import { connect } from 'react-redux';

class ClaimBook extends Component {

    state = {
        claimSubscription: this.props.location.pathname === '/get-subscription' ? true : false
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/login");
        } else if (this.props.user && (this.props.user.email === window.location.href.split('?email=')[1])) {
            if (this.state.claimSubscription) {
                this.props.history.push("/subscription-details");
            } else {
                this.props.history.push("/library");
            }
        }
    }

    render() {
        let content
        if (this.props.user) {
            content = (
                <>
                    <PageMeta>
                        <title>Claim {this.state.claimSubscription ? 'Subscription' : 'Book'} | Spiracle</title>
                        <link rel="canonical" href={window.location.href} />
                    </PageMeta>
                    <div className="container container__basic-page">
                        <div className="c-confirm-user">
                            <div className="c-confirm-user__get-book-problem">
                                <img src={PaymentProblemImage} alt="get-book-problem" />
                            </div>
                            <div className="c-confirm-user__error text-center">
                                <p>Hi {this.props.user.firstName}, in order to get this {this.state.claimSubscription ? 'subscription' : 'book'} , you need to login or register with this email address: {window.location.href.split('?email=')[1]}</p>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            content = <Loader />
        }
        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user
})

export default connect(mapStateToProps)(ClaimBook)
