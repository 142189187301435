import * as cartActionTypes from '../../../store/actions/cartActions';

import React, { Component } from 'react';

import ApiService from 'services/api.service';
import { StyledCheckoutForm } from 'components/UI/Ecommerce/CheckoutForm/style';
import { connect } from 'react-redux';
import { debounce } from '../../../shared/Functions/Debounce';

class SendAsAGiftForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            message: '',
            emailError: '',
            emailChecked: false,
            invalidEmail: false,
        }
        this.debounceEmail = debounce(this.checkEmail, 400);
        this.debounceMessage = debounce(this.setMessage, 400);
        this.debounceGiftInfo = debounce(this.setGiftInfo, 400);
    }

    setGiftInfo = () => {
        const data = {
            giftEmail: this.state.email,
            giftMessage: this.state.message,
            gift: true
        }
        ApiService.setGiftInfo(this.props.itemUuid, data).catch(
            e => {
                console.log(e);
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.giftCheckboxUpdated) {
            if (this.state.email) {
                if (this.state.emailError) {
                    this.setState({
                        email: '',
                        emailError: '',
                        message: '',
                    })
                } else {
                    this.setState({
                        email: '',
                        emailError: '',
                        message: '',
                    }, () => {
                        this.props.onValidateEmail(false, this.props.index)
                    })
                }
            }
        }
    }

    checkEmail = () => {
        // check if valid email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regex.test(String(this.state.email).toLowerCase())) {
            const data = {
                email: this.state.email
            }
            ApiService.checkEmail(this.props.type, this.props.slug, data).then(
                r => {
                    if (r.data.purchased) {
                        this.setState({
                            emailError: 'Already have this book',
                            emailChecked: false
                        }, () => {
                            this.props.onValidateEmail(false, this.props.index)
                            this.props.emailValid(false)
                        })
                    } else {
                        //if (this.state.emailChecked) {
                            this.setState({
                                emailError: '',
                                emailChecked: true,
                                emailSet: true,
                            }, () => {
                                this.props.onValidateEmail(true, this.props.index);
                                this.props.emailValid(true)
                                this.setGiftInfo();
                            })
                        //}
                    }
                }
            )
        }
    }
    setEmail = e => {
        // check if valid email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e && e.target) {
            this.setState({
                email: e.target.value
            }, () => {
                if(regex.test(String(e.target.value).toLowerCase())) {
                    this.debounceEmail();
                } else {
                    this.props.onValidateEmail(false, this.props.index)
                    this.props.emailValid(false)
                    this.setState({
                        emailChecked: false
                    })
                }
            })
        }
    }
    
    setMessage = e => {
        this.setState({
            message: e.target.value
        }, () => {
            this.debounceGiftInfo();
        })
    }

    handleEmailBlur = e => {
        // check if valid email
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regex.test(String(e.target.value).toLowerCase())) {
            if (!this.state.invalidEmail) {
                this.setState({
                    emailError: 'Invalid email',
                    invalidEmail: true
                }, () => {
                    if (this.state.emailSet) {
                        this.props.onValidateEmail(false, this.props.index)
                        this.props.emailValid(false)
                    }
                })
            } else {
                this.setState({
                    emailError: 'Invalid email'
                })
            }
        } else {
            if (this.state.emailError !== 'Already have this book') {
                this.setState({
                    emailError: '',
                    invalidEmail: false
                }, () => {
                    this.props.emailValid(true)
                })
            }
        }
    }

    handleEmailFocus = e => {
        if (this.state.emailError !== 'Already have this book') {
            this.setState({
                emailError: ''
            })
        }
    }

    render() {     
        return (
            <StyledCheckoutForm> 
                <div>
                    <label>Email to receiver</label>
                    <input  
                        className={`bg-white ${this.state.emailError && 'invalid'}`}
                        type="eMail"
                        name="eMail"
                        id="eMail" 
                        onChange={(e) => this.setEmail(e)}
                        onBlur={(e) => this.handleEmailBlur(e)}
                        onFocus={(e) => this.handleEmailFocus(e)}
                        value={this.state.email}
                        autoComplete="off"
                    />       
                    <div className="c-error-message mt-5">
                        {this.state.emailError ? <div>{this.state.emailError}</div> : null}
                    </div>                                    
                </div>

                <div className="mt-20">
                    <label>Message</label>
                    <textarea 
                        className="bg-white"                        
                        name="Message"
                        id="message"                             
                        onChange={(e) => this.setMessage(e)}
                        value={this.state.message}                 
                    />                                                
                </div>
            </StyledCheckoutForm>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onValidateEmail: (valid, index) => dispatch(cartActionTypes.onValidateEmail(valid, index)),
    }
} 

export default connect(null, mapDispatchToProps)(SendAsAGiftForm);