import * as redemptionActionTypes from '../../store/actions/redemptionActions';
import * as libraryActionTypes from '../../store/actions/libraryActions';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import ApiService from 'services/api.service';
import PageMeta from '../../shared/PageMeta/PageMeta';

import AudioBook from 'components/UI/Redemption/AudioBook/AudioBook';

import { Form, List, Paragraph, ParagraphSmall,  StyledContent, StyledRedemptionContainer, Title } from './style';
import { StyledCheckoutForm } from 'components/UI/Ecommerce/CheckoutForm/style';
import Loader from 'shared/Loader/Loader';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';


class Redemption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioBook: null,
            isLoading: true,
            redemptionCode: '',
            isRedeeming: false,
            is404: this.props.location?.state?.error === 404,
            spisin: this.props.match.params.spisin,
            isLoadingAddToLibrary: false,
        }
    }

    componentDidMount() {
        if (this.props.redemptionAudioBook?.spiSIN !== this.state.spisin) {
            ApiService.getAudioBookBySpisin(this.state.spisin).then(
                r => {
                    this.setState({
                        audioBook: r.data['hydra:member'][0],
                        isLoading: false
                    }, () => {
                        if (!this.state.audioBook) {
                            this.props.history.push('/404')
                        }
                    });

                    this.props.onSetRedemptionAudioBook({redemptionAudioBook: r.data['hydra:member'][0]})
                }
            ).catch((e) => {
                console.log(e)
                this.setState({
                    isLoading: false
                })
                this.props.onRemoveRedemptionAudioBook()
            })
        } else {
            this.setState({
                isLoading: false,
                audioBook: this.props.redemptionAudioBook
            })
        }
    }

    handleSetRedemptionCode = (e) => {
        this.setState({
            redemptionCode: e.target.value.trim()
        })
    }

    handleRedeem = (e) => {
        e.preventDefault();

        this.setState({
            isRedeeming: true,
        })

        ApiService.getRedemptionCode(this.state.redemptionCode).then((r) => {
            this.setState({
                isRedeeming: false,
            })

            if (!r.data.redeemed) {
                if (this.props.user) {
                    this.setState({
                        isLoadingAddToLibrary: true
                    })

                    const data = {
                        audioBook: {
                            spiSIN: this.state.spisin
                        }
                    }

                    ApiService.addToLibraryWithRedemptionCode(this.state.redemptionCode, data).then((r) => {
                        ApiService.getLibrary().then(
                            library => {
                                this.setState({
                                    isLoadingAddToLibrary: false
                                })
                                this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                                this.props.history.push(`/redemption/${this.state.spisin}/welcome`)
                            }
                        ).catch((e) => {
                            console.log(e);
                            this.setState({
                                isLoadingAddToLibrary: false
                            })
                            this.props.history.push(`/redemption/${this.state.spisin}/welcome`)
                        })
                    }).catch((eAdToL) => {
                        console.log(eAdToL)
                        ApiService.getLibrary().then(
                            library => {
                                this.setState({
                                    isLoadingAddToLibrary: false
                                })
                                this.props.onGetLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])

                                if (eAdToL.response.status === 422) {
                                    this.props.history.push({
                                        pathname: `/redemption/${this.state.spisin}/error`,
                                        state: {error: 422}
                                    })
                                }
                            }
                        ).catch((er) => {
                            console.log(er);
                            if (eAdToL.response.status === 422) {
                                this.props.history.push({
                                    pathname: `/redemption/${this.state.spisin}/error`,
                                    state: {error: 422}
                                })
                            }
                        })
                    })
                } else {
                    this.props.history.push(`/redemption/${this.state.spisin}/confirmation`)
                }

                this.props.onSetRedemptionCode({
                    redemptionCode: {
                        code: this.state.redemptionCode,
                        bookshop: r.data.outlet.name
                    }
                })
            } 
             if (r.data.redeemed) {
                this.props.onRemoveRedemptionCode()

                this.props.history.push({
                    pathname: `/redemption/${this.state.spisin}/error`,
                    state: {error: 422}
                })
            }
        }).catch((e) => {
            console.log(e)

            this.setState({
                isRedeeming: false,
            })

            this.props.onRemoveRedemptionCode()

            if (e.response.status === 404) {
                this.props.history.push({
                    pathname: `/redemption/${this.state.spisin}/error`,
                    state: {error: 404}
                })
            }
        })
    }

    render() {
        let content = <Loader />

        if (!this.state.isLoading) {
            content = 
                <StyledRedemptionContainer>
                    <AudioBook data={this.state.audioBook} />

                    <StyledContent center>
                        <Title>Spiracle Audiobooks</Title>
                        <Paragraph>Welcome, fellow bibliophile! Your audiobook adventure awaits.</Paragraph>

                        <ParagraphSmall className='mb-15'>To redeem your audiobook, follow these simple steps:</ParagraphSmall>
                        <List>
                            <li><span>Enter the Redemption Code</span>: Locate the unique code on your card and enter it in the field below.</li>
                            <li><span>Sign In <i className='icon-account font-14 font-black'></i> or Create an Account</span>.</li>
                            <li><span>Open the Library <i className='icon-library font-14 font-black'></i></span> : Your new audiobook will be automatically added to your Library</li>
                        </List>

                        <ParagraphSmall className='mt-30'>
                            Your online Library stores all your audiobooks. You can listen to them via the online Spiracle Player, or download the Spiracle Player App for <span>iOS or Android</span>.
                        </ParagraphSmall>

                        <Form>
                            <StyledCheckoutForm>
                                <label>Redemption Code</label>
                                <input className="bg-white" value={this.state.redemptionCode} onChange={(e) => this.handleSetRedemptionCode(e)} type="text" name="redemptionCode" id="redemptionCode" />

                                <button
                                    id='gtm-redeem-your-audiobook'
                                    disabled={!this.state.redemptionCode} 
                                    className={`c-btn c-btn__black c-btn__ghost--square font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30 ${this.state.isRedeeming || this.state.isLoadingAddToLibrary ? 'submitting' : ''}`} 
                                    onClick={(e) => this.handleRedeem(e)}>
                                        Redeem your audiobook
                                        <SpiracleLogoSmall style={{ filter: 'invert()' }} />
                                </button>
                            </StyledCheckoutForm>
                        </Form>

                        <ParagraphSmall className='mt-50'><span>Need assistance?</span> Just email <a href="mailto:support@spiracleaudiobooks.com">support@spiracleaudiobooks.com</a>.</ParagraphSmall>
                    </StyledContent>
                </StyledRedemptionContainer>
        }
        
        return (
            <>
                <PageMeta>
                    <title>Redemption | Spiracle</title>
                    <link rel="canonical" href={window.location.href} />
                </PageMeta>

                {content}
            </>
        )
    }
}

const mapStateToProps = state => ({
    redemptionAudioBook: state.redemptionReducer.redemptionAudioBook,
    user: state.userReducer.user
})

const mapDispatchToProps = dispatch => {
  return {
      onSetRedemptionCode: (data) => dispatch(redemptionActionTypes.onSetRedemptionCode(data)),
      onRemoveRedemptionCode: () => dispatch(redemptionActionTypes.onRemoveRedemptionCode()),
      onSetRedemptionAudioBook: (data) => dispatch(redemptionActionTypes.onSetRedemptionAudioBook(data)),
      onRemoveRedemptionAudioBook: () => dispatch(redemptionActionTypes.onRemoveRedemptionAudioBook()),
      onGetLibraryItems: (libraryItems, totalLibraryItems) => dispatch(libraryActionTypes.onGetLibraryItems(libraryItems, totalLibraryItems)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redemption);