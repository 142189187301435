import React, { Component } from "react";

export default class SkeletonAudioBooks extends Component {
  render() {
    return (
      <div className="c-skeleton mb-50">
        {this.props.withFilter ? <div className="c-skeleton__audio-book--listing h-flex h-flex-between">
          <div className="c-skeleton__audio-book--listing--bookNumber mt-50"></div>
          {this.props.withSort && <div className="c-skeleton__audio-book--listing--sort"></div>}
          <div className="c-skeleton__audio-book--listing--filter"></div>
        </div> : null}
        <div className="c-skeleton__audio-book-single--related-items mt-30 h-flex h-flex-between">
          <div className="c-skeleton__audio-book-single--related-item">
            <div className="c-skeleton__audio-book-single--image"></div>
            <div className="c-skeleton__audio-book-single--wrapper">
              <div className="c-skeleton__audio-book-single--title"></div>
              <div className="c-skeleton__audio-book-single--author"></div>
              <div className="c-skeleton__audio-book-single--price"></div>
            </div>
          </div>
          <div className="c-skeleton__audio-book-single--related-item">
            <div className="c-skeleton__audio-book-single--image"></div>
            <div className="c-skeleton__audio-book-single--wrapper">
              <div className="c-skeleton__audio-book-single--title"></div>
              <div className="c-skeleton__audio-book-single--author"></div>
              <div className="c-skeleton__audio-book-single--price"></div>
            </div>
          </div>
          <div className="c-skeleton__audio-book-single--related-item">
            <div className="c-skeleton__audio-book-single--image"></div>
            <div className="c-skeleton__audio-book-single--wrapper">
              <div className="c-skeleton__audio-book-single--title"></div>
              <div className="c-skeleton__audio-book-single--author"></div>
              <div className="c-skeleton__audio-book-single--price"></div>
            </div>
          </div>
          <div className="c-skeleton__audio-book-single--related-item">
            <div className="c-skeleton__audio-book-single--image"></div>
            <div className="c-skeleton__audio-book-single--wrapper">
              <div className="c-skeleton__audio-book-single--title"></div>
              <div className="c-skeleton__audio-book-single--author"></div>
              <div className="c-skeleton__audio-book-single--price"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
