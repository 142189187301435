import React, { Component } from 'react'

export default class SkeletonAudioBookSingle extends Component {
    render() {
        return (
            <div className="c-skeleton c-skeleton__audio-book-single">
                <div className="c-skeleton__audio-book-single--top-section">
                    <div className="c-skeleton__audio-book-single--top h-flex h-flex-between">
                        <div className="c-skeleton__audio-book-single--left">
                            <div className="c-skeleton__audio-book-single--image-book"></div>
                            <div className="c-skeleton__audio-book-single--play-btn-desktop"></div>
                        </div>
                        <div className="c-skeleton__audio-book-single--play-btn-mobile"></div>
                        <div className="c-skeleton__audio-book-single--details">
                            <div className="c-skeleton__audio-book-single--genre"></div>
                        </div>
                        <div className="c-skeleton__audio-book-single--summary">
                            <div className="c-skeleton__audio-book-single--price-p"></div>
                        </div>
                    </div>
                </div>

                <div className="c-skeleton__audio-book-single--related-title mt-100 margin-center"></div>

                <div className="c-skeleton__audio-book-single--related-items mt-30 h-flex h-flex-between">
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                </div>

                <div className="c-skeleton__audio-book-single--spiracle-extras-title mt-100 margin-center"></div>
                <div className="c-skeleton__audio-book-single--spiracle-extras-image mt-50 margin-center"></div>
                <div className="c-skeleton__audio-book-single--spiracle-extras-content mt-50 margin-center"></div>
            </div>
        )
    }
}