import React, { Component } from 'react'

export default class SkeletonOrderItem extends Component {
    render() {
        let item = <div className="c-skeleton__order-item--content h-flex">
                        <div className="c-skeleton__order-item--image"></div>

                        <div className="full-width">
                            <div className="c-skeleton__order-item--title"></div>
                            <div className="c-skeleton__order-item--author"></div>
                            <div className="c-skeleton__order-item--price"></div>
                        </div>
                    </div>
                    
        let order = <div className="c-skeleton__order-item mb-50">
                        {!this.props.noTop && <div className="c-skeleton__order-item--top h-flex h-flex-between">
                                                <div className="c-skeleton__order-item--number"></div>
                                                <div className="c-skeleton__order-item--date"></div>
                                            </div>}

                        {item}

                        {this.props.items > 1 && item}

                        {this.props.items > 2 && item}
                    </div>
        return (
            <>
                {order}

                {this.props.orders > 1 && order}

                {this.props.orders > 2 && order}
            </>
        )
    }
}