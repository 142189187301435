import styled from "styled-components/macro";

export const Heading = styled.div`
    p {
        letter-spacing: 3px;
        color: #721b00;
        margin-top: 50px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            margin-top: 30px;
        }
    }

    h2 {
        font-size: 50px;
        line-height: 1;
        letter-spacing: 0;
        text-transform: none;
        margin: 0 0 43px;
        font-family: 'JJannon';
        
        ${(props) => props.theme.breakpoints.down('sm')} {
            font-size: 36px;
            margin: 0 0 25px;
        }
    }
`;