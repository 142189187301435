import React, { Component } from 'react'

export default class SkeletonAudioBookSingle extends Component {
    render() {
        return (
            <div className="c-skeleton__publisher margin-center">
                <div className="c-skeleton__publisher--chip margin-center"></div>
                <div className="c-skeleton__publisher--title"></div>
                <div className="c-skeleton__publisher--about margin-center"></div>
                <div className="c-skeleton__publisher--link"></div>
                <div className="c-skeleton__audio-book-single--related-items mt-30 h-flex h-flex-between">
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                    <div className="c-skeleton__audio-book-single--related-item-book"></div>
                </div>
            </div>
        )
    }
}