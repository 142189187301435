import * as actionTypes from '../actions/discountActions';

const initialState = {
    relativeDiscount: 0,
    discountCode: ''
}

const discountReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADD_DISCOUNT:
            return {
                ...state,
                relativeDiscount: action.relativeDiscount,
                discountCode: action.discountCode
            }
        case actionTypes.REMOVE_DISCOUNT:
            return {
                ...state,
                relativeDiscount: 0,
                discountCode: '',
            }
        default:
            return state;
    }
}

export default discountReducer;